import styled from "styled-components";
import { space, SpaceProps } from "styled-system";

type ControlLabelProps = SpaceProps & {
  required?: boolean;
};

const ControlLabel = styled.label<ControlLabelProps>`
  display: block;
  font-weight: 600;
  font-size: 14px;
  ${space};

  &:after {
    content: '${(props) => (props.required ? " *" : "")}';
    color: ${(props) => props.theme.colors.danger};
  }
`;

ControlLabel.defaultProps = {
  mb: 1,
};

export default ControlLabel;
