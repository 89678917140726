import { UpdateAccountSecuritySpec } from "@oaktyres/model";
import {
  useAccountNew,
  useUpdateDeliveryInfo,
  useUpdateDeliverySecurity,
} from "@oaktyres/queries";
import {
  Box,
  Button,
  Circle,
  Flex,
  Loader,
  Panel,
  PinEntryModal,
  Text,
} from "@oaktyres/ui";
import React, { useState } from "react";
import { IoMdKeypad } from "react-icons/io";
import { useScopedAccount } from "../../components/ScopedAccountProvider";

export const AccountSecurityPane = () => {
  const [scopedAccount] = useScopedAccount();
  const account = useAccountNew(scopedAccount);
  const [enteringPin, setEnteringPin] = useState("");
  const updateAccountSecurity = useUpdateDeliverySecurity();

  if (account.data == null) {
    return (
      <Panel width="100%" p={5}>
        <Loader />
      </Panel>
    );
  }

  const toggleDeliveryPin = () => {
    if (account.data?.requirePinForDelivery) {
      updateAccountSecurity.mutateAsync({
        code: scopedAccount,
        deliveryPin: null,
      });
    } else {
      setEnteringPin("delivery");
    }
  };

  const toggleCollectionPin = () => {
    if (account.data?.requirePinForCollection) {
      updateAccountSecurity.mutateAsync({
        code: scopedAccount,
        collectionPin: null,
      });
    } else {
      setEnteringPin("collection");
    }
  };

  const onPinEntry = async (pin: string) => {
    const spec: UpdateAccountSecuritySpec = {};
    if (enteringPin === "delivery") {
      spec.deliveryPin = pin;
    } else if (enteringPin === "collection") {
      spec.collectionPin = pin;
    }

    setEnteringPin("");
    await updateAccountSecurity.mutateAsync({ code: scopedAccount, ...spec });
  };

  return (
    <Panel width="100%" p={3} style={{ position: "relative" }}>
      {enteringPin !== "" && (
        <PinEntryModal
          confirm
          onCancel={() => setEnteringPin("")}
          onEntry={onPinEntry}
        />
      )}
      {(updateAccountSecurity.isLoading || account.isLoading) && (
        <Loader overlay />
      )}
      <Flex borderRadius={6} border={1} flexDirection="column" p={3} mb={3}>
        <Text fontWeight={600} mb={2}>
          Account Security
        </Text>
        <Text mb={1}>
          If you would like to have control over who within your company can
          place orders on Oak Portal - while still allowing them to browse the
          store to check pricing and availability - you can activate a PIN which
          is required to complete checkout. The PIN will only be required for
          users who are logged in using your company’s account code, rather than
          an email address.
        </Text>
        <Text>
          You can choose to set the PIN requirement on orders that will be
          delivered, collected from one of our depots, our both. This allows for
          employees to be given different ordering rights, if required. If PIN
          is activated for both delivery and collection orders, you may enter
          the same PIN for both.
        </Text>
      </Flex>
      <Flex
        borderRadius={6}
        border={1}
        p={3}
        mb={3}
        justifyContent="space-between"
        alignItems={"center"}
      >
        <Flex alignItems={"center"}>
          <Circle
            icon={IoMdKeypad}
            color={account.data?.requirePinForDelivery ? "primary" : undefined}
            mr={2}
          />
          <Box>
            <Text fontWeight={600} lineHeight="1.2">
              Delivery Orders
            </Text>
            <Text fontSize={1} fontWeight={600} color="grey2">
              Require a PIN for delivery orders
            </Text>
          </Box>
        </Flex>
        <Button onClick={toggleDeliveryPin}>
          {account.data?.requirePinForDelivery ? "Remove" : "Enable"}
        </Button>
      </Flex>
      <Flex
        borderRadius={6}
        border={1}
        p={3}
        justifyContent="space-between"
        alignItems={"center"}
      >
        <Flex alignItems={"center"}>
          <Circle
            icon={IoMdKeypad}
            color={
              account.data?.requirePinForCollection ? "primary" : undefined
            }
            mr={2}
          />
          <Box>
            <Text fontWeight={600} lineHeight="1.2">
              Collection Orders
            </Text>
            <Text fontSize={1} fontWeight={600} color="grey2">
              Require a PIN for collection orders
            </Text>
          </Box>
        </Flex>
        <Button onClick={toggleCollectionPin}>
          {account.data?.requirePinForCollection ? "Remove" : "Enable"}
        </Button>
      </Flex>
    </Panel>
  );
};
