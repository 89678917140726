import {
  ProductDto,
  StockHistoryEntryDto,
  TyreSearchPayload,
} from "@oaktyres/model";
import { BasketItem } from "@oaktyres/queries";
import axios from "axios";
import React from "react";
import { useEffect, useState } from "react";
import Button from "./Button";
import Loader from "./Loader";
import Modal from "./Modal";
import Text from "./Text";
import { ProductModal } from "./ProductModal";
import { TyreModal } from "./TyreModal";

export type UniversalProductModalProps = {
  stockCode: string;
  accountCode?: string;
  showHistory?: "no" | "yes" | "locked";
  onClose: () => void;
  basketItems: BasketItem[];
  onAddToBasket: (stockCode: string, count: number, supply: string) => boolean;
  onOrderHistorySelect?: (item: StockHistoryEntryDto) => void;
};

type DataType = ProductDto | TyreSearchPayload;

const isProduct = (x: DataType): x is ProductDto => "attributes" in x;

export const UniversalProductModal = ({
  stockCode,
  onClose,
  onAddToBasket,
  ...rest
}: UniversalProductModalProps) => {
  const [data, setData] = useState<DataType | null>(null);
  const [error, setError] = useState<string>("");

  useEffect(() => {
    axios
      .get<DataType>(`/api/v2/stock/${stockCode}`)
      .then(({ data }) => {
        setData(data);
      })
      .catch((err) => {
        if (axios.isAxiosError(err) && err.response?.status === 404) {
          setError("Stock item not yet supported");
        }
      });
  }, [stockCode]);

  if (error !== "") {
    return (
      <Modal shown>
        <Text mb={2} fontWeight={600}>
          {error}
        </Text>
        <Button onClick={onClose}>Ok</Button>
      </Modal>
    );
  } else if (data == null) {
    return (
      <Modal shown>
        <Loader />
      </Modal>
    );
  } else if (isProduct(data)) {
    return (
      <ProductModal
        productId={data.id}
        variantId={data.variantId ?? null}
        onClose={onClose}
        onAddToBasket={(stockCode, count) =>
          onAddToBasket(stockCode, count, "delivery")
        }
        {...rest}
      />
    );
  } else {
    return (
      <TyreModal
        stockCode={stockCode}
        onClose={onClose}
        onAddToBasket={(count, supply) =>
          onAddToBasket(stockCode, count, supply)
        }
        {...rest}
      />
    );
  }
};
