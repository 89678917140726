export interface OakThemeColorSet {
  body: string;
  primary: string;
  primaryLight: string;
  primaryDark: string;
  background: string;
  grey1: string;
  grey2: string;
  grey3: string;
  grey4: string;
  grey5: string;
  grey6: string;
  accent: string;
  positive: string;
  warning: string;
  danger: string;
  dangerLight: string;
  dangerDark: string;
  darkBlue: string;
  purple: string;
  cyan: string;
  gold: string;
  silver: string;
  bronze: string;
  info: string;
  [x: string]: string;
}

type BreakpointList = string[] & {
  sm: string;
  md: string;
  lg: string;
  xl: string;
};

export interface OakTheme {
  baseline: number;
  baseFontSize: number;
  baseLineHeight: number;
  borderRadius: number[];
  breakpoints: BreakpointList;
  shadows: string[];
  borders: string[];
  fonts: {
    body: string;
    heading: string;
    mono: string;
  };
  fontSizes: number[];
  colors: OakThemeColorSet;
  space: number[];
  getSafeColor: (input: string, fallback?: OakColor) => string;
}

const baseline = 6;
const baseFontSize = 16;
const borderRadius = 5;

const calcLineHeight = (size: number) =>
  ((Math.ceil(size / baseline) + 1) * baseline) / size;

// Major third typography
const calcFontsize = (base: number, order: number) =>
  base * Math.pow(1.25, order);

const bp = ["768px", "1024px", "1400px", "1920px"];
const breakpoints: BreakpointList = Object.assign([...bp], {
  sm: bp[0],
  md: bp[1],
  lg: bp[2],
  xl: bp[3],
});

const defaultColors: OakThemeColorSet = {
  body: "#5A5D6F",
  primary: "#3498db",
  primaryLight: "#59b3f0",
  primaryDark: "#2980b9",
  background: "#EEF4F4",
  grey1: "#888",
  grey2: "#aaa",
  grey3: "#ccc",
  grey4: "#eee",
  grey5: "#efefef",
  grey6: "#f9f9f9",
  accent: "#F2AA46",
  positive: "#2ecc71",
  warning: "#e67e22",
  danger: "#e74c3c",
  dangerLight: "#f55e4e",
  dangerDark: "#c0392b",
  purple: "#9b59b6",
  cyan: "#1abc9c",
  gold: "#E4AF18",
  silver: "#9F9F9F",
  bronze: "#B95C00",
  info: "#3174bd",
  darkBlue: "#003c7b",
};

export type OakColor = keyof OakThemeColorSet;

export const getDefaultColors = (): OakThemeColorSet => ({ ...defaultColors });

export const getDefaultTheme = (colors: OakThemeColorSet): OakTheme => ({
  baseline,
  baseLineHeight: calcLineHeight(baseFontSize),
  baseFontSize,
  borderRadius: [0, borderRadius],
  breakpoints,
  shadows: [
    "none",
    "rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px",
    "rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px",
    "rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px",
    "rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px",
  ],
  borders: ["0", `1px solid ${colors.grey4}`, `1px solid ${colors.grey3}`],
  fonts: {
    body: "Lato,Helvetica,sans-serif",
    heading: "Lato,Helvetica,sans-serif",
    mono: "Roboto Mono,monospace",
  },
  fontSizes: [-1.5, -1, 0, 1, 2, 3, 4]
    .map((x) => calcFontsize(baseFontSize, x))
    .map(Math.round),
  colors: colors,
  space: [0, 6, 12, 18, 24, 36, 48, 72, 96],
  getSafeColor: function (input: any, fallback?: OakColor) {
    return (
      this.colors[input] ??
      (CSS.supports("color", input) ? input : this.colors[fallback ?? "grey3"])
    );
  },
});
