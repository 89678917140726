import {
  AccountLedgerItem,
  CurrencyType,
  LedgerReportItem,
} from "@oaktyres/model";
import {
  useAccountNew,
  useBalancesReport,
  useCurrentBalances,
  useLedger,
} from "@oaktyres/queries";
import {
  Box,
  Button,
  Flex,
  formatCurrency,
  Grid,
  Loader,
  Panel,
  Select,
  SortableTableColumn,
  TabList,
  Text,
} from "@oaktyres/ui";
import { SortableTable } from "@oaktyres/ui/dist/components/SortableTable";
import { addMonths, format, fromUnixTime, startOfMonth } from "date-fns";
import { range } from "lodash";
import React, { useMemo, useState } from "react";
import { useEffect } from "react";
import { useScopedAccount } from "../../components/ScopedAccountProvider";
import { CurrencyReportButton } from "./CurrencyReportButton";
import styled from "styled-components";
import opSrc from "../../img/fullpartnership.png";
import { FaClipboardList, FaMedal } from "react-icons/fa";
import { EarningRulesModal } from "./EarningRulesModal";

const OpLogo = styled.img`
  height: 25px;
  display: block;
`;

type TableType = AccountLedgerItem & { balance: number };

export const PartnershipDashboard = () => {
  const [scopedAccount] = useScopedAccount();
  const [active, setActive] = useState<CurrencyType>("gbp");
  const [activeTab, setActiveTab] = useState(0);
  const [showEarningRules, setShowEarningRules] = useState(false);
  const [month, setMonth] = useState(0);
  const account = useAccountNew(scopedAccount);

  const thisMonth = startOfMonth(new Date());

  const activeDate = addMonths(thisMonth, month);

  const ledger = useLedger(
    scopedAccount,
    activeDate.getMonth() + 1,
    activeDate.getFullYear(),
  );

  const currencies = useMemo(
    () =>
      [
        account.data?.rewardPoints.enabled && "rp",
        account.data?.dealerSupportPoints.enabled && "dsp",
        account.data?.businessDevelopmentFund.enabled && "bdf",
      ].filter((x): x is CurrencyType => typeof x === "string"),
    [account.data],
  );

  const balances = useCurrentBalances(scopedAccount);

  const dateOptions = useMemo(() => {
    const start = startOfMonth(new Date());
    return range(0, -24).map((x) => ({
      value: x.toString(),
      label: format(addMonths(start, x), "MMM - yy"),
    }));
  }, []);

  useEffect(() => {
    setActive(currencies[0] ?? "");
  }, [currencies]);

  const cols = useMemo<SortableTableColumn<TableType>[]>(
    () => [
      {
        name: "date",
        label: "Date",
        format: (x) => format(fromUnixTime(x.date), "do MMM"),
      },
      {
        name: "notes",
        label: "Notes",
      },
      {
        name: "invoice",
        label: "Ref",
        format: (x) => x.invoice,
      },
      {
        name: "earned",
        label: "Earned",
        numeric: true,
        format: (x) => (x.earned !== 0 ? formatCurrency(active)(x.earned) : ""),
      },
      {
        name: "spent",
        label: "Spent",
        numeric: true,
        format: (x) => (x.spent !== 0 ? formatCurrency(active)(x.spent) : ""),
      },
      {
        name: "balance",
        label: "Balance",
        hidden: activeTab > 0,
        numeric: true,
        format: (x) => {
          return formatCurrency(active)(x.balance);
        },
      },
    ],
    [active, activeTab],
  );

  const tabOptions = ["All Activity", "Earned", "Spent"];

  const data = useMemo<TableType[]>(
    () =>
      (ledger.data ?? [])
        .filter((x) => x.type === active)
        .filter(
          (x) =>
            activeTab === 0 ||
            (activeTab === 1 && x.earned !== 0) ||
            (activeTab === 2 && x.spent !== 0),
        )
        .sort((a, b) => a.date - b.date)
        .map((x, i, self) => {
          return {
            ...x,
            balance: self
              .slice(0, i + 1)
              .map((x) => x.earned - x.spent)
              .reduce((acc, val) => acc + val, 0),
          };
        }),
    [active, activeTab, ledger.data],
  );

  return (
    <Box width="100%" maxWidth="1600px">
      {showEarningRules && (
        <EarningRulesModal
          accountCode={scopedAccount}
          onClose={() => setShowEarningRules(false)}
        />
      )}
      <Panel p={3} mb={2}>
        <Flex
          justifyContent={["space-between"]}
          alignItems={["stretch", "center"]}
          flexDirection={["column", "row"]}
          style={{ gap: 6 }}
        >
          <Flex alignItems="center">
            <Box mb={"3px"}>
              <OpLogo src={opSrc} />
            </Box>
            <Text mx={1} lineHeight={1} fontSize={3} fontWeight={600}>
              {" "}
              /{" "}
            </Text>
            <Text lineHeight={1} fontSize={3} fontWeight={600}>
              Dashboard
            </Text>
          </Flex>
          <Button icon={FaMedal} onClick={() => setShowEarningRules(true)}>
            Earning Rules
          </Button>
        </Flex>
      </Panel>
      {account.isLoading ? (
        <Loader />
      ) : (
        <>
          <Grid
            gridTemplateColumns={["1fr", "1fr 1fr", "1fr 1fr 1fr 1fr"]}
            gridGap={2}
            mb={3}
          >
            {currencies.map((x) => {
              const c = balances.data?.find((d) => d.type === x);
              if (c == null) {
                return null;
              }
              return (
                <CurrencyReportButton
                  active={x === active}
                  onClick={() => setActive(x)}
                  currentBalance={c.value}
                  currencyType={x}
                  overdraft={
                    x === "dsp"
                      ? (account.data?.dealerSupportPoints.overdraft ?? 0)
                      : 0
                  }
                  spent={c.totalSpend}
                  earned={c.totalEarn}
                />
              );
            })}
          </Grid>
          <Flex alignItems={"center"}>
            <TabList
              options={tabOptions}
              activeIndex={activeTab}
              onSelect={setActiveTab}
            />
            <Select
              ml={[0, 3]}
              value={month.toString()}
              width={["100%", "150px"]}
              onChange={(ev) => setMonth(parseInt(ev.target.value, 10))}
              options={dateOptions}
            />
          </Flex>
          <Panel style={{ position: "relative" }}>
            {ledger.isLoading && <Loader overlay />}
            <SortableTable
              columns={cols}
              data={data}
              emptyMessage="No Items To Show"
              paged
              disableIncrementalRender
              disableSort
              defaultDesc={true}
              pageSize={250}
              rowSearch={(x) => [x.notes, x.invoice].join(" ")}
            />
            <Text textAlign={"center"} color="grey2" fontSize={1} py={2}>
              Points/Funds are calculated at the end of each day
            </Text>
          </Panel>
        </>
      )}
    </Box>
  );
};
