export type ReturnReasonData = {
  code: ReturnReasonCode;
  area: "tread" | "wall" | "bead" | "casing-interior" | "general";
  internalName: string;
  displayName: string;
  neverSold: boolean;
};

export type ReturnReasonCode = (typeof returnReasons)[number]["code"];

export type ReturnReasonArea = (typeof returnReasons)[number]["area"];

export const isValidReturnReasonCode = (
  value: any,
): value is ReturnReasonCode =>
  typeof value === "string" &&
  returnReasons.map((x) => x.code).includes(value as any);

export const tyreAreas: Record<ReturnReasonArea, string> = {
  tread: "Tread",
  bead: "Bead",
  wall: "Wall",
  general: "General",
  "casing-interior": "Casing Interior",
};

export const returnReasons = [
  {
    area: "tread",
    code: "T1",
    internalName: "Rubber separation",
    displayName: "Tread rubber separation",
    neverSold: true,
  },
  {
    area: "tread",
    code: "T2",
    internalName: "Ply separation",
    displayName: "Ply separation",
    neverSold: true,
  },
  {
    area: "tread",
    code: "T3",
    internalName: "Open tread joint",
    displayName: "Open tread joint",
    neverSold: true,
  },
  {
    area: "tread",
    code: "T4",
    internalName: "Lamination",
    displayName: "Tread delamination",
    neverSold: true,
  },
  {
    area: "tread",
    code: "T5",
    internalName: "Heavy tread join",
    displayName: "Uneven tread joint",
    neverSold: true,
  },
  {
    area: "tread",
    code: "T6",
    internalName: "Light stock",
    displayName: "Bubbles or pockets in rubber",
    neverSold: true,
  },
  {
    area: "tread",
    code: "T7",
    internalName: "Cracking at base of grooves",
    displayName: "Cracking at base of grooves",
    neverSold: true,
  },
  {
    area: "tread",
    code: "TX",
    internalName: "",
    displayName: "Other tread issue",
    neverSold: true,
  },
  {
    area: "wall",
    code: "W1",
    internalName: "Rubber separation",
    displayName: "Rubber separation",
    neverSold: true,
  },
  {
    area: "wall",
    code: "W2",
    internalName: "Ply separation",
    displayName: "Ply separation",
    neverSold: true,
  },
  {
    area: "wall",
    code: "W3",
    internalName: "Diagonal splits",
    displayName: "Diagonal split(s)",
    neverSold: true,
  },
  {
    area: "wall",
    code: "W4",
    internalName: "Tread edge lift/splits",
    displayName: "Tread edge lifting or splitting",
    neverSold: true,
  },
  {
    area: "wall",
    code: "W5",
    internalName: "Heavy sidewall join",
    displayName: "Heavy sidewall join",
    neverSold: true,
  },
  {
    area: "wall",
    code: "W6",
    internalName: "Light stock",
    displayName: "Bubbles or pockets in rubber",
    neverSold: true,
  },
  {
    area: "wall",
    code: "W7",
    internalName: "Crazing/cracking",
    displayName: "Crazing or cracking",
    neverSold: true,
  },
  {
    area: "wall",
    code: "W8",
    internalName: "Casing breakup",
    displayName: "Casing breakup",
    neverSold: false,
  },
  {
    area: "wall",
    code: "W9",
    internalName: "Pin hole",
    displayName: "Pin hole",
    neverSold: true,
  },
  {
    area: "wall",
    code: "W10",
    internalName: "Open veneer join",
    displayName: "Open joint",
    neverSold: true,
  },
  {
    area: "wall",
    code: "W11",
    internalName: "",
    displayName: "Bowed sidewall shape",
    neverSold: true,
  },
  {
    area: "wall",
    code: "WX",
    internalName: "",
    displayName: "Other sidewall issue",
    neverSold: true,
  },
  {
    area: "bead",
    code: "B1",
    internalName: "Rubber separation",
    displayName: "Rubber separation",
    neverSold: true,
  },
  {
    area: "bead",
    code: "B2",
    internalName: "Ply separation",
    displayName: "Ply separation",
    neverSold: true,
  },
  {
    area: "bead",
    code: "B3",
    internalName: "Cracking/splitting above bead",
    displayName: "Cracking/splitting above bead",
    neverSold: true,
  },
  {
    area: "bead",
    code: "B4",
    internalName: "Buckled/distorted bead",
    displayName: "Buckled/distorted bead",
    neverSold: true,
  },
  {
    area: "bead",
    code: "B5",
    internalName: "Casing break up (low sidewall)",
    displayName: "Casing break up (low sidewall)",
    neverSold: false,
  },
  {
    area: "bead",
    code: "B6",
    internalName: "Bead wire unwound",
    displayName: "Bead wire not intact",
    neverSold: false,
  },
  {
    area: "bead",
    code: "BX",
    internalName: "",
    displayName: "Other bead issue",
    neverSold: true,
  },
  {
    area: "casing-interior",
    code: "C1",
    internalName: "Liner separation",
    displayName: "Inner Liner Separation",
    neverSold: true,
  },
  {
    area: "casing-interior",
    code: "C2",
    internalName: "Ply separation",
    displayName: "Ply Separation",
    neverSold: true,
  },
  {
    area: "casing-interior",
    code: "C3",
    internalName: "Open ply or liner join",
    displayName: "Open ply or liner joint",
    neverSold: true,
  },
  {
    area: "casing-interior",
    code: "C4",
    internalName: "Exposed casing cords through liner",
    displayName: "Exposed casing cords through liner",
    neverSold: true,
  },
  {
    area: "casing-interior",
    code: "C5",
    internalName: "Heavy ply or liner join",
    displayName: "Uneven ply or liner joint",
    neverSold: true,
  },
  {
    area: "casing-interior",
    code: "C6",
    internalName: "Buckled/distorted casing",
    displayName: "Buckled/distorted casing",
    neverSold: true,
  },
  {
    area: "casing-interior",
    code: "C7",
    internalName: "Ply cords missing",
    displayName: "Ply cords missing",
    neverSold: false,
  },
  {
    area: "casing-interior",
    code: "CX",
    internalName: "",
    displayName: "Other casing issue",
    neverSold: true,
  },
  {
    area: "general",
    code: "G1",
    internalName: "Excessive noise",
    displayName: "Excessive noise",
    neverSold: false,
  },
  {
    area: "general",
    code: "G2",
    internalName: "Foreign matter moulded in tyre",
    displayName: "Foreign matter moulded in tyre",
    neverSold: true,
  },
  {
    area: "general",
    code: "G3",
    internalName: "Damage in transit/storage",
    displayName: "Suspeceted damage in transit/storage",
    neverSold: true,
  },
  {
    area: "general",
    code: "G4",
    internalName: "Out of balance",
    displayName: "Unable to balance",
    neverSold: true,
  },
  {
    area: "general",
    code: "G5",
    internalName: "Low mileage",
    displayName: "Low mileage achieved",
    neverSold: false,
  },
  {
    area: "general",
    code: "G6",
    internalName: "Lack of grip",
    displayName: "Lack of grip",
    neverSold: false,
  },
  {
    area: "general",
    code: "G7",
    internalName: "Moulding fault (lack of time, pressure or temperature).",
    displayName: "Moulding fault (lack of time, pressure or temperature).",
    neverSold: false,
  },
  {
    area: "general",
    code: "GX",
    internalName: "",
    displayName: "Other general issue",
    neverSold: true,
  },
] as const;
