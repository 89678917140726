import { lighten } from "polished";
import React from "react";
import styled from "styled-components";
import Button from "./Button";
import Flex from "./Flex";
import Text from "./Text";

const QtyButton = styled(Button)<{ active: boolean }>`
  padding: 3px;
  width: 24px;
  min-width: 24px;
  flex: 1 1 24px;
  background-color: ${(props) =>
    props.active
      ? props.theme.colors.primary
      : lighten(0.4, props.theme.colors.primary)};
`;

type MinimumQtyFilterProps = {
  value: number;
  onChange: (value: number) => void;
};

export const MinimumQtyFilter = ({
  value,
  onChange,
}: MinimumQtyFilterProps) => {
  return (
    <Flex style={{ gap: 6 }} alignItems="center" minWidth={0}>
      <Text
        fontSize={1}
        color="black"
        mr={2}
        fontWeight={600}
        style={{ whiteSpace: "nowrap", textTransform: "uppercase" }}
      >
        Min Qty Req.
      </Text>
      {[0, 1, 2, 4].map((x) => (
        <QtyButton key={x} onClick={() => onChange(x)} active={value === x}>
          {x}
        </QtyButton>
      ))}
    </Flex>
  );
};
