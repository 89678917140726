import { InvoiceLineResolutionDto } from "@oaktyres/model";
import { Box, IconButton, Modal, PanelHeader, Text } from "@oaktyres/ui";
import { format, fromUnixTime } from "date-fns";
import React from "react";
import { FaTimes } from "react-icons/fa";
import styled from "styled-components";

const Notes = styled.pre`
  border-radius: 6px;
  border: 1px solid ${(props) => props.theme.colors.grey3};
  padding: 6px 12px;
`;

const resolutionTypes = {
  cancel: "Cancelled",
  alternative: "Alternative Product Sent",
  different_qty: "Different Qty Sent",
  honour_price: "Honour Original Price",
  sell_at_current_price: "Sell At Current Price",
  became_avail: "Stock Became Available",
  other: "Other Reason",
} as const;

export type ResolutionModalProps = {
  resolution: InvoiceLineResolutionDto;
  onClose: () => void;
};

export const ResolutionModal = ({
  resolution,
  onClose,
}: ResolutionModalProps) => {
  return (
    <Modal shown p={0} width={500}>
      <PanelHeader justifyContent="space-between">
        <Text fontWeight={500} fontSize={3}>
          Line Resolution
        </Text>
        <IconButton icon={FaTimes} onClick={onClose} color="body" />
      </PanelHeader>
      <Box p={3}>
        <Text>
          <strong>Resolved at: </strong>
          {format(fromUnixTime(resolution.dateAdded), "dd/MM/yyyy p")}
        </Text>
        <Text>
          <strong>Resolution type: </strong>
          {resolutionTypes[resolution.type]}
        </Text>
        {"linkedAdviceNote" in resolution && (
          <>
            <Text>
              <strong>New Advice Note: </strong>
              {resolution.linkedAdviceNote}
            </Text>
          </>
        )}
        {resolution.type === "different_qty" && (
          <>
            <Text>
              <strong>New Advice Note(s): </strong>
              {resolution.linkedAdviceNotes.join(", ")}
            </Text>
          </>
        )}
        {resolution.type === "alternative" &&
          resolution.newStockCode !== "" && (
            <>
              <Text>
                <strong>Replacement Stock Code: </strong>
                {resolution.newStockCode}
              </Text>
            </>
          )}
        {"notes" in resolution && (
          <>
            <Text mt={2}>
              <strong>Notes</strong>
            </Text>
            <Notes>{resolution.notes || "None given"}</Notes>
          </>
        )}
      </Box>
    </Modal>
  );
};
