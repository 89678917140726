import { NewGoodReturnSpec } from "@oaktyres/model";
import { useAccountNew } from "@oaktyres/queries";
import {
  Box,
  Button,
  CheckBox,
  Flex,
  FormInputWrap,
  PanelFooter,
  QuantityInput,
  Text,
  TextArea,
} from "@oaktyres/ui";
import React from "react";
import { useState } from "react";
import { FaChevronRight } from "react-icons/fa";
import { useScopedAccount } from "../ScopedAccountProvider";

export type NewGoodReturnFormProps = {
  stockCode: string;
  adviceNote: string;
  maxQuantity: number;
  onCompletion: (spec: NewGoodReturnSpec) => void;
  onBackOut: () => void;
};

export const NewGoodReturnForm = ({
  stockCode,
  adviceNote,
  maxQuantity,
  onCompletion,
  onBackOut,
}: NewGoodReturnFormProps) => {
  const [accountCode] = useScopedAccount();
  const account = useAccountNew(accountCode);

  const [spec, setSpec] = useState<NewGoodReturnSpec>({
    stockCode,
    adviceNote,
    type: "newgood",
    quantity: 1,
    complaint: "",
  });

  const [chargeConsent, setChargeConsent] = useState(false);
  const [pickupConsent, setPickupConsent] = useState(false);
  const [unusedConsent, setUnusedConsent] = useState(false);

  const [step, setStep] = useState(0);

  const complete = () => {
    if (
      window.confirm("Are you sure you want to submit this return request?")
    ) {
      onCompletion(spec);
    }
  };

  const canAdvance = () => {
    if (step === 0) {
      return spec.complaint.trim() !== "";
    } else {
      return (
        (account.data?.noHandlingCharge || chargeConsent) &&
        pickupConsent &&
        unusedConsent
      );
    }
  };

  const advance = () => {
    if (step === 0) {
      setStep(1);
    } else {
      complete();
    }
  };

  const goBack = () => {
    if (step === 1) {
      setStep(0);
    } else {
      onBackOut();
    }
  };

  return (
    <>
      <Flex p={"30px"} flexDirection="column" alignItems={"center"}>
        {step === 0 ? (
          <>
            <Flex
              border={1}
              borderRadius={6}
              borderColor="grey3"
              width={"100%"}
              p={2}
              justifyContent="space-between"
              alignItems={"center"}
              mb={3}
            >
              <Text fontWeight={600}>Quantity to return</Text>
              <QuantityInput
                value={spec.quantity}
                maxValue={maxQuantity}
                onChange={(quantity) =>
                  setSpec((old) => ({ ...old, quantity }))
                }
              />
            </Flex>
            <FormInputWrap
              label="Reason for Return"
              name="complaint"
              width={"100%"}
            >
              <TextArea
                height={150}
                value={spec.complaint}
                onChange={(ev) =>
                  setSpec({ ...spec, complaint: ev.target.value })
                }
              />
            </FormInputWrap>
          </>
        ) : (
          <Flex
            border={1}
            flexDirection="column"
            p={3}
            borderRadius={6}
            maxWidth="100%"
          >
            <Text fontWeight={600} mb={3}>
              Declaration of Return
            </Text>
            {!account.data?.noHandlingCharge && (
              <Flex alignItems={"center"} width={500} mb={2} maxWidth="100%">
                <CheckBox
                  value={chargeConsent}
                  onChange={(val) => setChargeConsent(val!)}
                  allowNull={false}
                  mr={3}
                />
                <Text>
                  I understand and agree that a charge as stated on the invoice
                  may be applicable to return this item.
                </Text>
              </Flex>
            )}
            <Flex alignItems={"center"} width={500} maxWidth="100%" mb={2}>
              <CheckBox
                value={pickupConsent}
                onChange={(val) => setPickupConsent(val!)}
                allowNull={false}
                mr={3}
              />
              <Text>
                I understand and agree that collection can be any time within 3
                days of the return request being received or at the next
                delivery.
              </Text>
            </Flex>
            <Flex alignItems={"center"} width={500} maxWidth="100%" mb={2}>
              <CheckBox
                value={unusedConsent}
                onChange={(val) => setUnusedConsent(val!)}
                allowNull={false}
                mr={3}
              />
              <Text>
                I declare that the goods are in an unused and resalable
                condition.
              </Text>
            </Flex>
          </Flex>
        )}
      </Flex>
      <PanelFooter justifyContent={"space-between"} p={3}>
        <Button color="grey1" onClick={goBack}>
          Back
        </Button>
        <Button
          onClick={advance}
          icon={FaChevronRight}
          disabled={!canAdvance()}
        >
          {step === 0 ? "Next" : "Submit"}
        </Button>
      </PanelFooter>
    </>
  );
};
