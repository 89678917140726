import { Flex } from "../Flex";
import React, { useState } from "react";
import { FaBars } from "react-icons/fa";
import { useSpringCarousel } from "react-spring-carousel";
import styled from "styled-components";

const Container = styled.div`
  margin: 18px;
  overflow: hidden;
`;

const TyreImage = styled.img`
  max-height: 400px;
  max-width: 100%;
  width: 100%;
  touch-action: none;
  -moz-user-select: none;
  -webkit-user-drag: none;
  user-select: none;
  object-fit: contain;
`;

const ImageButton = styled.button<{ active: boolean }>`
  background-color: ${(props) =>
    props.active ? props.theme.colors.body : props.theme.colors.grey3};
  border-radius: 50%;
  border: 0;
  width: 18px;
  height: 18px;
  margin: 0 3px;
  cursor: pointer;
`;

const EprelButton = styled.button<{ active: boolean }>`
  background-color: ${(props) =>
    props.active ? props.theme.colors.body : props.theme.colors.grey3};
  height: 18px;
  margin: 0 3px;
  border: 0;
  font-size: 11px;
  padding: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 3px;
  cursor: pointer;
  color: ${(props) => (props.active ? "white" : "#333")};

  & svg {
    position: relative;
    margin-right: 3px;
    font-size: 10px;
  }
`;

export type TyreImageWidgetProps = {
  images: string[];
  eprelId: number | null;
};

const eprelImage = (id: number) =>
  `https://eprel.ec.europa.eu/labels/tyres/Label_${id}.svg`;

export const TyreImageWidget = ({ images, eprelId }: TyreImageWidgetProps) => {
  const [index, setIndex] = useState<number>(0);

  const imageUrls = [...images];

  if (eprelId) {
    imageUrls.push(eprelImage(eprelId));
  }

  const isEprel = (imageIndex: number) =>
    eprelId && imageIndex === imageUrls.length - 1;

  const { carouselFragment, slideToItem, useListenToCustomEvent } =
    useSpringCarousel({
      items: imageUrls.map((x) => ({
        id: x,
        renderItem: <TyreImage src={x} />,
      })),
      withLoop: true,
    });

  useListenToCustomEvent((data) => {
    if (data.eventName === "onSlideStartChange") {
      setIndex(data.nextItem.index);
    }
  });

  if (imageUrls.length === 0) {
    return null;
  }

  return (
    <Container>
      {carouselFragment}
      {imageUrls.length > 1 && (
        <Flex alignItems="center" justifyContent="center" mt={3}>
          {imageUrls.map((x, i) =>
            isEprel(i) ? (
              <EprelButton
                key={i}
                onClick={() => slideToItem(x)}
                active={i === index}
              >
                <FaBars />
                Label
              </EprelButton>
            ) : (
              <ImageButton
                key={i}
                onClick={() => slideToItem(x)}
                active={i === index}
              />
            ),
          )}
        </Flex>
      )}
    </Container>
  );
};
