import { CurrencyType } from "@oaktyres/model";

export const currencyTypeNames: Record<CurrencyType, string> = {
  gbp: "GBP",
  rp: "Reward Points",
  dsp: "Dealer Support Points",
  bdf: "Business Dev. Fund",
  cc: "Consumables Credit",
};

export const currencyTypeColors: Record<CurrencyType, string> = {
  gbp: "body",
  rp: "primary",
  dsp: "warning",
  cc: "black",
  bdf: "darkBlue",
};

export const formatCurrency =
  (curr: CurrencyType, includeSuffix: boolean = true) =>
  (value: number) =>
    ["cc", "bdf", "gbp"].includes(curr)
      ? value.toLocaleString("en-GB", { currency: "gbp", style: "currency" })
      : `${Math.round(value).toLocaleString()} ${includeSuffix ? curr.toUpperCase() : ""}`;
