import { ReturnRequestDto, ReturnRequestSpec } from "@oaktyres/model";
import axios from "axios";
import { useMutation, useQueryClient } from "react-query";

export const createReturnRequest = (spec: ReturnRequestSpec) =>
  axios
    .post<ReturnRequestDto>(`/api/v2/returns`, spec)
    .then(({ data }) => data);

export const useCreateReturnRequest = () => {
  const queryClient = useQueryClient();

  const mutation = useMutation(createReturnRequest, {
    onSuccess: () => {
      queryClient.invalidateQueries(["order-history"]);
      queryClient.invalidateQueries(["returns"]);
    },
  });

  return mutation;
};

export const signReturnRequest = (code: string) =>
  axios.put(`/api/v2/returns/${code}/sign`);

export const useSignReturnRequest = () => useMutation(signReturnRequest);
