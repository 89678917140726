import { PaymentDto, payTypeNames } from "@oaktyres/model";
import { usePayments } from "@oaktyres/queries";
import {
  Flex,
  Text,
  formatCurrency,
  Loader,
  Panel,
  SortableTable,
  SortableTableColumn,
  useAuth,
  Alert,
} from "@oaktyres/ui";
import { format, fromUnixTime } from "date-fns";
import React from "react";
import { FaExclamationTriangle, FaSearch } from "react-icons/fa";
import { useScopedAccount } from "../../components/ScopedAccountProvider";

const cols: SortableTableColumn<PaymentDto>[] = [
  {
    name: "date",
    label: "Date",
    format: (x) => format(fromUnixTime(x.date), "dd/MM/yyyy"),
    headingProps: {
      textAlign: "left",
    },
  },
  {
    name: "accountCode",
    label: "Account",
    headingProps: {
      textAlign: "left",
    },
  },
  {
    name: "payType",
    label: "Payment Type",
    headingProps: {
      textAlign: "left",
    },
    format: (x) => payTypeNames[x.payType],
  },
  {
    name: "reference",
    label: "Ref.",
    headingProps: {
      textAlign: "left",
    },
  },
  {
    name: "value",
    label: "Amount",
    format: (x) => formatCurrency("gbp")(x.value),
    numeric: true,
  },
];

export const PaymentsPage = () => {
  const [account] = useScopedAccount();
  const payments = usePayments(account);

  const auth = useAuth();
  const isNotUser = auth.user?.type !== "user";

  if (isNotUser) {
    return (
      <Panel width="100%">
        <Alert icon={FaExclamationTriangle} color="warning" m={2}>
          <Text fontWeight={600} fontSize={2}>
            Payment history is only available to named users.
          </Text>
          <Text fontWeight={500} fontSize={1}>
            Please login using an email address to access your statements.
          </Text>
        </Alert>
      </Panel>
    );
  }

  return (
    <Panel width="100%">
      {payments.isLoading ? (
        <Loader />
      ) : payments.data?.length === 0 ? (
        <Flex
          flexDirection={"column"}
          p={5}
          alignItems="center"
          justifyContent={"center"}
        >
          <Text color="grey3" fontSize={"72px"}>
            <FaSearch />
          </Text>
          <Text fontSize={3} color="grey3" fontWeight={600}>
            No Items to Display
          </Text>
        </Flex>
      ) : (
        <SortableTable columns={cols} data={payments.data ?? []} />
      )}
    </Panel>
  );
};
