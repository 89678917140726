import { SellOutGroupSpec, SellOutListSpec } from "@oaktyres/model";
import {
  arrMove,
  Button,
  Flex,
  FormInput,
  FormInputWrap,
  FormSelect,
  FormToggle,
  Input,
  InputGroup,
  Panel,
  PanelHeader,
  Text,
} from "@oaktyres/ui";
import { omit, set } from "lodash";
import React, { useState } from "react";
import { FaPlusCircle } from "react-icons/fa";
import { CreateGroupingPanel } from "./CreateGroupingPanel";
import { DefaultValueInput } from "./DefaultValueInput";
import { GroupEditor } from "./GroupEditor";
import { GroupsList } from "./GroupsList";

export type SellOutListEditorProps = {
  value: SellOutListSpec;
  onChange: (val: SellOutListSpec) => void;
};

const modifierOpts = [
  {
    value: "percent",
    label: "Percent",
  },
  {
    value: "value",
    label: "Value",
  },
];

const roundingOptions = [
  {
    value: "none",
    label: "No rounding",
  },
  {
    value: "pence",
    label: "Round to pence value",
  },
  {
    value: "multiple",
    label: "Round to multiple",
  },
];

const roundingDirections = [
  {
    value: "up",
    label: "Up",
  },
  {
    value: "down",
    label: "Down",
  },
];

const multipleOptions = [
  {
    value: 0.25,
    label: "£0.25",
  },
  {
    value: 0.5,
    label: "£0.50",
  },
  {
    value: 1.0,
    label: "£1.00",
  },
];

const penceOptions = [
  {
    value: 45,
    label: "45p",
  },
  {
    value: 50,
    label: "50p",
  },
  {
    value: 95,
    label: "95p",
  },
  {
    value: 99,
    label: "99p",
  },
];

const isCatchAll = (group: SellOutGroupSpec) =>
  Object.values(omit(group, ["id", "name", "bands"])).every(
    (x) => x === "" || x == null
  );

export const SellOutListEditor = ({
  value,
  onChange,
}: SellOutListEditorProps) => {
  const [activeGrouping, setActiveGrouping] = useState<number>(-1);

  const setProp = (path: string) => (val: any) => {
    if (val.target) {
      val = val.target.value;
    }

    const data = { ...value };
    set(data, path, val);
    onChange(data);
  };

  const convertRounding = (val: string) => {
    const newVal = parseFloat(val);
    if (Number.isNaN(newVal)) {
      throw new Error();
    }
    return newVal;
  };

  const hasCatchAll = value.groups.some((x) => isCatchAll(x));

  const addNewGrouping = () => {
    const len = value.groups.length;
    onChange({
      ...value,
      groups: [
        ...value.groups,
        {
          name: "New Group",
          brand: null,
          brandTier: null,
          profile: null,
          rim: null,
          section: null,
          lowerSpeed: null,
          upperSpeed: null,
          seasonality: null,
          isCommercial: null,
          isRunflat: null,
          bands: [],
        },
      ],
    });
    setActiveGrouping(len);
  };

  const updateGroup = (group: SellOutGroupSpec) => {
    value.groups[activeGrouping] = group;
    onChange({ ...value });
  };

  const moveGroup = (oldIndex: number, newIndex: number) => {
    value.groups = arrMove(value.groups, oldIndex, newIndex);
    onChange({ ...value });
    if (activeGrouping === oldIndex) {
      setActiveGrouping(newIndex);
    } else if (activeGrouping < oldIndex && activeGrouping >= newIndex) {
      setActiveGrouping(activeGrouping + 1);
    } else if (activeGrouping > oldIndex && activeGrouping <= newIndex) {
      setActiveGrouping(activeGrouping - 1);
    }
  };

  const removeGroup = (index: number) => {
    value.groups = value.groups.filter((_, i) => i !== index);
    if (activeGrouping === index) {
      setActiveGrouping(-1);
    } else if (index < activeGrouping) {
      setActiveGrouping(activeGrouping - 1);
    }
    onChange({ ...value });
  };

  return (
    <>
      <Panel mb={3}>
        <PanelHeader>
          <Text fontWeight={600} fontSize={3}>
            List Details
          </Text>
        </PanelHeader>
        <Flex
          style={{ gap: 12 }}
          flexDirection={["column", "column", "column", "row"]}
        >
          <Flex
            flexDirection={"column"}
            borderRight={[0, 1]}
            p={3}
            pb={[0, 0, 0, 3]}
          >
            <Text fontWeight={600} fontSize={2} mb={3}>
              General Details
            </Text>
            <FormInput
              value={value.name}
              onChange={setProp("name")}
              label="Name"
              width={300}
            />
            <FormToggle
              label="Apply VAT"
              subLabel="Whether or not to apply VAT (before rounding) to prices generated by this list"
              value={value.applyVat}
              onChange={setProp("applyVat")}
              maxWidth={600}
            />
          </Flex>
          <Flex
            flexDirection={"column"}
            borderRight={[0, 1]}
            p={3}
            pb={[0, 0, 0, 3]}
            pt={[0, 0, 0, 3]}
          >
            <Text fontWeight={600} fontSize={2}>
              Final Price Rounding
            </Text>
            <Text fontWeight={600} fontSize={1} color="grey2" mb={2}>
              How a sell out price should be rounded after all other
              modificiations.
            </Text>
            <FormSelect
              label="Rounding Type"
              options={roundingOptions}
              value={value.roundingType}
              onChange={setProp("roundingType")}
              width={300}
            />
            {value.roundingType !== "none" && (
              <>
                <FormSelect
                  label="Rounding Direction"
                  options={roundingDirections}
                  value={value.roundingDirection}
                  width={300}
                  onChange={setProp("roundingDirection")}
                />
                <DefaultValueInput
                  value={value.roundingValue}
                  label={
                    value.roundingType === "multiple"
                      ? "Multiple Value"
                      : "Pence Value"
                  }
                  onChange={setProp("roundingValue")}
                  customConversationFn={convertRounding}
                  defaultCustomValue={
                    value.roundingType === "multiple" ? "2.00" : "25"
                  }
                  options={
                    value.roundingType === "multiple"
                      ? multipleOptions
                      : penceOptions
                  }
                />
              </>
            )}
          </Flex>
          <Flex flexDirection={"column"} p={3} pt={[0, 0, 0, 3]}>
            <Text fontWeight={600} fontSize={2}>
              Global List Modifier
            </Text>
            <Text fontWeight={600} fontSize={1} color="grey2" mb={2}>
              This applies a modifier to all prices within this list if they
              match at least one group.
            </Text>
            <FormSelect
              label="Modifier type"
              options={modifierOpts}
              value={value.globalMarkupType}
              onChange={setProp("globalMarkupType")}
              width={300}
            />
            <FormInputWrap label="Modifier Value">
              <InputGroup
                prefix={value.globalMarkupType === "value" ? "£" : undefined}
                postfix={value.globalMarkupType === "percent" ? "%" : undefined}
                width={300}
              >
                <Input
                  value={value.globalMarkupAmount}
                  onChange={setProp("globalMarkupAmount")}
                />
              </InputGroup>
            </FormInputWrap>
          </Flex>
        </Flex>
      </Panel>
      <Panel>
        <PanelHeader>
          <Text fontWeight={600} fontSize={3}>
            Groups
          </Text>
        </PanelHeader>
        <Flex flexDirection={["column", "row"]}>
          <Flex
            width={["100%", 250]}
            borderRight={1}
            flexDirection="column"
            pb={2}
          >
            <GroupsList
              items={value.groups.map((x) => x.name)}
              activeIndex={activeGrouping}
              onIndexChange={setActiveGrouping}
              onItemMove={moveGroup}
              onItemRemove={removeGroup}
            />
            <Flex
              alignItems={"center"}
              p={3}
              borderTop={1}
              flexDirection="column"
              borderBottom={[1, 0]}
            >
              <Button onClick={addNewGrouping} icon={FaPlusCircle} mb={3}>
                Create New
              </Button>
              <Text textAlign={"center"} color="grey2" fontWeight={600} mb={3}>
                Groups higher in the list are matched first
              </Text>
              {!hasCatchAll && (
                <Text color="warning" fontWeight={600} textAlign="center">
                  This list has no catch-all group and may miss some products.
                </Text>
              )}
            </Flex>
          </Flex>
          <Flex flex={1}>
            {value.groups[activeGrouping] == null ? (
              <CreateGroupingPanel onCreate={addNewGrouping} />
            ) : (
              <GroupEditor
                value={value.groups[activeGrouping]}
                onChange={updateGroup}
              />
            )}
          </Flex>
        </Flex>
      </Panel>
    </>
  );
};
