import React from "react";
import styled from "styled-components";
import { space, layout, SpaceProps, LayoutProps } from "styled-system";
import ControlLabel from "./ControlLabel";
import HelpBlock from "./HelpBlock";

type ContainerProps = SpaceProps & LayoutProps;

export type FormInputWrapProps = ContainerProps & {
  name?: string;
  label: string;
  error?: any;
  children?: React.ReactNode;
  required?: boolean;
  style?: React.CSSProperties;
};

const Container = styled.div<ContainerProps>`
  ${space};
  ${layout};
  max-width: 100%;
`;

Container.defaultProps = {
  mb: 2,
};

const FormInputWrap = ({
  name,
  label,
  error,
  children,
  required,
  ...rest
}: FormInputWrapProps) => {
  return (
    <Container {...rest}>
      {label && (
        <ControlLabel htmlFor={name} required={required}>
          {label}
        </ControlLabel>
      )}
      {children}
      {error && (
        <HelpBlock color="danger" mt={1}>
          {typeof error === "string"
            ? error
            : error.message ||
              (error.type === "required"
                ? "Field is required"
                : "Invalid value")}
        </HelpBlock>
      )}
    </Container>
  );
};

export default FormInputWrap;
