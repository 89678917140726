import {
  Box,
  Button,
  Flex,
  FormInput,
  Loader,
  Panel,
  PanelFooter,
  Pips,
  Text,
} from "@oaktyres/ui";
import React, { useState } from "react";
import { useSpring, animated } from "react-spring";
import { BlueBackground } from "../../components/BlueBackground";
import logoSrc from "../../img/logo.png";
import styled from "styled-components";
import { FaArrowCircleRight } from "react-icons/fa";
import { Redirect, useHistory, useLocation } from "react-router";
import startSrc from "../../img/start.svg";
import invoiceSrc from "../../img/invoice.svg";
import noAccessSrc from "../../img/noaccess.svg";
import passwordSrc from "../../img/password.svg";
import { useSpringCarousel } from "react-spring-carousel";
import {
  confirmEnrollmentChallenge,
  getEnrollmentChallenge,
  resetPassword,
} from "@oaktyres/queries";
import {
  EnrollmentChallengeDto,
  EnrollmentChallengeResponseDto,
} from "@oaktyres/model";
import { ChallengeOptionSelect } from "./ChallengeOptionSelect";

const AniPanel = animated(Panel);

const Logo = styled.img`
  height: 50px;
`;

const Image = styled.img`
  display: none;
  width: 48%;
  margin-right: 24px;

  @media (min-width: ${(props) => props.theme.breakpoints[1]}) {
    display: block;
  }
`;

const GetStartedPanel = () => (
  <Flex
    flexDirection={["column", "row"]}
    justifyContent="center"
    alignItems="center"
    width={"100%"}
    px={[3, "150px"]}
    style={{ gap: 18 }}
  >
    <Image src={startSrc} />
    <Box>
      <Text fontWeight={600} fontSize={4} mb={3}>
        Getting Started
      </Text>
      <Text mb={1}>
        This is your first time logging into the Oak Portal. So we need to
        enroll you first.
      </Text>
      <Text mb={1}>
        This process is simple and will only take a minute. You will need a copy
        of{" "}
        <strong style={{ fontWeight: 600 }}>
          any invoice issued by Oak Tyres to your account code
        </strong>{" "}
        to complete the process.
      </Text>
      <Text>
        Click <strong style={{ fontWeight: 600 }}>Continue</strong> to get
        started
      </Text>
    </Box>
  </Flex>
);

const ForgotPasswordPanel = () => (
  <Flex
    flexDirection="row"
    justifyContent="center"
    alignItems="center"
    width={"100%"}
    px={[3, "150px"]}
    style={{ gap: 18 }}
  >
    <Image src={startSrc} />
    <Box>
      <Text fontWeight={600} fontSize={4} mb={3}>
        Forgot Your Password?
      </Text>
      <Text mb={1}>
        If you've forgotten your password you can easily and quickly reset it.
      </Text>
      <Text mb={1}>
        This process is simple and will only take a minute. You will need a copy
        of{" "}
        <strong style={{ fontWeight: 600 }}>
          any invoice issued by Oak Tyres to your account code
        </strong>{" "}
        to complete the process.
      </Text>
      <Text>
        Click <strong style={{ fontWeight: 600 }}>Continue</strong> to get
        started
      </Text>
    </Box>
  </Flex>
);

export const EnrollmentPage = () => {
  const params = new URLSearchParams(useLocation().search);
  const code = params.get("code");
  const forgot = params.get("forgot") === "1";
  const history = useHistory();
  const [invoiceNumber, setInvoiceNumber] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [page, setPage] = useState(0);
  const [invoiceNumberErr, setInvoiceNumberErr] = useState<string | null>(null);
  const [challenge, setChallenge] = useState<EnrollmentChallengeDto | null>(
    null,
  );
  const [challengeResponse, setChallengeResponse] =
    useState<EnrollmentChallengeResponseDto | null>(null);
  const [selectedOption, setSelectedOption] = useState<number | null>(null);
  const [password, setPassword] = useState<string>("");
  const [confirmPassword, setConfirmPassword] = useState<string>("");
  const [passwordErr, setPasswordErr] = useState<string | null>(null);
  const [confirmPasswordErr, setConfirmPasswordErr] = useState<string | null>(
    null,
  );

  const restart = () => {
    history.go(0);
  };

  const { carouselFragment, slideToItem, useListenToCustomEvent } =
    useSpringCarousel({
      items: [
        {
          id: "1",
          renderItem: forgot ? <ForgotPasswordPanel /> : <GetStartedPanel />,
        },
        {
          id: "2",
          renderItem: (
            <Flex
              flexDirection={["column", "row"]}
              justifyContent="center"
              alignItems="center"
              width={"100%"}
              px={[3, "150px"]}
              style={{ gap: 18 }}
            >
              <Image src={invoiceSrc} />
              <Box>
                {loading && <Loader overlay />}
                <Text fontWeight={600} fontSize={4} mb={3}>
                  Find an Invoice
                </Text>
                <Text mb={1}>
                  Enter an invoice number from any invoice received by Oak
                  Tyres. This will be in the form{" "}
                  <strong style={{ fontWeight: 600 }}>IN12345678</strong>
                </Text>
                <FormInput
                  value={invoiceNumber}
                  onChange={(ev) => setInvoiceNumber(ev.target.value)}
                  label="Invoice Number"
                  error={invoiceNumberErr}
                />
                <Text>
                  Click <strong style={{ fontWeight: 600 }}>Continue</strong>{" "}
                  when you've entered your invoice number.
                </Text>
              </Box>
            </Flex>
          ),
        },
        {
          id: "3",
          renderItem: (
            <Flex
              flexDirection={["column", "row"]}
              justifyContent="center"
              alignItems="center"
              width={"100%"}
              px={[3, "150px"]}
              style={{ gap: 18 }}
            >
              <Flex maxWidth={600} flexDirection="column" alignItems={"center"}>
                {loading && <Loader overlay />}
                <Box maxWidth={500}>
                  <Text fontWeight={600} fontSize={4} mb={3} textAlign="center">
                    Select an Option
                  </Text>
                  <Text mb={1}>
                    Select the option below that <strong>DOES</strong> appear on
                    the invoice.
                  </Text>
                  <Text mb={3}>
                    Click <strong style={{ fontWeight: 600 }}>Continue</strong>{" "}
                    when you've made your selection.
                  </Text>
                </Box>
                <ChallengeOptionSelect
                  options={challenge?.options ?? []}
                  selected={selectedOption}
                  onChange={setSelectedOption}
                />
              </Flex>
            </Flex>
          ),
        },
        {
          id: "4",
          renderItem: (
            <Flex
              flexDirection={["column", "row"]}
              justifyContent="center"
              alignItems="center"
              width={"100%"}
              px={[3, "150px"]}
              style={{ gap: 18 }}
            >
              {challengeResponse == null ? null : challengeResponse.failed ? (
                <>
                  <Image src={noAccessSrc} />
                  <Box>
                    {loading && <Loader overlay />}
                    <Text fontWeight={600} fontSize={4} mb={3}>
                      That's not quite right...
                    </Text>
                    <Text mb={1}>
                      Unfortunately you've answered incorrectly and haven't been
                      granted access right now.
                    </Text>
                    <Text mb={3}>
                      You can try again but you'll have to use a different
                      invoice. Click below to start again.
                    </Text>
                    <Button color="primary" onClick={restart}>
                      Restart
                    </Button>
                  </Box>
                </>
              ) : (
                <>
                  <Image src={passwordSrc} />
                  <Box>
                    {loading && <Loader overlay />}
                    <Text fontWeight={600} fontSize={4} mb={3}>
                      Set a new password
                    </Text>
                    <Text mb={1}>
                      Thanks. You can now set a new password for your account.
                      Please enter it below.
                    </Text>
                    <FormInput
                      value={password}
                      onChange={(ev) => setPassword(ev.target.value)}
                      label="Password"
                      type="password"
                      error={passwordErr}
                    />
                    <FormInput
                      value={confirmPassword}
                      type="password"
                      onChange={(ev) => setConfirmPassword(ev.target.value)}
                      label="Confirm Password"
                      error={confirmPasswordErr}
                    />
                    <Text>
                      Click{" "}
                      <strong style={{ fontWeight: 600 }}>Continue</strong> when
                      you've entered your new password.
                    </Text>
                  </Box>
                </>
              )}
            </Flex>
          ),
        },
        {
          id: "5",
          renderItem: (
            <Flex
              flexDirection="row"
              justifyContent="center"
              alignItems="center"
              width={"100%"}
              px={[3, "150px"]}
              style={{ gap: 18 }}
            >
              <Image src={invoiceSrc} />
              <Box>
                <Text fontWeight={600} fontSize={4} mb={3}>
                  Thank you.
                </Text>
                <Text mb={1}>
                  Your password has been set and your account has been created.
                </Text>
                <Text>
                  Click <strong style={{ fontWeight: 600 }}>Continue</strong> to
                  return to the login page where you can login with your new
                  credentials.
                </Text>
              </Box>
            </Flex>
          ),
        },
      ],
      withLoop: false,
      disableGestures: true,
    });

  useListenToCustomEvent((data) => {
    if (data.eventName === "onSlideStartChange") {
      setPage(data.nextItem.index);
    }
  });

  const panelStyles = useSpring({
    from: {
      y: 50,
      opacity: 0,
    },
    y: 0,
    opacity: 1,
  });

  if (code == null || code.length === 0) {
    return <Redirect to="/" />;
  }

  const getChallenge = async () => {
    setInvoiceNumberErr(null);
    setLoading(true);
    setSelectedOption(null);
    const challenge = await getEnrollmentChallenge(code, invoiceNumber);

    if (challenge == null) {
      setInvoiceNumberErr(
        `Invalid invoice number for account ${code.toUpperCase()}.`,
      );
    } else if (challenge.respondedOn != null) {
      setInvoiceNumberErr("This invoice has already been tried.");
    } else {
      setChallenge(challenge);
      slideToItem(2);
    }

    setLoading(false);
  };

  const confirmChallenge = async () => {
    setLoading(true);
    const response = await confirmEnrollmentChallenge(
      code,
      challenge!.id,
      challenge!.options[selectedOption!].stockCode,
    );

    setChallengeResponse(response);
    setLoading(false);
    slideToItem(3);
  };

  const doResetPassword = async () => {
    setPasswordErr(null);
    setConfirmPasswordErr(null);

    if (password.length < 8) {
      setPasswordErr("Must be at least 8 characters");
      return;
    }

    if (confirmPassword !== password) {
      setConfirmPasswordErr("Passwords must match");
      return;
    }

    setLoading(true);
    const result = await resetPassword(
      code,
      challengeResponse!.resetCode!,
      password,
    );

    setLoading(false);

    if (!result) {
      window.alert(
        "Sorry, there was a problem resetting your password. Contact support.",
      );
      restart();
    } else {
      slideToItem(4);
    }
  };

  const goBack = () => {
    slideToItem(page - 1);
  };

  const advance = () => {
    if (page === 0) {
      slideToItem(1);
    } else if (page === 1) {
      getChallenge();
    } else if (page === 2) {
      confirmChallenge();
    } else if (page === 3) {
      doResetPassword();
    } else if (page === 4) {
      history.push(`/login?code=${code}`);
    }
  };

  const canContinue = (() => {
    if (loading) {
      return false;
    }

    if (page === 0 || page === 4) {
      return true;
    }

    if (page === 1 && invoiceNumber.trim().length > 0) {
      return true;
    }

    if (page === 2 && selectedOption != null) {
      return true;
    }

    if (page === 3 && password !== "") {
      return true;
    }

    return false;
  })();

  const canGoBack = !loading && page !== 0 && page !== 3 && page !== 4;

  return (
    <BlueBackground>
      <AniPanel
        width={1200}
        maxWidth="100%"
        height={["auto", 500, 800]}
        p={0}
        border={0}
        m={3}
        style={panelStyles}
      >
        <Flex p={[3, 5]} pb={5}>
          <Logo src={logoSrc} />
        </Flex>
        <Flex
          flex={1}
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          overflow="hidden"
        >
          {carouselFragment}
          <Pips count={5} active={page} mt={5} mb={5} />
        </Flex>
        <PanelFooter p={3} height="auto">
          <Flex justifyContent="space-between" width="100%">
            <Button color="grey1" large disabled={!canGoBack} onClick={goBack}>
              Back
            </Button>
            <Button
              color="primary"
              large
              icon={FaArrowCircleRight}
              disabled={!canContinue}
              onClick={advance}
            >
              Continue
            </Button>
          </Flex>
        </PanelFooter>
      </AniPanel>
    </BlueBackground>
  );
};
