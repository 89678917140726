import { IStockItem } from "@oaktyres/queries";
import React, { ComponentProps } from "react";
import styled from "styled-components";
import { LayoutProps, layout } from "styled-system";
import defaultTyreSrc from "../img/defaulttyre.svg";

const TyreImageContainer = styled.div<LayoutProps>`
  background-color: white;
  box-shadow: ${(props) => props.theme.shadows[1]};
  min-width: 0;
  height: 130px;
  aspect-ratio: 1;
  overflow: hidden;
  padding: 12px;
  align-items: center;
  justify-content: center;
  display: flex;
  border-radius: 6px;
  margin-right: 18px;
  position: relative;
  ${layout};
`;

const DefaultTyreImage = styled.img`
  position: absolute;
  top: 24px;
  width: 95%;
  max-width: 100%;
  opacity: 0.3;
`;

const TyreImage = styled.img`
  position: absolute;
  top: 18px;
  max-width: 100%;
  width: 95%;
`;

const ProductImage = styled.img`
  max-width: 100%;
  max-height: 120%;
  object-fit: contain;
`;

DefaultTyreImage.defaultProps = {
  src: defaultTyreSrc,
};

export type StockImageProps = {
  item: IStockItem;
} & ComponentProps<typeof TyreImageContainer>;

export const StockImage = ({ item, ...rest }: StockImageProps) => {
  return (
    <TyreImageContainer {...rest}>
      {item.images.length > 0 ? (
        item.type === "tyre" ? (
          <TyreImage src={process.env.REACT_APP_STATIC_ROOT + item.images[0]} />
        ) : (
          <ProductImage
            src={process.env.REACT_APP_STATIC_ROOT + item.images[0]}
          />
        )
      ) : (
        <DefaultTyreImage />
      )}
    </TyreImageContainer>
  );
};
