import styled from "styled-components";

import { typography, TypographyProps, space, SpaceProps } from "styled-system";

export const LinkButton = styled.button<TypographyProps & SpaceProps>`
  background: none;
  border: none;
  display: inline-flex;
  align-items: center;
  font-size: inherit;
  text-align: left;
  color: ${(props) => props.theme.colors.primary};
  ${typography};
  ${space};

  &:hover {
    cursor: pointer;
    text-decoration: underline;
  }

  svg {
    margin-right: 6px;
  }
`;
