import styled from "styled-components";
import { space, SpaceProps } from "styled-system";

export const Table = styled.table<SpaceProps>`
  width: 100%;
  margin-bottom: 18px;
  border-collapse: collapse;

  td,
  th {
    padding: 4px 10px;
    font-size: 11px;
    font-variant-numeric: tabular-num;
    text-align: left;
  }

  th {
    color: ${(props) => props.theme.colors.grey1};
    border-bottom: 1px solid ${(props) => props.theme.colors.grey4};
    font-weight: 600;
  }

  @media (min-width: 768px) {
    td,
    th {
      font-size: 12px;
    }
  }

  tr:hover {
    th,
    td {
      background-color: ${(props) => props.theme.colors.grey6};
    }
  }

  td.right,
  th.right {
    text-align: right;
  }

  td.center,
  th.center {
    text-align: center;
  }

  .numeric {
    text-align: right;
    white-space: nowrap;
  }

  @media (max-width: 768px) {
    .desktop-only {
      display: none;
    }
  }

  ${space}
`;
