import {
  AccountDto,
  AccountFinancialsDto,
  AccountOwnershipDto,
  AccountUserDto,
  ContactDto,
  ContactSpec,
  EnrollmentChallengeDto,
  EnrollmentChallengeResponseDto,
  EnrollmentStatusDto,
  GetAccountPayload,
  GetContactsPayload,
} from "@oaktyres/model";
import { AccountPatch } from "@oaktyres/model/dist/api/GetAccountPayload";
import axios from "axios";
import { useMutation, useQueries, useQuery, useQueryClient } from "react-query";

export const setDefaultDeliveryAddress = (
  accountCode: string,
  addressId: number | null,
) =>
  axios.put(`/api/v2/accounts/${accountCode}/addresses`, {
    defaultAddressId: addressId,
  });

export const getAccountEnrollmentStatus = async (
  accountCode: string,
): Promise<EnrollmentStatusDto | null> => {
  try {
    return await axios
      .get<EnrollmentStatusDto>(
        `/api/v2/accounts/${accountCode}/enrollment-status`,
      )
      .then(({ data }) => data);
  } catch (err) {
    if (axios.isAxiosError(err) && err.response?.status === 404) {
      return null;
    } else {
      throw err;
    }
  }
};

export const getEnrollmentChallenge = async (
  accountCode: string,
  invoiceNumber: string,
): Promise<EnrollmentChallengeDto | null> => {
  try {
    return await axios
      .get<EnrollmentChallengeDto>(
        `/api/v2/accounts/${accountCode}/enrollment?invoiceNumber=${invoiceNumber}`,
      )
      .then(({ data }) => data);
  } catch (err) {
    if (axios.isAxiosError(err) && err.response?.status === 404) {
      return null;
    } else {
      throw err;
    }
  }
};

const createContact = (spec: ContactSpec) => axios.post("/api/contacts/", spec);

const editContact = (spec: ContactSpec) =>
  axios.put(`/api/contacts/${spec.id}`, spec);

const deleteContact = (id: number) => axios.delete(`/api/contacts/${id}`);

export const useCreateContact = () => {
  const queryClient = useQueryClient();

  const mutation = useMutation(
    (spec: ContactSpec) => createContact(spec),

    {
      onSuccess: (_, spec) => {
        queryClient.invalidateQueries(["contacts"]);
      },
    },
  );

  return mutation;
};

export const useDeleteContact = () => {
  const queryClient = useQueryClient();

  const mutation = useMutation(
    (id: number) => deleteContact(id),

    {
      onSuccess: () => {
        queryClient.invalidateQueries(["contacts"]);
      },
    },
  );

  return mutation;
};

export const useEditContact = () => {
  const queryClient = useQueryClient();

  const mutation = useMutation(
    (spec: ContactSpec) => editContact(spec),

    {
      onSuccess: (_, spec) => {
        queryClient.invalidateQueries(["contacts"]);
      },
    },
  );

  return mutation;
};

export const getContactsForAccount = (accountCode: string | null) =>
  accountCode == null
    ? []
    : axios
        .get<ContactDto[]>(`/api/v2/accounts/${accountCode}/contacts`)
        .then(({ data }) => data);

export const useContactsForAccount = (accountCode: string | null) =>
  useQuery(["contacts", accountCode], () => getContactsForAccount(accountCode));

export const getUsersForAccount = (accountCode: string | null) =>
  accountCode == null
    ? []
    : axios
        .get<AccountUserDto[]>(`/api/v2/accounts/${accountCode}/users`)
        .then(({ data }) => data);

export const useUsersForAccount = (accountCode: string | null) =>
  useQuery(["users", accountCode], () => getUsersForAccount(accountCode));

export const confirmEnrollmentChallenge = async (
  accountCode: string,
  challengeId: string,
  stockCode: string,
) => {
  return await axios
    .post<EnrollmentChallengeResponseDto>(
      `/api/v2/accounts/${accountCode}/enrollment`,
      {
        challengeId,
        stockCode,
      },
    )
    .then(({ data }) => data);
};

export const resetPassword = async (
  accountCodeOrEmail: string,
  resetCode: string,
  newPassword: string,
) => {
  const result = await axios.post(`/api/v2/accounts/reset-password`, {
    accountCodeOrEmail,
    resetCode,
    newPassword,
  });

  return result.status === 200;
};

const getStaffAccounts = (adminId: number) =>
  axios
    .get<AccountOwnershipDto[]>(`/api/staff/${adminId}/accounts`)
    .then(({ data }) => data);

const getAccount = (code: string | null) =>
  code == null
    ? null
    : axios
        .get<GetAccountPayload>(`/api/accounts/${code}`)
        .then(({ data }) => data);

export const getAccountNew = (code: string | null) =>
  code == null
    ? null
    : axios
        .get<AccountDto>(`/api/v2/accounts/${code}`)
        .then(({ data }) => data);

export const patchAccount = (code: string, dto: AccountPatch) =>
  axios.put(`/api/v2/accounts/${code}`, dto);

const getAccountFinancials = (code: string) =>
  axios
    .get<AccountFinancialsDto>(`/api/v2/accounts/${code}/financials`)
    .then(({ data }) => data);

export const useAccount = (code: string | null) =>
  useQuery(["accounts", code], () => getAccount(code), {
    enabled: code != null,
  });

export const useAccountNew = (code: string | null) =>
  useQuery(["accounts-new", code], () => getAccountNew(code), {
    enabled: code != null,
  });

export const useStaffAccounts = (adminId: number) =>
  useQuery(["staffaccounts", adminId], () => getStaffAccounts(adminId));

export const useAccountFinancials = (code: string) =>
  useQuery(["accounts", code, "financials"], () => getAccountFinancials(code));

export const usePatchAccount = () => {
  const queryClient = useQueryClient();

  const mutation = useMutation(
    ({ code, ...patch }: AccountPatch & { code: string }) =>
      patchAccount(code, patch),
    {
      onSuccess: (_, spec) => {
        queryClient.invalidateQueries(["accounts", spec.code]);
        queryClient.invalidateQueries(["accounts-new", spec.code]);
      },
    },
  );

  return mutation;
};

export const useSetDeliveryDeliveryAddress = () => {
  const queryClient = useQueryClient();

  const mutation = useMutation(
    ({
      code,
      defaultAddressId,
    }: {
      code: string;
      defaultAddressId: number | null;
    }) => setDefaultDeliveryAddress(code, defaultAddressId),
    {
      onSuccess: (_, spec) => {
        queryClient.invalidateQueries(["accounts", spec.code]);
        queryClient.invalidateQueries(["accounts-new", spec.code]);
      },
    },
  );

  return mutation;
};
