import { useRewardTemplates } from "@oaktyres/queries";
import {
  Box,
  Button,
  Circle,
  Flex,
  MiniTable,
  Modal,
  Text,
} from "@oaktyres/ui";
import React from "react";
import { FaMedal } from "react-icons/fa";

export type EarningRulesModalProps = {
  onClose: () => void;
  accountCode: string;
};

export const EarningRulesModal = ({
  accountCode,
  onClose,
}: EarningRulesModalProps) => {
  const rules = useRewardTemplates(accountCode);

  const data = rules.data ?? [];

  return (
    <Modal onClose={onClose} shown>
      <Flex alignItems={"center"} mb={3}>
        <Circle icon={FaMedal} color="primary" />
        <Text fontSize={3} fontWeight={600} ml={2}>
          Earning Rules
        </Text>
      </Flex>
      <Box mb={3}>
        {data.map((x) => (
          <Box>
            <MiniTable>
              <thead>
                <th style={{ textAlign: "left" }}>Name</th>
                <th style={{ textAlign: "right", width: "40px" }}>RP</th>
                <th style={{ textAlign: "right", width: "40px" }}>DSP</th>
                <th style={{ textAlign: "right", width: "40px" }}>BDF</th>
              </thead>
              <tbody>
                <tr>
                  <td style={{ fontWeight: 600 }}>
                    Per £{x.spendIncrement} non-tyre spend
                  </td>
                  <td style={{ textAlign: "right" }}>{x.pointsPerSpend}</td>
                  <td style={{ textAlign: "right" }}>-</td>
                  <td style={{ textAlign: "right" }}>-</td>
                </tr>
                {x.rules.map((x) => (
                  <tr>
                    <td style={{ fontWeight: 600 }}>{x.name}</td>
                    <td style={{ textAlign: "right" }}>
                      {x.points === 0 ? "-" : x.points}
                    </td>
                    <td style={{ textAlign: "right" }}>
                      {x.dsp === 0 ? "-" : x.dsp}
                    </td>
                    <td style={{ textAlign: "right" }}>
                      {x.bdf === 0 ? "-" : `£${(x.bdf / 100).toFixed(2)}`}
                    </td>
                  </tr>
                ))}
              </tbody>
            </MiniTable>
          </Box>
        ))}
      </Box>
      <Flex justifyContent={"center"}>
        <Button onClick={onClose}>Close</Button>
      </Flex>
    </Modal>
  );
};
