import React from "react";
import styled, { css } from "styled-components";
import { Button } from "..";

export type Option<T> = {
  value: T;
  label: string;
};

export type ButtonSelectProps<T> = {
  value: T;
  onChange: (value: T) => void;
  options: readonly Option<T>[];
};

const Container = styled.div`
  display: inline-flex;
  flex-wrap: no-wrap;
`;

const ButtonElem = styled(Button)<{ active: boolean }>`
  border-radius: 0;
  min-width: 0;

  &:first-child {
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
  }

  &:last-child {
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
  }

  background-color: ${(props) => props.theme.colors.grey3};
  transition: background-color 0.3s ease, box-shadow 0.3s ease;

  ${(props) =>
    props.active
      ? css`
          box-shadow: inset 2px 2px 5px rgba(0, 0, 0, 0.3);
          background-color: ${(props) => props.theme.colors.primary};
        `
      : ""}
`;

export function ButtonSelect<T>({
  value,
  onChange,
  options,
}: ButtonSelectProps<T>) {
  return (
    <Container>
      {options.map((x) => (
        <ButtonElem
          key={x.label}
          onClick={() => onChange(x.value)}
          active={value === x.value}
        >
          {x.label}
        </ButtonElem>
      ))}
    </Container>
  );
}
