import { Flex } from "@oaktyres/ui";
import React from "react";
import styled, { useTheme } from "styled-components";
import { MainSidebar, SIDEBAR_WIDTH } from "./MainSidebar";
import { TopBar } from "./TopBar";

const Container = styled.div`
  display: flex;
  width: 100%;
  min-height: 100vh;
`;

const Main = styled(Flex)`
  background: ${(props) => props.theme.colors.background};
  flex: 1;
`;

const CenterContainer = styled.div<{ floatWidth: number }>`
  display: flex;
  flex-direction: column;
  flex: 1;
  min-width: 0;

  @media (min-width: ${(props) => props.floatWidth}px) {
    padding-left: ${SIDEBAR_WIDTH}px;
  }
`;

export const MainContainer = ({ children }: { children: React.ReactNode }) => {
  const theme = useTheme();
  const floatWidth = parseInt(theme.breakpoints[2], 10);

  return (
    <Container>
      <MainSidebar floatWidth={floatWidth} />
      <CenterContainer floatWidth={floatWidth}>
        <TopBar />
        <Main p={[2, 3]} as="main">
          {children}
        </Main>
      </CenterContainer>
    </Container>
  );
};
