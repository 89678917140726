/**
 * Move item in array from one index to another
 *
 * @template T - The type of array
 * @param {T[]} array - The array
 * @param {number} oldIndex - The index to move
 * @param {number} newIndex - The new index for the item
 * @returns {T[]} A copy of the array with the moved item
 */

export function arrMove<T>(
  array: T[],
  oldIndex: number,
  newIndex: number
): T[] {
  if (newIndex >= array.length) {
    newIndex = array.length - 1;
  }

  array.splice(newIndex, 0, array.splice(oldIndex, 1)[0]);

  return [...array];
}
