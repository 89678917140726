import {
  CurrencyConversionDto,
  ProductAuditRow,
  ProductCategoryDto,
  ProductCategorySpec,
  ProductDto,
  ProductQuery,
  ProductSpec,
  ProductTypeDto,
  ProductTypeSpec,
} from "@oaktyres/model";
import axios from "axios";
import {
  useMutation,
  useQueries,
  useQuery,
  useQueryClient,
  UseQueryOptions,
} from "react-query";

// Product Categories

const getProductCategories = () =>
  axios
    .get<ProductCategoryDto[]>("/api/v2/product-categories")
    .then(({ data }) => data);

const getProductCategory = (id: string) =>
  axios
    .get<ProductCategoryDto[]>("/api/v2/product-categories")
    .then(({ data }) => data.find((x) => x.id === id));

const createProductCategory = (spec: ProductCategorySpec) =>
  axios
    .post<{ id: string }>("/api/v2/product-categories", spec)
    .then(({ data }) => data);

const updateProductCategory = (id: string, spec: ProductCategorySpec) =>
  axios.put(`/api/v2/product-categories/${id}`, spec);

export const useProductCategories = () =>
  useQuery(["productcategories"], getProductCategories);

export const useProductCategory = (id: string) =>
  useQuery(["productcategories", id], () => getProductCategory(id));

export const useCreateProductCategory = () => {
  const queryClient = useQueryClient();

  const mutation = useMutation(createProductCategory, {
    onSuccess: () => {
      queryClient.invalidateQueries(["productcategories"]);
    },
  });

  return mutation;
};

export const useUpdateProductCategory = (id: string) => {
  const queryClient = useQueryClient();

  const mutation = useMutation(
    (spec: ProductCategorySpec) => updateProductCategory(id, spec),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["productcategories"]);
      },
    },
  );

  return mutation;
};

// Product Types

const getProductTypes = () =>
  axios.get<ProductTypeDto[]>("/api/v2/product-types").then(({ data }) => data);

const getProductType = (id: string) =>
  axios
    .get<ProductTypeDto[]>("/api/v2/product-types")
    .then(({ data }) => data.find((x) => x.id === id));

const createProductType = (spec: ProductTypeSpec) =>
  axios
    .post<{ id: string }>(`/api/v2/product-types`, spec)
    .then(({ data }) => data);

const updateProductType = (id: string, spec: ProductTypeSpec) =>
  axios.put(`/api/v2/product-types/${id}`, spec);

export const useProductTypes = () =>
  useQuery(["producttypes"], getProductTypes);

export const useProductType = (id: string) =>
  useQuery(["producttypes", id], () => getProductType(id));

export const useCreateProductType = () => {
  const queryClient = useQueryClient();

  const mutation = useMutation(createProductType, {
    onSuccess: () => {
      queryClient.invalidateQueries(["producttypes"]);
    },
  });

  return mutation;
};

export const useUpdateProductType = (id: string) => {
  const queryClient = useQueryClient();

  const mutation = useMutation(
    (spec: ProductTypeSpec) => updateProductType(id, spec),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["producttypes", id]);
      },
    },
  );

  return mutation;
};

// Products

const getAudit = () =>
  axios
    .get<ProductAuditRow[]>("/api/v2/products/audit")
    .then(({ data }) => data);

const getProducts = (query?: ProductQuery) =>
  axios
    .get<ProductDto[]>("/api/v2/products", { params: query })
    .then(({ data }) => data);

export const getProductById = (id: string, accountCode?: string) =>
  axios
    .get<ProductDto>(`/api/v2/products/${id}`, { params: { accountCode } })
    .then(({ data }) => data);

const createProduct = (spec: ProductSpec) =>
  axios.post<{ id: string }>("/api/v2/products", spec).then(({ data }) => data);

const putProduct = (id: string, spec: ProductSpec) =>
  axios.put(`/api/v2/products/${id}`, spec);

export const useProductAudit = () => useQuery(["products", "audit"], getAudit);

const getProductsById = (
  ids: string[],
  accountCode?: string,
): Promise<ProductDto[]> =>
  Promise.all(ids.map((x) => getProductById(x, accountCode)));

export const useProductsById = (ids: string[], accountCode?: string) =>
  useQuery(["products", { ids, accountCode }], () =>
    getProductsById(ids, accountCode),
  );

export const useProducts = (
  query?: ProductQuery,
  opts?: { enabled: boolean },
) => useQuery(["products", query], () => getProducts(query), opts);

export const useProduct = (id: string, accountCode?: string) =>
  useQuery(["products", id], () => getProductById(id, accountCode));

export const useCreateProduct = () => {
  const queryClient = useQueryClient();

  const mutation = useMutation(createProduct, {
    onSuccess: () => {
      queryClient.invalidateQueries(["products"]);
    },
  });

  return mutation;
};

export const useUpdateProduct = (id: string) => {
  const queryClient = useQueryClient();

  const mutation = useMutation((spec: ProductSpec) => putProduct(id, spec), {
    onSuccess: () => {
      queryClient.invalidateQueries(["products", id]);
    },
  });

  return mutation;
};

export const getCurrencyConversions = () =>
  axios
    .get<CurrencyConversionDto[]>("/api/v2/currency-conversions")
    .then(({ data }) => data);

export const updateCurrencyConversions = (spec: CurrencyConversionDto[]) =>
  axios.post("/api/v2/currency-conversions", spec);

export const useCurrencyConversions = () =>
  useQuery(["currency-conversions"], getCurrencyConversions);

export const useUpdateCurrencyConversions = () => {
  const queryClient = useQueryClient();

  const mutation = useMutation(updateCurrencyConversions, {
    onSuccess: () => {
      queryClient.invalidateQueries(["currency-conversions"]);
    },
  });

  return mutation;
};
