import { WheelPosition } from "@oaktyres/model";
import React, { ComponentProps } from "react";
import styled from "styled-components";
import {
  display,
  layout,
  space,
  SpaceProps,
  LayoutProps,
  DisplayProps,
} from "styled-system";

const Container = styled.svg<SpaceProps & LayoutProps & DisplayProps>`
  ${space};
  ${display};
  ${layout};
`;

const Wheel = styled.rect<{ active: "true" | "false" }>`
  fill: ${(props) =>
    props.active === "true"
      ? props.theme.colors.primary
      : props.theme.colors.grey1};
`;

export type WheelPositionDiagramProps = {
  value: WheelPosition | "" | null;
} & ComponentProps<typeof Container>;

export const WheelPositionDiagram = ({
  value,
  ...rest
}: WheelPositionDiagramProps) => {
  return (
    <Container viewBox="0 0 38 65" {...rest}>
      <path
        d="m11.1 1.5241 0.24316 2.041-3.9707 1.7598 0.61816-1.7793 2.79-0.93555-0.11133-0.95508-3.0332 0.91699-2.1357 4.1377-0.03808 13.408c-2.9756 0.63672-4.7354 0.80469-4.1748 2.8086l4.1572-0.91797-0.1123 38.199 1.1055 3.2578 1.5352 0.71191c7.7705 0.1123 14.344 0.1123 22.133 0l1.5361-0.71191 1.1035-3.2578-0.12988-38.18 4.0996 0.89844c0.54297-1.9844-1.1602-2.1719-4.0996-2.79l-0.03906-13.427-2.1533-4.1377-3.0332-0.91699-0.09277 0.95508 2.7705 0.93555 0.61816 1.7793-3.9512-1.7598 0.24414-2.041c-10.056-1.0488-5.8418-1.0488-15.879 0zm1.2549 56.361-4.1768-1.0107 2.6221-6.6846c5.5059 0.01855 10.974 0.01855 16.478 0l2.6221 6.6846-4.1768 1.0107c-5.168-0.15039-8.2202-0.15039-13.369 0zm-2.8096-29.136-2.0596-6.3486c3.6338-1.5723 5.7676-2.8643 9.9063-2.6387h3.2954c4.1387-0.22559 6.2734 1.0664 9.9053 2.6387l-2.0596 6.3486c-2.3223-1.1045-16.666-1.1045-18.988 0zm21.292-2.7148-1.5732 4.0439-0.41113 17.171 1.9844-1.0479zm-23.613 0 1.5713 4.0439 0.41309 17.171-1.9658-1.0479z"
        fill="none"
        stroke="#000"
        strokeWidth="0.8"
      />
      <g>
        <Wheel
          active={value === "fl" ? "true" : "false"}
          x="4.1654"
          y="7.9514"
          width="4.5531"
          height="10.472"
          ry="1.0208"
        />
        <Wheel
          active={value === "fr" ? "true" : "false"}
          x="29.437"
          y="7.9514"
          width="4.5531"
          height="10.472"
          ry="1.0208"
        />
        <Wheel
          active={value === "rl" ? "true" : "false"}
          x="4.1654"
          y="48.847"
          width="4.5531"
          height="10.472"
          ry="1.0208"
        />
        <Wheel
          active={value === "rr" ? "true" : "false"}
          x="29.437"
          y="48.847"
          width="4.5531"
          height="10.472"
          ry="1.0208"
        />
      </g>
    </Container>
  );
};
