import {
  FaultyTyreReturnSpec,
  VehicleInfoDto,
  WheelPosition,
  wheelPositionNames,
} from "@oaktyres/model";
import {
  useAdviceNote,
  useManufacturers,
  useTyreByStockCode,
} from "@oaktyres/queries";
import {
  AddButton,
  AddressInput,
  Box,
  Button,
  Circle,
  Flex,
  FormInput,
  FormInputWrap,
  IconButton,
  Input,
  PanelFooter,
  Text,
  TextArea,
  UploadButton,
  WheelPositionDiagram,
} from "@oaktyres/ui";
import { FileProp } from "@oaktyres/ui/dist/components/Uploader";
import axios from "axios";
import { fromUnixTime, getUnixTime } from "date-fns";
import { max } from "lodash";
import React, { useState } from "react";
import { FaChevronRight, FaFileImage, FaPen, FaTrash } from "react-icons/fa";
import { EditTyreForm } from "./EditTyreForm";

export type FaultyTyreReturnFormProps = {
  stockCode: string;
  adviceNote: string;
  maxQuantity: number;
  onCompletion: (spec: FaultyTyreReturnSpec) => void;
  onBackOut: () => void;
};

const LAST_STEP = 3;

type Line = FaultyTyreReturnSpec["lines"][number];

export const FaultyTyreReturnForm = ({
  stockCode,
  adviceNote,
  maxQuantity,
  onCompletion,
  onBackOut,
}: FaultyTyreReturnFormProps) => {
  const [editingTyre, setEditingTyre] = useState<number | Line>(-1);
  const [busy, setBusy] = useState(false);
  const [spec, setSpec] = useState<FaultyTyreReturnSpec>({
    stockCode,
    adviceNote,
    type: "faultytyre",
    quantity: 1,
    complaint: "",
    consumerName: "",
    consumerEmail: "",
    addressLine1: "",
    addressLine2: "",
    city: "",
    county: "",
    postcode: "",
    supportingImages: [],
    lines: [],
    vehicleRegistration: "",
    vehicleMake: "",
    vehicleModel: "",
    vehicleYear: "",
    vehicleOdometerReading: "",
  });

  const manus = useManufacturers();
  const tyre = useTyreByStockCode(stockCode);
  const ad = useAdviceNote(adviceNote);

  const purchaseDate =
    ad.data == null ? new Date() : fromUnixTime(ad.data.date);

  const photosRequired =
    (manus.data ?? []).find(
      (x) => x.crossReference === tyre.data?.brand.crossReference,
    )?.returnPhotosReq ?? false;

  const [step, setStep] = useState(0);

  const complete = () => {
    if (
      window.confirm("Are you sure you want to submit this return request?")
    ) {
      onCompletion(spec as FaultyTyreReturnSpec);
    }
  };

  const canAdvance = () => {
    if (step === 0) {
      return [
        spec.consumerName,
        spec.consumerEmail,
        spec.postcode,
        spec.addressLine1,
      ].every((x) => x.trim() !== "");
    } else if (step === 1) {
      return (
        spec.lines.length > 0 && spec.lines.every((x) => x.wheelPosition !== "")
      );
    } else if (step === 2) {
      return [
        spec.vehicleRegistration,
        spec.vehicleOdometerReading,
        spec.vehicleYear,
        spec.vehicleModel,
        spec.vehicleMake,
      ].every((x) => x.trim() !== "");
    } else if (step === 3) {
      return (
        spec.complaint.trim() !== "" &&
        (!photosRequired || spec.supportingImages.length > 0)
      );
    }

    throw new Error("Invalid step");
  };

  const advance = () => {
    if (step === 0 && !spec.consumerEmail.includes("@")) {
      return window.alert("Consumer Email address must be a valid email.");
    }
    if (step < LAST_STEP) {
      setStep(step + 1);
    } else {
      complete();
    }
  };

  const goBack = () => {
    if (step > 0) {
      setStep(step - 1);
    } else {
      onBackOut();
    }
  };

  const changeFromEv =
    (key: keyof FaultyTyreReturnSpec) =>
    (ev: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) =>
      setSpec({
        ...spec,
        [key]: (ev.target as any).value,
      });

  const addTyre = () => {
    const newTyre: Line = {
      dot: "",
      barcode: "",
      purchaseDate: getUnixTime(purchaseDate),
      wheelPosition: "",
      mileage: null,
      fittingDate: null,
      isMileageEstimated: false,
      returnCode: "",
      returnInfo: "",
      treadDepth1: 0,
      treadDepth2: 0,
      treadDepth3: 0,
      treadDepth4: null,
    };

    setEditingTyre(newTyre);
  };

  const removeTyre = (index: number) => {
    setSpec({
      ...spec,
      lines: spec.lines.filter((x, i) => i !== index),
    });
  };

  const addUpload = (item: FileProp | null) => {
    if (item == null) {
      return;
    }
    setSpec({
      ...spec,
      supportingImages: [...spec.supportingImages, item],
    });
  };

  const wheelPositionOptionsForLine = (index: number | Line) => [
    {
      value: "",
      label: "(Please Select)",
      disabled: true,
    },
    ...Object.entries(wheelPositionNames)
      .filter(
        ([value]) =>
          !spec.lines
            .filter((x, i) => i !== index)
            .map((x) => x.wheelPosition)
            .includes(value as WheelPosition),
      )
      .map(([value, label]) => ({
        value,
        label,
      })),
  ];

  const removeImage = (index: number) => {
    setSpec({
      ...spec,
      supportingImages: spec.supportingImages.filter((x, i) => i !== index),
    });
  };

  const lookupReg = () => {
    setBusy(true);

    axios
      .get<VehicleInfoDto>(`/api/v2/vehicle/${spec.vehicleRegistration}`)
      .then(({ data }) => {
        setSpec({
          ...spec,
          vehicleMake: data.make,
          vehicleModel: data.model,
          vehicleYear: data.year.toString(),
        });
      })
      .finally(() => {
        setBusy(false);
      });
  };

  let odoError: string | null = null;

  const odoAsNumber = Number.parseInt(spec.vehicleOdometerReading, 10) || 0;

  if (
    spec.vehicleOdometerReading.trim() !== "" &&
    odoAsNumber < (max(spec.lines.map((x) => x.mileage)) ?? 0)
  ) {
    odoError = "Reading less than tyre mileages";
  }

  const saveTyre = (t: Line) => {
    if (typeof editingTyre === "number") {
      const lines = [...spec.lines];
      lines[editingTyre] = t;
      setSpec({ ...spec, lines });
    } else {
      setSpec({
        ...spec,
        lines: [...spec.lines, t],
      });
    }
    setEditingTyre(-1);
  };

  if (editingTyre !== -1) {
    const i = typeof editingTyre === "number" ? editingTyre : spec.lines.length;
    const tyre =
      typeof editingTyre === "number" ? spec.lines[editingTyre] : editingTyre;

    return (
      <EditTyreForm
        index={i}
        spec={tyre}
        wheelPositionOptions={wheelPositionOptionsForLine(editingTyre)}
        onSave={saveTyre}
        onCancel={() => setEditingTyre(-1)}
      />
    );
  }

  return (
    <>
      <Flex
        p={"30px"}
        flexDirection="column"
        alignItems={"center"}
        minHeight={400}
      >
        {step === 0 ? (
          <Flex flexDirection={"column"} width={400} maxWidth="100%">
            <Text fontWeight={600} textAlign="center" mb={2}>
              Consumer Information
            </Text>
            <Text fontWeight={600} color="grey1" mb={3} fontSize={1}>
              Please enter the details of the person who purchased the tyres
              from your company. This is the person who the claim is on behalf
              of - usually the owner of the vehicle - not yourself or an
              employee of your company.
            </Text>
            <FormInput
              label="Full Name"
              value={spec.consumerName}
              onChange={changeFromEv("consumerName")}
            />
            <FormInput
              label="Email Address"
              value={spec.consumerEmail}
              type="email"
              onChange={changeFromEv("consumerEmail")}
              mb={0}
            />
            <Text color="warning" fontSize={1} mb={2}>
              This is required to be accurate in order for the customer to
              submit their declaration
            </Text>

            <AddressInput
              label="Consumer Address"
              value={{
                name: "",
                address1: spec.addressLine1,
                address2: spec.addressLine2,
                city: spec.city,
                county: spec.county,
                postcode: spec.postcode,
              }}
              onChange={(val) => {
                setSpec({
                  ...spec,
                  addressLine1: val.address1,
                  addressLine2: val.address2,
                  city: val.city,
                  county: val.county,
                  postcode: val.postcode,
                });
              }}
            />
          </Flex>
        ) : step === 1 ? (
          <>
            <>
              <Text fontWeight={600}>Tyre Information</Text>
              <Text fontWeight={600} color="grey1" mb={3} fontSize={1}>
                Please add the tyres you wish to return
              </Text>
              <Flex
                style={{ gap: 6 }}
                flexDirection="column"
                width={500}
                maxWidth="100%"
              >
                {spec.lines.map((x, i) => (
                  <Flex border={1} borderRadius={6} p={2} alignItems="center">
                    <WheelPositionDiagram
                      value={x.wheelPosition}
                      height="60px"
                      mr={3}
                      ml={1}
                    />
                    <Flex flex={1} flexDirection="column">
                      <Text fontSize={1}>
                        <Text as="span" color="grey1">
                          Barcode:{" "}
                        </Text>
                        <Text
                          as="span"
                          color={x.barcode.trim() === "" ? "danger" : "body"}
                        >
                          {x.barcode.trim() === "" ? "<Not Given>" : x.barcode}
                        </Text>
                      </Text>
                      <Text fontSize={1}>
                        <Text as="span" color="grey1">
                          Wheel Position:{" "}
                        </Text>
                        <Text
                          as="span"
                          color={x.wheelPosition === "" ? "danger" : "body"}
                        >
                          {x.wheelPosition === ""
                            ? "<Not Given>"
                            : wheelPositionNames[x.wheelPosition]}
                        </Text>
                      </Text>
                    </Flex>
                    <IconButton
                      icon={FaPen}
                      onClick={() => setEditingTyre(i)}
                    />
                    <IconButton
                      icon={FaTrash}
                      ml={2}
                      color="danger"
                      onClick={() => removeTyre(i)}
                    />
                  </Flex>
                ))}
                {spec.lines.length < maxQuantity && (
                  <AddButton
                    width={1}
                    disabled={spec.lines.length >= maxQuantity}
                    onClick={addTyre}
                  >
                    Add Tyre {spec.lines.length + 1} of {maxQuantity}
                  </AddButton>
                )}
              </Flex>
            </>
          </>
        ) : step === 2 ? (
          <>
            <Text fontWeight={600}>Vehicle Information</Text>
            <Text fontWeight={600} color="grey1" mb={3} fontSize={1}>
              Enter the vehicle information...
            </Text>
            <Flex
              border={1}
              flexDirection="column"
              p={2}
              width={500}
              maxWidth="100%"
            >
              <FormInputWrap label="Registration">
                <Flex>
                  <Input
                    value={spec.vehicleRegistration}
                    onChange={changeFromEv("vehicleRegistration")}
                  />
                  <Button onClick={lookupReg} ml={2} disabled={busy}>
                    Lookup
                  </Button>
                </Flex>
              </FormInputWrap>
              <FormInput
                label="Vehicle Make"
                value={spec.vehicleMake}
                onChange={changeFromEv("vehicleMake")}
              />
              <FormInput
                label="Vehicle Model"
                value={spec.vehicleModel}
                onChange={changeFromEv("vehicleModel")}
              />
              <Flex style={{ gap: 6 }} flexDirection={["column", "row"]}>
                <FormInput
                  label="Vehicle Year"
                  value={spec.vehicleYear}
                  onChange={changeFromEv("vehicleYear")}
                />
                <FormInput
                  label="Odometer Reading"
                  value={spec.vehicleOdometerReading}
                  type="number"
                  onChange={changeFromEv("vehicleOdometerReading")}
                  error={odoError}
                />
              </Flex>
            </Flex>
          </>
        ) : step === 3 ? (
          <>
            <Text fontWeight={600}>Complaint Information</Text>
            <Text fontWeight={600} color="grey1" mb={3} fontSize={1}>
              Please provide information about the fault...
            </Text>
            <FormInputWrap label="Complaint Description" width={500} mb={3}>
              <TextArea
                value={spec.complaint}
                height={150}
                onChange={changeFromEv("complaint")}
              />
            </FormInputWrap>
            <Flex
              border={1}
              width={500}
              maxWidth="100%"
              p={2}
              borderRadius={6}
              flexDirection="column"
            >
              <Box mb={spec.supportingImages.length > 0 ? 2 : 0}>
                <Flex
                  justifyContent={"space-between"}
                  alignItems="center"
                  width="100%"
                  flexDirection={["column", "row"]}
                >
                  <Flex flexDirection={"column"} mr={2}>
                    <Text fontWeight={600}>
                      Supporting Images (
                      {photosRequired ? "Required" : "Optional"})
                    </Text>
                    <Text fontSize={1}>
                      Provide supporting images for your return
                    </Text>
                  </Flex>
                  <UploadButton onUpload={addUpload} />
                </Flex>
                {photosRequired && (
                  <Text color="warning" fontSize={1} mt={1}>
                    The manufacturer of this product requires photographs to be
                    submitted as part of warranty claim.
                  </Text>
                )}
              </Box>
              {spec.supportingImages.map((x, i, self) => (
                <Flex
                  alignItems={"center"}
                  p={2}
                  border={1}
                  borderRadius={3}
                  mb={i === self.length - 1 ? 0 : 2}
                >
                  <Circle icon={FaFileImage} mr={3} />
                  <Flex flexDirection={"column"} flex={1}>
                    <Text fontWeight={600}>
                      {x.originalFilename ?? x.filename}
                    </Text>
                    <Text fontSize={1}>{x.filename}</Text>
                  </Flex>
                  <IconButton
                    icon={FaTrash}
                    color="danger"
                    onClick={() => removeImage(i)}
                  />
                </Flex>
              ))}
            </Flex>
          </>
        ) : null}
      </Flex>
      <PanelFooter justifyContent={"space-between"} p={3}>
        <Button color="grey1" onClick={goBack}>
          Back
        </Button>
        <Button
          onClick={advance}
          icon={FaChevronRight}
          disabled={!canAdvance()}
        >
          {step === LAST_STEP ? "Submit" : "Next"}
        </Button>
      </PanelFooter>
    </>
  );
};
