export enum CamStatuses {
  Active = 0,
  OnStop = 1,
  Inactive = 2,
  Legal = 3,
  Prospect = 98,
  NonOakAccount = 99,
}

export enum StopReasons {
  OnStop = 0,
  PaymentPlan = 1,
  Atradius = 2,
  Legal = 3,
  BouncedCheques = 4,
  Insolvent = 5,
  Overdue = 6,
  CovidClosed = 7,
}

export interface CameoCustomer {
  STAT: CamStatuses;
  STATUS: string;
  STATCODE: StopReasons | null;
  STATNAME: string;
  CODE: string;
  RECORD: number;
  EDITING: number;
  REPORT: string;
  POLICY: string;
  LEDGER: string;
  CRTNDATE: Date;
  CRTNTIME: string;
  NAME: string;
  ADDR1: string;
  ADDR2: string;
  ADDR3: string;
  ADDR4: string;
  POSTCODE: string;
  CALLED: string;
  SECURITY: string;
  IOBO: string;
  COLOUR: number;
  PINSET: Date;
  PHONENUM: string;
  PHMOBILE: string;
  PHWORK: string;
  EMAIL: string;
  SALUT: string;
  INITIAL: string;
  FNAME: string;
  SNAME: string;
  TAXREF: string;
  DEPOTNUM: number;
  DEPARTMENT: number;
  USER: number;
  COMPANY: number;
  TRADING: number;
  COUNTRY: string;
  CURRENCY: string;
  TAXRATE1: number;
  TAXRATE2: number;
  PR: number;
  PRPERCENT: number;
  PRMIN: number;
  PRMPERCENT: number;
  PRCHANGE: number;
  CRSTATUS: number;
  TRLIST1: number;
  TRLIST2: number;
  TRLIST3: number;
  TRLIST4: number;
  TRLIST5: number;
  TRLIST6: number;
  TRSPECIAL: number;
  STDTERMS: number;
  NORETURN: number;
  QGROUPS: number;
  YOURDEP: string;
  BALANCE1: number;
  BALANCE2: number;
  BALANCE3: number;
  BALANCE4: number;
  BALANCE5: number;
  BALANCE6: number;
  BALANCE7: number;
  BALANCE8: number;
  BALANCE9: number;
  FORWARD: number;
  DATE1: Date;
  DATE2: Date;
  SELL_QTY1: number;
  SELL_QTY2: number;
  SELL_PRF1: number;
  SELL_PRF2: number;
  SELL_DPR1: number;
  SELL_DPR2: number;
  SELL_VAL1: number;
  SELL_VAL2: number;
  CREDLMT: number;
  INSURED: number;
  LASTALLOC: Date;
  OPENITEM: number;
  LADATE: Date;
  REP: string;
  GROUP: string;
  ACCOUNTREF: string;
  BANKSORT: string;
  AUTOMEMO: string;
  SETTLE1: number;
  SETTLE2: number;
  SETTLE3: number;
  FORCEORD: boolean;
  OVRADDR: boolean;
  STOPAPPEND: boolean;
  NOINVOICE: boolean;
  NOLINK: boolean;
  SWCASH: boolean;
  PRLEDGER: boolean;
  ACPOD: boolean;
  TYLABEL: boolean;
  FPOS: boolean;
  TREADOFF: boolean;
  SERIALOFF: boolean;
  SERIALON: boolean;
  REMREASON: boolean;
  ANALYSIS1: number;
  ANALYSIS2: number;
  ANALYSIS3: number;
  LASTINV: string;
  CREDITOP: string;
  PAYTERMS: number;
  VANRUN: number;
  SEQUENCE: number;
  ADVERT: number;
  AUTODISC: number;
  MAILSORT: number;
  DAYSTERMS: number;
  EXTRAPAY: number;
  PAYTYPE: string;
  PAYDAY: number;
}
