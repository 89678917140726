import { default as Circle, CircleProps } from "./Circle";
import React from "react";
import InitialDisplay from "./InitialDisplay";

export type UserProfileImageProps = {
  profileImage?: string | null;
  name: string;
} & CircleProps;

export const UserProfileImage = ({
  profileImage,
  name,
  ...rest
}: UserProfileImageProps) => {
  return profileImage ? (
    <Circle
      title={name}
      image={`${process.env.REACT_APP_STATIC_ROOT}/${profileImage}`}
      {...rest}
    />
  ) : (
    <InitialDisplay title={name} text={name} {...rest} />
  );
};

export default UserProfileImage;
