import {
  TyreSearchPayload,
  AccountDto,
  ManufacturerDto,
} from "@oaktyres/model";
import { useManufacturers } from "@oaktyres/queries";
import { Text, Flex, Panel, formatCurrency } from "@oaktyres/ui";
import {
  getBrandOption,
  getBrandTierOption,
  getCommercialOption,
  getRunflatOption,
  getSeasonOption,
  SmartSearchItem,
} from "@oaktyres/ui/dist/hooks/useSmartSearch";
import { min } from "lodash";
import React from "react";
import { useMemo } from "react";
import styled from "styled-components";
import allSeasonSrc from "../../img/allseason.svg";
import runflatIconSrc from "../../img/runflat.svg";
import budgetIconSrc from "../../img/budget.svg";
import midrangeIconSrc from "../../img/midrange.svg";
import premiumIconSrc from "../../img/premium.svg";
import commercialIconSrc from "../../img/commercial.svg";
import { useMeasure, useWindowSize } from "react-use";
import { useRetailView } from "../../components/RetailViewProvider";

type SuggestionBlock = {
  condition: (account: AccountDto) => boolean;
  getItems: (items: TyreSearchPayload[]) => TyreSearchPayload[];
  getSearch: (existing: SmartSearchItem[]) => SmartSearchItem[];
  isApplied: (existing: SmartSearchItem[]) => boolean;
  name: string;
  icon: string;
  color: string;
};

const brandBlock = (brand: ManufacturerDto): SuggestionBlock => ({
  name: brand.name,
  icon: `${process.env.REACT_APP_STATIC_ROOT}${brand.icon}`,
  color: brand.primaryColor ?? "body",
  isApplied: (existing) =>
    existing.some(
      (x) => x.type === "brand" && x.crossReference === brand.crossReference,
    ),

  getItems: (items: TyreSearchPayload[]) =>
    items.filter((x) => x.brand.crossReference === brand.crossReference),
  condition: (account: AccountDto) =>
    account.brandDealers.some((x) => x.brand === brand.crossReference),
  getSearch: (existing) =>
    existing
      .filter((x) => x.type !== "brand")
      .concat([getBrandOption(brand.crossReference, brand.name)]),
});

const blocks: SuggestionBlock[] = [
  {
    name: "Budget & Value",
    icon: budgetIconSrc,
    color: "#003381",
    isApplied: (existing) =>
      existing.some((x) => x.type === "brandtier" || x.type === "brand"),
    getItems: (items) => items.filter((x) => x.brand.tier === 0),
    condition: () => true,
    getSearch: (existing) =>
      existing
        .filter((x) => x.type !== "brand")
        .filter((x) => x.type !== "brandtier")
        .concat([getBrandTierOption(0)]),
  },
  {
    name: "Mid-Range",
    icon: midrangeIconSrc,
    color: "silver",
    isApplied: (existing) =>
      existing.some((x) => x.type === "brandtier" || x.type === "brand"),
    getItems: (items) => items.filter((x) => x.brand.tier === 1),
    condition: () => true,
    getSearch: (existing) =>
      existing
        .filter((x) => x.type !== "brand")
        .filter((x) => x.type !== "brandtier")
        .concat([getBrandTierOption(1)]),
  },
  {
    name: "Premium",
    icon: premiumIconSrc,
    color: "gold",
    isApplied: (existing) =>
      existing.some((x) => x.type === "brandtier" || x.type === "brand"),
    getItems: (items) => items.filter((x) => x.brand.tier === 2),
    condition: () => true,
    getSearch: (existing) =>
      existing
        .filter((x) => x.type !== "brand")
        .filter((x) => x.type !== "brandtier")
        .concat([getBrandTierOption(2)]),
  },
  {
    name: "All Season",
    icon: allSeasonSrc,
    color: "green",
    isApplied: (existing) =>
      existing.some((x) => x.type === "season" && x.season === "A"),
    condition: () => true,
    getItems: (items) => items.filter((x) => x.season === "A"),
    getSearch: (existing) =>
      existing
        .filter((x) => x.type !== "season")
        .concat([getSeasonOption("A")]),
  },
  {
    name: "Commercial",
    icon: commercialIconSrc,
    color: "grey1",
    isApplied: (existing) =>
      existing.some((x) => x.type === "commercial" && x.commercial),
    condition: () => true,
    getItems: (items) => items.filter((x) => x.isCommercial),
    getSearch: (existing) =>
      existing
        .filter((x) => x.type !== "commercial")
        .concat([getCommercialOption()]),
  },
  {
    name: "Run Flat",
    icon: runflatIconSrc,
    color: "positive",
    condition: () => true,
    getItems: (items) => items.filter((x) => !!x.runFlat),
    isApplied: (existing) =>
      existing.some((x) => x.type === "runflat" && x.runflat),
    getSearch: (existing) =>
      existing.filter((x) => x.type !== "runflat").concat([getRunflatOption()]),
  },
];

export const useSuggestions = (
  items: TyreSearchPayload[],
  account: AccountDto | null | undefined,
  currentParams: SmartSearchItem[],
  selloutListId?: string,
): (SuggestionBlock & { minPrice: number; selloutPrice?: number })[] => {
  const brands = useManufacturers();

  return useMemo(() => {
    if (items.length === 0 || account == null) {
      return [];
    }

    const houseBrands =
      brands.data == null
        ? []
        : brands.data.filter((x) =>
            ["DA", "LS", "EV"].includes(x.crossReference),
          );

    return [...houseBrands.map((x) => brandBlock(x)), ...blocks]
      .filter((x) => x.condition(account))
      .filter((x) => !x.isApplied(currentParams))
      .map((x) => ({
        ...x,
        minPrice:
          min(
            x
              .getItems(items)
              .map((x) => x.price?.value)
              .filter((x) => x != null),
          ) ?? 0,
        selloutPrice:
          min(
            x
              .getItems(items)
              .map(
                (x) =>
                  x.sellouts?.find((s) => s.listId === selloutListId)?.value,
              )
              .filter((x) => x != null),
          ) ?? undefined,
      }))
      .filter((x) => x.minPrice > 0);
  }, [items, account, currentParams, selloutListId]);
};

type SuggestionBlockDisplayProps = {
  name: string;
  price: number;
  selloutPrice?: number | null;
  color: string;
  icon: string;
  onClick: () => void;
};

const SuggestionBlockImage = styled.img`
  height: 60px;
  margin-right: 12px;
  aspect-ratio: 1;
  border-radius: 6px;
`;

const SuggestionPanel = styled(Panel)`
  cursor: pointer;
  transition: all 0.3s ease;

  &:hover {
    transform: scale(1.05);
  }
`;

export const SuggestionBlockDisplay = ({
  name,
  price,
  selloutPrice,
  color,
  icon,
  onClick,
}: SuggestionBlockDisplayProps) => {
  const [retailView] = useRetailView();
  return (
    <SuggestionPanel onClick={onClick} p={1} borderColor={color}>
      <Flex alignItems="center">
        <SuggestionBlockImage src={icon} />
        <Flex flexDirection="column" flex={1} mr={2}>
          <Text
            fontWeight={600}
            lineHeight={1}
            fontSize={2}
            mb="4px"
            style={{ whiteSpace: "nowrap" }}
          >
            {name}
          </Text>
          <Text fontSize={1} lineHeight={1} mb="6px">
            Tyres {(!retailView || selloutPrice != null) && " from"}
          </Text>
          {!retailView ? (
            <Text
              fontWeight={600}
              fontSize={2}
              fontStyle="italic"
              lineHeight={1}
              color={color}
            >
              {formatCurrency("gbp")(price)}
              {selloutPrice != null
                ? ` / ${formatCurrency("gbp")(selloutPrice)}`
                : null}
            </Text>
          ) : selloutPrice != null ? (
            <Text
              fontWeight={600}
              fontSize={2}
              fontStyle="italic"
              lineHeight={1}
              color={color}
            >
              {formatCurrency("gbp")(selloutPrice)}
            </Text>
          ) : null}
        </Flex>
      </Flex>
    </SuggestionPanel>
  );
};

const SuggestionBlockContainer = styled.div`
  display: grid;
  gap: 12px;
  margin-bottom: 12px;
  grid-template-columns: repeat(auto-fit, 230px);
`;

type SuggestionBlockBarProps = {
  items: TyreSearchPayload[];
  account?: AccountDto | null;
  selloutListId?: string;
  currentParams: SmartSearchItem[];
  onNewParams: (newValue: SmartSearchItem[]) => void;
};

export const SuggestionBlockBar = ({
  items,
  account,
  currentParams,
  onNewParams,
  selloutListId,
}: SuggestionBlockBarProps) => {
  const windowSize = useWindowSize();
  const [ref, size] = useMeasure<HTMLDivElement>();
  const blocks = useSuggestions(items, account, currentParams, selloutListId);

  if (blocks.length === 0) {
    return null;
  }

  const width = Math.min(windowSize.width, size.width);

  const maxBlocks = Math.floor(width / 250);

  if (maxBlocks === 1) {
    return null;
  }

  const blocksToShow = blocks.slice(0, maxBlocks);

  return (
    <SuggestionBlockContainer ref={ref}>
      {blocksToShow.map((x) => (
        <SuggestionBlockDisplay
          key={x.name}
          name={x.name}
          price={x.minPrice}
          selloutPrice={x.selloutPrice}
          color={x.color}
          icon={x.icon}
          onClick={() => {
            onNewParams(x.getSearch(currentParams));
          }}
        />
      ))}
    </SuggestionBlockContainer>
  );
};
