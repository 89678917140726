import { useAccountNew } from "@oaktyres/queries";
import { Circle, Flex, Text, useAuth } from "@oaktyres/ui";
import React, { useEffect, useRef, useState } from "react";
import {
  FaCashRegister,
  FaChevronCircleDown,
  FaDollarSign,
  FaSignOutAlt,
  FaUserCog,
} from "react-icons/fa";
import { Link } from "react-router-dom";
import { animated, useTransition } from "react-spring";
import { useClickAway } from "react-use";
import styled from "styled-components";
import { space, SpaceProps } from "styled-system";
import { PortalIcon } from "./PortalIcons";
import { useScopedAccount } from "./ScopedAccountProvider";

const Container = styled.div<SpaceProps>`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  z-index: 500;
  ${space};
`;

const MenuContainer = styled(animated.div)`
  background: white;
  box-shadow: ${(props) => props.theme.shadows[2]};
  border-radius: 6px;
  padding: ${(props) => props.theme.space[4]}px;
  position: absolute;
  right: 0;
  top: 80px;
  width: 350px;
  display: flex;
  flex-direction: column;
`;

const MenuItem = styled.div`
  margin-bottom: ${(props) => props.theme.space[3]}px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  &:last-child {
    margin-bottom: 0;
  }
`;

const MenuDivider = styled.div`
  height: 1px;
  width: 100%;
  background-color: ${(props) => props.theme.colors.grey5};
  margin-bottom: ${(props) => props.theme.space[3]}px;
`;

const MenuLink = styled(Link)`
  display: flex;
  align-items: center;
  flex: 1;
  padding: 6px 12px;
  font-size: 16px;
  color: ${(props) => props.theme.colors.body};
  font-weight: 500;

  svg {
    margin-right: 12px;
    width: 24px;
    fill: currentcolor;
  }

  &:hover {
    background-color: ${(props) => props.theme.colors.grey6};
    text-decoration: none;
  }
`;

const AccountSwitchButton = styled.button`
  display: flex;
  align-items: center;
  text-align: left;
  padding: 6px;
  background: none;
  border: 0;
  cursor: pointer;
  font-weight: 600;
  &:hover {
    background-color: ${(props) => props.theme.colors.grey6};
  }
`;

const ButtonContainer = styled.button<{ active: boolean }>`
  display: flex;
  text-align: left;
  align-items: center;
  background: none;
  border: 0;
  cursor: pointer;
  transitions: all 0.1s ease;
  &:hover {
    background-color: ${(props) => props.theme.colors.grey6};
  }
  padding: 8px 12px;
  border-radius: 5px;
  background-color: ${(props) =>
    props.active ? props.theme.colors.grey6 : ""};

  & > svg {
    transition: all 0.1s ease;
    color: ${(props) => props.theme.colors.grey3};
  }

  &:hover > svg {
    color: ${(props) => props.theme.colors.primary};
  }
`;

const springConfig = {
  mass: 1,
  tension: 300,
  friction: 26,
};

const AccountMenu = () => {
  const [open, setOpen] = useState(false);
  const { user } = useAuth();
  const [scopedAccount, setScopedAccount] = useScopedAccount();
  const account = useAccountNew(scopedAccount);
  const ref = useRef<HTMLDivElement>(null!);

  useEffect(() => {
    const close = (e: KeyboardEvent) => {
      if (e.keyCode === 27) {
        setOpen(false);
      }
    };
    window.addEventListener("keydown", close);
    return () => window.removeEventListener("keydown", close);
  }, []);

  useClickAway(ref, () => {
    setOpen(false);
  });

  const transitions = useTransition(open, {
    config: springConfig,
    from: {
      opacity: 0,
      transform: "translate3d(0,-100px,0) scale(1, 0.8)",
    },
    enter: { opacity: 1, transform: "translate3d(0,0px,0) scale(1, 1)" },
    leave: {
      opacity: 0,
      transform: "translate3d(0,0px,0) scale(0.8, 0.8)",
    },
  });

  if (user == null) {
    return null;
  }

  const hasManyAccounts = user.ownedAccounts.length > 1;

  return (
    <Container ref={ref as any} ml={[2, 3]}>
      <ButtonContainer active={open} onClick={() => setOpen((old) => !old)}>
        <Circle size="small" color="primary" mr={3}>
          {user.displayName.slice(0, 1)}
        </Circle>
        <Text fontWeight={600} mr={3} mt={"1px"}>
          {user.displayName}
          {hasManyAccounts && (
            <Text ml={1} as="span" fontSize={0} fontWeight={600}>
              ({scopedAccount})
            </Text>
          )}
        </Text>
        <FaChevronCircleDown />
      </ButtonContainer>
      {transitions(
        (props, item) =>
          item && (
            <MenuContainer style={props} key="1">
              <Flex flexDirection="column" alignItems="center">
                <Circle color="primary" mb={3} size="xlarge">
                  <Text fontSize={5} fontWeight={600}>
                    {user.displayName.slice(0, 1)}
                  </Text>
                </Circle>
                <Text fontWeight={600} fontSize={4} mb={3}>
                  {user.displayName}
                </Text>
              </Flex>
              <MenuDivider />
              <MenuItem>
                <MenuLink to={`/account`} onClick={() => setOpen(false)}>
                  <FaUserCog />
                  Manage Account
                </MenuLink>
              </MenuItem>
              <MenuDivider />
              {hasManyAccounts && (
                <>
                  <Flex mb={3} flexDirection="column">
                    {user.ownedAccounts.map((x) => (
                      <AccountSwitchButton
                        key={x.code}
                        onClick={() => {
                          setScopedAccount(x.code);
                          setOpen(false);
                        }}
                      >
                        <Circle color="primary" mr={3}>
                          {x.code.slice(0, 1)}
                        </Circle>
                        <Flex flexDirection="column" alignItems="flex-start">
                          <Text
                            style={{
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              whiteSpace: "nowrap",
                              maxWidth: "220px",
                            }}
                          >
                            {x.name}
                          </Text>
                          <Text fontWeight={600} color="grey3" fontSize={1}>
                            {x.code}
                          </Text>
                        </Flex>
                      </AccountSwitchButton>
                    ))}
                  </Flex>
                  <MenuDivider />
                </>
              )}
              <MenuItem>
                <MenuLink to={`/orders`} onClick={() => setOpen(false)}>
                  <PortalIcon iconType="orderhistory" />
                  Order History
                </MenuLink>
              </MenuItem>
              <MenuItem>
                <MenuLink to={`/financials`} onClick={() => setOpen(false)}>
                  <FaDollarSign />
                  Financials
                </MenuLink>
              </MenuItem>
              <MenuItem>
                <MenuLink to={`/sell-out-lists`} onClick={() => setOpen(false)}>
                  <FaCashRegister size="1.3em" />
                  Sell Out Pricing
                </MenuLink>
              </MenuItem>
              <MenuDivider />
              {user.type === "user" && (
                <MenuItem>
                  <MenuLink to={`/logout?account=${scopedAccount}`}>
                    <FaSignOutAlt size="1.3em" />
                    Logout to {scopedAccount}
                  </MenuLink>
                </MenuItem>
              )}
              <MenuItem>
                <MenuLink to="/logout">
                  <FaSignOutAlt size="1.3em" />
                  Logout
                </MenuLink>
              </MenuItem>
            </MenuContainer>
          ),
      )}
    </Container>
  );
};

export default AccountMenu;
