import {
  AttributeValueDto,
  ProductDto,
  ProductVariantDto,
} from "@oaktyres/model";
import { BasketItem, useProduct } from "@oaktyres/queries";
import { sortBy, sum } from "lodash";
import React, { useState } from "react";
import { FaLayerGroup, FaShoppingCart, FaTimes } from "react-icons/fa";
import { useWindowSize } from "react-use";
import styled from "styled-components";
import { formatCurrency } from "../utils";
import { AccountBrandLabel } from "./AccountBrandLabel";
import Box from "./Box";
import Flex from "./Flex";
import IconButton from "./IconButton";
import Label from "./Label";
import Loader from "./Loader";
import Modal from "./Modal";
import { PanelHeader } from "./Panel";
import { ProductImageSet } from "./ProductImageSet";
import { ProductSelectButton } from "./ProductSelectButton";
import Text from "./Text";
import { useToasts } from "./ToastProvider";

const ProductDescription = styled.div`
  margin-bottom: 18px;
  p,
  ul,
  li {
    font-size: 14px;
  }

  p {
    margin-bottom: 12px;
  }

  p:empty,
  p:-moz-only-whitespace {
    display: none;
  }
`;

const VariantTable = styled.table`
  width: 100%;
  border-collapse: collapse;
  td {
    padding: 6px;
    font-size: 0.9em;
    vertical-align: middle;
  }

  td:last-child {
    padding: 0;
    width: 0;
    padding-left: 6px;
  }

  td:nth-child(2) {
    font-weight: 600;
    text-align: right;
    min-width: 80px;
    color: ${(props) => props.theme.colors.grey1};
  }

  td:first-child {
    padding-left: 0;
    font-weight: 600;
    width: 100%;
  }

  tr:hover td {
    background-color: ${(props) => props.theme.colors.grey6};
  }
`;

export type ProductModalProps = {
  productId: string;
  variantId: string | null;
  accountCode?: string | null;
  onClose: () => void;
  basketItems: BasketItem[];
  onAddToBasket: (stockCode: string, count: number) => boolean;
};

const ProductAttributeDisplay = ({ value }: { value: AttributeValueDto }) => {
  const name = value.attribute.name;
  const { width } = useWindowSize();

  let valueDisplay = "";

  if (value.type === "select") {
    valueDisplay = value.value.map((x) => x.name).join(", ");
  } else if (value.type === "text") {
    valueDisplay = value.value;
  } else if (value.type === "packsize") {
    valueDisplay = `${value.value} per ${value.packName}`;
  } else if (value.type === "number") {
    valueDisplay = Array.isArray(value.value)
      ? `${value.value[0]}-${value.value[1]}`
      : value.value.toLocaleString();
    valueDisplay += value.units;
    if (value.tolerance) {
      valueDisplay += `(±${value.tolerance})`;
    }
  }

  const length = name.length + valueDisplay.length;

  return (
    <Text
      style={{
        width: length > 28 || width < 500 ? "100%" : "50%",
      }}
      mb={[0, 1]}
      pr={2}
    >
      <Text as="span" fontWeight={600} color="grey2" mr={2}>
        {name}:
      </Text>
      <Text as="span" fontWeight={600}>
        {valueDisplay}
      </Text>
    </Text>
  );
};

const mergeAttributes = (
  product: ProductDto,
  variant?: ProductVariantDto,
): AttributeValueDto[] => {
  if (variant == null) {
    return product.attributes;
  }

  return sortBy(
    [
      ...variant.attributes,
      ...product.attributes.filter(
        (x) =>
          !variant.attributes.some((a) => a.attribute.id === x.attribute.id),
      ),
    ],
    (x) => x.attribute.id,
  );
};

export const ProductModal = ({
  productId,
  variantId,
  accountCode,
  onClose,
  basketItems,
  onAddToBasket,
}: ProductModalProps) => {
  const toasts = useToasts();
  const product = useProduct(productId, accountCode ?? undefined);
  const [_selectedVariant, setSelectedVariant] = useState<string | null>(
    variantId ?? null,
  );

  if (!product.isSuccess) {
    return (
      <Modal width={600} onClose={onClose} shown>
        <Loader />
      </Modal>
    );
  }

  const selectedVariant =
    product.data.variants.length === 1
      ? product.data.variants[0].id
      : _selectedVariant;

  const variant = product.data.variants.find(
    (x) => x.id === selectedVariant || x.stockCode === selectedVariant,
  );

  const name = (variantId && variant?.name) ?? product.data.name;
  const images = [...(variant?.images ?? []), ...product.data.images];
  const allSameManu = product.data.variants
    .map((x) => x.manufacturer)
    .every((x, _, self) => x === self[0]);
  const manu = allSameManu ? product.data.variants[0].manufacturer : null;
  const attributes = mergeAttributes(product.data, variant);

  const onBuy = (qty: number) => {
    if (variant != null) {
      onAddToBasket(variant.stockCode, qty);
      toasts.push({
        title: "Basket",
        content: "Item added to basket",
        icon: FaShoppingCart,
      });
    }
  };

  const canBuy = (v: ProductVariantDto): boolean => {
    const count = sum(v.availability?.map((x) => x.qtyAvailable) ?? []) ?? 0;

    return count > 0 || v.noStockBehaviour === "sale";
  };

  const canShow = (v: ProductVariantDto): boolean => {
    if (!v.published) {
      return false;
    }
    const count = sum(v.availability?.map((x) => x.qtyAvailable) ?? []) ?? 0;

    return count > 0 || v.noStockBehaviour !== "hide";
  };

  const isOutOfStock = (v: ProductVariantDto): boolean => {
    return (sum(v.availability?.map((x) => x.qtyAvailable) ?? []) ?? 0) === 0;
  };

  const getMaxQty = (v: ProductVariantDto): number => {
    if (v.noStockBehaviour === "sale") {
      return 50;
    }

    const inStock = sum(v.availability?.map((x) => x.qtyAvailable) ?? []) ?? 0;
    const inBasket = basketItems.find((x) => x.code === v.stockCode)?.qty ?? 0;

    return inStock - inBasket;
  };

  return (
    <Modal width={800} onClose={onClose} shown p={0}>
      <PanelHeader justifyContent={"space-between"}>
        <Text fontWeight={600} fontSize={[2, 3]}>
          {name}
        </Text>
        <IconButton color="body" icon={FaTimes} onClick={onClose} />
      </PanelHeader>
      <Flex alignItems={"flex-start"} flexDirection={["column", "row"]}>
        <Flex width={1 / 3} p={3}>
          <ProductImageSet images={images} />
        </Flex>
        <Flex flex={1}>
          <Box p={3} width="100%">
            <Text fontWeight={600} color="primary" fontSize={[1, 2]}>
              {product.data.type.category.name}
            </Text>
            <Text fontSize={[3, 4]} fontWeight={600} mb={1}>
              {name}
            </Text>
            <Flex mb={3} style={{ gap: 6 }}>
              {manu && <AccountBrandLabel brand={manu} level={null} />}
              <Label withIcon>
                <FaLayerGroup style={{ marginRight: 6 }} />
                {product.data.type.name}
              </Label>
            </Flex>
            {product.data.description && (
              <ProductDescription
                dangerouslySetInnerHTML={{
                  __html: product.data.description.replaceAll(
                    "<p><br></p>",
                    "",
                  ),
                }}
              />
            )}
            <Flex flexWrap="wrap" mb={3}>
              {attributes.map((x) => (
                <ProductAttributeDisplay value={x} />
              ))}
            </Flex>
            <VariantTable>
              <tbody>
                {product.data.variants.filter(canShow).map((x) => (
                  <tr>
                    <td>
                      {x.name}{" "}
                      {isOutOfStock(x) &&
                      !!accountCode &&
                      x.noStockBehaviour === "sale"
                        ? `(${x.leadTime})`
                        : ""}
                    </td>
                    <td>
                      {x.price != null && x.price.value != null
                        ? formatCurrency(x.price.currency)(x.price.value)
                        : ""}
                    </td>
                    <td>
                      <ProductSelectButton
                        maxQty={getMaxQty(x)}
                        allowBuy={!!accountCode && canBuy(x)}
                        onSelect={() => setSelectedVariant(x.id)}
                        selected={variant?.id === x.id}
                        onBuy={onBuy}
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </VariantTable>
          </Box>
        </Flex>
      </Flex>
    </Modal>
  );
};
