import React, { useEffect, useState } from "react";
import { min as lodashMin, max as lodashMax } from "lodash";
import { addMonths, addDays } from "date-fns";
import Flex from "./Flex";
import Text from "./Text";
import Input from "./Input";
import Modal from "./Modal";
import Button from "./Button";
import ReactDatePicker from "react-datepicker";

export type TableFilterDateRangeConfig<T> = {
  type: "daterange";
  getValue: (item: T) => Date | null;
};

export type TableFilterDateRangeValues = {
  min: Date;
  max: Date;
};

export type TableFilterDateRangeModalProps<T> = {
  data: T[];
  config: TableFilterDateRangeConfig<T>;
  values: TableFilterDateRangeValues | null;
  onChange: (val: TableFilterDateRangeValues | null) => void;
};

export function TableFilterDateRange<T>(
  item: T,
  config: TableFilterDateRangeConfig<T>,
  values: TableFilterDateRangeValues
) {
  const val = config.getValue(item);
  if (val == null) {
    return false;
  }
  return val >= values.min && val <= values.max;
}

export function TableFilterDateRangeModal<T>({
  data,
  config,
  values,
  onChange,
}: TableFilterDateRangeModalProps<T>) {
  const [min, setMin] = useState<Date>(
    values?.min ?? addMonths(new Date(), -1)
  );
  const [max, setMax] = useState<Date>(values?.max ?? new Date());

  useEffect(() => {
    if (values == null) {
      const range = data.map(config.getValue).filter((x) => x != null);
      console.log(range);
      setMin(lodashMin(range) ?? addMonths(new Date(), -1));
      setMax(lodashMax(range) ?? new Date());
    }
  }, [values, data, config]);

  const setVals = () => {
    onChange({ min, max });
  };

  const lastNDays = (days: number) => () =>
    onChange({ min: addDays(new Date(), -days), max: new Date() });

  return (
    <Modal p={3} shown width={200}>
      <Flex alignItems="center" mb={3}>
        <ReactDatePicker
          selected={min}
          onChange={(d) => setMin(d as Date)}
          customInput={<Input />}
          dateFormat="dd/MM/yyyy"
        />
        <Text pl={2} pr={2}>
          -
        </Text>
        <ReactDatePicker
          selected={max}
          onChange={(d) => setMax(d as Date)}
          customInput={<Input />}
          dateFormat="dd/MM/yyyy"
        />
      </Flex>
      <Flex flexDirection="column" mb={2}>
        <Button onClick={lastNDays(30)} mb={1}>
          Last 30 Days
        </Button>
        <Button onClick={lastNDays(60)} mb={1}>
          Last 60 Days
        </Button>
        <Button onClick={lastNDays(90)} mb={1}>
          Last 90 Days
        </Button>
      </Flex>
      <Flex>
        <Button onClick={setVals} mr={1} style={{ flex: 1 }}>
          Set
        </Button>
        <Button onClick={() => onChange(null)} ml={1} style={{ flex: 1 }}>
          Clear
        </Button>
      </Flex>
    </Modal>
  );
}
