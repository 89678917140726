import styled from "styled-components";
import {
  grid,
  space,
  layout,
  GridProps,
  SpaceProps,
  LayoutProps
} from "styled-system";

export const Grid = styled.div<GridProps & SpaceProps & LayoutProps>`
  display: grid;
  ${grid};
  ${space};
  ${layout};
`;
