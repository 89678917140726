import {
  AdhocRewardLedgerAdjustment,
  CustomerRewardTemplateDto,
} from "@oaktyres/model";
import axios from "axios";
import { useMutation, useQuery, useQueryClient } from "react-query";

export const getRewardTemplatesForAccount = (code: string) =>
  axios
    .get<CustomerRewardTemplateDto[]>(`/api/v2/accounts/${code}/rewards`)
    .then(({ data }) => data);

export const postRewardAdjustment = (items: AdhocRewardLedgerAdjustment[]) =>
  axios.post("/api/v2/rewards/adjustment", items);

export const usePostRewardAdjustment = () => {
  const queryClient = useQueryClient();

  const mutation = useMutation(
    postRewardAdjustment,

    {
      onSuccess: () => {
        queryClient.invalidateQueries(["rewards-ledger"]);
      },
    },
  );

  return mutation;
};

export const useRewardTemplates = (code: string) =>
  useQuery(["reward-templates", code], () =>
    getRewardTemplatesForAccount(code),
  );
