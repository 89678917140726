import { getLuminance } from "polished";
import React from "react";
import { FaTimes } from "react-icons/fa";
import { IconType } from "react-icons/lib";
import styled, { css } from "styled-components";
import { space, SpaceProps, typography, TypographyProps } from "styled-system";

const getColor = (props: any) => {
  return props.theme.colors[props.color] || props.color || "#f3f3f3";
};

const getTextColor = (props: any) => {
  if (["positive", "warning", "danger", "primary"].includes(props.color)) {
    return "white";
  }
  const col = getColor(props);
  const l = getLuminance(col);
  return l > 0.5 ? "#666" : "white";
};

type LabelProps = SpaceProps &
  TypographyProps &
  React.ComponentPropsWithoutRef<"span"> & {
    children: React.ReactNode | React.ReactNode[];
    // @deprecated
    withIcon?: boolean;
    // @deprecated
    small?: boolean;
    // @deprecated
    warning?: boolean;
    icon?: IconType;
    labelSize?: "tiny" | "small" | "medium" | "large";
    color?: string;
    onRemove?: () => void;
  };

type LabelElemProps = {
  color: string;
  labelSize: "tiny" | "small" | "medium" | "large";
  withIcon: boolean;
} & SpaceProps &
  TypographyProps;

const DeleteButton = styled.button`
  padding: 0;
  display: inline-flex;
  align-items: center;
  margin: 0 6px;
  color: currentcolor;
  opacity: 0.5;
  background: none;
  border: none;
  cursor: pointer;
  padding-left: 6px;

  &:hover {
    opacity: 1;
  }
`;

const LabelElem = styled.span<LabelElemProps>`
  background-color: ${(props) => getColor(props)};
  color: ${(props) => getTextColor(props)};
  font-size: ${(props) =>
    props.labelSize === "large" ? 14 : props.labelSize === "tiny" ? 10 : 12}px;
  font-weight: 600;
  padding: ${(props) =>
    props.labelSize === "tiny"
      ? "1px 3px"
      : props.labelSize === "small"
        ? "3px 6px"
        : props.labelSize === "large"
          ? "8px 12px"
          : "6px 12px"};
  border-radius: 4px;
  border: 1px solid ${(props) => getColor(props)};
  word-break: keep-all;
  display: inline-block;
  user-select: none;
  cursor: default;
  display: inline-flex;
  align-items: center;

  ${(props) =>
    props.withIcon &&
    css`
      display: inline-flex;
      align-items: center;
    `}

  & + & {
    margin-left: ${(props) => (props.labelSize === "small" ? "3px" : "6px")};
  }

  ${space};
  ${typography};
`;

export const Label = ({
  children,
  withIcon,
  small,
  warning,
  icon: Icon,
  labelSize,
  color,
  onRemove,
  ...rest
}: LabelProps) => {
  labelSize = small ? "small" : labelSize;
  color = warning ? "warning" : color;

  return (
    <LabelElem
      withIcon={withIcon ?? false}
      color={color ?? "#f3f3f3"}
      labelSize={labelSize ?? "medium"}
      style={{
        paddingRight: onRemove ? 0 : undefined,
      }}
      {...rest}
    >
      {Icon && <Icon style={{ marginRight: labelSize === "tiny" ? 4 : 8 }} />}
      {children}
      {onRemove && (
        <DeleteButton onClick={onRemove}>
          <FaTimes />
        </DeleteButton>
      )}
    </LabelElem>
  );
};

export default Label;
