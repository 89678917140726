import { PhysicalAddressDto } from "@oaktyres/model";
import { AccountAddressDto } from "@oaktyres/model/dist/api/GetAccountPayload";
import { useSetDeliveryDeliveryAddress } from "@oaktyres/queries";
import {
  Button,
  Circle,
  Flex,
  IconButton,
  Label,
  Modal,
  PanelHeader,
  Text,
} from "@oaktyres/ui";
import React from "react";
import { FaBuilding, FaCheck, FaUserCog } from "react-icons/fa";
import styled from "styled-components";
import { border, BorderProps, space, SpaceProps } from "styled-system";

const ButtonContainer = styled.button<SpaceProps & BorderProps>`
  display: flex;
  align-items: center;
  text-align: left;
  background: none;
  cursor: pointer;

  ${space};
  ${border}
`;

export type AddressSelectorModalProps = {
  accountCode: string;
  options: AccountAddressDto[];
  value: number | null;
  onChange: (value: number | null) => void;
  onClose: () => void;
};

export const AddressSelectorModal = ({
  accountCode,
  options,
  value,
  onChange,
  onClose,
}: AddressSelectorModalProps) => {
  const setDefault = useSetDeliveryDeliveryAddress();

  const changeDefault = (id: number | null) => {
    setDefault.mutateAsync({ code: accountCode, defaultAddressId: id });
  };

  return (
    <Modal onClose={onClose} shown width={600}>
      <Text textAlign={"center"} fontSize={3} fontWeight={600} mb={5}>
        Select Delivery Address
      </Text>
      {options.map((x) => (
        <ButtonContainer
          onClick={() => onChange(x.id)}
          p={3}
          border={1}
          borderColor={x.id === value ? "primary" : undefined}
          borderRadius={6}
          mb={2}
        >
          <Circle
            icon={FaBuilding}
            mr={2}
            color={x.id === value ? "primary" : undefined}
          />
          <Flex flexDirection={"column"}>
            <Flex alignItems={"center"} mb={"2px"}>
              <Text fontWeight={600} lineHeight={1.25}>
                {x.name}
              </Text>
              {x.isDefault && (
                <Label labelSize="tiny" color="positive" ml={1}>
                  Default
                </Label>
              )}
            </Flex>
            <Text color="grey1" fontSize={1}>
              {[x.address1, x.address2, x.city, x.postcode].join(" ")}
            </Text>
          </Flex>
          <Flex justifyContent={"flex-end"} flex={1}>
            {!x.isDefault && (
              <IconButton
                icon={FaUserCog}
                color="primary"
                loading={setDefault.isLoading}
                disabled={setDefault.isLoading}
                onClick={(ev) => {
                  ev.stopPropagation();
                  changeDefault(x.id);
                }}
              />
            )}
            <IconButton
              icon={FaCheck}
              color="primary"
              ml={2}
              onClick={() => onChange(x.id)}
            />
          </Flex>
        </ButtonContainer>
      ))}
      <Flex justifyContent={"center"} mt={2}>
        <Button onClick={onClose}>Close</Button>
      </Flex>
    </Modal>
  );
};
