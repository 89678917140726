import {
  ProductDto,
  ReturnRequestDto,
  ReturnRequestSpec,
  ReturnRequestType,
  TyreSearchPayload,
} from "@oaktyres/model";
import {
  createReturnRequest,
  displayMutationError,
  genericProductToStockItem,
  isProduct,
  useAdviceNote,
  useCreateReturnRequest,
  useManufacturers,
  useStockItem,
} from "@oaktyres/queries";
import {
  Button,
  Flex,
  formatFullFitment,
  Loader,
  ManufacturerLabel,
  Modal,
  OptionButtonSet,
  PanelFooter,
  PanelHeader,
  SelectOption,
  StockImage,
  Text,
} from "@oaktyres/ui";
import React, { useState } from "react";
import { FaBoxOpen, FaChevronRight, FaHandshake, FaStar } from "react-icons/fa";
import { FaultyNonTyreReturnForm } from "./FaultyNonTyreReturnForm";
import { FaultyTyreReturnForm } from "./FaultyTyreReturnForm";
import { NewGoodReturnForm } from "./NewGoodReturnForm";
import { RequestSummary } from "./RequestSummary";

export type ReturnRequestModalProps = {
  adviceNote: string;
  stockCode: string;
  onClose: () => void;
  onSuccess: (newRequestId: string) => void;
};

const returnTypes: SelectOption<ReturnRequestType>[] = [
  {
    value: "faultytyre",
    label: "Manufacturer Warranty Claim",
    icon: FaBoxOpen,
  },
  {
    value: "newgood",
    label: "Return Unused Good",
    icon: FaStar,
  },
  {
    value: "faultynontyre",
    label: "Faulty Good",
    icon: FaBoxOpen,
  },
  //{
  //  value: "dealerwarranty",
  // label: "Dealer Warranty",
  //  icon: FaHandshake,
  // },
];

const productReturnTypes = returnTypes.filter((x) => x.value !== "faultytyre");

const tyreReturnTypes = returnTypes.filter((x) => x.value !== "faultynontyre");

type TypeSelectionProps = {
  item: ProductDto | TyreSearchPayload;
  stockCode: string;
  onSelect: (type: ReturnRequestType) => void;
  onClose: () => void;
};

const TypeSelection = ({
  item,
  stockCode,
  onSelect,
  onClose,
}: TypeSelectionProps) => {
  const [type, setType] = useState<ReturnRequestType | "">("");
  const manus = useManufacturers();

  const stockItem = genericProductToStockItem(item, stockCode);

  const validReturnTypes = isProduct(item)
    ? productReturnTypes
    : tyreReturnTypes;

  const brand = (manus.data ?? []).find(
    (x) => x.crossReference === stockItem.manufacturer,
  );

  return (
    <>
      <Flex p={"30px"} flexDirection="column" alignItems={"center"}>
        <Flex
          p={1}
          border={1}
          borderColor="primary"
          width={450}
          maxWidth={"100%"}
          borderRadius="6px"
          alignItems={"center"}
          mb={5}
        >
          <StockImage item={stockItem} height={90} />
          <Flex flexDirection={"column"}>
            {brand ? (
              <div style={{ fontWeight: 600 }}>
                <ManufacturerLabel
                  mb={"3px"}
                  code={brand.crossReference}
                  name={`${brand.name} ${stockItem.name}`}
                  icon={brand.icon}
                  staticRoot={process.env.REACT_APP_STATIC_ROOT as string}
                />
              </div>
            ) : (
              <Text fontWeight={600} fontSize={[1, 2]}>
                {stockItem.name}
              </Text>
            )}
            <Text fontWeight={600}>{stockItem.fitment}</Text>
            <Text color="grey1" fontWeight={600} fontSize={1} lineHeight={1}>
              {stockCode}
            </Text>
          </Flex>
        </Flex>
        <Text fontWeight={600} mb={3}>
          Please select the reason for your return
        </Text>
        <OptionButtonSet
          value={type}
          onChange={setType}
          options={validReturnTypes}
        />
      </Flex>
      <PanelFooter justifyContent={"space-between"} p={3}>
        <Button color="grey1" onClick={onClose}>
          Cancel
        </Button>
        <Button
          onClick={() => onSelect(type as ReturnRequestType)}
          icon={FaChevronRight}
          disabled={type === ""}
        >
          Next
        </Button>
      </PanelFooter>
    </>
  );
};

export const ReturnRequestModal = ({
  adviceNote,
  stockCode,
  onClose,
}: ReturnRequestModalProps) => {
  const item = useStockItem(stockCode);
  const order = useAdviceNote(adviceNote);
  const createRequest = useCreateReturnRequest();
  const [newReq, setNewReq] = useState<ReturnRequestDto | null>(null);

  const [type, setType] = useState<ReturnRequestType | "">("");

  const returnedQty =
    order.data?.lines
      .find((x) => x.stockCode === stockCode)
      ?.returns.map((x) => x.qty)
      .reduce((acc, val) => acc + val, 0) ?? 0;
  const maxQuantity =
    (order.data?.lines.find((x) => x.stockCode === stockCode)?.quantity ?? 1) -
    returnedQty;

  const onCompletion = async (spec: ReturnRequestSpec) => {
    try {
      const newRequest = await createRequest.mutateAsync(spec);
      setNewReq(newRequest);
    } catch (err) {
      displayMutationError(err);
    }
  };

  const confirmClose = () => {
    if (window.confirm("Are you sure you want to cancel this request?")) {
      onClose();
    }
  };

  return (
    <Modal shown onClose={confirmClose} width={700} maxWidth="100%" p={0}>
      <PanelHeader>
        <Text fontWeight={600} as="h2">
          Return Product
        </Text>
      </PanelHeader>
      {createRequest.isLoading && <Loader overlay />}
      {item.data == null || order.data == null ? (
        <Loader />
      ) : newReq != null ? (
        <RequestSummary item={newReq} onClose={onClose} />
      ) : type === "" ? (
        <TypeSelection
          item={item.data}
          stockCode={stockCode}
          onSelect={setType}
          onClose={confirmClose}
        />
      ) : type === "faultynontyre" ? (
        <FaultyNonTyreReturnForm
          maxQuantity={maxQuantity}
          stockCode={stockCode}
          adviceNote={adviceNote}
          onCompletion={onCompletion}
          onBackOut={() => setType("")}
        />
      ) : type === "newgood" ? (
        <NewGoodReturnForm
          maxQuantity={maxQuantity}
          stockCode={stockCode}
          adviceNote={adviceNote}
          onCompletion={onCompletion}
          onBackOut={() => setType("")}
        />
      ) : type === "faultytyre" ? (
        <FaultyTyreReturnForm
          maxQuantity={maxQuantity}
          stockCode={stockCode}
          adviceNote={adviceNote}
          onCompletion={onCompletion}
          onBackOut={() => setType("")}
        />
      ) : null}
    </Modal>
  );
};
