import React from "react";
import styled from "styled-components";
import { layout, LayoutProps, space, SpaceProps } from "styled-system";
import { Flex, Text, Toggle } from "..";

const Container = styled.div<SpaceProps & LayoutProps>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid ${(props) => props.theme.colors.grey3};
  border-radius: 6px;
  ${space};
  ${layout};
`;

Container.defaultProps = {
  mb: 2,
  p: 2,
};

export type FormToggleProps = {
  value: boolean;
  onChange: (val: boolean) => void;
  label: string;
  subLabel?: string;
} & SpaceProps &
  LayoutProps;

export const FormToggle = ({
  value,
  onChange,
  label,
  subLabel,
  ...rest
}: FormToggleProps) => {
  return (
    <Container {...rest}>
      <Flex justifyContent={"center"} flexDirection="column" mr={2}>
        <Text fontWeight={600}>{label}</Text>
        {subLabel && (
          <Text fontSize={1} color="grey1">
            {subLabel}
          </Text>
        )}
      </Flex>
      <Toggle value={value} onChange={onChange} />
    </Container>
  );
};
