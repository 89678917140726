import { darken } from "polished";
import React from "react";
import { FaPlusCircle } from "react-icons/fa";
import styled from "styled-components";
import {
  display,
  DisplayProps,
  layout,
  LayoutProps,
  space,
  SpaceProps,
} from "styled-system";

const ButtonElem = styled.button<SpaceProps & DisplayProps & LayoutProps>`
  background-color: ${(props) => props.theme.colors.grey4};
  border: 2px dashed ${(props) => props.theme.colors.grey3};
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 12px 12px;
  font-weight: 600;
  color: ${(props) => props.theme.colors.grey1};
  border-radius: 6px;
  cursor: pointer;
  transition: all 0.3s ease;
  ${space};
  ${display};
  ${layout};

  &:hover {
    background-color: ${(props) => darken(0.05, props.theme.colors.grey4)};
    border: 2px dashed ${(props) => props.theme.colors.grey1};
  }

  &:focus,
  &:active {
    border: 2px dashed ${(props) => props.theme.colors.grey1};
  }

  & svg {
    margin-right: 12px;
    width: 24px;
    height: 24px;
  }
`;

export type AddButtonProps = React.ComponentProps<typeof ButtonElem>;

export const AddButton = ({ children, ...props }: AddButtonProps) => (
  <ButtonElem {...props}>
    <FaPlusCircle />
    {children}
  </ButtonElem>
);
