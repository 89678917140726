import { MarkupBandSpec } from "@oaktyres/model";
import {
  Panel,
  Flex,
  Text,
  ButtonSelect,
  Input,
  ControlLabel,
  InputGroup,
  IconButton,
} from "@oaktyres/ui";
import { set } from "lodash";
import React from "react";
import { FaTrash } from "react-icons/fa";

export type MarkupBandEditorProps = {
  value: MarkupBandSpec;
  onChange: (val: MarkupBandSpec) => void;
  onRemove: () => void;
};

const opts = [
  {
    label: "Percent",
    value: "percent",
  },
  {
    label: "Amount",
    value: "value",
  },
];

export const MarkupBandEditor = ({
  value,
  onChange,
  onRemove,
}: MarkupBandEditorProps) => {
  const setProp = (path: string) => (val: any) => {
    if (val?.target) {
      val = val.target.value;
    }

    const data = { ...value };
    set(data, path, val);
    onChange(data);
  };

  const calcMargin = (percent: number | string) => {
    if (typeof percent === "string") {
      percent = parseInt(percent, 10);
    }

    if (Number.isNaN(percent)) {
      return 0;
    }

    return (percent / (100 + percent)) * 100;
  };

  return (
    <Panel mb={3} primary p={3}>
      <Flex
        alignItems={["stretch", "center"]}
        style={{ gap: 12 }}
        flexDirection={["column", "row"]}
      >
        <ControlLabel mb={0}>Start</ControlLabel>
        <InputGroup prefix={"£"}>
          <Input
            value={value.startCost}
            type="number"
            width={100}
            onChange={setProp("startCost")}
          />
        </InputGroup>
        <ControlLabel mb={0}>End</ControlLabel>
        <InputGroup prefix={"£"}>
          <Input
            value={value.endCost ?? ""}
            type="number"
            width={100}
            onChange={setProp("endCost")}
          />
        </InputGroup>
        <ControlLabel mb={0}>Markup Type</ControlLabel>
        <ButtonSelect
          options={opts}
          value={value.type}
          onChange={setProp("type")}
        />
        <InputGroup
          prefix={value.type === "value" ? "£" : undefined}
          postfix={value.type === "percent" ? "%" : undefined}
          width={130}
        >
          <Input
            value={value.amount}
            type="number"
            onChange={setProp("amount")}
          />
        </InputGroup>
        {value.type === "percent" && (
          <Text color="grey1" fontWeight={600} style={{ whiteSpace: "nowrap" }}>
            ({calcMargin(value.amount).toFixed(0)}% margin)
          </Text>
        )}
        <IconButton icon={FaTrash} color="danger" onClick={onRemove} />
      </Flex>
    </Panel>
  );
};
