import React, { useEffect, useRef, useState } from "react";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import { useLocation } from "react-router";
import { animated, useSpring } from "react-spring";
import { useSwipeable } from "react-swipeable";
import { useWindowSize } from "react-use";
import styled, { css } from "styled-components";
import { layout, LayoutProps } from "styled-system";
import { useOnClickOutside } from "../utils/useOnClickOutside";

export const FixedSidebarContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
`;

export const FixedSidebarMain = styled.main`
  flex: 1;
  background-color: #fafafa;
  overflow-y: auto;
`;

const Wrapper = styled.div`
  height: 100%;
  overflow-y: auto;
`;

type ContainerProps = {
  side: "left" | "right";
  width: number;
};

type HideButtonProps = {
  side: "left" | "right";
};

const Container = styled(animated.div)<ContainerProps>`
  ${(props) =>
    props.side === "left"
      ? css`
          left: 0;
          box-shadow: 3px 0 3px rgba(0, 0, 0, 0.14);
        `
      : css`
          right: 0;
          box-shadow: -3px 0 3px rgba(0, 0, 0, 0.14);
        `};
  width: ${(props) => props.width}px;
  background: white;
  z-index: 1000;
  height: 100%;
  position: fixed;
  top: 0;
  height: 100%;
`;

const HideButton = styled.button<HideButtonProps & LayoutProps>`
  position: absolute;
  border: 1px solid transparent;
  ${(props) =>
    props.side === "left"
      ? css`
          left: 100%;
          box-shadow: 3px 0 3px rgba(0, 0, 0, 0.14);
          border-top-right-radius: 3px;
          border-bottom-right-radius: 3px;
          border-left: 0;
        `
      : css`
          left: -28px;
          box-shadow: -3px 0 3px rgba(0, 0, 0, 0.14);
          border-top-left-radius: 3px;
          border-bottom-left-radius: 3px;
          border-right: 0;
        `};
  top: 40px;
  width: 28px;
  height: 32px;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  ${layout};
  &:hover {
    background-color: ${(props) => props.theme.colors.body};
    color: white;
  }
`;

type FloatingSidebarProps = {
  children: React.ReactNode;
  side: "left" | "right";
  floatWidth: number;
  width: number;
  alwaysFloat?: boolean;
};

export const Sidebar = ({
  children,
  side,
  floatWidth,
  width,
  alwaysFloat,
}: FloatingSidebarProps) => {
  const { width: windowWidth } = useWindowSize();
  const [open, setOpen] = useState(!alwaysFloat && windowWidth > floatWidth);

  const location = useLocation();
  const ref = useRef<HTMLDivElement>(null!);
  const shouldFloat = alwaysFloat || windowWidth < floatWidth;

  useEffect(() => {
    setOpen(false);
  }, [location]);

  useOnClickOutside(ref, () => {
    setOpen(false);
  });

  useEffect(() => {
    if (!alwaysFloat && windowWidth > floatWidth) {
      setOpen(true);
    } else if (windowWidth < floatWidth) {
      setOpen(false);
    }
  }, [alwaysFloat, floatWidth, windowWidth]);

  const handlers = useSwipeable({
    onSwipedLeft: () => setOpen(side === "right"),
  });

  useEffect(() => {
    (handlers as any).ref(document);
  });

  const toggle = () => {
    setOpen((old) => !old);
  };

  const props = useSpring({
    transform: `translate3d(${
      (open || !shouldFloat ? 0 : width + 2) * (side === "left" ? -1 : 1)
    }px, 0px, 0px)`,
  });

  const OpenIcon = side === "left" ? FaChevronLeft : FaChevronRight;
  const CloseIcon = side === "right" ? FaChevronLeft : FaChevronRight;

  return (
    <Container
      style={{
        ...props,
      }}
      {...handlers}
      ref={ref}
      width={width}
      side={side}
    >
      <HideButton
        onClick={toggle}
        side={side}
        display={windowWidth < floatWidth || alwaysFloat ? "flex" : "none"}
      >
        {open ? <OpenIcon /> : <CloseIcon />}
      </HideButton>
      <Wrapper>{children}</Wrapper>
    </Container>
  );
};
