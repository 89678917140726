import styled from "styled-components";

export const MiniTable = styled.table`
  font-size: 12px;
  border-collapse: collapse;
  width: 100%;

  th,
  td {
    padding: 2px 4px;
    font-variant-numeric: tabular-nums;
  }

  th {
    background-color: #efefef;
  }
  th:empty {
    background-color: white;
  }

  td {
    border: 1px solid #efefef;
  }

  td.highlight {
    font-weight: 600;
    background-color: #f3fff2;
  }

  td.right {
    text-align: right;
  }

  td.numeric {
    text-align: right;
    font-size: 11px;
    font-family: ${(props) => props.theme.fonts.mono};
  }
`;
