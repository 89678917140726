import { Box, Button, Flex, Modal, PanelHeader, Text } from "@oaktyres/ui";
import React from "react";
import { FaQuestionCircle } from "react-icons/fa";

type StockCheckHelpModalProps = {
  onClose: () => void;
};

export const StockCheckHelpModal = ({ onClose }: StockCheckHelpModalProps) => {
  return (
    <Modal shown onClose={onClose} p={0} width={500}>
      <PanelHeader>
        <Flex alignItems="center">
          <FaQuestionCircle style={{ marginRight: 6 }} />
          <Text fontWeight={600} fontSize={3}>
            What's This?
          </Text>
        </Flex>
      </PanelHeader>
      <Box p={3}>
        <Text mb={1}>
          In Stock Check Mode you can simply mark how many of each tyre you have
          in stock and we'll automatically generate an order to fill your
          imprest stock.
        </Text>
        <Text mb={3}>
          Alternatively, you can disable Stock Check Mode and simply select how
          many of each tyre you'd like to order.
        </Text>
        <Button onClick={onClose} color="primary">
          Ok
        </Button>
      </Box>
    </Modal>
  );
};
