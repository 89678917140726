import React from "react";
import Input, { InputGroup } from "./Input";
import FormInputWrap, { FormInputWrapProps } from "./FormInputWrap";

type FormInputProps = FormInputWrapProps &
  React.ComponentPropsWithoutRef<"input">;

const FormInput = React.forwardRef<HTMLInputElement, FormInputProps>(
  (
    {
      name,
      label,
      placeholder,
      type,
      error,
      defaultValue,
      required,
      value,
      disabled,
      readOnly,
      maxLength,
      step,
      onChange,
      ...rest
    }: FormInputProps,
    ref,
  ) => (
    <FormInputWrap
      name={name}
      label={label}
      error={error}
      required={required}
      {...rest}
    >
      <Input
        step={step}
        onChange={onChange}
        maxLength={maxLength}
        readOnly={readOnly}
        disabled={disabled}
        name={name}
        value={value}
        placeholder={placeholder}
        autoComplete="no"
        ref={ref}
        type={type}
        defaultValue={defaultValue}
      />
    </FormInputWrap>
  ),
);

export default FormInput;
