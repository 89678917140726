import { ClosedPeriodDto } from "@oaktyres/model";
import axios from "axios";
import { useMutation, useQuery, useQueryClient } from "react-query";

export async function getClosedPeriods(): Promise<ClosedPeriodDto[]> {
  return axios
    .get<ClosedPeriodDto[]>("/api/v2/closed-periods")
    .then(({ data }) => data);
}

export const createClosedPeriod = (item: Partial<ClosedPeriodDto>) =>
  axios.post("/api/v2/closed-periods", item);

export const updateClosedPeriod = (item: Partial<ClosedPeriodDto>) =>
  axios.put(`/api/v2/closed-periods/${item.id}`, item);

export const deleteClosedPeriod = (id: string) =>
  axios.delete(`/api/v2/closed-periods/${id}`);

export const useClosedPeriods = () =>
  useQuery("closedperiods", getClosedPeriods);

export const useCreateClosedPeriod = () => {
  const queryClient = useQueryClient();

  const mutation = useMutation(
    (spec: Partial<ClosedPeriodDto>) => createClosedPeriod(spec),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["closedperiods"]);
      },
    },
  );

  return mutation;
};

export const useDeleteClosedPeriod = () => {
  const queryClient = useQueryClient();

  const mutation = useMutation((id: string) => deleteClosedPeriod(id), {
    onSuccess: () => {
      queryClient.invalidateQueries(["closedperiods"]);
    },
  });

  return mutation;
};

export const useUpdateClosedPeriod = () => {
  const queryClient = useQueryClient();

  const mutation = useMutation(
    (item: Partial<ClosedPeriodDto>) => updateClosedPeriod(item),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["closedperiods"]);
      },
    },
  );

  return mutation;
};
