import React from "react";
import { useCallback, useEffect, useMemo, useState } from "react";

export type UsePaginationResult<T> = {
  data: T[];
  activePage: number;
  pageCount: number;
  nextPage: () => void;
  prevPage: () => void;
  hasNextPage: boolean;
  hasPrevPage: boolean;
  setPage: (newPage: number) => void;
};

const calcPageCount = (items: any[], pageSize: number) =>
  Math.ceil(items.length / pageSize);

export function usePagination<T>(items: T[], pageSize = 50, startingPage = 1) {
  const [page, setPage] = useState<number>(startingPage);

  useEffect(() => {
    setPage(startingPage);
  }, [items.length, startingPage]);

  const data = useMemo(() => {
    return items.slice((page - 1) * pageSize, (page - 1) * pageSize + pageSize);
  }, [page, items, pageSize]);

  const nextPage = useCallback(() => {
    setPage((old) => old + 1);
  }, []);

  const prevPage = useCallback(() => {
    setPage((old) => Math.max(1, old - 1));
  }, []);

  const pageCount = calcPageCount(items, pageSize);
  const hasPrevPage = page > 1;
  const hasNextPage = page < pageCount;

  return {
    data,
    activePage: page,
    pageCount,
    nextPage,
    prevPage,
    setPage,
    hasNextPage,
    hasPrevPage,
  };
}
