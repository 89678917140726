import React, { useEffect, useMemo, useState } from "react";
import Flex from "./Flex";
import Text from "./Text";
import Input from "./Input";
import Modal from "./Modal";
import Button from "./Button";
import { uniq } from "lodash";
import { MultiSelect } from "./MultiSelect";

export type TableFilterUniqueConfig<T> = {
  type: "unique";
  getValue: (item: T) => string;
  format: (val: string) => string;
};

export type TableFilterUniqueValues = {
  selected: string[];
};

export type TableFilterUniqueModalProps<T> = {
  data: T[];
  config: TableFilterUniqueConfig<T>;
  values: TableFilterUniqueValues | null;
  onChange: (val: TableFilterUniqueValues | null) => void;
};

export function TableFilterUnique<T>(
  item: T,
  config: TableFilterUniqueConfig<T>,
  values: TableFilterUniqueValues,
) {
  return values.selected.includes(config.getValue(item));
}

export function TableFilterUniqueModal<T>({
  data,
  config,
  values,
  onChange,
}: TableFilterUniqueModalProps<T>) {
  const [sel, setSel] = useState<string[]>(values?.selected ?? []);

  const setVals = () => {
    onChange({ selected: sel });
  };

  const options = useMemo(() => {
    return uniq(data.map(config.getValue)).map((x) => ({
      value: x,
      label: config.format(x),
    }));
  }, [config, data]);

  return (
    <Modal p={3} shown width={200}>
      <Flex alignItems="center" mb={3}>
        <MultiSelect value={sel} options={options} onChange={setSel} />
      </Flex>
      <Flex>
        <Button onClick={setVals} mr={1} style={{ flex: 1 }}>
          Set
        </Button>
        <Button onClick={() => onChange(null)} ml={1} style={{ flex: 1 }}>
          Clear
        </Button>
      </Flex>
    </Modal>
  );
}
