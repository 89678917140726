import {
  Button,
  Flex,
  FormInputWrap,
  PanelFooter,
  QuantityInput,
  Text,
  TextArea,
} from "@oaktyres/ui";
import React from "react";
import { useState } from "react";
import { FaChevronRight } from "react-icons/fa";

type FaultyNonTyreReturnSpec = {
  type: "faultynontyre";
  stockCode: string;
  adviceNote: string;
  quantity: number;
  complaint: string;
};

export type FaultyNonTyreReturnFormProps = {
  stockCode: string;
  adviceNote: string;
  maxQuantity: number;
  onCompletion: (spec: FaultyNonTyreReturnSpec) => void;
  onBackOut: () => void;
};

export const FaultyNonTyreReturnForm = ({
  stockCode,
  adviceNote,
  maxQuantity,
  onCompletion,
  onBackOut,
}: FaultyNonTyreReturnFormProps) => {
  const [spec, setSpec] = useState<FaultyNonTyreReturnSpec>({
    stockCode,
    adviceNote,
    type: "faultynontyre",
    quantity: 1,
    complaint: "",
  });

  const complete = () => {
    if (
      window.confirm("Are you sure you want to submit this return request?")
    ) {
      onCompletion(spec);
    }
  };

  return (
    <>
      <Flex p={"30px"} flexDirection="column" alignItems={"center"}>
        <Flex
          border={1}
          borderRadius={6}
          borderColor="grey3"
          width={"100%"}
          p={2}
          justifyContent="space-between"
          alignItems={"center"}
          mb={3}
        >
          <Text fontWeight={600}>Quantity to return</Text>
          <QuantityInput
            value={spec.quantity}
            maxValue={maxQuantity}
            onChange={(quantity) => setSpec((old) => ({ ...old, quantity }))}
          />
        </Flex>
        <FormInputWrap
          label="Brief Description of Complaint"
          name="complaint"
          width={"100%"}
        >
          <TextArea
            height={150}
            value={spec.complaint}
            onChange={(ev) => setSpec({ ...spec, complaint: ev.target.value })}
          />
        </FormInputWrap>
      </Flex>
      <PanelFooter justifyContent={"space-between"} p={3}>
        <Button color="grey1" onClick={onBackOut}>
          Back
        </Button>
        <Button
          onClick={complete}
          icon={FaChevronRight}
          disabled={spec.complaint.trim() === ""}
        >
          Submit
        </Button>
      </PanelFooter>
    </>
  );
};
