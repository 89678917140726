export const dayNames = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];

export const shortDayNames = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

export const formatTimeOfDay = (tod: number | null): string | null => {
  if (tod == null) {
    return null;
  }

  const hours = Math.floor(tod / 60);
  const minutes = tod % 60;

  return [hours, minutes].map((x) => x.toString().padStart(2, "0")).join(":");
};

export const getTimeOfDay = (date: Date): number =>
  date.getHours() * 60 + date.getMinutes();

export const isDayTomorrow = (day: number): boolean => {
  const today = new Date().getDay();

  const nextDay = today === 6 ? 0 : today + 1;

  return day === nextDay;
};

export const getDayName = (day: number, short?: boolean): string => {
  const names = short ? shortDayNames : dayNames;

  return names[day] ?? "Invalid";
};
