import React from "react";
import { Redirect, Route, RouteProps } from "react-router";
import { useAuth } from "./CookieAuth";

type AuthenticatedRouteProps = RouteProps & {};

export const CookieAuthenticatedRoute = ({
  component: Component,
  render,
  ...rest
}: AuthenticatedRouteProps) => {
  const auth = useAuth();

  return (
    <Route
      {...rest}
      render={(props) =>
        auth.user != null ? (
          Component ? (
            <Component {...rest} {...props} />
          ) : render ? (
            render(props)
          ) : null
        ) : (
          <Redirect
            to={{
              pathname: "/login",
              state: { from: props.location },
            }}
          />
        )
      }
    />
  );
};
