import React, { useRef } from "react";
import styled from "styled-components";
import { space, layout, LayoutProps, SpaceProps } from "styled-system";
import { FaTimes } from "react-icons/fa";
import { IconType } from "react-icons";

const Container = styled.div<SpaceProps & LayoutProps>`
  display: flex;
  width: 100%;
  align-items: center;
  padding: 6px 6px;
  padding-bottom: 0;
  border: 1px solid ${(props) => props.theme.colors.grey3};
  border-radius: 4px;
  flex-wrap: wrap;
  position: relative;

  &:focus-within {
    outline: -webkit-focus-ring-color auto 1px;
  }

  ${space};
  ${layout};
`;

const Input = styled.input`
  margin-left: 6px;
  margin-bottom: 6px;
  min-width: 0;
  border: 0;
  height: 28px;

  &:focus {
    outline: 0;
  }
`;

const Option = styled.span<{ color: string }>`
  background-color: ${(props) =>
    props.theme.colors[props.color] ?? props.color};
  color: white;
  font-weight: 600;
  padding: 3px;
  display: inline-flex;
  align-items: center;
  border-radius: 3px;
  margin-right: 6px;
  margin-bottom: 6px;

  & > svg {
    margin: 0 6px;
  }
`;

const DeleteButton = styled.button`
  padding: 0;
  display: inline-flex;
  align-items: center;
  margin: 0 6px;
  color: white;
  opacity: 0.5;
  background: none;
  border: none;
  cursor: pointer;

  &:hover {
    opacity: 1;
  }
`;

export type LabelInputProps = {
  items: {
    id: string;
    color: string;
    icon: IconType;
    label: string;
  }[];
  onItemRemove: (id: string) => void;
  value: string;
  onChange?: (ev: React.ChangeEvent<HTMLInputElement>) => void;
  onKeyDown?: (ev: React.KeyboardEvent<HTMLInputElement>) => void;
  onFocus?: () => void;
  onBlur?: () => void;
};

export const LabelInput = ({
  items,
  onItemRemove,
  value,
  onChange,
  onKeyDown,
  onFocus,
  onBlur,
}: LabelInputProps) => {
  const inputRef = useRef<HTMLInputElement>(null!);

  return (
    <Container onClick={() => inputRef.current.focus()}>
      {items.map(({ id, color, icon: Icon, label }) => (
        <Option key={id} color={color}>
          <Icon />
          {label}
          <DeleteButton onClick={() => onItemRemove(id)}>
            <FaTimes />
          </DeleteButton>
        </Option>
      ))}
      <Input
        ref={inputRef}
        onChange={onChange}
        value={value}
        onBlur={onBlur}
        onFocus={onFocus}
        onKeyDown={onKeyDown}
        placeholder="Start typing..."
        autoFocus
      />
    </Container>
  );
};

export default LabelInput;
