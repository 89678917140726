import React from "react";
import styled from "styled-components";
import { FaCheck, FaLock, FaClock, FaQuestion } from "react-icons/fa";
import { space, SpaceProps } from "styled-system";

const Label = styled.label<SpaceProps>`
  display: flex;
  align-items: center;
  user-select: none;
  ${space};
`;

type BoxProps = {
  active: boolean;
  grey: boolean;
};

const Box = styled.span<BoxProps>`
  width: 24px;
  height: 24px;
  border: 1px solid
    ${(props) => (props.active ? props.theme.colors.positive : "#ccc")};
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin-right: 6px;
  border-radius: 3px;
  color: ${(props) =>
    props.active
      ? props.theme.colors.positive
      : props.grey
        ? "#ccc"
        : "transparent"};
  cursor: pointer;
`;

type CheckboxProps = SpaceProps & {
  value: boolean | number | null;
  label?: string;
  onChange?: (newValue: boolean | null) => void;
  name?: string;
  waiting?: boolean;
  disabled?: boolean;
  allowNull?: boolean;
};

const CheckBox = ({
  value,
  label,
  onChange,
  name,
  waiting,
  disabled,
  allowNull,
  ...rest
}: CheckboxProps) => {
  const cycle = () => {
    if (onChange == null) return;
    if (!allowNull) {
      onChange(!value);
    } else {
      if (value == null) {
        onChange(false);
      } else if (value === false || value === 0) {
        onChange(true);
      } else {
        onChange(null);
      }
    }
  };

  return (
    <Label {...rest} onClick={cycle}>
      <input type="hidden" name={name} value={value ? 1 : 0} />
      <Box
        active={value == true}
        grey={!!((allowNull && value == null) || disabled || waiting)}
        style={{ marginRight: label ? 6 : 0 }}
      >
        {disabled ? (
          <FaLock />
        ) : waiting ? (
          <FaClock />
        ) : allowNull && value == null ? (
          <FaQuestion />
        ) : (
          <FaCheck />
        )}
      </Box>
      {label}
    </Label>
  );
};

export default CheckBox;
