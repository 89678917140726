import { PhysicalAddressDto } from "@oaktyres/model";
import { sortBy } from "lodash";
import axios from "axios";
import React, { useState } from "react";
import Button from "./Button";
import Flex from "./Flex";
import Text from "./Text";
import FormInput from "./FormInput";
import FormInputWrap from "./FormInputWrap";
import Input from "./Input";
import styled from "styled-components";

const Drawer = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background: white;
  top: 0;
  left: 0;
  transition: all 0.6s ease;
  overflow-y: auto;
  padding: ${(props) => props.theme.space[3]}px;

  ::-webkit-scrollbar {
    width: 5px;
  }

  ::-webkit-scrollbar-track {
    background: ${(props) => props.theme.colors.grey5};

    border-radius: 10px;
  }

  ::-webkit-scrollbar-thumb {
    background: ${(props) => props.theme.colors.grey2};
    border-radius: 10px;
  }
`;

export type AddressInputProps = {
  value: PhysicalAddressDto;
  onChange: (newValue: PhysicalAddressDto) => void;
  label: string;
};

export const AddressInput = ({ value, onChange, label }: AddressInputProps) => {
  const [options, setOptions] = useState<PhysicalAddressDto[] | null>(null);
  const [loading, setLoading] = useState(false);

  const changeFromEv =
    (key: keyof PhysicalAddressDto) =>
    (ev: React.ChangeEvent<HTMLInputElement>) => {
      onChange({
        ...value,
        [key]: ev.target.value,
      });
    };

  const lookup = () => {
    setLoading(true);
    axios
      .get<PhysicalAddressDto[]>(`/api/v2/postcode/${value.postcode}`)
      .then(({ data }) => {
        if (data.length === 0) {
          window.alert("No addresses found");
        } else {
          setOptions(data);
        }
      })
      .finally(() => setLoading(false));
  };

  return (
    <FormInputWrap label={label}>
      <Flex
        border={1}
        borderRadius="3px"
        flexDirection={"column"}
        p={3}
        overflow="hidden"
        style={{ position: "relative" }}
      >
        <FormInputWrap label="Post Code">
          <Flex alignItems={"center"}>
            <Input value={value.postcode} onChange={changeFromEv("postcode")} />
            <Button onClick={lookup} ml={2} disabled={loading}>
              Lookup
            </Button>
          </Flex>
        </FormInputWrap>
        <FormInput
          label="Address 1"
          value={value.address1}
          onChange={changeFromEv("address1")}
        />
        <FormInput
          label="Address 2"
          value={value.address2}
          onChange={changeFromEv("address2")}
        />
        <FormInput
          label="City"
          value={value.city}
          onChange={changeFromEv("city")}
        />
        <FormInput
          label="County"
          value={value.county}
          onChange={changeFromEv("county")}
          mb={0}
        />
        <Drawer
          style={{
            transform: `translate3d(0, ${options == null ? 100 : 0}%, 0)`,
          }}
        >
          {sortBy(options ?? [], (x) => parseInt(x.address1, 10)).map((x) => (
            <Flex mb={1}>
              <Flex flex={1} flexDirection="column">
                <Text fontSize={1}>{x.address1}</Text>
              </Flex>
              <Button
                ml={2}
                small
                onClick={() => {
                  setOptions(null);
                  onChange(x);
                }}
              >
                Select
              </Button>
            </Flex>
          ))}
        </Drawer>
      </Flex>
    </FormInputWrap>
  );
};
