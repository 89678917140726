import { ReturnRequestDto } from "@oaktyres/model";
import { Text, Button, Flex } from "@oaktyres/ui";
import React from "react";

export type RequestSummaryProps = {
  item: ReturnRequestDto;
  onClose: () => void;
};

export const RequestSummary = ({ item, onClose }: RequestSummaryProps) => {
  return (
    <Flex flexDirection={"column"} alignItems="center" p="30px">
      <Text fontWeight={600}>Thank you for your request</Text>
      <Text color="grey1" fontSize={1} fontWeight={600} mb={3}>
        Your return request has been received
      </Text>
      <Text fontWeight={600}>Your reference number is:</Text>
      <Text fontFamily={"mono"} color="primary" fontSize={3} mb={5}>
        {item.reference}
      </Text>
      <Button onClick={onClose}>Close</Button>
    </Flex>
  );
};
