import React, { ComponentProps } from "react";
import styled, { css } from "styled-components";
import { space, layout, SpaceProps, LayoutProps } from "styled-system";

export const Input = styled.input<SpaceProps & LayoutProps>`
  display: block;
  padding: 8px 12px;
  border: 1px solid ${(props) => props.theme.colors.grey3};
  border-radius: 4px;
  max-width: 100%;
  ${space};
  ${layout};

  &[type="color"] {
    padding: 1px 2px;
    height: 38px;
    width: 100px;
  }

  &[readonly] {
    background-color: ${(props) => props.theme.colors.grey5};
    cursor: not-allowed;
  }
`;

Input.defaultProps = {
  mb: 0,
  autoComplete: "no",
  width: "100%",
};

const Container = styled.div<SpaceProps & LayoutProps>`
  display: flex;
  border: 1px solid ${(props) => props.theme.colors.grey3};
  border-radius: 4px;
  max-width: 100%;

  &:focus-within {
    border-color: ${(props) => props.theme.colors.primary};
  }

  & ${Input} {
    border: 0;

    &:active,
    &:focus-visible {
      outline: 0;
    }
  }

  ${space};
  ${layout};
`;

const FixCommon = css`
  background: ${(props) => props.theme.colors.grey4};
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 12px;
  color: ${(props) => props.theme.colors.grey1};
  font-weight: 600;
`;

const Prefix = styled.span`
  ${FixCommon};
  border-right: 1px solid ${(props) => props.theme.colors.grey3};
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
`;

const Postfix = styled.span`
  ${FixCommon};
  border-left: 1px solid ${(props) => props.theme.colors.grey3};
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
`;

type InputGroupProps = {
  prefix?: string;
  postfix?: string;
  children: React.ReactNode;
} & ComponentProps<typeof Container>;

export const InputGroup = ({
  prefix,
  postfix,
  children,
  ...rest
}: InputGroupProps) => {
  return (
    <Container {...rest}>
      {prefix && <Prefix>{prefix}</Prefix>}
      {children}
      {postfix && <Postfix>{postfix}</Postfix>}
    </Container>
  );
};

export default Input;
