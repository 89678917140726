import { InvoiceLineResolutionDto, OrderHistoryDto } from "@oaktyres/model";
import {
  useAccountNew,
  useFullOrderHistory,
  useInfiniteOrderHistorySearch,
} from "@oaktyres/queries";
import {
  AccountBrandLabel,
  Box,
  Button,
  Flex,
  formatCurrency,
  InputWithSearch,
  LinkButton,
  Loader,
  Panel,
  Select,
  Table,
  TabList,
  Text,
  UniversalProductModal,
  useAuth,
} from "@oaktyres/ui";
import axios from "axios";
import {
  addMonths,
  endOfMonth,
  format,
  fromUnixTime,
  startOfMonth,
} from "date-fns";
import { range } from "lodash";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  FaPhone,
  FaQuestion,
  FaShoppingCart,
  FaThumbsUp,
  FaUndo,
} from "react-icons/fa";
import { useHistory, useLocation } from "react-router";
import { useDebounce } from "react-use";
import styled from "styled-components";
import { useScopedBasket } from "../../basket";
import { DocumentModal } from "../../components/DocumentModal";
import { ReturnRequestModal } from "../../components/ReturnRequestModal";
import { useScopedAccount } from "../../components/ScopedAccountProvider";
import opMark from "../../img/opmark.svg";
import { ResolutionModal } from "./ResolutionModal";

const DISABLE_RETURNS = process.env.REACT_APP_ENVIRONMENT === "production";

const ReturnButton = styled(Button)`
  display: none;
  font-size: 10px;
  padding: 1px;
  padding-bottom: 0;
  min-width: 60px;
  ${DISABLE_RETURNS ? "display: none !important;" : ""}

  &:disabled {
    display: flex;
    background-color: ${(props) => props.theme.colors.grey1};
  }
`;

const LineTable = styled(Table)`
  table-layout: fixed;

  th {
    padding: 6px 12px !important;
    font-weight: 600;
  }

  td {
    vertical-align: middle;
  }

  tr:hover ${ReturnButton} {
    display: block;
  }
`;

type OrderDisplayProps = {
  item: OrderHistoryDto;
  onShowStock: (code: string) => void;
  onViewDocument: (invnum: string) => void;
  onShowResolution: (res: InvoiceLineResolutionDto) => void;
  onReturnItem: (adviceNoteNumber: string, stockCode: string) => void;
};

const HeadingLabel = styled.p`
  flex: 1;
  font-size: 14px;
  white-space: nowrap;

  & strong {
    font-weight: 600;
    color: #333;
    margin-right: 6px;
  }
`;

const Source = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  & img,
  & svg {
    max-height: 32px;
    max-width: 32px;
    width: 32px;
  }
`;

const OrderDisplay = React.memo(
  ({
    item,
    onShowStock,
    onReturnItem,
    onShowResolution,
    onViewDocument,
  }: OrderDisplayProps) => {
    const canReturn = (
      item: OrderHistoryDto,
      i: OrderHistoryDto["lines"][number],
    ) => {
      const returnedQty = i.returns
        .map((x) => x.quantity)
        .reduce((acc, val) => acc + val, 0);

      return (
        item.adviceNoteNumber != null &&
        i.status !== "credited" &&
        i.status !== "backorder" &&
        i.price > 0 &&
        returnedQty < i.qty
      );
    };

    const isPartialReturn = (i: OrderHistoryDto["lines"][number]) => {
      const returnedQty = i.returns
        .map((x) => x.quantity)
        .reduce((acc, val) => acc + val, 0);

      return returnedQty > 0 && returnedQty < i.qty;
    };

    return (
      <Panel mb={3}>
        <Flex width="100%" p={3} mb={[0, 1]}>
          <Flex
            flex="1"
            flexWrap={"wrap"}
            justifyContent="space-between"
            style={{ gap: 6 }}
          >
            <HeadingLabel>
              <strong>Date:</strong>
              {format(fromUnixTime(item.date), "dd/MM/yyyy HH:mm")}
            </HeadingLabel>
            <HeadingLabel>
              <strong>Order Ref:</strong>
              {item.orderReference}
            </HeadingLabel>
            <HeadingLabel>
              {item.reg && (
                <>
                  <strong>Registration:</strong>
                  {item.reg}
                </>
              )}
            </HeadingLabel>
            <HeadingLabel>
              <strong>Oak AD Ref:</strong>
              {item.adviceNoteNumber}
            </HeadingLabel>
          </Flex>
          <Flex justifyContent="flex-end">
            <HeadingLabel style={{ textAlign: "right" }}>
              {item.fitter === "OP" ? (
                <Source>
                  <img src={opMark} alt="Oak Portal Logo" title="Oak Portal" />
                </Source>
              ) : item.fitter === "GL" ? (
                <Source>
                  <FaShoppingCart />
                </Source>
              ) : (
                <Source>
                  <FaPhone title="Phone Order" />
                </Source>
              )}
            </HeadingLabel>
          </Flex>
        </Flex>
        <Box overflowX={"auto"}>
          <LineTable mb={0}>
            <thead>
              <tr>
                <th style={{ textAlign: "left", width: "140px" }}>
                  Stock Code
                </th>
                <th style={{ textAlign: "left", width: "250px" }}>
                  Description
                </th>
                <th style={{ textAlign: "left", width: "170px" }}>Brand</th>

                <th style={{ textAlign: "right", width: "60px" }}>Qty</th>
                <th style={{ textAlign: "right", width: "100px" }}>
                  Line Price
                </th>
                <th style={{ textAlign: "left", width: "120px" }}>Status</th>
                <th style={{ textAlign: "left", width: "180px" }}>
                  Document(s)
                </th>
                <th style={{ width: "80px" }} />
              </tr>
            </thead>
            <tbody>
              {item.lines.map((x, i) => (
                <tr key={i}>
                  <td
                    style={{
                      whiteSpace: "nowrap",
                    }}
                  >
                    <LinkButton
                      onClick={() => onShowStock(x.stockCode)}
                      style={{
                        fontFamily: "Roboto Mono",
                      }}
                    >
                      {x.stockCode}
                    </LinkButton>
                    {item.resolves && x.resolution?.type !== "alternative" && (
                      <FaThumbsUp
                        title="Resolves a placed order"
                        style={{ marginLeft: 6 }}
                      />
                    )}
                  </td>
                  <td>{x.description}</td>
                  <td>
                    <AccountBrandLabel
                      brand={x.manufacturer}
                      level={null}
                      labelSize="small"
                      disableColors
                    />
                  </td>
                  <td style={{ textAlign: "right" }}>
                    <Flex alignItems={"center"} justifyContent="flex-end">
                      {x.qty}
                      {isPartialReturn(x) ? (
                        <FaUndo
                          title="Partially returned"
                          style={{ marginLeft: 6 }}
                        />
                      ) : null}
                    </Flex>
                  </td>
                  <td style={{ textAlign: "right" }}>
                    {x.resolution?.type === "sell_at_current_price" && (
                      <FaQuestion
                        title="Different price to order"
                        style={{ marginRight: 6 }}
                      />
                    )}
                    {formatCurrency(x.currency)(
                      x.resolution?.type === "alternative" ? 0 : x.price,
                    )}
                  </td>
                  <td>
                    {x.resolution ? (
                      <LinkButton
                        onClick={() => onShowResolution(x.resolution!)}
                      >
                        {getStatusText(x)}
                      </LinkButton>
                    ) : (
                      getStatusText(x)
                    )}
                  </td>
                  <td>
                    {x.documents.map((x) =>
                      x.startsWith("AD") ? (
                        <>{x}</>
                      ) : (
                        <LinkButton
                          onClick={() => onViewDocument(x)}
                          style={{ marginRight: 6, fontFamily: "Roboto Mono" }}
                        >
                          {x}
                        </LinkButton>
                      ),
                    )}
                  </td>
                  <td>
                    <ReturnButton
                      disabled={!canReturn(item, x)}
                      onClick={() =>
                        onReturnItem(item.adviceNoteNumber!, x.stockCode)
                      }
                    >
                      {canReturn(item, x) ? "Return" : "Returned"}
                    </ReturnButton>
                  </td>
                </tr>
              ))}
            </tbody>
          </LineTable>
        </Box>
      </Panel>
    );
  },
);

type Page =
  | "Orders"
  | "Credits"
  | "Rebates"
  | "Warranty Credits"
  | "Back Orders";

const allTabs: Page[] = [
  "Orders",
  "Credits",
  "Rebates",
  "Warranty Credits",
  "Back Orders",
];
const accountTabs: Page[] = ["Orders", "Credits", "Back Orders"];

type OrderLine = OrderHistoryDto["lines"][number];
type LinePredicate = (line: OrderLine) => boolean;

const isRebate = (line: OrderLine) =>
  ["NSADJUSTMENT", "NSREBATE", "CASINGCONTRIBUTI"].includes(line.stockCode);

const isWarrantyCredit = (line: OrderLine) =>
  (!isRebate(line) && line.stockCode.startsWith("NS")) ||
  line.stockCode.startsWith("TLG");

const linePredicates: Record<Page, LinePredicate> = {
  Orders: (line) =>
    !(
      line.stockCode.startsWith("NS") ||
      line.stockCode.startsWith("TLG") ||
      line.stockCode === "CASINGCONTRIBUTI"
    ) &&
    !line.isBackOrder &&
    line.qty > 0,
  Credits: (line) => line.qty < 0 && !isWarrantyCredit(line) && !isRebate(line),
  Rebates: isRebate,
  "Warranty Credits": isWarrantyCredit,
  "Back Orders": (line) => line.isBackOrder,
};

const getStatusText = (line: OrderHistoryDto["lines"][number]) => {
  if (line.qty < 1) {
    return "Credited";
  } else if (line.status === "cancelled") {
    return "Cancelled";
  } else if (line.resolution?.type === "alternative") {
    return "Alternative Sent";
  } else if (line.status === "dataunavailable") {
    return "Data unavailable";
  } else if (line.status === "readyforcollection") {
    return "Ready to collect";
  } else if (line.status === "complete") {
    return "Complete";
  } else if (line.status === "dispatchedbycourier") {
    return "Dispatched by Courier";
  } else if (line.status === "awaitingdispatch") {
    if (line.scheduledDispatch != null) {
      return `Dispatch due @ ${format(
        fromUnixTime(line.scheduledDispatch!),
        "p eee",
      )}`;
    } else {
      return "Awaiting Dispatch";
    }
  } else if (line.status === "credited") {
    return "Credited";
  } else if (line.status === "delivered") {
    return "Delivered";
  } else if (line.status === "outfordelivery") {
    return "Dispatched";
  } else if (line.status === "backorder") {
    return "On Back Order";
  } else if (line.status === "resolved") {
    return "Resolved";
  }

  return "Pending";
};

export const OrderHistoryPage = () => {
  const [account] = useScopedAccount();
  const location = useLocation();
  const history = useHistory();
  const query = new URLSearchParams(location.search);
  const search = query.get("search") ?? "";
  const accountData = useAccountNew(account);
  const auth = useAuth();
  const [month, setMonth] = useState(0);
  const [active, setActive] = useState<number>(0);

  const from = startOfMonth(addMonths(new Date(), month));
  const to = endOfMonth(from);
  const orderHistory = useFullOrderHistory(account, from, to);
  const [shownStock, setShownStock] = useState<string | null>(null);
  const [shownResolution, setShownResolution] =
    useState<InvoiceLineResolutionDto | null>(null);
  const basket = useScopedBasket();
  const [docUrl, setDocUrl] = useState<string | null>(null);
  const [debouncedSearch, setDebouncedSearch] = useState<string>("");
  const [returningItem, setReturningItem] = useState<{
    adviceNote: string;
    stockCode: string;
  } | null>(null);
  const searchData = useInfiniteOrderHistorySearch(
    account,
    debouncedSearch ?? "",
  );

  const setSearch = (val: string) => {
    if (val === "") {
      history.replace(`/orders`);
    } else {
      history.replace(`/orders?search=${val}`);
    }
  };

  const clearSearch = () => setSearch("");

  const dateOptions = useMemo(() => {
    const start = startOfMonth(new Date());
    return range(0, -24).map((x) => ({
      value: x.toString(),
      label: format(addMonths(start, x), "MMM - yy"),
    }));
  }, []);

  const returnItem = useCallback((adviceNote: string, stockCode: string) => {
    setReturningItem({ adviceNote, stockCode });
  }, []);

  const viewDocument = useCallback((invnum: string) => {
    axios
      .get(`/api/v2/documents/${invnum}`)
      .then(({ data }) => {
        setDocUrl(data.url);
      })
      .catch(() => {
        window.alert("Document not yet available.");
      });
  }, []);

  useDebounce(
    () => {
      setDebouncedSearch(search ?? "");
    },
    1000,
    [search],
  );

  const isAccountType = auth.user?.type === "account";
  const hasRebates = accountData.data?.hasRebates ?? false;

  let pages = isAccountType ? accountTabs : allTabs;

  if (!hasRebates) {
    pages = pages.filter((x) => x !== "Rebates");
  }

  useEffect(() => {
    setActive(0);
  }, [pages.length]);

  const page = pages[active];

  const noOrderMessage = `No ${page} available`;
  const pred = linePredicates[page];

  const onBackorders = page === "Back Orders";

  const data = useMemo(() => {
    if (orderHistory.data == null) {
      return [];
    }

    return orderHistory.data
      .map((x) => ({
        ...x,
        lines: x.lines.filter(pred),
      }))
      .filter((x) => x.lines.length > 0);
  }, [pred, orderHistory.data]);

  const hasSearch = search !== "";

  return (
    <>
      {docUrl && <DocumentModal url={docUrl} onClose={() => setDocUrl(null)} />}
      {returningItem && (
        <ReturnRequestModal
          {...returningItem}
          onClose={() => setReturningItem(null)}
          onSuccess={() => setReturningItem(null)}
        />
      )}
      {shownStock && (
        <UniversalProductModal
          stockCode={shownStock}
          accountCode={account}
          onClose={() => setShownStock(null)}
          basketItems={basket.items}
          showHistory="locked"
          onOrderHistorySelect={(item) => {
            history.push(`/orders?search=${item.invoice}`);
            setShownStock(null);
          }}
          onAddToBasket={(stockCode, count, supply) =>
            basket.addToBasket(stockCode, count, supply)
          }
        />
      )}
      {shownResolution && (
        <ResolutionModal
          resolution={shownResolution}
          onClose={() => setShownResolution(null)}
        />
      )}
      <Box width="100%" maxWidth="1600px">
        <Text as="h1" fontSize={4} fontWeight={600} mb={3}>
          Transaction History
        </Text>
        <Flex
          alignItems={"center"}
          flexDirection={["column", "row"]}
          mb={[3, 0]}
        >
          {hasSearch ? (
            <TabList
              key={"search"}
              options={["Search Results"]}
              activeIndex={0}
              onSelect={() => {}}
            />
          ) : (
            <TabList
              options={pages}
              activeIndex={active}
              onSelect={setActive}
            />
          )}
          {!onBackorders && (
            <>
              {
                <InputWithSearch
                  value={search ?? ""}
                  onClear={clearSearch}
                  onChange={(ev) => setSearch(ev.target.value)}
                  searching={searchData.isFetching}
                  width={["100%", "240px"] as any}
                  ml={[0, 3]}
                  mb={[2, 0]}
                />
              }
              <Select
                ml={[0, 3]}
                disabled={hasSearch}
                value={hasSearch ? "" : month.toString()}
                width={["100%", "150px"]}
                onChange={(ev) => setMonth(parseInt(ev.target.value, 10))}
                options={dateOptions}
              />
            </>
          )}
        </Flex>
        {hasSearch ? (
          <>
            {searchData.data?.pages.map((x, i) => (
              <React.Fragment key={i}>
                {x.map((x) => (
                  <OrderDisplay
                    key={x.adviceNoteNumber ?? x.portalId}
                    item={x}
                    onShowStock={setShownStock}
                    onViewDocument={viewDocument}
                    onReturnItem={returnItem}
                    onShowResolution={setShownResolution}
                  />
                ))}
              </React.Fragment>
            ))}
            <Flex justifyContent={"center"}>
              {searchData.hasNextPage ? (
                <Button
                  onClick={() => searchData.fetchNextPage()}
                  disabled={searchData.isFetchingNextPage}
                >
                  {searchData.isFetchingNextPage ? "Fetching" : "Fetch More"}
                </Button>
              ) : searchData.isFetching ? (
                <Text fontWeight={600} color="grey3">
                  Loading...
                </Text>
              ) : (
                <Text fontWeight={600} color="grey3">
                  End of results
                </Text>
              )}
            </Flex>
          </>
        ) : orderHistory.isSuccess ? (
          <>
            {data.map((x) => (
              <OrderDisplay
                key={x.adviceNoteNumber ?? x.portalId}
                item={x}
                onShowStock={setShownStock}
                onViewDocument={viewDocument}
                onReturnItem={returnItem}
                onShowResolution={setShownResolution}
              />
            ))}
            {data.length === 0 && (
              <Flex p={3} justifyContent="center">
                <Text
                  fontWeight={500}
                  color="grey3"
                  fontSize={5}
                  textAlign="center"
                >
                  {noOrderMessage}
                </Text>
              </Flex>
            )}
          </>
        ) : (
          <Loader />
        )}
      </Box>
    </>
  );
};
