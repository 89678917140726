import { Flex, IconButton, Text } from "@oaktyres/ui";
import { lighten } from "polished";
import React, { CSSProperties } from "react";
import {
  DragDropContext,
  Draggable,
  Droppable,
  DropResult,
} from "react-beautiful-dnd";
import { FaGripVertical, FaLayerGroup, FaTrash } from "react-icons/fa";
import styled from "styled-components";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: stretch;
  width: 100%;
  padding: 6px;

  & > button {
    background-color: white;
    border: 0;
  }
`;

const Item = styled.button<{ active: boolean }>`
  border: 0;
  display: flex;
  justify-content: space-between;
  padding: 8px 18px;
  padding-right: 6px;
  align-items: center;
  font-weight: ${(props) => (props.active ? 600 : 500)};
  flex: 1;
  border-radius: 6px;
  cursor: pointer;
  background-color: white;
  transition: background-color 0.3s ease;
  margin-bottom: 6px;

  &:last-child {
    margin-bottom: 0;
  }

  &:hover {
    background-color: ${(props) => lighten(0.45, props.theme.colors.primary)};
  }

  & svg {
    height: 14px;
    margin-right: 12px;
    transition: all 0.3s ease;
    fill: ${(props) =>
      props.active ? props.theme.colors.primary : props.theme.colors.grey2};
  }
`;

export type GroupsListProps = {
  items: string[];
  activeIndex: number;
  emptyMessage?: string;
  onIndexChange: (newIndex: number) => void;
  onItemMove?: ((oldIndex: number, newIndex: number) => void) | null;
  onItemRemove?: (index: number) => void;
};

const StaticListView = ({
  items,
  activeIndex,
  emptyMessage = "No Items",
  onIndexChange,
  onItemRemove,
}: GroupsListProps) => {
  return (
    <Container>
      {items.length === 0 && (
        <Text fontWeight={600} color="grey2" textAlign="center" p={2}>
          {emptyMessage}
        </Text>
      )}
      {items.map((x, i) => (
        <Item
          key={i}
          active={activeIndex === i}
          onClick={() => onIndexChange(i)}
        >
          <FaLayerGroup />
          {x}
          {onItemRemove && (
            <IconButton
              icon={FaTrash}
              onClick={() => onItemRemove(i)}
              color="danger"
            />
          )}
        </Item>
      ))}
    </Container>
  );
};

const getItemStyle = (
  isDragging: boolean,

  draggableStyle: CSSProperties | undefined
): CSSProperties => ({
  userSelect: "none",
  boxShadow: isDragging ? "0px 3px 6px rgba(0,0,0,0.2)" : "none",
  border: isDragging ? 0 : undefined,
  ...draggableStyle,
});

const ReorderableListView = ({
  items,
  activeIndex,
  onIndexChange,
  onItemMove,
  onItemRemove,
}: GroupsListProps) => {
  const onDragEnd = async (result: DropResult) => {
    if (!result.destination) {
      return;
    }

    onItemMove!(result.source.index, result.destination.index);
  };

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="listview">
        {(provided) => (
          <Container {...provided.droppableProps} ref={provided.innerRef}>
            {items.map((x, i) => (
              <Draggable key={i} draggableId={i.toString()} index={i}>
                {(provided, snapshot) => (
                  <Item
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    style={getItemStyle(
                      snapshot.isDragging,
                      provided.draggableProps.style
                    )}
                    key={i}
                    active={activeIndex === i}
                    onClick={() => onIndexChange(i)}
                  >
                    <Flex alignItems="center">
                      <Flex {...provided.dragHandleProps} alignItems="center">
                        <FaGripVertical color="#999" />
                      </Flex>
                      <FaLayerGroup />
                      {x}
                    </Flex>
                    {onItemRemove && (
                      <IconButton
                        icon={FaTrash}
                        color="danger"
                        onClick={(ev) => {
                          ev.preventDefault();
                          ev.stopPropagation();
                          onItemRemove(i);
                        }}
                      />
                    )}
                  </Item>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </Container>
        )}
      </Droppable>
    </DragDropContext>
  );
};

export const GroupsList = (props: GroupsListProps) => {
  if (props.onItemMove == null || props.items.length === 0) {
    return <StaticListView {...props} />;
  } else {
    return <ReorderableListView {...props} />;
  }
};
