import { range } from "lodash";
import React, { useState } from "react";
import styled from "styled-components";
import Flex from "./Flex";

export type ProductImageSetProps = {
  images: string[];
};

const ProductImage = styled.img`
  object-fit: contain;
  aspect-ratio: 1;
  max-width: 100%;
  margin-bottom: 6px;
`;

export const ProductImageSet = ({ images }: ProductImageSetProps) => {
  const [active, setActive] = useState(0);

  return (
    <Flex flexWrap="wrap">
      <ProductImage src={process.env.REACT_APP_STATIC_ROOT + images[active]} />
      {range(images.length)
        .filter((x) => x !== active)
        .map((x) => (
          <ProductImage
            style={{ width: "25%", cursor: "pointer" }}
            src={process.env.REACT_APP_STATIC_ROOT + images[x]}
            onClick={() => setActive(x)}
          />
        ))}
    </Flex>
  );
};
