import {
  AccountLedgerItem,
  CurrencyBalance,
  LedgerAdjustmentItem,
  LedgerReportItem,
  MonthlyLedgerReportItem,
} from "@oaktyres/model";
import axios from "axios";
import { useQuery } from "react-query";

const getCurrentBalances = (accountCode: string) =>
  axios
    .get<CurrencyBalance[]>(`/api/v2/accounts/${accountCode}/balances`)
    .then(({ data }) => data);

const getLedger = async (accountCode: string, month: number, year: number) =>
  axios
    .get<AccountLedgerItem[]>(
      `/api/v2/accounts/${accountCode}/ledger?month=${month}&year=${year}`
    )
    .then(({ data }) => data);

const getBalancesReport = async (month: number, year: number) =>
  axios
    .get<LedgerReportItem[]>(
      `/api/v2/reports/points?month=${month}&year=${year}`
    )
    .then(({ data }) => data);

const getMonthlyBalancesReport = async () =>
  axios
    .get<MonthlyLedgerReportItem[]>(`/api/v2/reports/monthlypoints`)
    .then(({ data }) => data);

const getLedgerAdjustments = async (month: number, year: number) =>
  axios
    .get<LedgerAdjustmentItem[]>(
      `/api/v2/reports/ledger-adjustments?month=${month}&year=${year}`
    )
    .then(({ data }) => data);

export const useLedger = (
  accountCode: string | null,
  month: number,
  year: number
) =>
  useQuery(["ledger", accountCode, month, year], async () =>
    accountCode == null ? null : await getLedger(accountCode, month, year)
  );

export const useBalancesReport = (month: number, year: number) =>
  useQuery(
    ["balances", month, year],
    async () => await getBalancesReport(month, year)
  );

export const useMonthlyBalancesReport = () =>
  useQuery(["monthlybalances"], async () => await getMonthlyBalancesReport());

export const useLedgerAdjustments = (month: number, year: number) =>
  useQuery(
    ["adjustments", month, year],
    async () => await getLedgerAdjustments(month, year)
  );

export const useCurrentBalances = (accountCode: string) =>
  useQuery(["balances", accountCode], () => getCurrentBalances(accountCode));
