import { useAuth } from "@oaktyres/ui";
import React from "react";
import styled from "styled-components";
import AccountMenu from "./AccountMenu";
import { BarBalances } from "./BarBalances";
import { BasketButton } from "./BasketButton";
import { useRetailView } from "./RetailViewProvider";

const Container = styled.header`
  height: 70px;
  background: white;
  border-bottom: 1px solid ${(props) => props.theme.colors.grey3};
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 24px;
`;

export const TopBar = () => {
  const { user } = useAuth();
  const [retailView] = useRetailView();

  return (
    <Container>
      {user?.type === "user" && !retailView && <BarBalances />}
      <BasketButton />
      <AccountMenu />
    </Container>
  );
};
