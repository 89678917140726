import {
  RemittableItemDto,
  RemittanceDto,
  RemittanceSpec,
} from "@oaktyres/model";
import { useCreateRemittance } from "@oaktyres/queries";
import {
  Box,
  Button,
  Flex,
  formatCurrency,
  MiniTable,
  Modal,
  PanelFooter,
  PanelHeader,
  Pips,
  Table,
  Text,
} from "@oaktyres/ui";
import { format, fromUnixTime } from "date-fns";
import { sortBy, sum } from "lodash";
import React from "react";
import { useState } from "react";
import {
  FaArrowCircleRight,
  FaCheck,
  FaCreditCard,
  FaMoneyCheck,
  FaPhone,
  FaPiggyBank,
} from "react-icons/fa";
import styled from "styled-components";
import { useScopedAccount } from "../../components/ScopedAccountProvider";
import { SelectButton } from "./PaymentOptionSelect";

export type RemittanceModalProps = {
  items: RemittableItemDto[];
  onClose: (complete: boolean) => void;
};

const Well = styled.div`
  box-shadow: inset 1px 1px 3px rgba(0, 0, 0, 0.1);
  border: 1px solid ${(props) => props.theme.colors.grey3};
  height: 240px;
  overflow-y: auto;
  margin-bottom: 6px;
  border-radius: 3px;
`;

export const RemittanceModal = ({ items, onClose }: RemittanceModalProps) => {
  const [step, setStep] = useState(0);
  const [paymentMethod, setPaymentMethod] = useState<string>("");
  const [remittance, setRemittance] = useState<RemittanceDto | null>(null);
  const createRemittance = useCreateRemittance();
  const [account] = useScopedAccount();

  const totalValue = sum(items.map((x) => x.amountDue));

  const advance = async () => {
    if (step === 2) {
      onClose(true);
    } else if (step === 1 || totalValue === 0) {
      const spec: RemittanceSpec = {
        accountCode: account,
        paymentMethod: paymentMethod,
        items: items.map((x) => x.camId),
      };
      const r = await createRemittance.mutateAsync(spec);
      setRemittance(r);
      setStep(2);
    } else {
      setStep(step + 1);
    }
  };

  const goBack = () => {
    setStep(step - 1);
  };

  const canAdvance = () => {
    if (createRemittance.isLoading) {
      return false;
    }

    if (step === 0) {
      return true;
    } else if (step === 1) {
      return paymentMethod !== "";
    }

    return true;
  };

  const canGoBack = !createRemittance.isLoading && step === 1;

  return (
    <Modal shown p={0} onClose={() => onClose(false)} width={800}>
      <PanelHeader>
        <Text fontSize={3} fontWeight={600}>
          Remit Payment
        </Text>
      </PanelHeader>
      <Flex
        p={3}
        pb={5}
        height={450}
        flexDirection="column"
        justifyContent={"space-between"}
      >
        {step === 0 ? (
          <Box mb={3}>
            <Text mb={3}>
              Please confirm you'd like to remit payment for the following items
            </Text>
            <Well>
              <Table>
                <thead>
                  <tr>
                    <th style={{ width: 90 }}>Date</th>
                    <th>Account</th>
                    <th>Reference</th>
                    <th className="right">Amount Due</th>
                  </tr>
                </thead>
                <tbody>
                  {sortBy(items, (x) => x.date).map((r) => (
                    <tr>
                      <td>{format(fromUnixTime(r.date), "MMM do")}</td>
                      <td>{r.accountCode}</td>
                      <td>
                        <Text fontFamily={"Roboto Mono"} fontSize="inherit">
                          {r.reference}
                        </Text>
                      </td>
                      <td className="right">
                        {formatCurrency("gbp")(r.amountDue)}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Well>
            <Text textAlign={"right"} fontSize={1} pr={2} fontWeight={600}>
              Total: {formatCurrency("gbp")(totalValue)}
            </Text>
          </Box>
        ) : step === 1 ? (
          <Flex
            mb={3}
            flexDirection="column"
            justifyContent={"center"}
            flex="1"
          >
            <Text fontWeight={600} mb={4} fontSize={3} textAlign="center">
              Choose payment method
            </Text>
            <Flex justifyContent={"center"}>
              <Flex
                style={{ gap: 6 }}
                width="400px"
                alignItems="stretch"
                flexDirection={"column"}
              >
                <SelectButton
                  icon={FaCreditCard}
                  name="Credit Card"
                  active={paymentMethod === "card"}
                  onSelect={() => setPaymentMethod("card")}
                />
                <SelectButton
                  icon={FaMoneyCheck}
                  name="Cheque"
                  active={paymentMethod === "cheque"}
                  onSelect={() => setPaymentMethod("cheque")}
                />
                <SelectButton
                  icon={FaPiggyBank}
                  name="Bank Transfer"
                  active={paymentMethod === "banktransfer"}
                  onSelect={() => setPaymentMethod("banktransfer")}
                />
              </Flex>
            </Flex>
          </Flex>
        ) : totalValue === 0 ? (
          <Flex mb={3} alignItems="center" flexDirection={"column"}>
            <Text fontWeight={600} fontSize={3} mb={3}>
              Thank you
            </Text>
            <Box width="400px" maxWidth={"100%"}>
              <Text mb={3}>
                Thank you for remitting payment. As the total value of the
                remittance is 0, no futher action is required.
              </Text>
            </Box>
          </Flex>
        ) : (
          <Flex mb={3} alignItems="center" flexDirection={"column"}>
            <Text fontWeight={600} fontSize={3} mb={3}>
              Payment Instructions
            </Text>
            {remittance?.paymentMethod === "banktransfer" ? (
              <>
                <Box width="400px" maxWidth={"100%"}>
                  <Text mb={3}>
                    Please make a payment to the following account:
                  </Text>
                  <Flex justifyContent={"center"} mb={3}>
                    <MiniTable style={{ width: 300 }}>
                      <tbody>
                        <tr>
                          <td>Account Name</td>
                          <td>Oak Tyres Uk Ltd</td>
                        </tr>
                        <tr>
                          <td>Sort Code</td>
                          <td>60-70-08</td>
                        </tr>
                        <tr>
                          <td>Account No.</td>
                          <td>09782729</td>
                        </tr>
                        <tr>
                          <td>Bank</td>
                          <td>Natwest</td>
                        </tr>
                      </tbody>
                    </MiniTable>
                  </Flex>
                  <Text>
                    Please use the reference code:
                    <Text as="span" fontFamily={"Roboto Mono"} color="primary">
                      {" "}
                      {remittance?.reference}
                    </Text>
                  </Text>
                </Box>
              </>
            ) : remittance?.paymentMethod === "card" ? (
              <>
                <Box width={400} maxWidth="100%">
                  <Text mb={1}>
                    To make a credit or debit card payment, please call our
                    accounts department on:
                  </Text>
                  <Flex alignItems="center" mb={1} justifyContent="center">
                    <FaPhone />
                    <Text
                      ml={2}
                      textAlign={"center"}
                      fontWeight={600}
                      fontSize={3}
                    >
                      8444 93 8001
                    </Text>
                  </Flex>
                  <Text mb={1}>
                    Please quote reference code:{" "}
                    <Text as="span" fontFamily={"Roboto Mono"} color="primary">
                      {remittance?.reference}
                    </Text>
                  </Text>
                </Box>
              </>
            ) : remittance?.paymentMethod === "cheque" ? (
              <>
                <Box width={500} maxWidth="100%">
                  <Text mb={1}>
                    Please make cheques payable to:{" "}
                    <strong>Oak Tyres UK Ltd.</strong>
                  </Text>
                  <Text mb={1}>
                    Cheques can either be posted to us or dependant on delivery
                    schedule collected by one of our delivery drivers.
                  </Text>
                  <Text mb={1}>Please send cheques to:</Text>
                  <Text fontWeight={500}>
                    Oak Tyres UK Ltd
                    <br />
                    Oak House
                    <br />
                    Woodlands Business Park
                    <br />
                    Newton-Le-Willows
                    <br />
                    WA12 0HF
                    <br />
                  </Text>
                </Box>
              </>
            ) : null}
          </Flex>
        )}
        <Pips count={3} active={step} onClick={(index) => {}} />
      </Flex>
      <PanelFooter>
        <Flex justifyContent="space-between" p={3} width="100%">
          <Button onClick={goBack} disabled={!canGoBack}>
            Back
          </Button>
          <Button
            icon={FaArrowCircleRight}
            onClick={advance}
            disabled={!canAdvance()}
          >
            {["Continue", "Remit Payment", "Close"][step]}
          </Button>
        </Flex>
      </PanelFooter>
    </Modal>
  );
};
