import React, { createContext, useContext, useState } from "react";

type ContextValue = [boolean, React.Dispatch<React.SetStateAction<boolean>>];

export const RetailViewContext = createContext([
  false,
  () => {},
] as ContextValue) as React.Context<ContextValue>;

export const RetailViewProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const state = useState<boolean>(false);

  return (
    <RetailViewContext.Provider value={state}>
      {children}
    </RetailViewContext.Provider>
  );
};

export const useRetailView = () => useContext(RetailViewContext);
