import {
  FaultyTyreReturnSpec,
  returnReasons,
  tyreAreas,
} from "@oaktyres/model";
import {
  Flex,
  Text,
  InputGroup,
  Input,
  FormInputWrap,
  FormSelect,
  FormInput,
  SelectOption,
  CheckBox,
  Button,
  TextArea,
  ControlLabel,
} from "@oaktyres/ui";
import {
  differenceInMonths,
  format,
  fromUnixTime,
  getUnixTime,
  startOfDay,
} from "date-fns";
import React from "react";
import { useEffect } from "react";
import ReactDatePicker from "react-datepicker";
import { Controller, useForm } from "react-hook-form";

const reasonOptions = [
  {
    value: "",
    label: "(Please Select)",
    disabled: true,
  },
  ...returnReasons.map((x) => ({
    value: x.code,
    label: x.displayName,
    group: tyreAreas[x.area],
  })),
];

type Line = FaultyTyreReturnSpec["lines"][number];

export type EditTyreFormProps = {
  index: number;
  spec: Line;
  onSave: (newValues: Line) => void;
  onCancel: () => void;
  wheelPositionOptions: SelectOption<string>[];
};

export const EditTyreForm = ({
  index,
  wheelPositionOptions,
  spec,
  onSave,
  onCancel,
}: EditTyreFormProps) => {
  const { register, handleSubmit, control, errors, reset } = useForm<Line>({
    defaultValues: spec,
  });

  useEffect(() => {
    reset(spec);
  }, [reset, spec]);

  const onSubmit = (values: Line) => {
    const monthsSincePurchase = differenceInMonths(
      new Date(),
      fromUnixTime(spec.purchaseDate),
    );
    const milesPerMonth = (values.mileage ?? 0) / monthsSincePurchase;

    if (values.mileage != null && milesPerMonth > 1500) {
      if (
        window.confirm(
          `The mileage entered is ${milesPerMonth.toFixed(0)} per month since the tyre was purchased. Please confirm this is correct.`,
        )
      ) {
        onSave({
          ...values,
          purchaseDate: spec.purchaseDate,
        });
      }
    } else {
      onSave({
        ...values,
        purchaseDate: spec.purchaseDate,
      });
    }
  };

  return (
    <Flex
      p={"30px"}
      flexDirection="column"
      alignItems={"stretch"}
      minHeight={400}
      width={600}
      maxWidth="100%"
      ml={"auto"}
      mr={"auto"}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <Text fontWeight={600} textAlign="center" mb={3}>
          Tyre #{index + 1}
        </Text>
        <Flex flexDirection={["column", "row"]}>
          <FormInputWrap
            mr={2}
            name="DOT"
            label="DOT"
            style={{ flex: 1 }}
            required
            error={errors.dot}
          >
            <InputGroup prefix="DOT">
              <Input
                name="dot"
                ref={register({
                  required: true,
                  pattern: /^[a-zA-Z0-9]{4,5}\s?[a-zA-Z0-9]{4}\s?\d{4}$/,
                })}
                placeholder="XXXX[X] XXXX ####"
              />
            </InputGroup>
          </FormInputWrap>
          <FormSelect
            name="wheelPosition"
            label="Wheel Position"
            required
            options={wheelPositionOptions}
            error={errors.wheelPosition}
            ref={register({ required: true })}
            style={{ flex: 1 }}
          />
        </Flex>
        <FormInput
          name="barcode"
          ref={register}
          label="Barcode (bead)"
          style={{ flex: 1 }}
          mb={"3px"}
        />
        <Text fontSize={1} mb={2} color="grey2">
          This is the barcode on the small white label on the tyre’s bead. It is
          typically not possible to read this once a tyre has been fitted, and
          is therefore not essential information.
        </Text>
        <FormInputWrap
          label="Tread Depth Measurements (3 or 4 groove tyres in mm)"
          error={[
            errors.treadDepth1,
            errors.treadDepth2,
            errors.treadDepth3,
            errors.treadDepth4,
          ].find((x) => x)}
        >
          <Flex style={{ gap: 6 }}>
            <Input
              type="number"
              name="treadDepth1"
              step={0.01}
              ref={register({
                required: "At least 3 values are required",
                valueAsNumber: true,
              })}
            />
            <Input
              type="number"
              name="treadDepth2"
              step={0.01}
              ref={register({
                required: "At least 3 values are required",
                valueAsNumber: true,
              })}
            />
            <Input
              type="number"
              name="treadDepth3"
              step={0.01}
              ref={register({
                required: "At least 3 values are required",
                valueAsNumber: true,
              })}
            />
            <Input
              type="number"
              name="treadDepth4"
              step={0.01}
              ref={register({ valueAsNumber: true })}
            />
          </Flex>
        </FormInputWrap>

        <Flex>
          <FormInput
            label="Purchase Date"
            value={format(fromUnixTime(spec.purchaseDate), "dd/MM/yyyy")}
            ref={register}
            readOnly
            mr={2}
          />
          <Controller
            control={control}
            name="fittingDate"
            rules={{
              required: true,
            }}
            render={({ value, onChange }) => (
              <FormInputWrap
                label="Fitting Date"
                mr={2}
                required
                error={errors.fittingDate}
              >
                <ReactDatePicker
                  selected={value == null ? null : fromUnixTime(value)}
                  onChange={(val) => onChange(getUnixTime(val as Date))}
                  dateFormat="dd/MM/yyyy"
                  customInput={<Input />}
                  minDate={startOfDay(fromUnixTime(spec.purchaseDate))}
                  maxDate={startOfDay(new Date())}
                />
              </FormInputWrap>
            )}
          />
        </Flex>

        <FormInputWrap label="Mileage" error={errors.mileage} required>
          <Flex>
            <Input
              mr={2}
              type="number"
              name="mileage"
              ref={register({
                valueAsNumber: true,
                required: true,
              })}
            />
            <Controller
              control={control}
              name="isMileageEstimated"
              render={({ value, onChange }) => (
                <CheckBox
                  value={value}
                  label="Estimated?"
                  onChange={onChange}
                />
              )}
            />
          </Flex>
        </FormInputWrap>
        <FormSelect
          options={reasonOptions}
          name="returnCode"
          error={errors.returnCode}
          ref={register({ required: true })}
          required
          label={"Return Reason"}
        />
        <FormInputWrap
          label="Other Information"
          required
          error={errors.returnInfo}
          mb={3}
        >
          <TextArea
            ref={register({ required: true })}
            name="returnInfo"
            height={80}
          />
        </FormInputWrap>
        <Flex>
          <Button
            type="button"
            onClick={onCancel}
            mr={2}
            color="grey2"
            flex={1}
          >
            Cancel
          </Button>
          <Button type="submit" flex={1}>
            Save
          </Button>
        </Flex>
      </form>
    </Flex>
  );
};
