import { Modal, PanelHeader, Text, IconButton } from "@oaktyres/ui";
import React from "react";
import { FaTimes } from "react-icons/fa";

export const DocumentModal = ({
  url,
  onClose,
}: {
  url: string;
  onClose: () => void;
}) => (
  <Modal shown width={1000} height="90vh" onClose={onClose} p={0}>
    <PanelHeader justifyContent={"space-between"}>
      <Text fontSize={3} fontWeight={600}>
        Document
      </Text>
      <IconButton icon={FaTimes} onClick={onClose} color="body" />
    </PanelHeader>
    <iframe src={url} height="100%" />
  </Modal>
);
