import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { useScopedBasket } from "../basket";
import basketIcon from "../img/iconbasket.svg";

const Image = styled.img`
  opacity: 0.7;
  transition: all 0.3s ease;
  position: relative;
  top: 2px;
`;

const BasketButtonElem = styled(Link)`
  border: 0;
  border-radius: 50%;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 32px;
  height: 32px;
  padding: 5px;
  cursor: pointer;
  margin-left: 24px;
  background-color: transparent;
  transition: all 0.3s ease;

  &:hover {
    background-color: ${(props) => props.theme.colors.grey5};
  }

  &:hover ${Image} {
    opacity: 1;
  }
`;

const Badge = styled.span`
  color: white;
  background-color: #333;
  border-radius: 8px;
  position: absolute;
  border: 1px solid ${(props) => props.theme.colors.white};
  top: 60%;
  left: 60%;
  font-size: 9px;
  height: 16px;
  font-weight: 600;
  min-width: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 0;
  pointer-events: none;
`;

export const BasketButton = () => {
  const basket = useScopedBasket();

  return (
    <BasketButtonElem to="/basket">
      <Image src={basketIcon} />
      {basket.items.length > 0 && <Badge>{basket.items.length}</Badge>}
    </BasketButtonElem>
  );
};
