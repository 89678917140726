import { Flex, Text, Button } from "@oaktyres/ui";
import React from "react";

export type CreateGroupingPanelProps = {
  onCreate: () => void;
};

export const CreateGroupingPanel = ({ onCreate }: CreateGroupingPanelProps) => {
  return (
    <Flex
      flex={1}
      alignItems={"center"}
      justifyContent="center"
      height={400}
      flexDirection="column"
    >
      <Text fontWeight={600} fontSize={3}>
        Select a group
      </Text>
      <Text color="grey2" fontWeight={600} mb={3}>
        Or create a new one
      </Text>
      <Button size="small" onClick={onCreate}>
        Create
      </Button>{" "}
    </Flex>
  );
};
