import { useAccountFinancials, useAccountNew } from "@oaktyres/queries";
import {
  Box,
  Flex,
  formatCurrency,
  MiniTable,
  Panel,
  Select,
  Text,
  useAuth,
} from "@oaktyres/ui";
import { addMonths, format } from "date-fns";
import { range } from "lodash";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { FaDollarSign, FaFile } from "react-icons/fa";
import { NavLink, Redirect, Route, Switch } from "react-router-dom";
import styled from "styled-components";
import { PortalIcon } from "../../components/PortalIcons";
import { useScopedAccount } from "../../components/ScopedAccountProvider";
import { StatementsPage } from "../Statements";
import { PaymentsPage } from "./Payments";
import { RemittancePage } from "./Remittance";

const balances = [
  "balanceCurrent",
  "balance30Day",
  "balance60Day",
  "balance90Day",
  "balance120Day",
] as const;

const BalancesWidget = () => {
  const [scoped] = useScopedAccount();
  const account = useAccountNew(scoped);
  const [viewedAccount, setViewedAccount] = useState(scoped);
  const financials = useAccountFinancials(viewedAccount);

  const ledgered = [scoped, ...(account?.data?.ledgeredAccounts ?? [])];

  const options = ledgered.map((x) => ({ value: x, label: x }));

  useEffect(() => {
    setViewedAccount(scoped);
  }, [scoped]);

  const getBalance = (balanceIndex: number) => {
    const d = financials.data?.[balances[balanceIndex]] ?? 0;
    const color =
      d > 0 && (financials.data?.payTerms ?? 0) < balanceIndex + 1
        ? "danger"
        : "body";

    return (
      <Text color={color} fontSize="inherit" fontFamily="inherit">
        {formatCurrency("gbp")(d)}
      </Text>
    );
  };

  const getMonthName = (index: number) =>
    format(addMonths(new Date(), -index), "MMMM") +
    (index === 4 ? " and earlier" : "");

  return (
    <Panel p={3} order={[0, 0, 1]} mb={3}>
      <Text fontWeight={600} mb={2}>
        Outstanding Balances
      </Text>
      {options.length > 1 && (
        <Select
          mb={2}
          options={options}
          value={viewedAccount}
          onChange={(ev) => setViewedAccount(ev.target.value)}
        />
      )}
      <MiniTable>
        <tbody>
          {range(5).map((x) => (
            <tr key={x}>
              <td>{getMonthName(x)}</td>
              <td className="numeric">{getBalance(x)}</td>
            </tr>
          ))}
          <tr>
            <td className="highlight">Total</td>
            <td className="numeric highlight">
              {formatCurrency("gbp")(financials.data?.balanceTotal ?? 0)}
            </td>
          </tr>
        </tbody>
      </MiniTable>
      <Text fontSize={0} fontStyle="italic" color="grey1" mt={1}>
        Updated every 30 minutes.
      </Text>
    </Panel>
  );
};

const MenuLink = styled(NavLink)`
  border: 1px solid transparent;
  color: ${(props) => props.theme.colors.grey1};
  border-radius: 3px;
  margin-bottom: 6px;
  padding: 3px 6px;
  display: flex;
  align-items: center;
  transition: all 0.3s ease;

  & > svg {
    font-size: 0.8em;
    margin-right: 6px;
    fill: ${(props) => props.theme.colors.grey1};
  }

  &:last-child {
    margin-bottom: 0;
  }

  &:hover {
    text-decoration: none;
    border: 1px solid ${(props) => props.theme.colors.grey3};
    color: ${(props) => props.theme.colors.body};
  }

  &.active {
    color: ${(props) => props.theme.colors.primary};
    svg {
      fill: ${(props) => props.theme.colors.primary};
    }
  }
`;

export const FinancialsPage = () => {
  const auth = useAuth();
  const isUser = auth.user?.type === "user";

  return (
    <Box width="100%" maxWidth={1600}>
      <Panel p={3} mb={3}>
        <Switch>
          <Route path="/financials/statements">
            <Text fontWeight={600} fontSize={4}>
              Monthly Statements
            </Text>
          </Route>
          <Route path="/financials/remittance">
            <Text fontWeight={600} fontSize={4}>
              Remit Payment
            </Text>
          </Route>
          <Route path="/financials/payments">
            <Text fontWeight={600} fontSize={4}>
              Payments
            </Text>
          </Route>
        </Switch>
      </Panel>
      <Flex flexDirection={["column", "column", "row"]} width="100%">
        <Flex flexDirection={"column"} width={["100%", "100%", "300px"]} mr={3}>
          <Panel p={3} mb={3} order={[1, 1, 0]}>
            <MenuLink to="/financials/statements">
              <FaFile style={{ width: "20px" }} />
              Monthly Statements
            </MenuLink>
            <MenuLink to="/financials/remittance">
              <PortalIcon iconType="remitpayment" width="20px" />
              Remit Payment
            </MenuLink>
            <MenuLink to="/financials/payments">
              <PortalIcon iconType="paymenthistory" width="20px" />
              Payment History
            </MenuLink>
          </Panel>
          {isUser && <BalancesWidget />}
        </Flex>
        <Flex flex="1">
          <Switch>
            <Route path="/financials/statements" component={StatementsPage} />
            <Route path="/financials/remittance" component={RemittancePage} />
            <Route path="/financials/payments" component={PaymentsPage} />
            <Redirect to="/financials/statements" />
          </Switch>
        </Flex>
      </Flex>
    </Box>
  );
};
