import styled from "styled-components";
import { Link } from "react-router-dom";
import Modal from "./Modal";
import Panel, { PanelHeader } from "./Panel";
import IconButton from "./IconButton";
import Text from "./Text";
import React, { useState } from "react";
import Flex from "./Flex";
import Button from "./Button";
import { FaEllipsisH, FaTimes } from "react-icons/fa";
import Box from "./Box";
import AccountSearch from "./AccountSearch";
import { LinkButton } from "./LinkButton";

type AccountSwitcherProps = {
  value: string | null;
  onChange: (val: string | null) => void;
};

function AccountSelectionModal({
  onSelect,
  onCancel,
}: {
  onSelect: (code: string) => void;
  onCancel: () => void;
}) {
  return (
    <Modal onClose={() => onCancel()} shown={true} p={0}>
      <PanelHeader justifyContent="space-between">
        <Text fontSize={3}>Select Account</Text>
        <IconButton icon={FaTimes} onClick={onCancel} />
      </PanelHeader>
      <Box p={3}>
        <AccountSearch
          onSelect={(v) => onSelect(v.accountCode)}
          allowProspects={false}
        />
      </Box>
    </Modal>
  );
}

const Container = styled(Panel)`
  border: 1px solid ${(props) => props.theme.colors.grey3};
  box-shadow: none;
`;

export function AccountSelector({ value, onChange }: AccountSwitcherProps) {
  const [modal, setModal] = useState<boolean>(false);

  const onSelect = (val: string) => {
    onChange(val);
    setModal(false);
  };

  return (
    <>
      {modal && (
        <AccountSelectionModal
          onCancel={() => setModal(false)}
          onSelect={onSelect}
        />
      )}
      <Container p={"8px 12px"}>
        <Flex alignItems="center">
          <Flex flex={1}>
            <Text
              fontWeight={500}
              mr={2}
              fontSize={2}
              style={{ whiteSpace: "nowrap" }}
            >
              <Text as="span" color="grey2" fontWeight={600} fontSize={1}>
                ACCOUNT:
              </Text>{" "}
              {value ? (
                <Link to={`/accounts/${value}`}>{value}</Link>
              ) : (
                <LinkButton as="span" ml={1} onClick={() => setModal(true)}>
                  None
                </LinkButton>
              )}
            </Text>
          </Flex>
          <Button
            pl={1}
            pr={1}
            onClick={() => setModal(true)}
            style={{ minWidth: 0 }}
            height={24}
            type="button"
          >
            <FaEllipsisH style={{ marginLeft: 0 }} />
          </Button>
          <Button
            pl={1}
            pr={1}
            ml={1}
            onClick={() => onChange(null)}
            style={{ minWidth: 0 }}
            height={24}
            type="button"
          >
            <FaTimes style={{ marginLeft: 0 }} />
          </Button>
        </Flex>
      </Container>
    </>
  );
}
