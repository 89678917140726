import React, { useEffect, useMemo, useRef } from "react";
import { useState } from "react";
import { FaSearch, FaTimes } from "react-icons/fa";
import styled from "styled-components";
import { useDebounce } from "../utils/useDebounce";
import Flex from "./Flex";
import Text from "./Text";
import IconButton from "./IconButton";
import { clamp } from "lodash";
import Panel from "./Panel";

type KeysMatching<T, V> = {
  [K in keyof T]-?: T[K] extends V ? K : never;
}[keyof T];

export type SearchSelectProps<TOption> = {
  options: TOption[];
  keyProp: KeysMatching<TOption, string>;
  value: string | null;
  onChange: (value: string) => void;
  render: (value: TOption) => React.ReactNode;
  matchingFn: (item: TOption, value: string) => boolean;
  compFn?: (itemA: TOption, itemB: TOption, val: string) => TOption;
  onClear: () => void;
  disabled?: boolean;
};

const Wrapper = styled.div`
  position: relative;
`;

const Container = styled.div`
  display: flex;
  border: 1px solid ${(props) => props.theme.colors.grey3};
  border-radius: 4px;
  max-width: 100%;
  padding: 8px 12px;
  position: relative;
  cursor: text;

  &:focus-within {
    border-color: ${(props) => props.theme.colors.primary};
  }
`;

const InputContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;

  & svg {
    margin-right: 12px;
  }
`;

const Input = styled.input`
  padding: 0;
  width: 100%;
  flex: 1;
  border: 0;

  &:focus {
    outline: 0;
    border: 0;
  }
`;

const ItemsPanel = styled(Panel)`
  position: absolute;
  top: calc(100% + 12px);
  left: 12px;
  z-index: 1;
`;

const Item = styled.button<{ active: boolean }>`
  border: 0;
  display: flex;
  text-align: left;
  padding: 8px 12px;
  border-radius: 4px;
  background-color: ${(props) =>
    props.active ? props.theme.colors.grey4 : "transparent"};
`;

export function SearchSelect<T>({
  options,
  keyProp,
  value,
  render,
  onClear,
  matchingFn,
  onChange,
  disabled = false,
  compFn = (a, b) => (a[keyProp] < b[keyProp] ? a : b),
}: SearchSelectProps<T>) {
  const [inputVal, setInputVal] = useState<string>("");
  const [isFocus, setIsFocus] = useState<boolean>(false);
  const [selectedIndex, setSelectedIndex] = useState<number>(-1);
  const inputRef = useRef<HTMLInputElement | null>(null);

  const debouncedVal = useDebounce(inputVal, 200);

  const items = useMemo(
    () =>
      options
        .filter((x) => matchingFn(x, debouncedVal))
        .sort((a, b) =>
          compFn(a, b, debouncedVal.toLowerCase()) === a ? -1 : 1
        ),
    [debouncedVal, matchingFn]
  );

  useEffect(() => {
    setSelectedIndex(
      items.length === 0 ? 0 : clamp(selectedIndex, 0, items.length - 1)
    );
  }, [items.length]);

  const activeItem = options.find((x) => (x[keyProp] as any) === value);

  const selectItem = (item: T) => {
    const key = item[keyProp] as any;
    onChange(key);
    setInputVal("");
  };

  const onKeyDown = (ev: React.KeyboardEvent) => {
    if (ev.key === "Enter") {
      const i = items[selectedIndex];
      if (i != null) {
        selectItem(i);
      }
    }
    if (ev.key === "ArrowDown" || ev.key === "Tab") {
      setSelectedIndex(Math.min(selectedIndex + 1, items.length - 1));
    } else if (ev.key === "ArrowUp") {
      setSelectedIndex(Math.max(selectedIndex - 1, 0));
    }
  };

  const focus = () => {
    if (inputRef.current != null) {
      inputRef.current.focus();
    }
  };

  const clear = () => {
    onClear();
    setTimeout(focus, 300);
  };

  return (
    <Wrapper>
      <Container onClick={focus}>
        {activeItem != null ? (
          <Flex justifyContent="space-between" width={"100%"}>
            {render(activeItem)}
            {!disabled && <IconButton icon={FaTimes} onClick={clear} />}
          </Flex>
        ) : (
          <InputContainer>
            <FaSearch />
            <Input
              onChange={(ev) => setInputVal(ev.target.value)}
              onKeyDown={onKeyDown}
              value={inputVal}
              ref={inputRef}
              disabled={disabled}
              onFocus={() => setIsFocus(true)}
              onBlur={() => setIsFocus(false)}
            />
          </InputContainer>
        )}
      </Container>
      {debouncedVal.length > 1 && activeItem == null && isFocus ? (
        <ItemsPanel p={2} minWidth={300}>
          {items.length === 0 ? (
            <Text color="grey3">No Items Found</Text>
          ) : (
            <Flex flexDirection={"column"}>
              {items.map((x, i) => (
                <Item
                  active={selectedIndex === i}
                  onClick={() => selectItem(x)}
                  type="button"
                >
                  {render(x)}
                </Item>
              ))}
            </Flex>
          )}
        </ItemsPanel>
      ) : null}
    </Wrapper>
  );
}
