import { useAccountNew, useRewardTemplates } from "@oaktyres/queries";
import { currencyTypeNames } from "@oaktyres/ui";

export const useAvailableCurrencies = (code: string) => {
  const account = useAccountNew(code);
  const rewards = useRewardTemplates(code);

  // If no rewards then no currencies
  if ((rewards.data ?? []).length === 0) {
    return [];
  }

  const shownBalances = (
    Object.keys(currencyTypeNames) as (keyof typeof currencyTypeNames)[]
  ).filter((x) => {
    if (x === "rp") return account.data?.rewardPoints.enabled;
    else if (x === "dsp") return account.data?.dealerSupportPoints.enabled;
    else if (x === "bdf") return account.data?.businessDevelopmentFund.enabled;
    else return false;
  });

  return shownBalances;
};
