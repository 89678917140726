import React from "react";
import { FaQuestionCircle } from "react-icons/fa";
import styled from "styled-components";
import { SpaceProps } from "styled-system";
import { Panel, Toggle, Text, IconButton, Flex } from "..";

const Container = styled(Panel)`
  box-shadow: 0;
  border: 1px solid ${(props) => props.theme.colors.grey3};
  white-space: nowrap;
  flex-wrap: nowrap;
  display: inline-flex;
  align-items: center;
  flex-direction: row;
  height: 42px;
`;

type MiniToggleProps = {
  value: boolean;
  onChange: (val: boolean) => void;
  disabled?: boolean;
  label: string;
  rightLabel?: string;
  help?: () => void;
} & SpaceProps;

export const MiniToggle = ({
  value,
  onChange,
  label,
  disabled = false,
  rightLabel,
  help,
  ...rest
}: MiniToggleProps) => (
  <Container padding="8px 12px" boxShadow={0} {...rest}>
    <Flex mr={2} flex={1}>
      <Text color="grey1" fontWeight={600} fontSize={1} mr={"3px"}>
        {label}
      </Text>
      {help && (
        <IconButton
          icon={FaQuestionCircle}
          onClick={help}
          title="What's this?"
          style={{
            fontSize: "0.8em",
            position: "relative",
            top: -5,
          }}
        />
      )}
    </Flex>
    <Toggle
      value={value}
      onChange={onChange}
      size="small"
      disabled={disabled}
    />
    {rightLabel && (
      <Text
        color="grey1"
        textAlign={"right"}
        fontWeight={600}
        fontSize={1}
        ml={2}
        style={{ flex: 1 }}
      >
        {rightLabel}
      </Text>
    )}
  </Container>
);
