import { AuthResponse } from "@oaktyres/model";
import { useAuth } from "@oaktyres/ui";
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";

type ScopedAccountContextValue = readonly [string, (value: string) => void];

const ScopedAccountContext = createContext<ScopedAccountContextValue>(null!);

const STORAGEKEY = "op-last-account";

const getDefaultAccount = (user: AuthResponse | null): string => {
  const fromStorage = window.localStorage.getItem(STORAGEKEY);

  if (
    fromStorage != null &&
    user?.ownedAccounts.some((x) => x.code === fromStorage)
  ) {
    return fromStorage;
  }

  return user?.ownedAccounts[0]?.code ?? "";
};

export const ScopedAccountProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const { user } = useAuth();
  const [value, setValue] = useState<string>(getDefaultAccount(user));

  useEffect(() => {
    window.localStorage.setItem(STORAGEKEY, value);
  }, [value]);

  useEffect(() => {
    setValue((old) => {
      if (user?.ownedAccounts.map((x) => x.code).includes(old)) {
        // Don't need to change scoped account
        return old;
      }
      return user?.ownedAccounts[0]?.code ?? "";
    });
  }, [user]);

  const setCode = useCallback(
    (newVal: string) => {
      if (user?.ownedAccounts.map((x) => x.code).includes(newVal)) {
        setValue(newVal);
      } else {
        throw new Error("Invalid code");
      }
    },
    [user]
  );

  const data = [value, setCode] as const;

  return (
    <ScopedAccountContext.Provider value={data}>
      {children}
    </ScopedAccountContext.Provider>
  );
};

export const useScopedAccount = () => useContext(ScopedAccountContext);
