import { SellOutListDto, SellOutListSpec } from "@oaktyres/model";
import axios from "axios";
import { useMutation, useQuery, useQueryClient } from "react-query";

const getSellOutLists = async (accountCode: string | null) =>
  accountCode == null
    ? []
    : await axios
        .get<SellOutListDto[]>(`/api/v2/accounts/${accountCode}/sellout-lists`)
        .then(({ data }) => data);

const createSellOutList = (data: SellOutListSpec) =>
  axios
    .post<{ id: string }>(`/api/v2/sellout-lists`, data)
    .then(({ data }) => data);

const deleteSellOutList = (id: string) =>
  axios.delete(`/api/v2/sellout-lists/${id}`);

const updateSellOutList = (id: string, data: SellOutListSpec) =>
  axios.put(`/api/v2/sellout-lists/${id}`, data);

const pullSellOutLists = (code: string) =>
  axios.put(`/api/v2/accounts/${code}/sellout-lists/pull`);

export const useUpdateSellOutList = (id: string) => {
  const queryClient = useQueryClient();

  const mutation = useMutation(
    (spec: SellOutListSpec) => updateSellOutList(id, spec),
    {
      onSuccess: (res, req) => {
        queryClient.invalidateQueries(["sellouts", req.accountCode]);
      },
    }
  );

  return mutation;
};

export const usePullSellOutLists = (code: string) => {
  const queryClient = useQueryClient();

  const mutation = useMutation(() => pullSellOutLists(code), {
    onSuccess: (res, req) => {
      queryClient.invalidateQueries(["sellouts", code]);
    },
  });

  return mutation;
};

export const useCreateSellOutList = () => {
  const queryClient = useQueryClient();

  const mutation = useMutation(
    (spec: SellOutListSpec) => createSellOutList(spec),
    {
      onSuccess: (res, req) => {
        queryClient.invalidateQueries(["sellouts", req.accountCode]);
      },
    }
  );

  return mutation;
};

export const useDeleteSellOutList = () => {
  const queryClient = useQueryClient();

  const mutation = useMutation((id: string) => deleteSellOutList(id), {
    onSuccess: (res, req) => {
      queryClient.invalidateQueries(["sellouts"]);
    },
  });

  return mutation;
};

export const useSellOutLists = (code: string | null) =>
  useQuery(["sellouts", code], () => getSellOutLists(code));
