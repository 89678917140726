import { useCurrentBalances } from "@oaktyres/queries";
import {
  currencyTypeColors,
  currencyTypeNames,
  Flex,
  formatCurrency,
  Loader,
  Text,
} from "@oaktyres/ui";
import React from "react";
import { useScopedAccount } from "./ScopedAccountProvider";

import { animated, useSprings } from "react-spring";
import { useAvailableCurrencies } from "../hooks/accounts";
import { PortalIcon } from "./PortalIcons";

export const BarBalances = () => {
  const [scopedAccount] = useScopedAccount();
  const balances = useCurrentBalances(scopedAccount);
  const shownBalances = useAvailableCurrencies(scopedAccount);

  const b = (balances.data ?? []).filter((x) => shownBalances.includes(x.type));

  const [springs] = useSprings(
    shownBalances.length,
    (i) => ({
      from: { value: 0 },
      to: { value: b[i]?.value ?? 0 },
    }),
    [balances.data],
  );

  return (
    <Flex display={["none", "flex"]}>
      {!balances.isFetched && (
        <Flex px={3} flexDirection="row" alignItems={"center"}>
          <Loader tiny />
          <Text fontWeight={600} color="grey3" ml={3} fontSize={1} mr={3}>
            LOADING
          </Text>
        </Flex>
      )}
      {b.map((x, i) => (
        <Flex mr={3} alignItems="flex-start" key={x.type}>
          <PortalIcon
            iconType={x.type}
            color={currencyTypeColors[x.type]}
            width="24px"
            height="24px"
            mr={1}
          />
          <div>
            <Text
              fontWeight={600}
              fontSize={3}
              lineHeight={1}
              color={currencyTypeColors[x.type]}
              style={{ whiteSpace: "nowrap" }}
              mb="3px"
            >
              <animated.span>
                {springs[i].value.to((v) => formatCurrency(x.type)(v))}
              </animated.span>
            </Text>
            <Text fontSize="11px" color="black" lineHeight={1}>
              {currencyTypeNames[x.type]}
            </Text>
          </div>
        </Flex>
      ))}
    </Flex>
  );
};
