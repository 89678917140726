import { groupBy, parseInt, sortBy, sum } from "lodash";
import { RemittableItemDto } from "@oaktyres/model";
import { useAccountNew, useRemittableItems } from "@oaktyres/queries";
import {
  Box,
  CheckBox,
  Flex,
  formatCurrency,
  Text,
  Table,
  LinkButton,
  Panel,
  Button,
  Loader,
  Alert,
  useAuth,
  useToasts,
} from "@oaktyres/ui";
import { uniq } from "lodash";
import React, { useState } from "react";
import { useCallback } from "react";
import { useMemo } from "react";
import { useScopedAccount } from "../../components/ScopedAccountProvider";
import { addMonths, format, fromUnixTime } from "date-fns";
import axios from "axios";
import { DocumentModal } from "../../components/DocumentModal";
import styled from "styled-components";
import { animated, useSpring } from "react-spring";
import { RemittanceModal } from "./RemittanceModal";
import { RemittanceDisplayModal } from "./RemittanceDisplayModal";
import {
  FaExclamationTriangle,
  FaInfo,
  FaInfoCircle,
  FaQuestionCircle,
  FaSearch,
} from "react-icons/fa";

const RemittanceBar = styled(animated.div)`
  width: 100%;
  background: white;
  box-shadow: 0 -3px 3px rgba(0, 0, 0, 0.1);
  position: fixed;
  bottom: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding: ${(props) => props.theme.space[4]}px;
`;

export const RemittancePage = () => {
  const [accountCode] = useScopedAccount();
  const account = useAccountNew(accountCode);
  const items = useRemittableItems(accountCode);
  const [selectedItems, setSelectedItems] = useState<string[]>([]);
  const [docUrl, setDocUrl] = useState<string | null>(null);
  const [remittingItems, setRemittingItems] = useState<RemittableItemDto[]>([]);
  const [shownRemittance, setShownRemittance] = useState<string | null>(null);
  const [expanded, setExpanded] = useState<Record<number, boolean>>({
    [5]: true,
  });
  const auth = useAuth();
  const isNotUser = auth.user?.type !== "user";
  const toast = useToasts();

  const toggleItem = useCallback(
    (item: RemittableItemDto, value: boolean) => {
      if (value) {
        const newIds = [item.camId];

        if (item.hasUnpaidInvoice) {
          const unpaidItem = items.data?.find(
            (x) => x.reference === item.creditedReference,
          );

          console.log(unpaidItem);

          if (unpaidItem != null) {
            newIds.push(unpaidItem.camId);

            toast.push({
              icon: FaInfoCircle,
              title: "Unpaid Invoice",
              content:
                "This credit relates to an unpaid invoice which has been added to the remittance automatically.",
            });
          }
        }

        setSelectedItems((old) => uniq([...old, ...newIds]));
      } else {
        const toRemove = [item.camId];

        const credited = items.data?.find(
          (x) => x.hasUnpaidInvoice && x.creditedReference === item.reference,
        );

        if (credited != null) {
          toRemove.push(credited.camId);

          toast.push({
            icon: FaInfoCircle,
            title: "Untaken credit",
            content:
              "This item relates to an untaken credit which has been removed from your remittance",
          });
        }

        setSelectedItems((old) => old.filter((x) => !toRemove.includes(x)));
      }
    },
    [items.data],
  );

  const groupedData = useMemo(() => {
    const byMonth = groupBy(items.data ?? [], (x) => x.monthIndex);

    const ordered = sortBy(Object.entries(byMonth), (x) => x[0])
      .reverse()
      .map(
        ([key, data]) =>
          [
            key,
            sortBy(
              data.map((x) => ({
                ...x,
                selected: selectedItems.includes(x.camId),
              })),
              (x) => x.date,
            ),
          ] as const,
      );

    return ordered;
  }, [items.data, selectedItems]);

  const remittedItems = useMemo(
    () => items.data?.filter((x) => selectedItems.includes(x.camId)) ?? [],
    [items.data, selectedItems],
  );

  const remittanceSpring = useSpring({
    y: remittedItems.length > 0 ? "0%" : "100%",
  });

  const getMonthName = (index: number) =>
    format(addMonths(new Date(), -index), "MMMM") +
    (index === 4 ? " and earlier" : "");

  const viewDocument = useCallback((invnum: string) => {
    axios
      .get(`/api/v2/documents/${invnum}`)
      .then(({ data }) => {
        setDocUrl(data.url);
      })
      .catch(() => {
        window.alert("Document not yet available.");
      });
  }, []);

  const startRemittance = () => {
    setRemittingItems(
      items.data?.filter((x) => selectedItems.includes(x.camId)) ?? [],
    );
  };

  const isRemittable = (item: RemittableItemDto) => {
    if (item.associatedRemittance != null) {
      return false;
    }

    if (item.amountDue === 0) {
      return false;
    }

    if (item.creditedReference == null) {
      return true;
    }

    return true;
  };

  const remittedValue = sum(remittedItems.map((x) => x.amountDue));

  if (isNotUser) {
    return (
      <Panel width="100%">
        <Alert icon={FaExclamationTriangle} color="warning" m={2}>
          <Text fontWeight={600} fontSize={2}>
            Remitting Payments is only available to named users.
          </Text>
          <Text fontWeight={500} fontSize={1}>
            Please login using an email address to access your statements.
          </Text>
        </Alert>
      </Panel>
    );
  }

  const toggleExpanded = (monthIndex: number) => {
    setExpanded({ ...expanded, [monthIndex]: !expanded[monthIndex] });
  };

  const forMonth = (monthIndex: number) => {
    return groupedData
      .filter((x) => x[0] === monthIndex.toString())
      .flatMap((x) => x[1]);
  };

  const allSelected = (monthIndex: number) => {
    return forMonth(monthIndex).every((x) => selectedItems.includes(x.camId));
  };

  const toggleMonth = (monthIndex: number) => {
    const items = forMonth(monthIndex);
    if (allSelected(monthIndex)) {
      const ids = items.map((x) => x.camId);
      setSelectedItems(selectedItems.filter((x) => !ids.includes(x)));
    } else {
      const ids = items.filter(isRemittable).map((x) => x.camId);
      setSelectedItems(uniq([...selectedItems, ...ids]));
    }
  };

  return (
    <Panel mb="200px" width="100%" pb={3}>
      {items.isLoading && <Loader />}
      {docUrl && <DocumentModal url={docUrl} onClose={() => setDocUrl(null)} />}
      {remittingItems.length > 0 && (
        <RemittanceModal
          items={remittingItems}
          onClose={(comp) => {
            setRemittingItems([]);
            if (comp) {
              setSelectedItems([]);
            }
          }}
        />
      )}
      {shownRemittance && (
        <RemittanceDisplayModal
          remittanceId={shownRemittance}
          onClose={() => setShownRemittance(null)}
        />
      )}
      <RemittanceBar style={remittanceSpring}>
        <Flex
          alignItems={"flex-end"}
          justifyContent="flex-end"
          flexDirection="column"
        >
          <Text>{remittedItems.length} items</Text>
          <Text fontWeight={600} mb={3}>
            Total Payment: {formatCurrency("gbp")(remittedValue)}
          </Text>
          <Button mb={1} onClick={() => setSelectedItems([])} width={200}>
            Clear
          </Button>
          <Button
            onClick={startRemittance}
            width={200}
            disabled={remittedValue < 0 && remittedItems.length > 0}
          >
            Complete Remittance
          </Button>
        </Flex>
      </RemittanceBar>

      {account.data?.ledger != null ? (
        <Alert icon={FaInfoCircle} color="info" m={2}>
          <Text fontWeight={600} fontSize={2}>
            This account is ledgered to {account.data.ledger}.
          </Text>
          <Text fontWeight={500} fontSize={1}>
            Please login to account {account.data.ledger} to remit payment.
          </Text>
        </Alert>
      ) : null}
      {!items.isLoading && groupedData.length === 0 ? (
        <>
          <Flex
            flexDirection={"column"}
            p={5}
            alignItems="center"
            justifyContent={"center"}
          >
            <Text color="grey3" fontSize={"72px"}>
              <FaSearch />
            </Text>
            <Text fontSize={3} color="grey3" fontWeight={600}>
              No Items to Display
            </Text>
          </Flex>
        </>
      ) : null}
      {groupedData.map((x, i, self) => (
        <Box key={x[0]}>
          <Flex justifyContent={"space-between"} pl={3} pt={3}>
            <Flex alignItems={"flex-end"}>
              <Text fontWeight={600} mr={1}>
                {getMonthName(parseInt(x[0], 10) - 1)}
              </Text>
              <LinkButton
                onClick={() => toggleExpanded(parseInt(x[0], 10))}
                fontSize={"11px"}
                mb={"2px"}
                style={{ whiteSpace: "nowrap", flex: 1 }}
              >
                {expanded[parseInt(x[0], 10)] ? "Collapse ▲" : "Expand ▼"}
              </LinkButton>
            </Flex>
            <Flex alignItems={"center"}>
              <Text fontSize="11px" mt={"3px"} style={{ whiteSpace: "nowrap" }}>
                <Text as="span" display={["none", "inline"]}>
                  Select{" "}
                </Text>
                All
              </Text>
              <Flex justifyContent={"center"} ml={2} mr="48px">
                <CheckBox
                  value={allSelected(parseInt(x[0], 10))}
                  onChange={() => toggleMonth(parseInt(x[0], 10))}
                />
              </Flex>
            </Flex>
          </Flex>
          {expanded[parseInt(x[0], 10)] && (
            <Table mb={i < self.length - 1 ? 2 : 0} mt={3}>
              <thead>
                <tr>
                  <th style={{ width: 80 }}>Date</th>
                  <th className="desktop-only">Account</th>
                  <th>Reference</th>
                  <th className="desktop-only">Order Number</th>
                  <th style={{ width: 90 }} className="desktop-only">
                    Due Date
                  </th>
                  <th className="right desktop-only">Value</th>
                  <th className="right">Amount Due</th>
                  <th className="center" style={{ width: 120 }}>
                    Remit Payment
                  </th>
                </tr>
              </thead>
              <tbody>
                {x[1].map((r) => (
                  <tr key={r.camId}>
                    <td>{format(fromUnixTime(r.date), "MMM do")}</td>
                    <td className="desktop-only">{r.accountCode}</td>
                    <td>
                      <LinkButton
                        onClick={() => viewDocument(r.reference)}
                        style={{ fontFamily: "Roboto Mono" }}
                      >
                        {r.reference}
                      </LinkButton>
                    </td>
                    <td className="desktop-only">{r.orderNumber}</td>
                    <td className="desktop-only">
                      {format(fromUnixTime(r.dueDate), "MMM do")}
                    </td>
                    <td className="numeric desktop-only">
                      {formatCurrency("gbp")(r.totalValue)}
                    </td>
                    <td className="numeric">
                      {formatCurrency("gbp")(r.amountDue)}
                    </td>
                    <td>
                      <Flex justifyContent={"center"}>
                        {r.associatedRemittance ? (
                          <LinkButton
                            textAlign={"center"}
                            onClick={() =>
                              setShownRemittance(r.associatedRemittance)
                            }
                          >
                            View Remittance
                          </LinkButton>
                        ) : !isRemittable(r) ? (
                          <Text
                            fontSize="inherit"
                            color="grey2"
                            fontWeight={600}
                          >
                            N/A
                          </Text>
                        ) : (
                          <CheckBox
                            value={r.selected}
                            onChange={(val) => toggleItem(r, val!)}
                          />
                        )}
                      </Flex>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          )}
        </Box>
      ))}
    </Panel>
  );
};
