import React from "react";
import { Link } from "react-router-dom";
import Text from "./Text";
import styled from "styled-components";

const Container = styled.ul`
  list-style: none;
  display: flex;
  padding: 0;
  margin: 0;
  flex-wrap: wrap;

  & li {
    display: flex;
    align-items: center;
  }

  & p,
  & a {
    font-size: 16px;
    font-weight: 600;
    white-space: nowrap;

    @media (min-width: ${(props) => props.theme.breakpoints[1]}) {
      font-size: 20px;
    }
  }

  & li:before {
    content: "/";
    display: block;
    padding-left: 8px;
    padding-right: 8px;
    font-size: 20px;
    font-weight: 600;
  }

  & li:first-child:before {
    display: none;
  }
`;

const Img = styled.img`
  height: 25px;
  display: block;
`;

export type Breadcrumb = {
  url: string | null;
  img?: string;
  label: string;
};

export type BreadcrumbsProps = {
  items: Breadcrumb[];
};

export const Breadcrumbs = ({ items }: BreadcrumbsProps) => {
  return (
    <Container>
      {items.map((x, i, self) =>
        i === self.length - 1 || x.url == null ? (
          <li>
            {x.img && <Img src={x.img} />}
            <Text>{x.label}</Text>
          </li>
        ) : (
          <li>
            <Link to={x.url}>
              {x.img && <Img src={x.img} />}
              {x.label}
            </Link>
          </li>
        ),
      )}
    </Container>
  );
};
