import React, { useEffect, useRef, useState } from "react";
import { animated, useSpring } from "react-spring";
import styled from "styled-components";
import { useOnClickOutside } from "../utils/useOnClickOutside";
import Panel, { PanelProps } from "./Panel";

const Container = styled(animated.div)`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2000;
`;

const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);
  z-index: 1000;
`;

const ModalContainer = styled(animated.div)`
  padding: 6px;
  overflow-y: scroll;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  z-index: 2000;
  justify-content: center;

  @media (min-width: ${(props) => props.theme.breakpoints[1]}) {
    padding: 32px;
  }
`;

const ModalPanel = styled(Panel)`
  position: relative;
  z-index: 2000;
  margin: auto;
  max-width: 95%;
  display: inline-flex;
  flex: 0 1 auto;
  min-width: 300px;
`;

type ModalProps = PanelProps & {
  shown: boolean;
  children?: React.ReactNode;
  onClose?: () => any;
};

let lastIndex = 2000;

const ModalElem = ({ onClose, children, ...rest }: Partial<ModalProps>) => {
  const [zIndex] = useState(lastIndex + 1);
  const ref = useRef<HTMLElement>(null!);

  useEffect(() => {
    lastIndex += 2;
  }, []);

  const overlayProps = useSpring({
    opacity: 1,
    from: {
      opacity: 0.0,
    },
  });

  const containerProps = useSpring({
    transform: "translate3d(0px, 0px, 0px)",
    opacity: 1,
    from: {
      transform: "translate3d(0px, 60px, 0px)",
      opacity: 0,
    },
  });

  useOnClickOutside(ref, () => {
    onClose && onClose();
  });

  return (
    <Container style={{ ...overlayProps, zIndex: zIndex }}>
      <Overlay onClick={onClose} />
      <ModalContainer style={containerProps}>
        <ModalPanel p={3} {...(rest as any)} ref={ref as any} boxShadow={2}>
          {children}
        </ModalPanel>
      </ModalContainer>
    </Container>
  );
};

const Modal = ({ shown, children, onClose, ...rest }: ModalProps) => {
  useEffect(() => {
    document.body.style.overflow = shown ? "hidden" : "";

    return () => {
      document.body.style.overflow = "";
    };
  }, [shown]);

  if (!shown) {
    return null;
  }

  return (
    <ModalElem onClose={onClose} {...rest}>
      {children}
    </ModalElem>
  );
};

export default Modal;
