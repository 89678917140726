import React, { useState } from "react";
import Flex from "./Flex";
import Modal from "./Modal";
import Button from "./Button";
import FormInput from "./FormInput";

export type TableFilterSearchConfig<T> = {
  type: "search";
  getValue: (item: T) => string;
};

export type TableFilterSearchValues = {
  query: string;
};

export type TableFilterSearchModalProps<T> = {
  data: T[];
  config: TableFilterSearchConfig<T>;
  values: TableFilterSearchValues | null;
  onChange: (val: TableFilterSearchValues | null) => void;
};

export function TableFilterSearch<T>(
  item: T,
  config: TableFilterSearchConfig<T>,
  values: TableFilterSearchValues
) {
  return config
    .getValue(item)
    .toLowerCase()
    .includes(values.query.toLowerCase());
}

export function TableFilterSearchModal<T>({
  values,
  onChange,
}: TableFilterSearchModalProps<T>) {
  const [q, setQ] = useState<string>(values?.query ?? "");

  const setVals = () => {
    onChange({ query: q });
  };

  return (
    <Modal p={3} shown width={200}>
      <FormInput
        label="Search"
        value={q}
        width={"100%"}
        onChange={(ev) => setQ(ev.target.value)}
      />
      <Flex>
        <Button onClick={setVals} mr={1} style={{ flex: 1 }}>
          Set
        </Button>
        <Button onClick={() => onChange(null)} ml={1} style={{ flex: 1 }}>
          Clear
        </Button>
      </Flex>
    </Modal>
  );
}
