import { TyreQueryDto, TyreSearchPayload } from "@oaktyres/model";
import { groupBy } from "lodash";

export type DataPayload = {
  params: TyreQueryDto;
  data: DataSet[];
};

export type DataSet = {
  heading?: string;
  stockStatus: PairStockStatus;
  items: (TyreSearchPayload & { side?: "f" | "r" })[];
};

export enum PairStockStatus {
  Ignore,
  None,
  RearOnly,
  FrontOnly,
  Both,
}

const matchesParams = (
  params: TyreQueryDto,
  item: TyreSearchPayload
): boolean =>
  (["section", "profile", "rim"] as const).every(
    (x) => params[x] === "" || params[x] === item[x].toString()
  );

const buildGroupString = (x: TyreSearchPayload) =>
  [
    x.brand.name,
    x.pattern.name,
    ...x.oes.map((x) => x.mark),
    x.selfSealing ? "Self-Sealing" : null,
    x.noiseCancelling ? "Noise-Cancelling" : null,
    x.runFlat ? "Run-Flat" : null,
    `${x.speed}-Rated`,
  ]
    .filter((x) => x != null)
    .join(" ");

export const groupResults = (
  data: TyreSearchPayload[],
  params1: TyreQueryDto,
  params2: TyreQueryDto
): DataSet[] =>
  Object.entries(groupBy(data, buildGroupString))
    .map(([heading, srcItems]) => {
      const items = srcItems.map((x) => ({
        ...x,
        side: (matchesParams(params1, x) ? "f" : "r") as "f" | "r",
      }));

      const hasFront = items.some((x) => x.side === "f" && x.totalStock > 0);
      const hasRear = items.some((x) => x.side === "r" && x.totalStock > 0);

      return {
        heading,
        items,
        stockStatus:
          hasFront && hasRear
            ? PairStockStatus.Both
            : hasFront
            ? PairStockStatus.FrontOnly
            : hasRear
            ? PairStockStatus.RearOnly
            : PairStockStatus.None,
      };
    })
    .filter((x) =>
      (["f", "r"] as const).every((p) => x.items.some((i) => i.side === p))
    );
