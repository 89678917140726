import { Box, ButtonSelect, ControlLabel, Input, Option } from "@oaktyres/ui";
import React, { useEffect, useState } from "react";

export type DefaultValueInputProps<T> = {
  value: T;
  label: string;
  defaultCustomValue: string;
  customConversationFn: (str: string) => T;
  onChange: (val: T) => void;
  options: readonly Option<T>[];
};

export function DefaultValueInput<T>({
  value,
  label,
  onChange,
  defaultCustomValue,
  customConversationFn,
  options,
}: DefaultValueInputProps<T>) {
  const optIndex = options.findIndex((x) => x.value === value);
  const [custom, setCustom] = useState<string>((value as any).toString());

  useEffect(() => {
    //setCustom(defaultCustomValue);
  }, [defaultCustomValue]);

  useEffect(() => {
    //onChange(options[0].value);
  }, [options]);

  const setVal = (val: T | "custom") => {
    if (val !== "custom") {
      onChange(val);
    } else {
      processCustom(defaultCustomValue);
    }
  };

  const processCustom = (value: string) => {
    try {
      setCustom(value);
      const newVal = customConversationFn(value);
      onChange(newVal);
    } catch {
      setCustom(defaultCustomValue);
      onChange(customConversationFn(defaultCustomValue));
    }
  };

  const opts = [
    ...options,
    {
      value: "custom",
      label: "Custom",
    },
  ] as const;

  return (
    <Box mb={3}>
      <ControlLabel>{label}</ControlLabel>
      <ButtonSelect<T | "custom">
        options={opts}
        value={optIndex === -1 ? "custom" : value}
        onChange={setVal}
      />
      {optIndex === -1 && (
        <Input
          width={200}
          mt={2}
          value={custom}
          onChange={(ev) => setCustom(ev.target.value)}
          onBlur={(ev) => processCustom(ev.target.value)}
        />
      )}
    </Box>
  );
}
