import { useAccountNew } from "@oaktyres/queries";
import {
  Alert,
  Box,
  Button,
  Circle,
  Flex,
  Panel,
  Text,
  useAuth,
} from "@oaktyres/ui";
import axios from "axios";
import {
  addMonths,
  differenceInMonths,
  endOfMonth,
  format,
  startOfMonth,
} from "date-fns";
import { range } from "lodash";
import React, { useState } from "react";
import {
  FaDownload,
  FaExclamationTriangle,
  FaFile,
  FaInfoCircle,
} from "react-icons/fa";
import { DocumentModal } from "../../components/DocumentModal";
import { useScopedAccount } from "../../components/ScopedAccountProvider";

const earliest = new Date(2023, 6, 1);

const userAgent = window.navigator.userAgent;
const isMobileSafari = userAgent.match(/iPad/i) || userAgent.match(/iPhone/i);

export const StatementsPage = () => {
  const [downloading, setDownloading] = useState<number>(-1);
  const [docUrl, setDocUrl] = useState<string | null>(null);
  const auth = useAuth();
  const [scopedAccount] = useScopedAccount();
  const account = useAccountNew(scopedAccount);
  const isNotUser = auth.user?.type !== "user";
  const downloadsDisabled = isNotUser || account.data?.ledger != null;

  const dates = range(
    differenceInMonths(endOfMonth(new Date()), earliest) + 1,
  ).map((x) => addMonths(startOfMonth(new Date()), -x));

  const downloadStatement = (index: number) => {
    setDownloading(index);
    const date = dates[index];
    const url = `/api/v2/accounts/${scopedAccount}/statements/${format(
      date,
      "MMM-yyyy",
    )}`;
    axios
      .get(url)
      .then(({ data }) => {
        if (isMobileSafari) {
          window.location = data.url;
        } else {
          setDocUrl(data.url);
        }
      })
      .catch(() => {
        window.alert("Document not yet available.");
      })
      .finally(() => setDownloading(-1));
  };

  return (
    <Panel width="100%">
      {docUrl && <DocumentModal url={docUrl} onClose={() => setDocUrl(null)} />}
      {isNotUser && (
        <Alert icon={FaExclamationTriangle} color="warning" m={2}>
          <Text fontWeight={600} fontSize={2}>
            Statements are only available to named users.
          </Text>
          <Text fontWeight={500} fontSize={1}>
            Please login using an email address to access your statements.
          </Text>
        </Alert>
      )}
      {account.data?.ledger != null ? (
        <Alert icon={FaInfoCircle} color="info" m={2}>
          <Text fontWeight={600} fontSize={2}>
            This account is ledgered to {account.data.ledger}.
          </Text>
          <Text fontWeight={500} fontSize={1}>
            Please login to account {account.data.ledger} to view statements.
          </Text>
        </Alert>
      ) : (
        <>
          {dates.map((x, i, self) => (
            <Flex borderBottom={1} p={2} alignItems="center" key={x.toString()}>
              <Circle icon={FaFile} color="primary" size="large" />
              <Flex
                alignItems="flex-start"
                flexDirection={"column"}
                flex={1}
                ml={3}
              >
                <Text fontWeight={600}>Monthly Statement</Text>
                <Text
                  fontWeight={500}
                  fontSize={1}
                  color="grey1"
                  style={{ textTransform: "uppercase" }}
                >
                  {format(x, "MMMM yyyy")}
                </Text>
              </Flex>
              <Button
                icon={FaDownload}
                onClick={() => downloadStatement(i)}
                disabled={downloadsDisabled || downloading === i}
              >
                Download
              </Button>
            </Flex>
          ))}
          <Text textAlign={"center"} color="grey2" fontSize={1} my={3}>
            Note: Statements before July 2023 are not currently available
          </Text>
        </>
      )}
    </Panel>
  );
};
