import { MarkupBandSpec, SellOutGroupSpec } from "@oaktyres/model";
import { useManufacturers } from "@oaktyres/queries";
import {
  Flex,
  FormInput,
  FormInputWrap,
  FormSelect,
  Select,
  Text,
  AddButton,
} from "@oaktyres/ui";
import { set } from "lodash";
import React from "react";
import { MarkupBandEditor } from "./MarkupBandEditor";

export type GroupEditorProps = {
  value: SellOutGroupSpec;
  onChange: (value: SellOutGroupSpec) => void;
};

const speedOpts = [
  {
    value: "",
    label: "<Any>",
  },
  ...[
    "B",
    "C",
    "K",
    "L",
    "M",
    "N",
    "O",
    "P",
    "Q",
    "R",
    "S",
    "T",
    "U",
    "H",
    "V",
    "W",
    "Y",
    "Z",
  ].map((x) => ({
    value: x,
    label: x,
  })),
];

const brandTierOptions = [
  {
    value: "",
    label: "<Any>",
  },
  {
    value: "0",
    label: "Budget",
  },
  {
    value: "1",
    label: "Midrange",
  },
  {
    value: "2",
    label: "Premium",
  },
];

const boolOpts = [
  {
    value: "",
    label: "<Any>",
  },
  {
    value: "false",
    label: "No",
  },
  {
    value: "true",
    label: "Yes",
  },
];

const seasonOpts = [
  {
    value: "",
    label: "<Any>",
  },
  {
    value: "A",
    label: "All-Season",
  },
  {
    value: "S",
    label: "Summer",
  },
  {
    value: "W",
    label: "Winter",
  },
];

export const GroupEditor = ({ value, onChange }: GroupEditorProps) => {
  const manus = useManufacturers();

  const setProp = (path: string) => (val: any) => {
    if (val?.target) {
      val = val.target.value;
    }

    const data = { ...value };
    set(data, path, val);
    onChange(data);
  };

  const setBrand = (val: string) => {
    if (val === "") {
      onChange({
        ...value,
        brand: null,
      });
    } else {
      onChange({
        ...value,
        brand: val,
        brandTier: null,
      });
    }
  };

  const setBrandTier = (val: string) => {
    if (val === "") {
      onChange({
        ...value,
        brandTier: null,
      });
    } else {
      onChange({
        ...value,
        brand: null,
        brandTier: parseInt(val, 10),
      });
    }
  };

  const brandOptions = [
    { value: "", label: "<Any>" },
    ...(manus?.data ?? []).map((x) => ({
      value: x.crossReference,
      label: x.name,
    })),
  ];

  const addNewBand = () => {
    const nextCost =
      parseFloat(
        value.bands[value.bands.length - 1]?.endCost?.toString() ?? "-0.01"
      ) + 0.01;
    value.bands.push({
      type: "percent",
      startCost: nextCost,
      endCost: null,
      amount: 0,
    });

    onChange({ ...value });
  };

  const updateBand = (index: number, val: MarkupBandSpec) => {
    value.bands[index] = val;
    onChange({ ...value });
  };

  const removeBand = (index: number) => {
    if (window.confirm("Are you sure?")) {
      value.bands = value.bands.filter((_, i) => i !== index);
      onChange({ ...value });
    }
  };

  const setLowerSpeed = (ev: React.ChangeEvent<HTMLSelectElement>) => {
    let val: string | null = ev.target.value;

    if (val === "") {
      val = null;
    }

    const lowerSpeed = val;
    let upperSpeed = value.upperSpeed;

    if (val != null) {
      const currentUpper = value.upperSpeed;

      if (
        currentUpper == null ||
        speedOpts.findIndex((x) => x.value === currentUpper) <
          speedOpts.findIndex((x) => x.value === val)
      ) {
        upperSpeed = val;
      }
    }

    onChange({
      ...value,
      lowerSpeed,
      upperSpeed,
    });
  };

  const setUpperSpeed = (ev: React.ChangeEvent<HTMLSelectElement>) => {
    let val: string | null = ev.target.value;

    if (val === "") {
      val = null;
    }

    const upperSpeed = val;
    let lowerSpeed = value.lowerSpeed;

    if (val != null) {
      if (
        lowerSpeed == null ||
        speedOpts.findIndex((x) => x.value === lowerSpeed) >
          speedOpts.findIndex((x) => x.value === val)
      ) {
        lowerSpeed = val;
      }
    }

    onChange({
      ...value,
      lowerSpeed,
      upperSpeed,
    });
  };

  return (
    <Flex flexDirection="column" p={3} width={"100%"}>
      <FormInput
        label="Group Name"
        width={300}
        value={value.name}
        onChange={setProp("name")}
      />
      <FormSelect
        value={value.brand ?? ""}
        onChange={(ev) => setBrand(ev.target.value)}
        options={brandOptions}
        disabled={value.brandTier != null}
        label="Brand"
      />
      <FormSelect
        value={value.brandTier?.toString() ?? ""}
        onChange={(ev) => setBrandTier(ev.target.value)}
        options={brandTierOptions}
        disabled={value.brand != null}
        label="Brand Tier"
      />
      <Flex style={{ gap: 6 }}>
        <FormSelect
          style={{ flex: 1 }}
          value={
            value.isCommercial == null
              ? ""
              : value.isCommercial
              ? "true"
              : "false"
          }
          onChange={(ev) =>
            setProp("isCommercial")(
              ev.target.value === "" ? null : ev.target.value === "true"
            )
          }
          options={boolOpts}
          label="Is Commercial"
        />
        <FormSelect
          style={{ flex: 1 }}
          value={
            value.isRunflat == null ? "" : value.isRunflat ? "true" : "false"
          }
          onChange={(ev) =>
            setProp("isRunflat")(
              ev.target.value === "" ? null : ev.target.value === "true"
            )
          }
          options={boolOpts}
          label="Is Runflat"
        />
        <FormSelect
          style={{ flex: 1 }}
          value={value.seasonality ?? ""}
          onChange={(ev) =>
            setProp("seasonality")(
              ev.target.value === "" ? null : ev.target.value
            )
          }
          options={seasonOpts}
          label="Seasonality"
        />
      </Flex>
      <Flex style={{ gap: 6 }} mb={3}>
        <Flex flexDirection={"column"} width={3 / 4}>
          <Flex style={{ gap: 6 }}>
            <FormInput
              style={{ flex: 1 }}
              label="Section"
              value={value.section ?? ""}
              onChange={(ev) =>
                setProp("section")(
                  ev.target.value === "" ? null : ev.target.value
                )
              }
            />
            <FormInput
              style={{ flex: 1 }}
              label="Profile"
              value={value.profile ?? ""}
              onChange={(ev) =>
                setProp("profile")(
                  ev.target.value === "" ? null : ev.target.value
                )
              }
            />
            <FormInput
              style={{ flex: 1 }}
              label="Rim"
              value={value.rim ?? ""}
              onChange={(ev) =>
                setProp("rim")(ev.target.value === "" ? null : ev.target.value)
              }
            />
          </Flex>
          <Text>
            Section, profile and rim can be input as ranges and comma delimited
            lists. <br />
            For example <code>205,215,250-280</code> would match values 205, 215
            or any value between 250 and 280 inclusively.
          </Text>
        </Flex>
        <FormInputWrap label="Speed Ratings (Inclusive)" style={{ flex: 1 }}>
          <Flex alignItems="center" flex={1}>
            <Select
              style={{ flex: 1 }}
              value={value.lowerSpeed ?? ""}
              onChange={setLowerSpeed}
              options={speedOpts}
            />
            <Text mx={1}>-</Text>
            <Select
              style={{ flex: 1 }}
              value={value.upperSpeed ?? ""}
              onChange={setUpperSpeed}
              options={speedOpts}
            />
          </Flex>
        </FormInputWrap>
      </Flex>
      <Text fontSize={3} fontWeight={600}>
        Markup Bands
      </Text>
      <Text fontSize={1} fontWeight={600} mb={3} color="grey2">
        The base price for the markup is the cost price provided by Oak Tyres.
      </Text>
      {value.bands.map((x, i) => (
        <MarkupBandEditor
          key={i}
          value={x}
          onChange={(val) => updateBand(i, val)}
          onRemove={() => removeBand(i)}
        />
      ))}
      <AddButton onClick={addNewBand}>Add New Band</AddButton>
    </Flex>
  );
};
