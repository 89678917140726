import React from "react";
import styled from "styled-components";
import { space, SpaceProps } from "styled-system";
import Flex from "./Flex";

export const ManufacturerIcon = styled.img<SpaceProps>`
  height: 24px;
  width: 24px;
  border-radius: 3px;
  margin-right: 6px;
  ${space}
`;

const ManufacturerIconSub = styled.span<SpaceProps>`
  font-weight: 600;
  height: 20px;
  width: 20px;
  border-radius: 3px;
  margin-right: 6px;
  background-color: #eee;
  color: #aaa;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  ${space}
`;

export type ManufacturerLabelProps = React.ComponentPropsWithoutRef<
  typeof Flex
> & {
  name: string | null;
  code: string;
  staticRoot: string;
  icon: string | null;
};

export default function ManufacturerLabel(props: ManufacturerLabelProps) {
  const { name, code, icon, staticRoot, ...rest } = props;

  if (icon != null && icon !== "") {
    return (
      <Flex alignItems="center" {...rest}>
        <ManufacturerIcon
          src={staticRoot + icon}
          alt={name || code}
          mr={name ? 1 : 0}
        />
        {name}
      </Flex>
    );
  } else {
    return (
      <Flex alignItems="center" {...rest}>
        <ManufacturerIconSub mr={name ? 1 : 0}>{code}</ManufacturerIconSub>
        {name}
      </Flex>
    );
  }
}
