import React from "react";
import FormInputWrap, { FormInputWrapProps } from "./FormInputWrap";
import Select, { SelectProps } from "./Select";

type FormSelectProps<T extends string> = FormInputWrapProps & {
  name?: string;
  label?: string;
  error?: any;
  defaultValue?: string;
  required?: boolean;
  value?: T;
  disabled?: boolean;
  onChange?: SelectProps<T>["onChange"];
  options: SelectProps<T>["options"];
};

const FormSelect = React.forwardRef<HTMLSelectElement, FormSelectProps<string>>(
  (
    {
      name,
      label,
      error,
      defaultValue,
      required,
      value,
      options,
      onChange,
      disabled,
      ...rest
    },
    ref,
  ) => (
    <FormInputWrap
      name={name}
      label={label}
      error={error}
      required={required}
      {...rest}
    >
      <Select
        name={name}
        value={value}
        ref={ref}
        defaultValue={defaultValue}
        options={options}
        onChange={onChange}
        disabled={disabled}
      />
    </FormInputWrap>
  ),
);

export default FormSelect;
