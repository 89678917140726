import { stores } from "@oaktyres/model";
import React from "react";
import { NavLink, Route } from "react-router-dom";
import styled from "styled-components";
import logoSrc from "../img/logo.png";
import opSrc from "../img/op.png";
import opTextSrc from "../img/optext.png";

import { useAccountNew, useRewardTemplates } from "@oaktyres/queries";
import { Flex, Sidebar, useAuth, useUser } from "@oaktyres/ui";
import { PortalIcon } from "./PortalIcons";
import { useScopedAccount } from "./ScopedAccountProvider";
import { NextVan } from "./NextVan";

export const SIDEBAR_WIDTH = 280;

const MainNavLink = styled(NavLink)`
  width: 100%;
  display: flex;
  align-items: center;
  padding: 12px;
  padding-left: 32px;
  color: ${(props) => props.theme.colors.grey1};
  font-weight: 500;
  position: relative;
  transition: all 0.3s ease;
  font-size: 15px;

  &:hover,
  &:focus {
    text-decoration: none;
    background-color: ${(props) => props.theme.colors.grey6};
  }

  &:before {
    position: absolute;
    width: 6px;
    left: 0;
    top: 0;
    height: 100%;
    content: " ";
  }

  & svg,
  & img {
    margin-right: 12px;
    width: 24px;
    height: 24px;
    opacity: 0.7;
  }

  &.active {
    color: ${(props) => props.theme.colors.body};
    background-color: ${(props) => props.theme.colors.grey6};

    &:before {
      background-color: ${(props) => props.theme.colors.primary};
    }

    & svg,
    & img {
      opacity: 1;
      color: ${(props) => props.theme.colors.primary};
      fill: ${(props) => props.theme.colors.primary};
      filter: grayscale(0);
    }
  }
`;

const LinkImage = styled.img`
  filter: grayscale(1);
`;

const PartnershipText = styled.img`
  height: 22px !important;
  width: auto !important;
  filter: grayscale(1);
`;

const SecondaryNavLink = styled(NavLink)`
  width: 100%;
  display: flex;
  align-items: center;
  padding: 12px;
  padding-left: 96px;
  color: ${(props) => props.theme.colors.grey1};
  font-weight: 500;
  position: relative;
  transition: all 0.3s ease;
  font-size: 15px;
  background-color: ${(props) => props.theme.colors.grey6};

  &:before {
    position: absolute;
    height: 4px;
    left: 60px;
    top: calc(50% - 2px);
    width: 18px;
    content: " ";
    background-color: ${(props) => props.theme.colors.grey4};
    border-radius: 2px;
  }

  &:hover,
  &:focus {
    text-decoration: none;
    color: ${(props) => props.theme.colors.body};
  }

  &.active {
    color: ${(props) => props.theme.colors.body};

    &:before {
      background-color: ${(props) => props.theme.colors.primary};
    }
  }
`;

const Logo = styled.img`
  max-width: 80%;
  margin: 48px 24px;
`;

type MainSidebarProps = {
  floatWidth: number;
};

export const MainSidebar = ({ floatWidth }: MainSidebarProps) => {
  const [scopedAccount] = useScopedAccount();
  const auth = useAuth();
  const account = useAccountNew(scopedAccount);
  const rules = useRewardTemplates(scopedAccount);

  const hasImprest = account.data?.hasImprest ?? false;

  const hasDsp = account.data?.dealerSupportPoints.enabled ?? false;
  const hasPartnership =
    auth.user?.type === "user" && hasDsp && (rules.data ?? []).length > 0;

  return (
    <Sidebar side="left" floatWidth={floatWidth} width={SIDEBAR_WIDTH}>
      <Flex
        width="100%"
        height="100%"
        flexDirection={"column"}
        justifyContent="space-between"
      >
        <Flex flexDirection={"column"} width="100%">
          <Logo src={logoSrc} />
          <NextVan />
          <MainNavLink to="/tyres">
            <PortalIcon iconType="tyre" width="24px" height="24px" />
            Tyres
          </MainNavLink>
          {hasImprest && (
            <Route path="/tyres">
              <SecondaryNavLink to="/tyres" exact>
                Search Tyres
              </SecondaryNavLink>
              <SecondaryNavLink to="/tyres/imprest" exact>
                Imprest Stock
              </SecondaryNavLink>
            </Route>
          )}
          {stores
            .filter((x) => x.enabled)
            .map((x) => (
              <MainNavLink to={`/store/${x.id}`} key={x.id}>
                <PortalIcon iconType={x.id} width="24px" height="24px" />
                {x.name}
              </MainNavLink>
            ))}
          {hasPartnership && (
            <>
              <MainNavLink
                to={"/partnership"}
                isActive={(match, location) => {
                  return (
                    location.pathname.startsWith("/partnership") ||
                    location.pathname.startsWith("/store/dealersupport")
                  );
                }}
              >
                <LinkImage src={opSrc} />
                <PartnershipText src={opTextSrc} />
              </MainNavLink>
              <Route path={["/store/dealersupport", "/partnership"]}>
                <SecondaryNavLink to="/partnership" exact>
                  Dashboard
                </SecondaryNavLink>
                {hasDsp && (
                  <SecondaryNavLink to="/store/dealersupport" exact>
                    Dealer Support
                  </SecondaryNavLink>
                )}
              </Route>
            </>
          )}
        </Flex>

        <Flex flexDirection={"column"} width="100%">
          <MainNavLink to="/orders" exact>
            <PortalIcon iconType="orderhistory" width="24px" height="24px" />
            Order History
          </MainNavLink>
          <MainNavLink to="/basket" exact>
            <PortalIcon iconType="basket" width="24px" height="24px" />
            Basket
          </MainNavLink>
        </Flex>
      </Flex>
    </Sidebar>
  );
};
