import { ImprestItemDto, TyreSearchPayload } from "@oaktyres/model";
import axios from "axios";
import { useQuery } from "react-query";
import { searchTyres } from "./stock";

const getImprestStockForAccount = async (
  accountCode?: string | null
): Promise<(TyreSearchPayload & ImprestItemDto)[]> => {
  if (accountCode == null) {
    return [];
  }

  const imprest = await axios
    .get<ImprestItemDto[]>(`/api/v2/accounts/${accountCode}/imprest`)
    .then(({ data }) => data);

  if (imprest.length === 0) {
    return [];
  }

  const stockCodes = imprest.map((x) => x.stockCode);

  const tyres = await searchTyres({ stockCodes, accountCode });

  return tyres.map((x) => ({
    ...x,
    ...imprest.find((i) => i.stockCode === x.stockCode)!,
  }));
};

export const useImprestForAccount = (accountCode?: string | null) =>
  useQuery(["imprest", accountCode], () =>
    getImprestStockForAccount(accountCode)
  );
