import { ProspectStatus, TyreSearchPayload } from "@oaktyres/model";
import { formatFullFitment } from "@oaktyres/queries";
import {
  AccountBrandLabel,
  Box,
  Button,
  Flex,
  ManufacturerLabel,
  Modal,
  PanelFooter,
  PanelHeader,
  Text,
} from "@oaktyres/ui";
import { sum } from "lodash";
import React from "react";

export type OrderProposalItem = {
  item: TyreSearchPayload;
  qty: number;
};

export type OrderProposalModalProps = {
  proposal: OrderProposalItem[];
  onClose: (accepted: boolean) => void;
};

export const OrderProposalModal = ({
  proposal,
  onClose,
}: OrderProposalModalProps) => {
  const total =
    sum(proposal.map((x) => (x.item.price?.value ?? 0) * x.qty)) ?? 0;

  return (
    <Modal onClose={() => onClose(false)} shown p={0} width={500}>
      <PanelHeader>
        <Text fontSize={3} fontWeight={600}>
          Quote
        </Text>
      </PanelHeader>
      <Box p={3}>
        {proposal.map((x) => (
          <Flex
            mb={1}
            pb={1}
            borderBottom={1}
            justifyContent="space-between"
            alignItems={"center"}
          >
            <Flex flexDirection="column" alignItems={"flex-start"}>
              <div style={{ fontWeight: 600 }}>
                <ManufacturerLabel
                  mb={1}
                  code={x.item.brand.crossReference}
                  name={`${x.item.brand.name} ${x.item.pattern.name}`}
                  icon={x.item.brand.icon}
                  staticRoot={process.env.REACT_APP_STATIC_ROOT as string}
                />
              </div>
              <Text fontWeight={600}>{formatFullFitment(x.item)}</Text>
              <Text fontSize={1} fontFamily="Roboto Mono">
                {x.item.stockCode}
              </Text>
            </Flex>

            <Flex
              flexDirection="column"
              alignItems="flex-end"
              order={[0, 1]}
              width={100}
            >
              <Text fontSize={4} fontWeight={600} lineHeight={1}>
                £{((x.item.price?.value ?? 0) * x.qty).toFixed(2)}
              </Text>
              <Text fontSize={2} fontWeight={600} color="grey2">
                £{(x.item.price?.value ?? 0).toFixed(2)} X {x.qty}
              </Text>
            </Flex>
          </Flex>
        ))}
        <Flex justifyContent={"flex-end"} mt={3}>
          <Text fontSize={4} fontWeight={600} lineHeight={1}>
            £{total.toFixed(2)}
          </Text>
        </Flex>
      </Box>
      <Flex p={3}>
        <Button onClick={() => onClose(true)} mr={2} color="primary">
          Add to basket
        </Button>
        <Button onClick={() => onClose(false)} color="grey2">
          Cancel
        </Button>
      </Flex>
    </Modal>
  );
};
