import { FileUploadPermission } from "@oaktyres/model";
import axios, { AxiosRequestConfig } from "axios";
import React, { useRef, useState } from "react";
import Button from "./Button";
import { FileProp } from "./Uploader";

export type UploadButtonProps = {
  onUpload: (value: FileProp | null) => void;
};

export const UploadButton = ({ onUpload }: UploadButtonProps) => {
  const fileElem = useRef<HTMLInputElement>(null!);
  const [isUploading, setIsUploading] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);

  const startUpload = () => {
    if (fileElem.current) {
      fileElem.current.click();
    }
  };

  const onUploadFinished = (filename: string, originalFilename: string) => {
    onUpload({
      filename,
      originalFilename,
    });
  };

  const upload = async (ev: React.ChangeEvent<HTMLInputElement>) => {
    const file = (ev.currentTarget.files || [])[0];

    if (file == null) {
      return;
    }

    const config: AxiosRequestConfig = {
      onUploadProgress: (progressEvent) => {
        setUploadProgress(
          Math.round((progressEvent.loaded * 100) / progressEvent.total),
        );
      },
    };

    setUploadProgress(0);
    setIsUploading(true);

    const uploadUrl = await axios
      .post<FileUploadPermission>(`/api/v2/uploads`, { filename: file.name })
      .then(({ data }) => data);

    axios
      .put(uploadUrl.url, file, config)
      .then((res) => {
        onUploadFinished(uploadUrl.key, file.name);
      })
      .catch((err) => {
        alert(err.message);
      })
      .finally(() => {
        setIsUploading(false);
        setUploadProgress(0);
      });
  };

  return (
    <>
      <input
        type="file"
        onChange={upload}
        style={{ display: "none" }}
        ref={fileElem}
      />
      <Button type="button" onClick={!isUploading ? startUpload : undefined}>
        Upload {uploadProgress > 0 ? `(${uploadProgress}%)` : ""}
      </Button>
    </>
  );
};
