import { range } from "lodash";
import React from "react";
import styled from "styled-components";
import { space, SpaceProps } from "styled-system";

const Container = styled.div<SpaceProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 6px;
  ${space};
`;

const Pip = styled.button<{ active: boolean }>`
  background: ${(props) =>
    props.active ? props.theme.colors.primary : props.theme.colors.grey3};
  width: 18px;
  height: 18px;
  border-radius: 50%;
  border: 0;
  transform: scale(${(props) => (props.active ? 1 : 0.6)});
  transition: all 0.3s ease;
`;

export type PipsProps = {
  count: number;
  active: number;
  onClick?: (index: number) => void;
} & SpaceProps;

export const Pips = ({ count, active, onClick, ...rest }: PipsProps) => {
  return (
    <Container {...rest}>
      {range(count).map((x) => (
        <Pip
          key={x}
          active={x === active}
          onClick={onClick && (() => onClick(x))}
        />
      ))}
    </Container>
  );
};
