import { range } from "lodash";
import React, { useCallback, useEffect, useState } from "react";
import { FaIdCardAlt, FaLock } from "react-icons/fa";
import { animated, useSpring } from "react-spring";
import styled from "styled-components";
import Circle from "./Circle";
import Flex from "./Flex";
import Loader from "./Loader";
import Modal from "./Modal";
import Text from "./Text";

export type PinEntryModalProps = {
  confirm?: boolean;
  checkPin?: (value: string) => Promise<boolean>;
  onEntry: (value: string) => void;
  onCancel: () => void;
};

const PinDigitContainer = styled.div`
  width: 70px;
  height: 120px;
  background-color: ${(props) => props.theme.colors.grey4};
  border-radius: 6px;
  color: ${(props) => props.theme.colors.grey1};
  font-size: 72px;
  display: flex;
  align-items: center;
  justify-content: center;

  & span {
    display: block;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    background-color: ${(props) => props.theme.colors.grey1};
  }
`;

const PinDigit = ({ filled }: { filled: boolean }) => {
  const props = useSpring({
    scale: filled ? 1 : 0.3,
    opacity: filled ? 1 : 0,
  });

  return (
    <PinDigitContainer>
      <animated.span style={props}></animated.span>
    </PinDigitContainer>
  );
};

export const PinEntryModal = ({
  confirm,
  checkPin,
  onEntry,
  onCancel,
}: PinEntryModalProps) => {
  const [pin, setPin] = useState("");
  const [pinToConfirm, setPinToConfirm] = useState("");
  const [checking, setChecking] = useState(false);

  const addDigit = useCallback((digit: string) => {
    setPin((old) => old + digit);
  }, []);

  const removeDigit = useCallback(() => {
    setPin((old) => old.slice(0, -1));
  }, []);

  useEffect(() => {
    const callback = (ev: KeyboardEvent) => {
      if (checking) {
        return;
      }

      if (/^[0-9]$/i.test(ev.key)) {
        addDigit(ev.key);
      } else if (ev.key === "Backspace") {
        removeDigit();
      } else if (ev.key === "Escape") {
        onCancel();
      }
    };

    window.addEventListener("keydown", callback);

    return () => window.removeEventListener("keydown", callback);
  }, [addDigit, removeDigit, onCancel, checking]);

  const failConfirm = useCallback(() => {
    setPinToConfirm("");
    setPin("");
    window.alert("PINs don't match");
  }, []);

  const invalidPin = useCallback(() => {
    setPinToConfirm("");
    setPin("");
    window.alert("Invalid PIN");
  }, []);

  const confirmPin = useCallback(
    async (pin: string) => {
      if (pinToConfirm.length > 0) {
        if (pin !== pinToConfirm) {
          failConfirm();
        } else {
          onEntry(pin);
        }
      } else if (checkPin != null) {
        setChecking(true);
        const isValid = await checkPin(pin);
        setChecking(false);
        if (isValid) {
          onEntry(pin);
        } else {
          invalidPin();
        }
      } else if (confirm) {
        setPinToConfirm(pin);
        setPin("");
      }
    },
    [confirm, checkPin, pinToConfirm, failConfirm, invalidPin],
  );

  useEffect(() => {
    if (pin.length === 4) {
      confirmPin(pin);
    }
  }, [pin]);

  const isConfirming = pinToConfirm.length > 0;

  return (
    <Modal onClose={onCancel} shown p={5}>
      {checking && <Loader overlay />}
      <Flex flexDirection={"column"} alignItems="center">
        <Circle size="xlarge" color="danger" icon={FaLock} mb={3} />
        <Text fontSize={3} fontWeight={600} color="danger" mb={3}>
          {isConfirming
            ? "Please confirm your PIN"
            : confirm
              ? "Please enter a new PIN"
              : "Please enter your PIN"}
        </Text>
      </Flex>
      <Flex style={{ gap: 6 }}>
        {range(0, 4).map((x) => (
          <PinDigit filled={pin.length > x} />
        ))}
      </Flex>
    </Modal>
  );
};
