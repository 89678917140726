import {
  PaymentDto,
  RemittableItemDto,
  RemittanceDto,
  RemittanceSpec,
} from "@oaktyres/model";
import axios from "axios";
import { useMutation, useQuery, useQueryClient } from "react-query";

const getPayments = (code: string) =>
  axios
    .get<PaymentDto[]>(`/api/v2/accounts/${code}/payments`)
    .then(({ data }) => data);

const getRemittance = (id: string) =>
  axios
    .get<RemittanceDto>(`/api/v2/remittances/${id}`)
    .then(({ data }) => data);

const getRemittances = (accountCode?: string | null) =>
  axios
    .get<RemittanceDto[]>(`/api/v2/remittances`, { params: { accountCode } })
    .then(({ data }) => data);

const getRemittableItems = (accountCode: string) =>
  axios
    .get<RemittableItemDto[]>(`/api/v2/remittables/${accountCode}`)
    .then(({ data }) => data);

const createNewRemittance = (spec: RemittanceSpec) =>
  axios
    .post<RemittanceDto>(`/api/v2/remittances`, spec)
    .then(({ data }) => data);

export const useRemittableItems = (accountCode: string) =>
  useQuery(["remittances", accountCode, "items"], () =>
    getRemittableItems(accountCode),
  );

export const usePayments = (accountCode: string) =>
  useQuery(["payments", accountCode], () => getPayments(accountCode));

export const useRemittance = (id: string) =>
  useQuery(["remittances", id], () => getRemittance(id));

export const useCreateRemittance = () => {
  const queryClient = useQueryClient();

  const mutation = useMutation(
    (spec: RemittanceSpec) => createNewRemittance(spec),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["remittances"]);
      },
    },
  );

  return mutation;
};

export const useRemittances = (accountCode: string | null) =>
  useQuery(["remittances", accountCode], () => getRemittances(accountCode));
