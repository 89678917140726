export const stores = [
  {
    id: "consumables",
    name: "Consumables",
    currency: "gbp",
    enabled: true,
  },
  {
    id: "tne",
    name: "Tools & Equipment",
    currency: "gbp",
    enabled: true,
  },
  {
    id: "innertubes",
    name: "Inner Tubes",
    currency: "gbp",
    enabled: true,
  },
  {
    id: "dealersupport",
    name: "Dealer Support",
    currency: "dsp",
    enabled: false,
  },
  {
    id: "tpms",
    name: "TPMS",
    currency: "gbp",
    enabled: true,
  },
  {
    id: "rewards",
    name: "Rewards",
    currency: "rp",
    enabled: false,
  },
] as const;

export type StoreDefinition = (typeof stores)[number];

export type StoreId = (typeof stores)[number]["id"];
