import { useManufacturers } from "@oaktyres/queries";
import { readableColor } from "polished";
import React from "react";
import { FaStar } from "react-icons/fa";
import styled from "styled-components";
import Label from "./Label";
import Text from "./Text";

export const Container = styled(Label)`
  display: inline-flex;
  align-items: center;
  overflow: hidden;
  flex: 0 0 auto;
`;

const ManufacturerIcon = styled.img`
  display: block;
  border-radius: 3px;
  margin-right: 12px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
`;

const StarContainer = styled.div<{ color: string }>`
  display: flex;
  height: 30px;
  width: 30px;
  color: white;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  flex: 0 0 30px;
  background-color: ${(props) => props.theme.colors[props.color]};
`;

const colors = {
  gold: "gold",
  silver: "silver",
  bronze: "bronze",
  unknown: "positive",
  previous: "danger",
};

export type AccountBrandLabelProps = {
  brand: string;
  level: "gold" | "silver" | "bronze" | "unknown" | "previous" | null;
  labelSize?: "small" | "medium" | "large";
  disableColors?: boolean;
};

export const AccountBrandLabel = ({
  brand,
  level,
  labelSize = "large",
  disableColors = false,
}: AccountBrandLabelProps) => {
  const brands = useManufacturers();

  const ourBrand = brands.data?.find((x) => x.crossReference === brand);

  const labelColor = disableColors
    ? "#f3f3f3"
    : ourBrand?.secondaryColor ?? "#f3f3f3";
  const textColor = readableColor(labelColor, "#555", "#fff", false);

  return (
    <Container
      p={0}
      style={{ backgroundColor: labelColor, color: textColor }}
      labelSize={labelSize}
    >
      {ourBrand?.icon && (
        <ManufacturerIcon
          height={labelSize === "large" ? 30 : 24}
          src={process.env.REACT_APP_STATIC_ROOT + ourBrand?.icon}
        />
      )}
      <Text as="span" mr={"15px"} color={textColor}>
        {ourBrand?.name}
      </Text>
      {level != null && (
        <StarContainer color={colors[level]}>
          <FaStar />
        </StarContainer>
      )}
    </Container>
  );
};
