import React, { useRef } from "react";
import { FaSearch, FaTimes } from "react-icons/fa";
import styled, { keyframes } from "styled-components";
import { space, layout, SpaceProps, LayoutProps } from "styled-system";
import { IconButton } from "..";

type ContainerProps = SpaceProps & LayoutProps;

const Container = styled.div<ContainerProps>`
  position: relative;
  max-width: 100%;
  display: flex;
  align-items: center;
  background-color: white;
  border: 1px solid ${(props) => props.theme.colors.grey3};
  border-radius: 6px;
  line-height: inherit;
  max-width: 100%;
  padding-left: 12px;
  padding-right: 0;
  padding-top: 0;
  padding-bottom: 0;
  height: 37px;

  &:focus-within {
    border-color: ${(props) => props.theme.colors.primary};
  }
  ${space};
  ${layout};
`;

const spin = keyframes`
{
  to {transform: rotate(360deg);}
}
`;

const Input = styled.input`
  flex: 1;
  padding: 9px 12px;
  padding-left: 4px;
  padding-bottom: 9px;
  border: 0;
  background-color: transparent;
  min-width: 0;

  &:focus,
  &:active {
    outline: 0;
  }
`;

const Spinner = styled.span`
  position: absolute;
  top: 18px;
  right: 20px;

  &:before {
    content: "";
    box-sizing: border-box;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 20px;
    height: 20px;
    margin-top: -10px;
    margin-left: -10px;
    border-radius: 50%;
    border-top: 2px solid ${(props) => props.theme.colors.primary};
    border-right: 2px solid transparent;
    animation: ${spin} 0.6s linear infinite;
  }
`;

type InputWithSearchProps = {
  searching: boolean;
  onClear?: () => void;
} & ContainerProps &
  React.ComponentPropsWithoutRef<"input">;

const InputWithSearch = React.forwardRef<
  HTMLInputElement,
  InputWithSearchProps
>(
  (
    { searching, onClear, mb, ml, mr, width, maxWidth, height, size, ...props },
    ref
  ) => {
    const innerRef = useRef<HTMLInputElement>(ref as any);

    const handleKeyDown = (ev: React.KeyboardEvent) => {
      if (ev.key === "Enter") {
        innerRef.current?.blur();
      }
    };

    return (
      <Container mb={mb} width={width} maxWidth={maxWidth} ml={ml} mr={mr}>
        <FaSearch style={{ marginRight: 6, flex: "0 0 auto" }} />
        <Input {...props} ref={innerRef} onKeyDown={handleKeyDown} />
        {searching ? (
          <Spinner />
        ) : onClear ? (
          <IconButton icon={FaTimes} onClick={onClear} color="body" mr={2} />
        ) : null}
      </Container>
    );
  }
);

export default InputWithSearch;
