import styled from "styled-components";
import {
  border,
  BorderProps,
  boxShadow,
  BoxShadowProps,
  flexbox,
  FlexboxProps,
  grid,
  GridProps,
  layout,
  LayoutProps,
  space,
  SpaceProps,
  background,
  BackgroundProps,
  ColorProps,
  color,
} from "styled-system";

export type PanelProps = SpaceProps &
  LayoutProps &
  GridProps &
  BoxShadowProps &
  BorderProps &
  BackgroundProps &
  ColorProps &
  FlexboxProps & {
    primary?: boolean;
    positive?: boolean;
    danger?: boolean;
  };

export type PanelHeaderProps = SpaceProps & FlexboxProps & LayoutProps;

export type PanelFooterProps = SpaceProps & FlexboxProps & LayoutProps;

const Panel = styled.div<PanelProps>`
  background: white;
  border-radius: 6px;
  border: 1px solid
    ${(props) =>
      props.positive
        ? props.theme.colors.positive
        : props.primary
        ? props.theme.colors.primary
        : props.danger
        ? props.theme.colors.danger
        : props.theme.colors.grey6};
  box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  ${space};
  ${layout};
  ${flexbox};
  ${grid};
  ${boxShadow};
  ${border};
  ${background};
  ${color};
`;

Panel.defaultProps = {
  boxShadow: 1,
};

export const PanelHeader = styled.div<PanelHeaderProps>`
  display: flex;
  height: 65px;
  align-items: center;
  background-color: #fcfcfc;
  border-top-right-radius: 6px;
  border-top-left-radius: 6px;
  ${space};
  ${flexbox};
  ${layout};
  border-bottom: 1px solid ${(props) => props.theme.colors.grey5};
`;

export const PanelFooter = styled.div<PanelFooterProps>`
  display: flex;
  height: 65px;
  align-items: center;
  ${space};
  ${flexbox};
  ${layout};
  border-top: 1px solid ${(props) => props.theme.colors.grey5};
`;

PanelHeader.defaultProps = {
  p: 3,
};

export default Panel;
