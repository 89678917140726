export enum DealerSupportBrand {
  None = 0,
  Davanti = 1,
  Evergreen = 2,
  Landsail = 3,
}

export enum ProspectStatus {
  NeverProspect = 0,
  IsProspect = 1,
  ApplicationStarted = 2,
  CamRegistered = 3,
  WasProspect = 4,
}

export interface DbAccount {
  AccountID?: number;
  AccountCode: string;
  Name: string;
  RewardPointsEnabled: boolean;
  BusinessDevelopmentFund: boolean;
  DealerSupportPoint: boolean;
  DeliveryAddress1: string;
  DeliveryAddress2: string;
  DeliveryAddress3: string;
  DeliveryAddress4: string;
  DeliveryAddress5: string;
  DeliveryPostCode: string;
  DealerSupportOverdraft: number;
  DealerSupportBrand: DealerSupportBrand;
  Depot: number | null;
  VanRun: string;
  DeliveryTimesMonday: string;
  DeliveryTimesWednesday: string;
  DeliveryTimesTuesday: string;
  DeliveryTimesThursday: string;
  DeliveryTimesFriday: string;
  DeliveryTimesSaturday: string;
  MarkupAboveThreshold: number | null;
  MarkupBelowThreshold: number | null;
  AccountDescription: string;
  ProspectStatus: ProspectStatus;
  CreatedByID: number | null;
  CreatedOn: Date | null;
  MidasID: number | null;
  CurrencyID: number | null;
}
