import { SellOutListSpec } from "@oaktyres/model";
import { displayMutationError, useCreateSellOutList } from "@oaktyres/queries";
import { Box, Button, Flex, Loader, Text } from "@oaktyres/ui";
import React, { useState } from "react";
import { FaSave } from "react-icons/fa";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";
import { useScopedAccount } from "../../components/ScopedAccountProvider";
import { SellOutListEditor } from "./SellOutListEditor";

const getDefault = (accountCode: string): SellOutListSpec => ({
  name: "",
  applyVat: true,
  accountCode: accountCode,
  roundingDirection: "up",
  roundingType: "pence",
  roundingValue: 95,
  globalMarkupType: "percent",
  globalMarkupAmount: 0,
  groups: [],
});

export const CreateSellOutList = () => {
  const [code] = useScopedAccount();
  const history = useHistory();

  const createList = useCreateSellOutList();

  const [spec, setSpec] = useState<SellOutListSpec>(getDefault(code));

  const save = async () => {
    try {
      const res = await createList.mutateAsync(spec);
      history.push(`/sell-out-lists/${res.id}`);
    } catch (err) {
      displayMutationError(err);
    }
  };

  return (
    <Box width="100%">
      <Flex justifyContent="space-between" alignItems={"center"} mb={3}>
        <Text fontSize={4} fontWeight={600}>
          {code} / <Link to={`/sell-out-lists`}>Sell Out Lists</Link> / Create
          New List
        </Text>
        <Button icon={FaSave} onClick={save}>
          Save
        </Button>
      </Flex>
      <div style={{ position: "relative" }}>
        {createList.isLoading && <Loader overlay />}
        <SellOutListEditor value={spec} onChange={setSpec} />
      </div>
    </Box>
  );
};
