import React, { useEffect, useState } from "react";
import Flex from "./Flex";
import Text from "./Text";
import Input from "./Input";
import Modal from "./Modal";
import Button from "./Button";

export type TableFilterRangeConfig<T> = {
  type: "range";
  prefix?: string;
  getValue: (item: T) => number;
};

export type TableFilterRangeValues = {
  min: number;
  max: number;
};

export type TableFilterRangeModalProps<T> = {
  data: T[];
  config: TableFilterRangeConfig<T>;
  values: TableFilterRangeValues | null;
  onChange: (val: TableFilterRangeValues | null) => void;
};

export function TableFilterRange<T>(
  item: T,
  config: TableFilterRangeConfig<T>,
  values: TableFilterRangeValues
) {
  const val = config.getValue(item);
  const stat = val >= values.min && val <= values.max;
  return stat;
}

export function TableFilterRangeModal<T>({
  data,
  config,
  values,
  onChange,
}: TableFilterRangeModalProps<T>) {
  const [min, setMin] = useState<string>((values?.min ?? 0).toString());
  const [max, setMax] = useState<string>((values?.max ?? 1).toString());

  useEffect(() => {
    if (values == null) {
      const range = data.map(config.getValue);
      setMin(Math.min(...range).toString());
      setMax(Math.max(...range).toString());
    }
  }, [values, data, config]);

  const setVals = () => {
    const mn = parseFloat(min);
    const mx = parseFloat(max);
    if ([mn, mx].some(Number.isNaN)) {
      window.alert("Values must be numbers");
      return;
    }
    onChange({
      min: parseFloat(min) || 0,
      max: parseFloat(max) || 1,
    });
  };

  return (
    <Modal p={3} shown width={200}>
      <Flex alignItems="center" mb={3}>
        {config.prefix && <Text mr={1}>{config.prefix}</Text>}

        <Input value={min} onChange={(ev) => setMin(ev.target.value)} />
        <Text pl={2} pr={2}>
          -
        </Text>
        {config.prefix && <Text mr={1}>{config.prefix}</Text>}
        <Input value={max} onChange={(ev) => setMax(ev.target.value)} />
      </Flex>
      <Flex>
        <Button onClick={setVals} mr={1} style={{ flex: 1 }}>
          Set
        </Button>
        <Button onClick={() => onChange(null)} ml={1} style={{ flex: 1 }}>
          Clear
        </Button>
      </Flex>
    </Modal>
  );
}
