import { max, min } from "lodash";
import { DepotDto } from "..";
import { getTimeOfDay } from "./time";

export const getClosingTime = (
  depot: DepotDto,
  day?: number,
): number | null => {
  if (day == null) {
    day = new Date().getDay();
  }

  return (
    max(depot.openingTimes.filter((x) => x.day === day).map((x) => x.close)) ??
    null
  );
};

export const getOpeningTime = (
  depot: DepotDto,
  day?: number,
): number | null => {
  if (day == null) {
    day = new Date().getDay();
  }

  return (
    min(depot.openingTimes.filter((x) => x.day === day).map((x) => x.open)) ??
    null
  );
};

export const isOpenOnDay = (depot: DepotDto, day: number): boolean =>
  depot.openingTimes.some((x) => x.day === day);

const isOpenAt = (depot: DepotDto, day: number, timeOfDay: number): boolean => {
  const closing = getClosingTime(depot, day);

  if (closing == null) {
    return false;
  }

  return closing > timeOfDay;
};

export const isStillOpen = (depot: DepotDto): boolean => {
  return isOpenAt(depot, new Date().getDay(), getTimeOfDay(new Date()));
};

export const getNextOpenDay = (
  depot: DepotDto,
  day?: number,
): number | null => {
  if (day == null) {
    day = new Date().getDay();
  }

  const nextDay = day === 6 ? 0 : day + 1;

  return (
    [0, 1, 2, 3, 4, 5, 6, 0, 1, 2, 3, 4, 5, 6]
      .slice(nextDay)
      .find((x) => isOpenOnDay(depot, x)) ?? null
  );
};
