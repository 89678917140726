import { CurrencyType } from "@oaktyres/model";
import {
  Box,
  currencyTypeColors,
  currencyTypeNames,
  Flex,
  formatCurrency,
  Text,
} from "@oaktyres/ui";
import { format } from "path";
import React from "react";
import styled from "styled-components";
import { PortalIcon } from "../../components/PortalIcons";

const Container = styled.button<{ active: boolean }>`
  border: 1px solid ${(props) => props.theme.colors[props.color ?? "black"]};
  box-shadow: ${(props) => props.theme.shadows[props.active ? 2 : 0]};
  border-radius: 6px;
  background-color: white;
  transition: all 0.3s ease;
  cursor: pointer;
  text-align: left;
  opacity: ${(props) => (props.active ? 1 : 0.5)};
`;

export type CurrencyReportButtonProps = {
  active: boolean;
  onClick: () => void;
  currentBalance: number;
  spent: number;
  earned: number;
  overdraft: number | null;
  currencyType: CurrencyType;
};

export const CurrencyReportButton = ({
  active,
  onClick,
  currentBalance,
  currencyType,
  spent,
  earned,
  overdraft,
}: CurrencyReportButtonProps) => {
  return (
    <Container
      onClick={onClick}
      active={active}
      color={currencyTypeColors[currencyType]}
    >
      <Flex p={2} py={2}>
        <PortalIcon
          iconType={currencyType}
          color={currencyTypeColors[currencyType]}
          width="24px"
          height="24px"
          mr={1}
        />
        <Text fontWeight={600}>{currencyTypeNames[currencyType]}</Text>
      </Flex>
      <Flex backgroundColor={"grey5"} px={2}>
        <Text
          fontWeight={600}
          fontSize={5}
          color={currencyTypeColors[currencyType]}
          style={{ flex: 1 }}
        >
          {formatCurrency(currencyType, false)(currentBalance)}
        </Text>
        {overdraft ? (
          <Flex flex={1} flexDirection="column" justifyContent={"center"}>
            <Text fontWeight={600} fontSize={1} lineHeight={1} mb={"3px"}>
              Overdraft
            </Text>
            <Text
              color={currencyTypeColors[currencyType]}
              lineHeight={1}
              fontSize={3}
              fontWeight={600}
            >
              {formatCurrency(currencyType)(overdraft)}
            </Text>
          </Flex>
        ) : null}
      </Flex>
      <Flex px={2} py={1}>
        <Box flex={1}>
          <Text fontWeight={600} fontSize={1} lineHeight={1} mb={"3px"}>
            Earned
          </Text>
          <Text
            color={currencyTypeColors[currencyType]}
            lineHeight={1}
            fontSize={3}
            fontWeight={600}
          >
            {formatCurrency(currencyType)(earned)}
          </Text>
        </Box>
        <Box flex={1}>
          <Text fontWeight={600} fontSize={1} lineHeight={1} mb={"3px"}>
            Spent
          </Text>
          <Text
            color={currencyTypeColors[currencyType]}
            lineHeight={1}
            fontSize={3}
            fontWeight={600}
          >
            {formatCurrency(currencyType)(spent)}
          </Text>
        </Box>
      </Flex>
    </Container>
  );
};
