import React from "react";
import styled from "styled-components";
import { space, SpaceProps } from "styled-system";

type ContainerProps = SpaceProps & {
  color: string;
  size: "small" | "normal";
  value: boolean;
};

const Container = styled.div<ContainerProps>`
  background: ${(props) => props.theme.colors[props.color]};
  box-shadow: inset 1px 1px 5px rgba(0, 0, 0, 0.3);
  width: ${(props) => (props.size === "small" ? 40 : 60)}px;
  height: ${(props) => (props.size === "small" ? 18 : 28)}px;
  border-radius: ${(props) => (props.size === "small" ? 9 : 14)}px;
  position: relative;
  transition: all 0.3s ease;
  flex: 0 0 ${(props) => (props.size === "small" ? 40 : 60)}px;
  cursor: pointer;
  ${space};

  &:after {
    content: "";
    display: block;
    background-color: white;
    border-radius: 50%;
    width: ${(props) => (props.size === "small" ? 14 : 24)}px;
    height: ${(props) => (props.size === "small" ? 14 : 24)}px;
    box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.3);
    position: absolute;
    transform: translate3d(
      ${(props) => (props.value ? (props.size === "small" ? 24 : 34) : 2)}px,
      2px,
      0
    );
    transition: all 0.3s ease;
  }
`;

export type ToggleProps = {
  value: boolean;
  onChange: (newVal: boolean) => void;
  disabled?: boolean;
  size?: "small" | "normal";
  noColor?: boolean;
} & SpaceProps;

export const Toggle = ({
  value,
  onChange,
  noColor = false,
  disabled = false,
  size = "normal",
  ...rest
}: ToggleProps) => {
  return (
    <Container
      {...rest}
      size={size}
      color={noColor || !value ? "grey3" : "positive"}
      onClick={disabled ? undefined : () => onChange(!value)}
      value={value}
      style={{ opacity: disabled ? 0.5 : 1 }}
    />
  );
};
