import { TyreSearchPayload } from "@oaktyres/model";
import React, { useMemo } from "react";
import Box from "./Box";
import CheckBox from "./Checkbox";
import { FilterSet } from "./Filters";
import Flex from "./Flex";
import FormHeading from "./FormHeading";
import Text from "./Text";

type FilterBoxProps = {
  name: string;
  count: number;
  value: boolean;
  onChange: (value: boolean) => void;
};

const FilterBox = ({ name, count, value, onChange }: FilterBoxProps) => {
  return (
    <Flex justifyContent="space-between" alignItems="center" mb={1}>
      <Flex alignItems="center">
        <CheckBox value={value} onChange={(val) => onChange(!!val)} />
        <Text fontSize={1} fontWeight={500} ml={1}>
          {name}
        </Text>
      </Flex>
      <Text fontWeight={600} fontSize={1} color={"grey"}>
        ({count})
      </Text>
    </Flex>
  );
};

type TyreFiltersProps = {
  data: TyreSearchPayload[];
  filters: FilterSet;
  minQty: number;
  value: string[];
  onChange: (newValue: string[]) => void;
};

export const TyreFilters = ({
  data,
  value,
  minQty,
  filters,
  onChange,
}: TyreFiltersProps) => {
  const filterCounts = useMemo(() => {
    const counts: Record<string, number> = {};

    Object.entries(filters).forEach(([header, fs]) => {
      Object.entries(fs).forEach(([name, fn]) => {
        const ident = `${header}.${name}`;
        counts[ident] = data.filter((x) => fn(x, minQty)).length;
      });
    });

    return counts;
  }, [data, filters, minQty]);

  const toggleFilter = (filterName: string) => (filterValue: boolean) => {
    if (filterValue) {
      onChange(value.concat(filterName));
    } else {
      onChange(value.filter((x) => x !== filterName));
    }
  };

  const visibleFilters = Object.entries(filters).filter(
    ([header]) =>
      header === "Availability" ||
      value.some((x) => x.startsWith(header)) ||
      Object.entries(filterCounts).filter(
        (x) => x[0].includes(header) && x[1] > 0,
      ).length > 1,
  );

  if (visibleFilters.length === 0) {
    return null;
  }

  return (
    <Box maxHeight="100%" overflowY="auto">
      {visibleFilters.map(([header, fs]) => (
        <Box pt={2} key={header}>
          <FormHeading mt={0}>{header}</FormHeading>
          {Object.entries(fs)
            .map(([name, fn]) => ({
              name,
              fn,
              count: filterCounts[`${header}.${name}`],
            }))
            .filter((x) => x.count > 0)
            .map(({ name, count }) => (
              <FilterBox
                key={name}
                name={name}
                count={count}
                value={value.includes(`${header}.${name}`)}
                onChange={toggleFilter(`${header}.${name}`)}
              />
            ))}
        </Box>
      ))}
    </Box>
  );
};
