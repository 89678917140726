import { useAuth } from "@oaktyres/ui";
import React from "react";
import { useEffect } from "react";
import { Redirect, useLocation } from "react-router";

export const LogOut = () => {
  const account = new URLSearchParams(useLocation().search).get("account");
  const auth = useAuth();

  useEffect(() => {
    if (account) {
      auth.logoutToAccount(account);
    } else {
      auth.logout();
    }
  }, [account]);

  if (auth.user?.ownedAccounts.some((x) => x.code === account)) {
    return <Redirect to="/" />;
  }

  return null;
};
