import styled from "styled-components";
import Text from "./Text";

const FormHeading = styled(Text)`
  text-transform: uppercase;
`;

FormHeading.defaultProps = {
  mt: 3,
  mb: 2,
  fontWeight: 600,
  color: "#333",
  fontSize: "12px"
};

export default FormHeading;
