import { ProductCategoryDto } from "@oaktyres/model";
import React from "react";
import styled from "styled-components";
import { Panel, Text } from "@oaktyres/ui";

const Container = styled.div`
  display: grid;
  gap: 12px;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
`;

const ItemContainer = styled(Panel)`
  cursor: pointer;
  border: 0;
`;

const ImageContainer = styled.div`
  border-top-right-radius: 6px;
  border-top-left-radius: 6px;
  overflow: hidden;

  & > img {
    display: block;
    width: 100%;
    transition: all 0.3s ease;
    aspect-ratio: 16/9;

    ${ItemContainer}:hover & {
      transform: scale(1.2);
      opacity: 0.8;
    }
  }
`;

const getImageUrl = (filename: string | null) =>
  filename == null
    ? "https://placehold.co/640x360"
    : `${process.env.REACT_APP_STATIC_ROOT}${filename}`;

export type ProductCategoryDisplayProps = {
  item: ProductCategoryDto;
  brand?: string;
  onClick: () => void;
};

export const ProductCategoryDisplay = ({
  item,
  onClick,
  brand,
}: ProductCategoryDisplayProps) => {
  return (
    <ItemContainer onClick={onClick}>
      <ImageContainer>
        <img src={getImageUrl(brand ? item.brandImages[brand] : item.image)} />
      </ImageContainer>
      <Text fontWeight={600} fontSize="1em" p={2}>
        {item.name}
      </Text>
    </ItemContainer>
  );
};
