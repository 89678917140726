import { StockHistoryEntryDto } from "@oaktyres/model";
import { useStockOrderHistory } from "@oaktyres/queries";
import { format, fromUnixTime } from "date-fns";
import { transparentize } from "polished";
import React, { useState } from "react";
import { useMemo } from "react";
import { useTheme } from "styled-components";
import Flex from "../Flex";
import Loader from "../Loader";
import { MiniToggle } from "../MiniToggle";
import Text from "../Text";
import SortableTable, { SortableTableColumn } from "../SortableTable";
import Button from "../Button";

export type OrderHistoryTableProps = {
  accountCode?: string | undefined;
  lockToAccount?: boolean;
  showPrice?: boolean;
  stockCode: string;
  onSelect?: (item: StockHistoryEntryDto) => void;
};

export const OrderHistoryTable = ({
  accountCode,
  lockToAccount = false,
  showPrice = false,
  stockCode,
  onSelect,
}: OrderHistoryTableProps) => {
  const theme = useTheme();
  const [onlyAccount, setOnlyAccount] = useState(accountCode != null);
  const data = useStockOrderHistory(
    stockCode,
    onlyAccount ? accountCode : undefined,
  );

  const cols = useMemo<SortableTableColumn<StockHistoryEntryDto>[]>(
    () => [
      {
        name: "date",
        label: "Date",
        format: (x) => format(fromUnixTime(x.date), "dd MMM yyyy"),
      },
      {
        name: "invoice",
        label: "Invoice",
      },
      {
        name: "accountCode",
        label: "Account",
        hidden: lockToAccount,
      },
      {
        name: "adviceNote",
        label: "Advice Note",
      },
      {
        name: "customerOrderRef",
        label: "Order Ref",
      },
      {
        name: "quantity",
        label: "Quantity",
        props: {
          textAlign: "right",
        },
        headingProps: {
          textAlign: "right",
        },
      },
      {
        name: "price",
        label: "Price",
        hidden: !showPrice,
        props: {
          textAlign: "right",
        },
        headingProps: {
          textAlign: "right",
        },
        format: (x) =>
          (x.price / x.quantity).toLocaleString("en-GB", {
            style: "currency",
            currency: "GBP",
          }),
      },
      {
        name: "onselect",
        label: "",
        hidden: onSelect == null,
        format: (x) => (
          <Flex justifyContent={"center"}>
            <Button
              tiny
              onClick={onSelect == null ? undefined : () => onSelect(x)}
            >
              View
            </Button>
          </Flex>
        ),
      },
    ],
    [showPrice, onSelect],
  );

  if (data.isLoading) {
    return <Loader />;
  }

  return (
    <Flex flexDirection="column">
      {accountCode != null && (
        <Flex
          justifyContent={"space-between"}
          p={1}
          pl={3}
          borderBottom={1}
          alignItems="center"
        >
          <Text fontWeight={600} fontSize={2} mb={0}>
            Order History
          </Text>
          {!lockToAccount && (
            <MiniToggle
              label={`Only ${accountCode}`}
              value={onlyAccount}
              onChange={setOnlyAccount}
            />
          )}
        </Flex>
      )}
      <SortableTable
        columns={cols}
        data={data.data ?? []}
        defaultDesc
        emptyMessage="No Previous History Found"
        defaultSort={0}
        rowSearch={(x) =>
          [x.invoice, x.adviceNote, x.accountCode, x.customerOrderRef].join(" ")
        }
        rowStyle={(x) => ({
          backgroundColor:
            x.price < 0
              ? transparentize(0.5, (theme as any).colors.danger)
              : undefined,
        })}
        paged
        pageSize={25}
        disableIncrementalRender
      />
    </Flex>
  );
};
