import { useRemittance } from "@oaktyres/queries";
import { sortBy } from "lodash";
import {
  Box,
  Button,
  formatCurrency,
  FormHeading,
  Loader,
  Modal,
  PanelFooter,
  PanelHeader,
  Table,
  Text,
} from "@oaktyres/ui";
import { format, fromUnixTime } from "date-fns";
import React from "react";
import styled from "styled-components";

export type RemittanceDisplayModalProps = {
  remittanceId: string;
  onClose: () => void;
};

const Well = styled.div`
  box-shadow: inset 1px 1px 3px rgba(0, 0, 0, 0.1);
  border: 1px solid ${(props) => props.theme.colors.grey3};
  height: 180px;
  overflow-y: auto;
  margin-bottom: 6px;
  border-radius: 3px;
`;

export const RemittanceDisplayModal = ({
  remittanceId,
  onClose,
}: RemittanceDisplayModalProps) => {
  const data = useRemittance(remittanceId);

  return (
    <Modal shown onClose={onClose} p={0} width={500}>
      <PanelHeader>
        <Text fontSize={3} fontWeight={600}>
          Remittance
        </Text>
      </PanelHeader>
      {data.isLoading ? (
        <Loader />
      ) : (
        <Box p={3} style={{ whiteSpace: "pre" }}>
          <FormHeading mb={0} mt={0}>
            Created On
          </FormHeading>
          <Text>
            {format(fromUnixTime(data.data!.createdOn), "dd/MM/yyyy p")}
          </Text>
          <FormHeading mb={0}>Reference</FormHeading>
          <Text fontFamily={"mono"}>{data.data?.reference}</Text>
          <FormHeading mb={0}>Total Value</FormHeading>
          <Text>
            {formatCurrency("gbp")(
              data
                .data!.items.map((x) => x.unallocatedValue)
                .reduce((acc, val) => acc + val, 0),
            )}
          </Text>
          <FormHeading mb={0}>Items</FormHeading>
          <Well>
            <Table>
              <thead>
                <tr>
                  <th style={{ width: 90 }}>Date</th>
                  <th>Reference</th>
                  <th className="right">Amount Due</th>
                </tr>
              </thead>
              <tbody>
                {sortBy(data.data!.items, (x) => x.dueDate).map((r) => (
                  <tr>
                    <td>{format(fromUnixTime(r.dueDate), "MMM do")}</td>
                    <td>
                      <Text fontFamily={"Roboto Mono"} fontSize="inherit">
                        {r.referenceNumber}
                      </Text>
                    </td>
                    <td className="right">
                      {formatCurrency("gbp")(r.unallocatedValue)}
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Well>
        </Box>
      )}
      <PanelFooter p={2}>
        <Button onClick={onClose}>Close</Button>
      </PanelFooter>
    </Modal>
  );
};
