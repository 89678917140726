import styled from "styled-components";
import {
  space,
  flexbox,
  layout,
  SpaceProps,
  FlexboxProps,
  LayoutProps,
} from "styled-system";

type BoxProps = SpaceProps & FlexboxProps & LayoutProps;

const Box = styled.div<BoxProps>`
  ${space};
  ${flexbox};
  ${layout};
`;

export default Box;
