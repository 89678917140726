import React, { useState, useEffect } from "react";
import axios from "axios";
import styled from "styled-components";
import Text from "./Text";

import { ProspectStatus } from "@oaktyres/model";
import { FaBuilding, FaRegGem } from "react-icons/fa";
import { space, SpaceProps } from "styled-system";
import Flex from "./Flex";
import { useDebounce } from "../utils/useDebounce";
import InputWithSearch from "./InputWithSearch";
import Button from "./Button";

interface AccountSummaryDto {
  accountID: number;
  accountCode: string;
  name: string;
  emailAddress: string;
  deliveryPostCode: string;
  prospectStatus: ProspectStatus;
}

const postCodeRegex =
  /^(GIR ?0AA|[A-PR-UWYZ]([0-9]{1,2}|([A-HK-Y][0-9]([0-9ABEHMNPRV-Y])?)|[0-9][A-HJKPS-UW]) ?[0-9][ABD-HJLNP-UW-Z]{2})$/i;

const Container = styled.div<SpaceProps>`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  max-width: 100%;
  ${space}
`;

const AccountListItem = styled.button`
  text-decoration: none;
  color: ${(props) => props.theme.colors.body};
  display: flex;
  align-items: center;
  background: white;
  padding: 12px;
  border: 0;

  border-bottom: 1px solid #ddd;
  white-space: nowrap;
  justify-content: space-between;

  &:hover {
    text-decoration: underline;
  }

  & svg {
    margin-right: 6px;
  }
`;

const AccountListTitle = styled.div`
  background: white;
  padding: 6px 12px;
  font-weight: bold;
  border-bottom: 2px solid #ddd;
  color: ${(props) => props.theme.colors.primary};
`;

type AccountListProps = {
  title: string;
  label1: "accountCode" | "name" | "deliveryPostCode";
  label2: "accountCode" | "name" | "deliveryPostCode";
  items: AccountSummaryDto[];
  onSelect: (account: AccountSummaryDto) => any;
};

type AccountSearchProps = {
  onSelect: (account: AccountSummaryDto) => any;
  allowProspects: boolean;
} & SpaceProps;

const MainLabel = styled(Text)`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const iconStyle: React.CSSProperties = {
  position: "relative",
  top: "1px",
};

const AccountList = ({
  title,
  items,
  label1,
  label2,
  onSelect,
}: AccountListProps) =>
  items.length > 0 ? (
    <Flex flexDirection="column" mb="1">
      <AccountListTitle>{title}</AccountListTitle>
      {items.map((x, i) => (
        <AccountListItem key={i} onClick={() => onSelect(x)}>
          <MainLabel fontWeight="600" as="span" mr={1}>
            {x.prospectStatus === ProspectStatus.NeverProspect ||
            x.prospectStatus === ProspectStatus.WasProspect ? (
              <FaBuilding size="0.8em" style={iconStyle as any} />
            ) : (
              <FaRegGem size="0.8em" style={iconStyle as any} />
            )}
            {x[label1]}
          </MainLabel>
          <Text color="grey" fontSize={1} fontWeight="600">
            {x[label2].toUpperCase()}
          </Text>
        </AccountListItem>
      ))}
    </Flex>
  ) : null;

const AccountSearch = ({
  onSelect,
  allowProspects,
  ...rest
}: AccountSearchProps) => {
  const [accounts, setAccounts] = useState<AccountSummaryDto[]>([]);
  const [search, setSearch] = useState<string>("");
  const [searching, setSearching] = useState<boolean>(false);
  const debouncedSearch = useDebounce(search, 500);

  useEffect(() => {
    if (debouncedSearch === "") {
      setAccounts([]);
      return;
    }

    if (debouncedSearch.length < 2) {
      return;
    }

    setSearching(true);

    axios
      .get("/api/accounts/search?query=" + debouncedSearch)
      .then(({ data }) => {
        setSearching(false);
        setAccounts(data.accounts);
      })
      .catch((error) => {
        setSearching(false);
        if (axios.isCancel(error)) {
          console.log("Request canceled", error.message);
        } else {
          alert("There was an error.");
        }
      });
  }, [debouncedSearch]);

  const selectDefault = () => {
    const account = accounts.find((x) =>
      x.accountCode.toLowerCase().includes(debouncedSearch.toLowerCase()),
    );

    if (account != null) {
      onSelect(account);
    }
  };

  const accountCodeMatches = accounts.filter((x) =>
    x.accountCode.toLowerCase().includes(debouncedSearch.toLowerCase()),
  );

  const nameMatches = accounts.filter((x) =>
    x.name.toLowerCase().includes(debouncedSearch.toLowerCase()),
  );

  const postcodeMatches = accounts.filter((x) =>
    x.deliveryPostCode.toLowerCase().includes(debouncedSearch.toLowerCase()),
  );

  const isValidPostCode = postCodeRegex.test(debouncedSearch);

  const anyMatches = [accountCodeMatches, nameMatches, postcodeMatches].some(
    (x) => x.length > 0,
  );

  return (
    <Container {...rest}>
      <form onSubmit={selectDefault} style={{ width: "100%" }}>
        <InputWithSearch
          searching={searching}
          mb={3}
          width={["320px", "400px"] as any}
          maxWidth="100%"
          autoFocus
          placeholder="Start typing..."
          onChange={(ev: React.FormEvent<HTMLInputElement>) => {
            if (ev.currentTarget.value.length > 1) {
              setSearching(true);
            }
            setSearch(ev.currentTarget.value);
          }}
          value={search}
        />
      </form>
      <Flex
        width={["100%", "400px"] as any}
        maxWidth="100%"
        justifyContent="stretch"
        flexDirection="column"
        mb={anyMatches ? 2 : 0}
      >
        {allowProspects && isValidPostCode && (
          <Flex justifyContent="center" width="100%" mb={2}>
            <Button
              to={`/accounts/create-prospect?postcode=${debouncedSearch.toUpperCase()}`}
            >
              Create a new prospect with post code{" "}
              {debouncedSearch.toUpperCase()}
            </Button>
          </Flex>
        )}
        <AccountList
          title="By Account Code"
          items={accountCodeMatches}
          label1="name"
          label2="accountCode"
          onSelect={onSelect}
        />
        <AccountList
          title="By Name"
          items={nameMatches}
          label1="name"
          label2="accountCode"
          onSelect={onSelect}
        />
        <AccountList
          title="By Post Code"
          items={postcodeMatches}
          label1="name"
          label2="deliveryPostCode"
          onSelect={onSelect}
        />
      </Flex>
    </Container>
  );
};

export default AccountSearch;
