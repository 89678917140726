import { format, isSameYear } from "date-fns";
import React, { ComponentProps, useState } from "react";
import { FaCalendarAlt } from "react-icons/fa";
import styled from "styled-components";
import { space, SpaceProps } from "styled-system";
import { DateRangeModal } from "./DateRangeModal";

const Container = styled.button<SpaceProps>`
  min-width: 160px;
  background-color: white;
  border-radius: 6px;
  padding: 8px 12px;
  height: 42px;
  border: 1px solid ${(props) => props.theme.colors.grey3};
  display: inline-flex;
  margin: 0;
  align-items: center;
  cursor: pointer;
  position: relative;
  padding-right: 36px;
  white-space: nowrap;
  ${space};

  &:after {
    content: "";
    justify-self: end;
    width: 0.7em;
    height: 0.4em;
    margin-top: 0.1em;
    pointer-events: none;
    margin-right: 0.2em;
    background-color: ${(props) => props.theme.colors.primary};
    clip-path: polygon(100% 0%, 0 0%, 50% 100%);
    position: absolute;
    right: 6px;
  }

  & > svg {
    margin-right: 6px;
  }
`;

export type DateRangeButtonPropsWithNull = {
  value: [Date, Date] | null;
  onChange: (val: [Date, Date] | null) => void;
  noNull?: false;
};

export type DateRangeButtonPropsWithoutNull = {
  value: [Date, Date];
  onChange: (val: [Date, Date]) => void;
  noNull: true;
};

export type DateRangeButtonProps = (
  | DateRangeButtonPropsWithoutNull
  | DateRangeButtonPropsWithNull
) & {
  presets?: number[];
} & ComponentProps<typeof Container>;

export function DateRangeButton({
  value,
  onChange,
  noNull,
  presets,
  ...rest
}: DateRangeButtonProps) {
  const [showModal, setShowModal] = useState(false);

  const formatString =
    value == null || !isSameYear(value[0], value[1]) ? "dd/MM/yyyy" : "dd/MM";

  const displayedValue =
    value == null
      ? "None"
      : value.map((x) => format(x, formatString)).join(" - ");

  const setValues = (value: [Date, Date] | null) => {
    setShowModal(false);
    onChange(value!);
  };

  return (
    <>
      <Container onClick={() => setShowModal(true)} {...rest}>
        <FaCalendarAlt />
        {displayedValue}
      </Container>
      {showModal && (
        <DateRangeModal
          values={value}
          onChange={setValues}
          noNull={!!noNull}
          presets={presets}
        />
      )}
    </>
  );
}
