import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import Panel from "./Panel";
import usePortal from "react-useportal";
import Text from "./Text";
import Flex from "./Flex";
import { useSpring, animated } from "react-spring";
import { FaQuestionCircle } from "react-icons/fa";
import clamp from "lodash/clamp";
import useComponentSize from "@rehooks/component-size";

const MARGIN = 16;

type PopOverProps = {
  title?: string;
  content?: string;
  render?: () => React.ReactNode;
  target: string;
  noIcon?: boolean;
  small?: boolean;
};

const PopOverContainer = styled(animated(Panel))`
  position: fixed;
  max-width: 200px;
`;

const PopOver = ({
  title,
  content,
  target,
  render,
  noIcon,
  small
}: PopOverProps) => {
  const [pos, setPos] = useState({ top: 0, left: 0 });
  const ref = useRef<HTMLDivElement>(null!);
  const size = useComponentSize(ref);

  const { openPortal, closePortal, isOpen, Portal } = usePortal({
    onOpen: ({ targetEl }: any) => {
      const rect = targetEl.current.getBoundingClientRect();
      setPos({
        top: rect.bottom + 10,
        left: rect.left + rect.width / 2
      });
    }
  });

  useEffect(() => {
    const elem = document.getElementById(target);

    if (elem) {
      elem.addEventListener("mouseenter", openPortal);
      elem.addEventListener("mouseleave", closePortal);
      return () => {
        elem.removeEventListener("mouseenter", openPortal);
        elem.removeEventListener("mouseleave", closePortal);
      };
    }
  }, [target]);

  const props = useSpring({
    opacity: isOpen ? 1 : 0,
    transform: isOpen ? "translate3d(0,0,0)" : "translate3d(0, 20px, 0)",
    display: isOpen ? "block" : "none"
  });

  const clampedPos = {
    top: pos.top,
    left: clamp(
      pos.left - size.width / 2,
      MARGIN,
      window.innerWidth - (size.width + MARGIN)
    )
  };

  return (
    <Portal>
      <PopOverContainer
        ref={ref}
        pl={2}
        pr={2}
        pt={small ? 1 : 2}
        pb={small ? 1 : 2}
        style={{
          ...props,
          ...clampedPos
        }}
      >
        {title && (
          <Flex alignItems="center" mb="3px">
            {!noIcon && (
              <FaQuestionCircle style={{ position: "relative", top: 2 }} />
            )}
            <Text ml={noIcon ? 0 : 1} fontWeight={600}>
              {title}
            </Text>
          </Flex>
        )}
        {content && <Text fontSize={1}>{content}</Text>}
        {render && render()}
      </PopOverContainer>
    </Portal>
  );
};

export default PopOver;
