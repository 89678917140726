import {
  getDefaultColors,
  getDefaultTheme,
  OakTheme,
  OakThemeColorSet,
} from "@oaktyres/ui";

export const colors: OakThemeColorSet = {
  ...getDefaultColors(),
  background: "#f0f0f0",
  primary: "#ed1c23",
};

export const theme: OakTheme = {
  ...getDefaultTheme(colors),
  fonts: {
    body: "'Poppins', sans-serif",
    heading: "'Poppins', sans-serif",
    mono: "'Roboto Mono', monospace",
  },
};
