import {
  AdviceNoteDto,
  BasketOrderDto,
  InvoiceLineResolutionSpecDto,
  OrderDto,
  OrderHistoryDto,
  StockHistoryEntryDto,
} from "@oaktyres/model";
import axios from "axios";
import { format, getUnixTime } from "date-fns";
import { UTCDate } from "@date-fns/utc";
import {
  useInfiniteQuery,
  useMutation,
  useQuery,
  useQueryClient,
} from "react-query";

export type GetOrdersParams = {
  from: Date;
  to: Date;
  onlyResolvable: boolean;
  accountCode?: string | null;
};

const getOrders = (params: GetOrdersParams | null) =>
  params == null
    ? null
    : axios
        .get<OrderDto[]>(`/api/v2/orders`, {
          params: {
            from: format(params.from, "yyyy-MM-dd"),
            to: format(params.to, "yyyy-MM-dd"),
            onlyResolvable: params.onlyResolvable ? "true" : "false",
            accountCode: params.accountCode,
          },
        })
        .then(({ data }) => data);

const getOrder = (id: string) =>
  axios.get<OrderDto>(`/api/v2/orders/${id}`).then(({ data }) => data);

export const syncOrderStatus = (id: string) =>
  axios.put(`/api/v2/orders/${id}/sync`);

const getStockOrderHistory = (code: string, accountCode: string | undefined) =>
  axios
    .get<StockHistoryEntryDto[]>(`/api/v2/stock/${code}/history`, {
      params: { accountCode },
    })
    .then(({ data }) => data);

export const useOrders = (params: GetOrdersParams | null) =>
  useQuery(["orders", params], () => getOrders(params));

export const useOrder = (id: string) =>
  useQuery(["orders", id], () => getOrder(id));

export const useStockOrderHistory = (stockCode: string, accountCode?: string) =>
  useQuery(["stockhistory", stockCode, accountCode], () =>
    getStockOrderHistory(stockCode, accountCode),
  );

const resolveOrderLine = (id: string, spec: InvoiceLineResolutionSpecDto) =>
  axios.put(`/api/v2/invoice-lines/${id}/resolve`, spec);

const getFullOrderHistory = (
  accountCode: string,
  dateFrom: Date,
  dateTo: Date,
) =>
  axios
    .get<OrderHistoryDto[]>(`/api/v2/order-history`, {
      params: {
        accountCode,
        dateFrom: getUnixTime(
          new UTCDate(
            dateFrom.getFullYear(),
            dateFrom.getMonth(),
            dateFrom.getDate(),
          ),
        ),
        dateTo: getUnixTime(
          new UTCDate(
            dateTo.getFullYear(),
            dateTo.getMonth(),
            dateTo.getDate(),
          ),
        ),
      },
    })
    .then(({ data }) => data);

const searchOrderHistory = async (
  accountCode: string,
  search: string,
  skip: number,
  take: number,
) =>
  search == ""
    ? []
    : await axios
        .get<OrderHistoryDto[]>(`/api/v2/order-history`, {
          params: {
            accountCode,
            search,
            skip,
            take,
          },
        })
        .then(({ data }) => data);

const placeOrder = (order: BasketOrderDto) =>
  axios.post<string>(`/api/v2/orders`, order).then(({ data }) => data);

export const useFullOrderHistory = (
  accountCode: string,
  dateFrom: Date,
  dateTo: Date,
) =>
  useQuery(["order-history", { accountCode, dateFrom, dateTo }], () =>
    getFullOrderHistory(accountCode, dateFrom, dateTo),
  );

export const useInfiniteOrderHistorySearch = (
  accountCode: string,
  search: string,
) =>
  useInfiniteQuery({
    queryKey: ["order-history", accountCode, search],
    queryFn: ({ pageParam }) =>
      searchOrderHistory(accountCode, search, (pageParam || 0) * 20, 20),
    getNextPageParam: (lastPage, pages) =>
      lastPage?.length < 20 ? undefined : pages.length,
  });

export const usePlaceOrder = () => {
  const queryClient = useQueryClient();

  const mutation = useMutation(placeOrder, {
    onSuccess: (_, spec) => {
      queryClient.invalidateQueries(["orders"]);
      queryClient.invalidateQueries(["balances", spec.accountCode]);
    },
  });

  return mutation;
};

export const useResolveOrderLine = (id: string) => {
  const queryClient = useQueryClient();

  const mutation = useMutation(
    (spec: InvoiceLineResolutionSpecDto) => resolveOrderLine(id, spec),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["orders"]);
      },
    },
  );

  return mutation;
};

export const useSyncOrder = () => {
  const queryClient = useQueryClient();

  const mutation = useMutation((id: string) => syncOrderStatus(id), {
    onSuccess: (data, id) => {
      queryClient.invalidateQueries(["orders"]);
    },
  });

  return mutation;
};

export const getAdviceNote = (adviceNoteNumber: string) =>
  axios
    .get<AdviceNoteDto>(`/api/v2/advice-notes/${adviceNoteNumber}`)
    .then(({ data }) => data);

export const useAdviceNote = (adviceNoteNumber: string) =>
  useQuery(["advice-notes", adviceNoteNumber], () =>
    getAdviceNote(adviceNoteNumber),
  );
