import { SellOutGroupDto, SellOutListDto } from "@oaktyres/model";
import {
  displayMutationError,
  useAccountNew,
  useDeleteSellOutList,
  useManufacturers,
  usePullSellOutLists,
  useSellOutLists,
} from "@oaktyres/queries";
import {
  Box,
  Button,
  Flex,
  Grid,
  IconButton,
  Loader,
  MiniTable,
  Panel,
  PanelHeader,
  Text,
  AddButton,
} from "@oaktyres/ui";
import { omit, sortBy } from "lodash";
import React, { useCallback } from "react";
import { FaFileImport, FaLayerGroup, FaTrash } from "react-icons/fa";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";
import { useScopedAccount } from "../../components/ScopedAccountProvider";
import { seasonNames } from "@oaktyres/model";

const capFirst = (val: string) => val.charAt(0).toUpperCase() + val.slice(1);

const roundingDescription = (list: SellOutListDto) => {
  if (list.roundingType === "none") {
    return "None";
  } else if (list.roundingType === "multiple") {
    return `${capFirst(
      list.roundingDirection,
    )} to the nearest multiple of £${list.roundingValue.toFixed(2)}`;
  } else {
    return `${capFirst(
      list.roundingDirection,
    )} to the nearest value ending in £${(list.roundingValue / 100).toFixed(
      2,
    )}.`;
  }
};

export const asCurrency = (val: number | null) =>
  val == null
    ? ""
    : val.toLocaleString("en-GB", {
        style: "currency",
        currency: "GBP",
      });

const ConditionRow = ({
  name,
  value,
}: {
  name: string;
  value: string | null;
}) =>
  value == null || value === "" ? null : (
    <tr>
      <td width="50%">{name}</td>
      <td>{value}</td>
    </tr>
  );

const isCatchAll = (group: SellOutGroupDto) =>
  Object.values(omit(group, ["id", "name", "bands"])).every(
    (x) => x === "" || x == null,
  );

export const SellOutLists = () => {
  const [code] = useScopedAccount();
  const history = useHistory();
  const account = useAccountNew(code);
  const lists = useSellOutLists(code);
  const delListMut = useDeleteSellOutList();
  const pullMut = usePullSellOutLists(code);
  const manus = useManufacturers();

  const brands = manus.data ?? [];

  const deleteList = useCallback(
    async (id: string) => {
      if (window.confirm("Are you sure you want to delete this list?")) {
        await delListMut.mutateAsync(id);
      }
    },
    [delListMut],
  );

  const pullFromMidas = async () => {
    if (
      window.confirm(
        "This will overwrite any existing lists that were imported from GroupLink\n\nAre you sure?",
      )
    ) {
      try {
        await pullMut.mutateAsync();
      } catch (err) {
        displayMutationError(err);
      }
    }
  };

  const newList = () => {
    history.push(`/sell-out-lists/create`);
  };

  const importAllowed =
    account.data != null && !account.data.sellOutPriceImportingDisabled;

  return (
    <Box width="100%">
      <Flex mb={3} justifyContent="space-between">
        <Text fontSize={4} fontWeight={600} as="h1" mb={0}>
          {code} / Sell Out Lists
        </Text>
        {importAllowed && (
          <Button
            icon={FaFileImport}
            onClick={pullFromMidas}
            disabled={pullMut.isLoading}
          >
            Import From Group Link
          </Button>
        )}
      </Flex>
      {lists.data == null || pullMut.isLoading ? (
        <Loader />
      ) : (
        <Grid
          gridTemplateColumns={["1fr", "1fr 1fr", "1fr 1fr 1fr"]}
          gridGap={2}
        >
          {lists.data.map((x) => (
            <Panel>
              <PanelHeader justifyContent={"space-between"}>
                <Text fontSize={3} fontWeight={600}>
                  <Link to={`/sell-out-lists/${x.id}`}>{x.name}</Link>
                </Text>
                <IconButton
                  icon={FaTrash}
                  color="danger"
                  onClick={() => deleteList(x.id)}
                />
              </PanelHeader>
              <Box p={3}>
                <Text>
                  <strong>Rounding: </strong>
                  {roundingDescription(x)}
                </Text>
                <Text>
                  <strong>Global Modifier: </strong>
                  {x.globalMarkupType === "percent"
                    ? `${x.globalMarkupAmount}%`
                    : `£${x.globalMarkupAmount.toFixed(2)}`}
                </Text>
                <Text fontWeight={600} mb={2}>
                  {x.applyVat ? "Includes VAT." : "Excludes VAT."}
                </Text>
                {x.groups.map((x) => (
                  <>
                    <Flex alignItems="center" mb={2}>
                      <FaLayerGroup fontSize={"0.8em"} />
                      <Text fontWeight={600} ml={2}>
                        {x.name} {isCatchAll(x) ? "(Catch All)" : null}
                      </Text>
                    </Flex>
                    <Flex
                      borderLeft={1}
                      flexDirection="column"
                      pl={2}
                      pt={1}
                      pb={1}
                      mb={3}
                    >
                      {isCatchAll(x) ? null : (
                        <Box mb={2}>
                          <Text
                            ml={"2px"}
                            fontSize="10px"
                            color="grey2"
                            fontWeight={600}
                            style={{ textTransform: "uppercase" }}
                            mb={1}
                          >
                            Conditions
                          </Text>
                          <MiniTable>
                            <tbody>
                              <ConditionRow
                                name={"Section"}
                                value={x.section}
                              />
                              <ConditionRow name="Profile" value={x.profile} />
                              <ConditionRow name="Rim" value={x.rim} />
                              <ConditionRow
                                name="Speed Rating"
                                value={
                                  x.lowerSpeed == null
                                    ? null
                                    : x.lowerSpeed === x.upperSpeed
                                      ? x.lowerSpeed
                                      : `${x.lowerSpeed}-${x.upperSpeed}`
                                }
                              />
                              <ConditionRow
                                name="Brand"
                                value={
                                  brands.find(
                                    (b) => b.crossReference === x.brand,
                                  )?.name ??
                                  x.brand ??
                                  null
                                }
                              />
                              <ConditionRow
                                name="Brand Tier"
                                value={
                                  x.brandTier == null
                                    ? null
                                    : ["Budget", "Midrange", "Premium"][
                                        x.brandTier
                                      ]
                                }
                              />
                              <ConditionRow
                                name="Seasonality"
                                value={
                                  x.seasonality == null
                                    ? null
                                    : seasonNames[x.seasonality]
                                }
                              />
                              <ConditionRow
                                name="Is Commercial"
                                value={
                                  x.isCommercial == null
                                    ? null
                                    : x.isCommercial
                                      ? "Yes"
                                      : "No"
                                }
                              />
                              <ConditionRow
                                name="Is Run Flat"
                                value={
                                  x.isRunflat == null
                                    ? null
                                    : x.isRunflat
                                      ? "Yes"
                                      : "No"
                                }
                              />
                            </tbody>
                          </MiniTable>
                        </Box>
                      )}
                      <Text
                        ml={"2px"}
                        fontSize="10px"
                        color="grey2"
                        fontWeight={600}
                        style={{ textTransform: "uppercase" }}
                        mb={1}
                      >
                        Bands
                      </Text>
                      <MiniTable>
                        <tbody>
                          {sortBy(x.bands, "startCost").map((x) => (
                            <tr>
                              <td width="50%">
                                {asCurrency(x.startCost)} -{" "}
                                {x.endCost == null
                                  ? "Inf."
                                  : asCurrency(x.endCost)}
                              </td>
                              <td>
                                {x.type === "percent"
                                  ? `${x.amount}%`
                                  : asCurrency(x.amount)}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </MiniTable>
                    </Flex>
                  </>
                ))}
              </Box>
            </Panel>
          ))}
          <AddButton onClick={newList}>Create New List</AddButton>
        </Grid>
      )}
    </Box>
  );
};
