import React from "react";
import { IconType } from "react-icons";
import Flex from "./Flex";
import { OptionButton } from "./OptionButton";

export type SelectOption<T extends string> = {
  value: T;
  label: string;
  icon?: IconType;
  disabled?: boolean;
};

export type OptionButtonSetProps<T extends string> = {
  value: T | "";
  options: SelectOption<T>[];
  onChange: (newValue: T | "") => void;
};

export const OptionButtonSet = <T extends string>({
  value,
  options,
  onChange,
}: OptionButtonSetProps<T>) => {
  return (
    <Flex flexDirection="column" style={{ gap: 6 }}>
      {options.map((x) => (
        <OptionButton
          key={x.value}
          icon={x.icon}
          name={x.label}
          disabled={x.disabled}
          active={value === x.value}
          onSelect={() => onChange(x.value)}
        />
      ))}
    </Flex>
  );
};
