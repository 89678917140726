import React from "react";
import { groupBy } from "lodash";
import styled from "styled-components";
import {
  space,
  layout,
  typography,
  SpaceProps,
  LayoutProps,
  TypographyProps,
} from "styled-system";

type SelectElemProps = SpaceProps & LayoutProps & TypographyProps;

export type SelectOption<T extends string> = {
  value: T;
  label: string;
  disabled?: boolean;
  group?: string;
};

type TypedSelectElement<T extends string> = HTMLSelectElement & {
  value: T;
};

type TypeChangedEvent<T extends string> = React.ChangeEvent<
  TypedSelectElement<T>
>;

export type SelectProps<T extends string> = SelectElemProps & {
  options: readonly SelectOption<T>[];
  onChange?: (ev: TypeChangedEvent<T>) => void;
} & React.ComponentPropsWithoutRef<"select">;

const SelectWrapper = styled.div<SpaceProps & LayoutProps & TypographyProps>`
  width: 100%;
  border: 1px solid ${(props) => props.theme.colors.grey3};
  border-radius: 6px;
  cursor: pointer;
  background-color: #fff;
  background-image: linear-gradient(to top, #f9f9f9, #fff 33%);
  display: grid;
  grid-template-areas: "select";
  align-items: center;
  padding-right: 6px;
  ${space};
  ${layout};
  ${typography};

  &:after {
    content: "";
    justify-self: end;
    width: 0.7em;
    height: 0.4em;
    margin-top: 0.1em;
    pointer-events: none;
    margin-right: 0.2em;
    background-color: ${(props) => props.theme.colors.primary};
    clip-path: polygon(100% 0%, 0 0%, 50% 100%);
    grid-area: select;
  }
`;

const SelectElem = styled.select<SelectElemProps>`
  appearance: none;
  background-color: transparent;
  outline: none;
  padding: 8px 12px;
  margin: 0;
  font-family: inherit;
  border: 0;
  color: ${(props) => props.theme.colors.body};
  font-size: inherit;
  cursor: inherit;
  display: block;
  width: 100%;
  grid-area: select;

  &:disabled {
    cursor: not-allowed;
  }
`;

const Select = React.forwardRef<HTMLSelectElement, SelectProps<string>>(
  <T extends string>(
    { options, size, value, onChange, ...rest }: SelectProps<T>,

    ref:
      | ((instance: HTMLSelectElement | null) => void)
      | React.RefObject<HTMLSelectElement>
      | null
      | undefined,
  ) => (
    <SelectWrapper {...(rest as any)}>
      <SelectElem {...rest} ref={ref} value={value} onChange={onChange}>
        {Object.entries(groupBy(options, (x) => x.group)).map(
          ([group, items]) => {
            // groupBy will cast to string
            if (group === "undefined") {
              return items.map(({ value, label, disabled }) => (
                <option
                  key={value}
                  value={value?.toString() ?? ""}
                  disabled={disabled}
                >
                  {label}
                </option>
              ));
            } else {
              return (
                <optgroup label={group}>
                  {items.map(({ value, label, disabled }) => (
                    <option
                      key={value}
                      value={value?.toString() ?? ""}
                      disabled={disabled}
                    >
                      {label}
                    </option>
                  ))}
                </optgroup>
              );
            }
          },
        )}
      </SelectElem>
    </SelectWrapper>
  ),
);

export default Select;
