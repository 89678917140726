import React, { useState } from "react";
import styled from "styled-components";
import { Text } from "@oaktyres/ui";
import { PortalIcon } from "./PortalIcons";
import { useScopedAccount } from "./ScopedAccountProvider";
import { useAccountVanRuns, useClosedPeriods } from "@oaktyres/queries";
import { useEffect } from "react";
import { ClosedPeriodDto, VanRunDto } from "@oaktyres/model";
import {
  addDays,
  nextDay,
  format,
  differenceInSeconds,
  isWithinInterval,
  fromUnixTime,
} from "date-fns";
import { sortBy } from "lodash";

const Container = styled.div`
  background-color: #333;
  height: ${(props) => props.theme.space[6]}px;
  width: 100%;
  margin-bottom: ${(props) => props.theme.space[3]}px;
  display: flex;
  padding: 12px 18px;
  align-items: center;
  justify-content: center;
`;

export const getNextInstance = (
  day: number,
  hours: number,
  minutes: number,
  exclusions: ClosedPeriodDto[],
) => {
  const time = hours * 60 + minutes;
  const now = new Date();
  const nowTime = now.getHours() * 60 + now.getMinutes();
  let next = nextDay(now, day as Day);
  if (now.getDay() === day) {
    next = now;
    if (nowTime >= time) {
      next = addDays(next, 7);
    }
  }

  next.setHours(hours);
  next.setMinutes(minutes);
  next.setSeconds(0);

  const isExcluded = (date: Date) => {
    return exclusions.some((x) =>
      isWithinInterval(date, {
        start: fromUnixTime(x.start),
        end: fromUnixTime(x.end),
      }),
    );
  };

  while (isExcluded(next)) {
    next = addDays(next, 7);
  }

  return next;
};

const formatTimeLeft = (seconds: number): string => {
  return [seconds / 60 / 60, (seconds / 60) % 60, seconds % 60]
    .map((x) => Math.floor(x))
    .join(":")
    .replace(/\b(\d)\b/g, "0$1");
};

const formatNextRun = (next: Date | null) => {
  if (next == null) {
    return "";
  }

  return format(next, "EEE MMM dd HH:mm");
};

export const NextVan = () => {
  const [account] = useScopedAccount();
  const vanruns = useAccountVanRuns(account);
  const closedPeriods = useClosedPeriods();
  const [timeleft, setTimeLeft] = useState<number>(0);
  const [nextRun, setNextRun] = useState<VanRunDto | null>(null);

  useEffect(() => {
    const int = setInterval(() => {
      const runs = sortBy(vanruns.data ?? [], (x) =>
        getNextInstance(x.day, x.hours, x.minutes, closedPeriods.data ?? []),
      );
      const nextRun = runs[0];
      const tl =
        nextRun == null
          ? 0
          : differenceInSeconds(
              getNextInstance(
                nextRun.day,
                nextRun.hours,
                nextRun.minutes,
                closedPeriods.data ?? [],
              ),
              new Date(),
            );
      setTimeLeft(tl);
      setNextRun(nextRun ?? null);
    });

    return () => clearInterval(int);
  }, [vanruns.data, closedPeriods.data]);

  if (vanruns?.data?.length === 0) {
    return null;
  }

  const next =
    nextRun == null
      ? null
      : getNextInstance(
          nextRun.day,
          nextRun.hours,
          nextRun.minutes,
          closedPeriods.data ?? [],
        );

  return (
    <Container>
      <PortalIcon iconType="nextvan" fill="white" style={{ flex: 0.25 }} />
      <Text
        style={{ whiteSpace: "nowrap", flex: 1 }}
        fontSize={1}
        fontWeight={600}
        textAlign="center"
        color="white"
      >
        {formatNextRun(next)}
      </Text>
      <Text
        color="white"
        fontSize={1}
        textAlign="right"
        fontFamily={"Roboto Mono"}
      >
        {formatTimeLeft(timeleft)}
      </Text>
    </Container>
  );
};
