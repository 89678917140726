import React, { useState } from "react";
import styled from "styled-components";
import img from "../../img/loginhero.jpg";
import logoSrc from "../../img/logo.png";
import {
  Alert,
  Box,
  Button,
  Flex,
  FormInput,
  LinkButton,
  Loader,
  Panel,
  Text,
} from "@oaktyres/ui";
import { useForm } from "react-hook-form";
import { animated, useSpring, useSprings } from "react-spring";
import { BlueBackground } from "../../components/BlueBackground";
import { getAccountEnrollmentStatus } from "@oaktyres/queries";
import { Redirect, useHistory, useLocation } from "react-router";
import { useAuth } from "@oaktyres/ui/dist/auth";
import axios from "axios";
import heroVidSrc from "../../img/loginvideo.mp4";
import { FaExclamationTriangle } from "react-icons/fa";

const Logo = styled.img`
  height: 50px;
`;

const HeroImage = styled.div`
  flex: 0 0 60%;
  background-image: url("${img}");
  background-size: cover;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  display: none;
  align-items: flex-end;
  justify-content: center;
  padding: 60px;

  @media (min-width: ${(props) => props.theme.breakpoints[0]}) {
    display: flex;
  }
`;

const HeroVideo = styled.video`
  flex: 0 0 60%;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  display: none;
  border-left: 1px solid ${(props) => props.theme.colors.grey4};

  @media (min-width: ${(props) => props.theme.breakpoints[0]}) {
    display: flex;
  }
`;

const AniPanel = animated(Panel);

type LoginFormType = {
  accountCodeOrEmail: string;
  password: string;
};

const LoginShell = ({ children }: { children: React.ReactNode }) => {
  const panelStyles = useSpring({
    from: {
      y: 50,
      opacity: 0,
    },
    y: 0,
    opacity: 1,
  });

  const showPrivacyPolicy = () => {
    window.alert("Go to privacy policy here.");
  };

  return (
    <BlueBackground>
      <AniPanel
        width={1200}
        height={["auto", 800]}
        minHeight={500}
        p={0}
        flexDirection="row"
        border={0}
        boxShadow={3}
        style={panelStyles}
      >
        <Flex
          flex={1}
          flexDirection="column"
          p={[3, 5]}
          justifyContent="space-between"
        >
          <Flex mb={3}>
            <Logo src={logoSrc} />
          </Flex>
          <Flex
            flexDirection="column"
            p={[0, 5]}
            pt={[0, 3]}
            style={{ position: "relative" }}
          >
            {children}
          </Flex>
          <Flex>
            <LinkButton onClick={showPrivacyPolicy}>Privacy Policy</LinkButton>
          </Flex>
        </Flex>
        <HeroVideo src={heroVidSrc} autoPlay loop muted />
      </AniPanel>
    </BlueBackground>
  );
};

const SetNewPasswordForm = ({
  onSend,
}: {
  onSend: (newPassword: string) => void;
}) => {
  const { register, errors, handleSubmit, watch } = useForm<{
    password: string;
    confirm: string;
  }>();

  const onSubmit = (values: { password: string }) => {
    onSend(values.password);
  };

  return (
    <LoginShell>
      <Text fontSize={[5, 6]} color="#222" fontWeight={600} mb={3}>
        Reset Password
      </Text>
      <Text fontSize={2} fontWeight={500} mb={5}>
        Enter your new password below.
      </Text>
      <form onSubmit={handleSubmit(onSubmit)}>
        <FormInput
          label="Password"
          name="password"
          type="password"
          minLength={8}
          ref={register({
            required: true,
            minLength: { value: 8, message: "Must be at least 8 characters" },
          })}
          error={errors.password}
        />
        <FormInput
          label="Confirm Password"
          name="confirm"
          type="password"
          ref={register({
            required: true,
            validate: (value) => value === watch("password"),
          })}
          error={errors.confirm}
          mb={5}
        />
        <Flex style={{ gap: 12 }}>
          <Button>Continue</Button>
          <Button to="/login" color="grey1">
            Back to Login
          </Button>
        </Flex>
      </form>
    </LoginShell>
  );
};

export const ResetPasswordPage = () => {
  const auth = useAuth();
  const code = new URLSearchParams(useLocation().search).get("code");
  const email = useLocation<{ email: string }>().state?.email;
  const { register, errors, handleSubmit } = useForm<{ email: string }>({
    defaultValues: {
      email,
    },
  });
  const [sent, setSent] = useState(false);

  if (auth.user != null) {
    return <Redirect to="/" />;
  }

  if (code == null) {
    const onSubmit = (values: { email: string }) => {
      axios
        .post("/api/v2/users/reset-password-code", { email: values.email })
        .then(() => {
          setSent(true);
        });
    };

    return (
      <LoginShell>
        <Text fontSize={[5, 6]} color="#222" fontWeight={600} mb={3}>
          Reset Password
        </Text>
        {sent ? (
          <>
            <Text fontSize={2} fontWeight={500} mb={5}>
              Check your email for instructions on how to reset your password.
            </Text>{" "}
            <Button to="/login" color="grey1">
              Back to Login
            </Button>
          </>
        ) : (
          <>
            <Text fontSize={2} fontWeight={500} mb={5}>
              Enter your email address below <br /> to reset your password.
            </Text>
            <form onSubmit={handleSubmit(onSubmit)}>
              <FormInput
                label="Email Address"
                name="email"
                type="email"
                ref={register({ required: true })}
                error={errors.email}
                mb={5}
              />
              <Flex style={{ gap: 12 }}>
                <Button>Continue</Button>
                <Button to="/login" color="grey1">
                  Back to Login
                </Button>
              </Flex>
            </form>
          </>
        )}
      </LoginShell>
    );
  } else {
    return (
      <SetNewPasswordForm
        onSend={(newPassword) => {
          auth.resetPassword(code, newPassword);
        }}
      />
    );
  }
};

export const LoginPage = () => {
  const history = useHistory();
  const auth = useAuth();
  const code = new URLSearchParams(useLocation().search).get("code");
  const [showPassword, setShowPassword] = useState<boolean>(!!code);
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const { handleSubmit, register, errors, getValues } = useForm<LoginFormType>({
    defaultValues: {
      accountCodeOrEmail: code ?? "",
    },
  });

  const onCodeChange = () => {
    setShowPassword(false);
  };

  const onForgotPassword = () => {
    const user = getValues().accountCodeOrEmail;
    if (user.includes("@")) {
      history.push(`/forgot-password`, { email: user });
    } else {
      history.push(`/enroll?code=${user}&forgot=1`);
    }
  };

  const onSubmit = async (values: LoginFormType) => {
    setError(null);
    if (showPassword) {
      const res = await auth.login({
        type: values.accountCodeOrEmail.includes("@") ? "user" : "account",
        ident: values.accountCodeOrEmail,
        password: values.password,
      });

      if (!res) {
        setError("Invalid password");
      }
    } else if (values.accountCodeOrEmail.includes("@")) {
      setShowPassword(true);
    } else {
      setLoading(true);
      const status = await getAccountEnrollmentStatus(
        values.accountCodeOrEmail,
      );
      setLoading(false);
      if (status === null) {
        setError("Account code not found.");
      } else if (status.enrollmentStatus === "enrolled") {
        setShowPassword(true);
      } else {
        history.push(`/enroll?code=${values.accountCodeOrEmail}`);
      }
    }
  };

  if (auth.user != null) {
    return <Redirect to="/" />;
  }

  return (
    <LoginShell>
      {loading && <Loader overlay />}
      <Text
        fontSize={[5, 6]}
        color="#222"
        fontWeight={600}
        mb={3}
        lineHeight={1.2}
      >
        Welcome Back
      </Text>
      <Text fontSize={2} fontWeight={500} mb={5}>
        Enter your account code or
        <br /> email address below
      </Text>
      {error && (
        <Alert icon={FaExclamationTriangle} color="danger" mb={3} size="small">
          {error}
        </Alert>
      )}
      <form onSubmit={handleSubmit(onSubmit)}>
        <FormInput
          label="Account Code or Email"
          name="accountCodeOrEmail"
          ref={register({ required: true })}
          error={errors.accountCodeOrEmail}
          onChange={onCodeChange}
        />
        {showPassword && (
          <>
            <Box mb={3}>
              <FormInput
                label="Password"
                name="password"
                ref={register({ required: true })}
                error={errors.password}
                type="password"
                mb={1}
              />
              <Flex justifyContent={"flex-end"}>
                <LinkButton
                  onClick={onForgotPassword}
                  type="button"
                  style={{ fontSize: "80%" }}
                >
                  Forgot password?
                </LinkButton>
              </Flex>
            </Box>
          </>
        )}
        <Button>Continue</Button>
      </form>
    </LoginShell>
  );
};
