import { CurrencyType } from "@oaktyres/model";
import { useOrder } from "@oaktyres/queries";
import {
  Box,
  Flex,
  formatCurrency,
  FormHeading,
  Loader,
  Panel,
  Table,
  Text,
} from "@oaktyres/ui";
import { format, fromUnixTime } from "date-fns";
import { sum } from "lodash";
import React from "react";
import { useParams } from "react-router";

type CheckoutItemProps = {
  stockCode: string;
  quantity: number;
  price: number;
  currency: CurrencyType;
};

const CheckoutItem = ({
  stockCode,
  quantity,
  price,
  currency,
}: CheckoutItemProps) => {
  return (
    <tr>
      <td style={{ paddingLeft: 0 }}>{stockCode}</td>
      <td style={{ textAlign: "right" }}>{quantity}</td>
      <td style={{ textAlign: "right" }}>{formatCurrency(currency)(price)}</td>
    </tr>
  );
};

export const CheckoutPage = () => {
  const { id: orderId } = useParams<{ id: string }>();
  const order = useOrder(orderId);

  const items = order.data?.invoices.flatMap((x) => x.lines) ?? [];

  return (
    <Flex flexDirection={"column"} width="100%" maxWidth={800}>
      <Panel p={3}>
        <Text fontSize={5} mb={3}>
          Order Complete
        </Text>
        <Text>Thank you for your order. See the details below.</Text>
        {order.data == null ? (
          <Loader />
        ) : (
          <>
            <Flex mb={3}>
              <Flex flex={1} flexDirection="column">
                <FormHeading mb={0}>Order Placed</FormHeading>
                <Text>
                  {format(
                    fromUnixTime(order.data?.orderPlaced!),
                    "dd/MM/yyyy HH:mm",
                  )}
                </Text>
              </Flex>
              <Flex flex={1} flexDirection="column">
                <FormHeading mb={0}>Account</FormHeading>
                <Text>{order.data.customerCode}</Text>
              </Flex>
              <Flex flex={1} flexDirection="column">
                <FormHeading mb={0}>Order Number</FormHeading>
                <Text>{order.data.customerOrderNumber || "None Given"}</Text>
              </Flex>
            </Flex>
            <Table>
              <thead>
                <tr>
                  <th style={{ textAlign: "left" }}>Stock Code</th>
                  <th style={{ textAlign: "right" }}>Quantity</th>
                  <th style={{ textAlign: "right" }}>Price</th>
                </tr>
              </thead>
              <tbody>
                {items.map((x) => (
                  <CheckoutItem
                    currency={x.currency}
                    stockCode={x.stockCode}
                    quantity={x.quantity}
                    price={x.linePrice}
                  />
                ))}
              </tbody>
              <tfoot>
                <tr>
                  <td style={{ fontWeight: 600 }}>Total</td>
                  <td />
                  <td style={{ textAlign: "right" }}>
                    {formatCurrency("gbp")(
                      sum(
                        items
                          .filter((x) => x.currency === "gbp")
                          .map((x) => x.linePrice * x.quantity),
                      ),
                    )}
                  </td>
                </tr>
              </tfoot>
            </Table>
          </>
        )}
      </Panel>
    </Flex>
  );
};
