import { SellOutListSpec } from "@oaktyres/model";
import { useSellOutLists, useUpdateSellOutList } from "@oaktyres/queries";
import { Box, Button, Flex, Loader, Text, useToasts } from "@oaktyres/ui";
import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { FaSave } from "react-icons/fa";
import { Redirect, useHistory, useParams } from "react-router";
import { Link } from "react-router-dom";
import { useScopedAccount } from "../../components/ScopedAccountProvider";
import { SellOutListEditor } from "./SellOutListEditor";

export const EditSellOutList = () => {
  const { id } = useParams<{ code: string; id: string }>();
  const [code] = useScopedAccount();

  const history = useHistory();

  const canUpdate = useRef<boolean>(true);
  const update = useUpdateSellOutList(id);
  const lists = useSellOutLists(code);
  const toasts = useToasts();

  const [spec, setSpec] = useState<SellOutListSpec | null>(null);

  useEffect(() => {
    setSpec(() => lists.data?.find((x) => x.id === id) ?? null);
  }, [lists.data, id]);

  useEffect(() => {
    canUpdate.current = false;
  }, [spec]);

  const save = async () => {
    try {
      await update.mutateAsync(spec!);
      canUpdate.current = true;
      toasts.push({
        icon: FaSave,
        title: "Saved",
        content: "List saved!",
      });
      history.push("/sell-out-lists");
    } catch (err) {
      if (axios.isAxiosError(err)) {
        window.alert(err.response?.data.message.error ?? "Unknown Error");
      }
    }
  };

  if (lists.isFetched && !lists.data?.some((x) => x.id === id)) {
    return <Redirect to="/sell-out-lists" />;
  }

  return (
    <Box width="100%">
      <Flex justifyContent="space-between" alignItems={"center"} mb={3}>
        <Text fontSize={4} fontWeight={600}>
          {code} / <Link to={`/sell-out-lists`}>Sell Out Lists</Link> / Edit
          List
        </Text>
        <Button icon={FaSave} onClick={save}>
          Save
        </Button>
      </Flex>
      <div style={{ position: "relative" }}>
        {lists.isLoading ? (
          <Loader overlay />
        ) : spec != null ? (
          <SellOutListEditor value={spec!} onChange={setSpec} />
        ) : null}
      </div>
    </Box>
  );
};
