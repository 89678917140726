import React, { createContext, useContext, useState, useEffect } from "react";
import axios from "axios";

type UserToken = {
  token: string;
  email: string;
  roles: string[];
};

type UserContextValue = [
  UserToken | null,
  React.Dispatch<React.SetStateAction<UserToken | null>>
];

export const UserContext = createContext(
  null
) as React.Context<UserContextValue | null>;

const setInterceptor = (setUser: React.Dispatch<UserToken | null>) => {
  axios.interceptors.response.use(
    function (response) {
      // Any status code that lie within the range of 2xx cause this function to trigger
      // Do something with response data
      return response;
    },
    function (error) {
      if (error.response.status === 401) {
        setUser(null);
      }
      return Promise.reject(error);
    }
  );
};

export const UserProvider = ({
  children,
  localStorageKey,
}: {
  children: React.ReactNode;
  localStorageKey: string;
}) => {
  const existingUser: UserToken | null = JSON.parse(
    window.localStorage.getItem(localStorageKey) || "null"
  );

  const [user, setUser] = useState(existingUser);

  const token = user && user.token;

  // Do it here too so it affects the first load because
  // changing the token doesn't trigger a rerender
  axios.defaults.headers.common["Authorization"] = "Bearer " + token;

  useEffect(() => {
    window.localStorage.setItem(localStorageKey, JSON.stringify(user));
    setInterceptor(setUser);
  }, [user, setUser]);

  useEffect(() => {
    axios.defaults.headers.common["Authorization"] = "Bearer " + token;
  }, [token]);

  return (
    <UserContext.Provider value={[user, setUser]}>
      {children}
    </UserContext.Provider>
  );
};

export const useUser = () => useContext(UserContext) as UserContextValue;

export * from "./CookieAuth";
export * from "./CookieAuthenticateRoute";
