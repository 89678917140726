import styled from "styled-components";
import { space, layout, SpaceProps, LayoutProps } from "styled-system";

type TextAreaProps = SpaceProps & LayoutProps;

const TextArea = styled.textarea<TextAreaProps>`
  display: block;
  width: 100%;
  padding: 8px 12px;
  border: 1px solid ${(props) => props.theme.colors.grey3};
  border-radius: 4px;
  ${space};
  ${layout};
  max-width: 100%;
`;

TextArea.defaultProps = {
  mb: 0,
};

export default TextArea;
