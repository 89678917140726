export const unitsData = [
  {
    symbol: "mm",
    name: "Milimetres",
  },
  {
    symbol: "m",
    name: "Metres",
  },
  {
    symbol: "Nm",
    name: "Newton Metres",
  },
  {
    symbol: "km/h",
    name: "Kilometres per hour",
  },
  {
    symbol: "bar",
    name: "Bar",
  },
  {
    symbol: "degrees",
    name: "Degrees",
  },
  {
    symbol: "g",
    name: "Grams",
  },
  {
    symbol: "kg",
    name: "Kilograms",
  },
  {
    symbol: "ml",
    name: "Millilitres",
  },
  {
    symbol: "l",
    name: "Litres",
  },
  {
    symbol: "°C",
    name: "Degress Celcius",
  },
  {
    symbol: "l/min",
    name: "Litres Per Minutes",
  },
  {
    symbol: '"',
    name: "Inches",
  },
];

export type UnitDataType = typeof unitsData[number];

export type UnitSymbol = UnitDataType["symbol"];
