import { clamp } from "lodash";
import React, { useEffect, useState } from "react";
import { FaMinus, FaPlus, FaShoppingCart } from "react-icons/fa";
import Button from "./Button";
import Flex from "./Flex";
import Text from "./Text";

export type ProductSelectButtonProps = {
  selected: boolean;
  onSelect: () => void;
  onBuy: (qty: number) => void;
  allowBuy: boolean;
  maxQty: number;
};

export const ProductSelectButton = ({
  selected,
  onSelect,
  onBuy,
  allowBuy,
  maxQty,
}: ProductSelectButtonProps) => {
  const [qty, setQty] = useState<number>(Math.min(maxQty, 1));

  const inc = () => setQty(qty + 1);
  const dec = () => setQty(qty - 1);

  useEffect(() => {
    setQty((val) => clamp(val, 0, maxQty));
  }, [maxQty]);

  const handleBuy = () => {
    onBuy(qty);
    setQty(1);
  };

  if (!selected || !allowBuy) {
    return (
      <Button minWidth={120} small onClick={onSelect} disabled={selected}>
        {maxQty === 0 ? "No Stock" : "Select"}
      </Button>
    );
  } else {
    return (
      <Flex alignItems="center" width="120px">
        <Button onClick={dec} disabled={qty <= 1} minWidth={28} p={1}>
          <FaMinus style={{ margin: 0 }} />
        </Button>
        <Text fontWeight={600} style={{ flex: 1 }} textAlign="center">
          {qty}
        </Text>
        <Button
          onClick={inc}
          minWidth={28}
          p={1}
          mr={"3px"}
          disabled={qty === maxQty}
        >
          <FaPlus style={{ margin: 0 }} />
        </Button>
        <Button
          onClick={handleBuy}
          minWidth={28}
          p={1}
          color="positive"
          disabled={qty < 1}
        >
          <FaShoppingCart style={{ margin: 0 }} />
        </Button>
      </Flex>
    );
  }
};
