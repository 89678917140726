import { BasketProvider } from "./basket";
import {
  BaseStyles,
  CookieAuthenticatedRoute,
  CookieUserProvider,
  ToastProvider,
} from "@oaktyres/ui";
import React from "react";
import { QueryClient, QueryClientProvider } from "react-query";
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from "react-router-dom";
import { ThemeProvider } from "styled-components";
import { MainContainer } from "./components/MainContainer";
import { ScopedAccountProvider } from "./components/ScopedAccountProvider";
import { BasketPage } from "./pages/Basket";
import { EnrollmentPage } from "./pages/Enrollment";
import { LoginPage, ResetPasswordPage } from "./pages/LoginPage";
import { LogOut } from "./pages/Logout/Logout";
import { MainPage } from "./pages/Main";
import { TyreStorePage } from "./pages/TyreStore";
import { theme } from "./theme";
import { CheckoutPage } from "./pages/Checkout";
import { StorePage } from "./pages/Store";
import { SellOutLists } from "./pages/SellOutPricing";
import { EditSellOutList } from "./pages/SellOutPricing/EditSellOutList";
import { CreateSellOutList } from "./pages/SellOutPricing/CreateSellOutList";
import { OrderHistoryPage } from "./pages/OrderHistory";
import { StatementsPage } from "./pages/Statements";
import { ImprestStorePage } from "./pages/ImprestStore";
import { RetailViewProvider } from "./components/RetailViewProvider";
import { TyreShopSettingsProvider } from "./pages/TyreStore/VatModal";
import { FinancialsPage } from "./pages/Financials";
import { ReturnDeclarationPage } from "./pages/ReturnDeclaration";
import { PartnershipDashboard } from "./pages/Partnership/PartnershipDashboard";
import { ManageAccount } from "./pages/Account";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnMount: true,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      refetchIntervalInBackground: false,
    },
  },
});

const Routing = () => {
  return (
    <Router>
      <Switch>
        <Route path="/returns/:code" exact component={ReturnDeclarationPage} />
        <Route path="/login" component={LoginPage} />
        <Route path="/forgot-password" component={ResetPasswordPage} />

        <Route path="/enroll" component={EnrollmentPage} />
        <CookieAuthenticatedRoute path="/logout" component={LogOut} />
        <Route>
          <MainContainer>
            <Switch>
              <CookieAuthenticatedRoute
                path="/account"
                component={ManageAccount}
              />
              <CookieAuthenticatedRoute
                path="/orders"
                component={OrderHistoryPage}
                exact
              />
              <CookieAuthenticatedRoute
                path="/sell-out-lists"
                component={SellOutLists}
                exact
              />
              <CookieAuthenticatedRoute
                path="/partnership"
                component={PartnershipDashboard}
                exact
              />
              <CookieAuthenticatedRoute
                path="/sell-out-lists/create"
                component={CreateSellOutList}
                exact
              />
              <CookieAuthenticatedRoute
                path="/sell-out-lists/:id"
                component={EditSellOutList}
                exact
              />
              <CookieAuthenticatedRoute
                path="/statements"
                component={StatementsPage}
              />
              <CookieAuthenticatedRoute
                path="/financials"
                component={FinancialsPage}
              />
              <CookieAuthenticatedRoute
                path="/tyres/imprest"
                exact
                component={ImprestStorePage}
              />
              <CookieAuthenticatedRoute
                path="/tyres"
                exact
                component={TyreStorePage}
              />
              <CookieAuthenticatedRoute
                path="/store/:storeId/:categoryId?"
                component={StorePage}
              />
              <CookieAuthenticatedRoute path="/basket" component={BasketPage} />
              <CookieAuthenticatedRoute
                path="/checkout/:id"
                component={CheckoutPage}
              />
              <Redirect path="/" exact to={"/tyres"} />
            </Switch>
          </MainContainer>
        </Route>
      </Switch>
    </Router>
  );
};

const Providers = ({ children }: { children: React.ReactNode }) => (
  <QueryClientProvider client={queryClient} contextSharing={true}>
    <CookieUserProvider>
      <ScopedAccountProvider>
        <RetailViewProvider>
          <TyreShopSettingsProvider>
            <ThemeProvider theme={theme}>
              <ToastProvider>
                <BasketProvider>{children}</BasketProvider>
              </ToastProvider>
            </ThemeProvider>
          </TyreShopSettingsProvider>
        </RetailViewProvider>
      </ScopedAccountProvider>
    </CookieUserProvider>
  </QueryClientProvider>
);

export const App = () => {
  return (
    <Providers>
      <BaseStyles />
      <Routing />
    </Providers>
  );
};
