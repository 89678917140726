import { PriceDto, ProductDto } from "@oaktyres/model";
import { minBy } from "lodash";
import React from "react";
import { FaLayerGroup } from "react-icons/fa";
import styled from "styled-components";
import { formatCurrency } from "../utils/currencyUtils";
import Flex from "./Flex";
import Text from "./Text";
import Panel from "./Panel";

export type ProductCardProps = {
  product: ProductDto;
  onClick?: React.MouseEventHandler;
};

const defaultProductImage = "https://via.placeholder.com/640x640";

const Container = styled(Panel)`
  border: 0;
  transition: all 0.3s ease;
  cursor: pointer;

  &:hover {
    transform: scale3d(1.02, 1.02, 1);
    box-shadow: ${(props) => props.theme.shadows[2]};
  }
`;

const ProductImage = styled.img`
  display: block;
  object-fit: contain;
  width: 100%;
  height: 200px;
`;

function getImage(product: ProductDto) {
  return product.images.length > 0
    ? `${process.env.REACT_APP_STATIC_ROOT}${product.images[0]}`
    : defaultProductImage;
}

const ProductPrice = ({ product }: { product: ProductDto }) => {
  const prices = product.variants
    .filter((x) => x.published)
    .map((x) => x.price)
    .filter((x): x is PriceDto => x != null)
    .filter((x) => x.value != null);

  if (prices.length === 0) {
    return null;
  }

  const minPrice = minBy(prices, (x) => x.value);

  let text = formatCurrency(minPrice?.currency!)(minPrice?.value!);

  text = `${prices.length > 1 && prices.map((x) => x.value).some((x, i, self) => x !== self[0]) ? "From " : ""}${text}`;

  return (
    <Text fontWeight={600} fontSize="0.8em">
      {text}
    </Text>
  );
};

export const ProductCard = ({ product, onClick }: ProductCardProps) => {
  return (
    <Container overflow={"hidden"} onClick={onClick}>
      <Flex flexDirection={"column"}>
        <ProductImage src={getImage(product)} />
        <Flex p={2} alignItems="flex-end" justifyContent="space-between">
          <Flex flexDirection="column" alignItems="">
            <Text fontWeight={600} fontSize="1em">
              {product.name}
            </Text>
            <Flex alignItems="center" mb="2px">
              <FaLayerGroup fontSize="0.8em" color="#ddd" />
              <Text color="grey3" fontWeight={600} fontSize="0.8em" ml={1}>
                {product.type.name}
              </Text>
            </Flex>
            <ProductPrice product={product} />
          </Flex>
        </Flex>
      </Flex>
    </Container>
  );
};
