import styled from "styled-components";
import {
  space,
  typography,
  color,
  layout,
  ColorProps,
  SpaceProps,
  TypographyProps,
  LayoutProps,
} from "styled-system";

export type TextProps = ColorProps & SpaceProps & TypographyProps & LayoutProps;

const Text = styled.p<TextProps>`
  ${space};
  ${typography};
  ${color};
  ${layout};
`;

export default Text;
