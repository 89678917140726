import { ProductDto, TyreQueryDto, TyreSearchPayload } from "@oaktyres/model";
import axios from "axios";
import { useQuery } from "react-query";

export async function getTyreByStockCode(
  stockCode: string,
  accountCode?: string | null,
): Promise<TyreSearchPayload> {
  const { data } = await axios.get<TyreSearchPayload[]>(
    `/api/v2/tyres/${stockCode}${
      accountCode ? `?accountCode=${accountCode}` : ""
    }`,
  );

  return data[0];
}

export const searchTyres = (query: TyreQueryDto | null) =>
  query == null
    ? []
    : axios
        .get<TyreSearchPayload[]>(`/api/v2/tyres`, { params: query })
        .then(({ data }) => data);

export function useTyreByStockCode(code: string, accountCode?: string | null) {
  return useQuery(["tyre", code, accountCode], () =>
    getTyreByStockCode(code, accountCode),
  );
}

export const useTyres = (query: TyreQueryDto | null) =>
  useQuery(["tyres", query], () => searchTyres(query));

export const getAlternativesForStockCode = (
  stockCode: string,
  accountCode?: string,
) =>
  axios
    .get<TyreSearchPayload[]>(
      `/api/v2/tyres/${stockCode}/alternatives${
        accountCode ? `?accountCode=${accountCode}` : ""
      }
`,
    )
    .then(({ data }) => data);

export type StockDataType = ProductDto | TyreSearchPayload;

export const isProduct = (
  x: StockDataType | null | undefined,
): x is ProductDto => x != null && "attributes" in x;

export const getStockItem = (stockCode: string, accountCode?: string) =>
  axios
    .get<StockDataType>(
      `/api/v2/stock/${stockCode}${accountCode ? `?accountCode=${accountCode}` : ""}`,
    )
    .then(({ data }) => data);

export const useStockItem = (stockCode: string, accountCode?: string) =>
  useQuery(["stock", { stockCode, accountCode }], () =>
    getStockItem(stockCode, accountCode),
  );
