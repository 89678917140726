import { createGlobalStyle } from "styled-components";

export const BaseStyles = createGlobalStyle`

html,
body,
#root {
  height: 100%;
  min-height: 100%;
}

html {
  font-size: 80%;

  @media(min-width: ${(props) => props.theme.breakpoints[0]}) {
    font-size: 100%;
  }
}

body, button {
  font-family: ${(props) => props.theme.fonts.body};
  font-size: ${(props) => props.theme.baseFontSize}px;
  line-height: ${(props) => props.theme.baseLineHeight};
  padding: 0;
  margin: 0;
  color: ${(props) => props.theme.colors.body};
}

optgroup {
  font-weight: 600;
}

h1,
h2,
h3,
h4,
h5,
h6,
pre,
p {
  margin: 0;
  font-family: ${(props) => props.theme.fonts.body};
    font-size: ${(props) => props.theme.baseFontSize}px;
  line-height: ${(props) => props.theme.baseLineHeight};
  font-weight: normal;
}

strong {
  font-weight: 600;
}

*,
*:before,
*:after {
  box-sizing: border-box;
}

a {
  color: ${(props) => props.theme.colors.primary};
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }

}

code {
  background-color: ${(props) => props.theme.colors.grey4};
  border-radius: 3px;
  border: 1px solid ${(props) => props.theme.colors.grey3};
  font-size: 14px;
  padding: 2px;
}

`;
