import { Box, Button, CheckBox, Flex, Panel, Text } from "@oaktyres/ui";
import React from "react";
import { useParams } from "react-router";
import { animated, useSpring } from "react-spring";
import { BlueBackground } from "../../components/BlueBackground";
import logoSrc from "../../img/logo.png";

import styled from "styled-components";
import { useState } from "react";
import { useSignReturnRequest } from "@oaktyres/queries";

const AniPanel = animated(Panel);

const Logo = styled.img`
  height: 50px;
`;

const decText = [
  "I/We certify that the product referred to above is my/our property and was fitted to my/our vehicle of which details are given above.",
  "I/We consider the product has not given satisfactory service whilst on my/our vehicle and request you arrange examination by the manufacturer.",
  "I/We declare that the above mentioned tyre/tube caused no damage to vehicles, property or injuries to people and hence no consequential claim will be made related to the tyre/tube detailed on this form.",
  "I/We declare the details given above are correct to the best of my/our knowledge.",
  "I/we agree that my/our personal data set out in this Form may be retained by the Dealer in its records and be passed on to the Manufacturer of the product (and/or its relevant affiliate) which may hold and process my/our information in order to deal with this application on the terms of its privacy made available on the manufacturer's website.",
];

export const ReturnDeclarationPage = () => {
  const { code } = useParams<{ code: string }>();
  const [done, setDone] = useState(false);
  const sign = useSignReturnRequest();

  const [items, setItems] = useState<boolean[]>([
    false,
    false,
    false,
    false,
    false,
  ]);

  const panelStyles = useSpring({
    from: {
      y: 50,
      opacity: 0,
    },
    y: 0,
    opacity: 1,
  });

  const setItem = (index: number) => (value: boolean | null) => {
    const it = [...items];
    it[index] = !!value;
    setItems(it);
  };

  const confirm = async () => {
    try {
      await sign.mutateAsync(code);
      setDone(true);
    } catch {
      window.alert("There was a problem completing your request");
    }
  };

  return (
    <>
      <BlueBackground />
      <Flex
        p={3}
        alignItems="center"
        justifyContent="center"
        minHeight={"100%"}
      >
        <AniPanel
          width={600}
          maxWidth="100%"
          p={[3, 5]}
          alignItems="flex-start"
          flexDirection="column"
          border={0}
          boxShadow={3}
          style={{
            ...panelStyles,
            position: "relative",
          }}
        >
          <Logo src={logoSrc} />
          <Text fontWeight={600} fontSize={4} lineHeight={1} mt={3} mb={3}>
            Return Declaration
          </Text>
          {done ? (
            <Box>
              <Text mb={2}>Thank you for confirming your declaration.</Text>
              <Text>You may now close this window.</Text>{" "}
            </Box>
          ) : (
            <>
              <Text mb={1}>
                With regards to your recent tyre return, please confirm the
                following statements are true.
              </Text>
              <Text mb={5}>
                This is{" "}
                <Text color="danger" as="span" fontWeight={600}>
                  required
                </Text>{" "}
                to continue with your warranty claim
              </Text>
              <Box px={[1, 5]} mb={5}>
                {decText.map((x, i) => (
                  <Flex alignItems={"center"} mb={2} maxWidth="100%">
                    <CheckBox
                      value={items[i]}
                      onChange={setItem(i)}
                      allowNull={false}
                      mr={3}
                    />
                    <Text fontSize={1}>{x}</Text>
                  </Flex>
                ))}
              </Box>
              <Flex justifyContent={"center"} width="100%">
                <Button onClick={confirm} disabled={items.some((x) => !x)}>
                  Confirm
                </Button>
              </Flex>
            </>
          )}
        </AniPanel>
      </Flex>
    </>
  );
};
