import {
  createContext,
  useContext,
  useState,
  useCallback,
  useRef,
  useEffect,
} from "react";
import styled from "styled-components";
import { Panel, Text, Flex } from "..";
import { animated, useTransition } from "react-spring";
import { IconType } from "react-icons/lib";
import React from "react";

export type ToastContextType = {
  push: (item: ToastItemSpec) => number;
};

export type ToastItemSpec = {
  title: string;
  content: string;
  icon: IconType;
};

export type ToastItem = ToastItemSpec & { id: number; added: number };

export const ToastContext = createContext<ToastContextType>(null!);

const ToastPanelContainer = styled(Panel)`
  position: fixed;
  right: 30px;
  bottom: 15px;
  width: 300px;
  z-index: 1000000;
  pointer-events: none;
`;

type ToastPanelProps = {
  style: React.CSSProperties;
} & ToastItem;

const ToastPanel = ({ title, content, icon: Icon, style }: ToastPanelProps) => {
  return (
    <ToastPanelContainer p={2} boxShadow={2} style={style}>
      <Flex alignItems="center">
        <Icon fontSize={"2.5em"} style={{ flex: "0 0 auto" }} color="#ccc" />
        <Flex flexDirection="column" ml={3}>
          <Text fontWeight={600} fontSize={2} lineHeight={1.25} mb={"3px"}>
            {title}
          </Text>
          <Text color="grey2" fontSize={1}>
            {content}
          </Text>
        </Flex>
      </Flex>
    </ToastPanelContainer>
  );
};

const AniToastPanel = animated(ToastPanel);

export const ToastProvider = ({ children }: { children: React.ReactNode }) => {
  const [items, setItems] = useState<ToastItem[]>([]);
  const lastId = useRef<number>(0);

  const transitions = useTransition(
    [items[0]].filter((x) => x),
    {
      from: { opacity: 0 },
      enter: { opacity: 1 },
      leave: { opacity: 0 },
    },
  );

  useEffect(() => {
    const handler = () => {
      setItems((old) => {
        const now = Date.now();
        if (old.length > 0 && now - old[0].added > 5000) {
          return old.slice(1);
        } else {
          return old;
        }
      });
    };

    const id = setInterval(handler, 5000);

    return () => {
      clearInterval(id);
    };
  }, [items]);

  const push = useCallback((spec: ToastItemSpec): number => {
    const nextId = lastId.current + 1;
    lastId.current = nextId;
    const item = {
      id: nextId,
      added: Date.now(),
      ...spec,
    };

    setItems((old) => [...old, item]);

    return item.id;
  }, []);

  const value = {
    push,
  };

  return (
    <ToastContext.Provider value={value}>
      {transitions((props, item) => (
        <AniToastPanel {...item} style={props} />
      ))}
      {children}
    </ToastContext.Provider>
  );
};

export const useToasts = () => useContext(ToastContext);
