import Panel from "./Panel";
import React from "react";
import { useEffect, useState } from "react";
import styled from "styled-components";
import { debounce } from "lodash";
import { useSpring, animated } from "react-spring";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";
import { useSwipeable } from "react-swipeable";

const floatBreakpoint = 1600;

const Container = styled(animated(Panel))`
  position: fixed;
  right: 0;
  top: 0;
  height: 100%;
  z-index: 1200;

  @media (max-width: ${floatBreakpoint}px) {
    border-radius: 0;
  }

  @media (min-width: ${floatBreakpoint}px) {
    position: relative;
    height: auto;
  }
`;

const OpenButton = styled.button`
  position: absolute;
  left: -28px;
  top: 110px;
  width: 28px;
  height: 80px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: white;
  border: 1px solid transparent;
  border-right: 0;
  cursor: pointer;
  box-shadow: -2px 0 3px rgba(0, 0, 0, 0.1);

  color: ${(props) => props.theme.colors.primary};

  &:active,
  &:focus {
    outline: 0;
    border-color: ${(props) => props.theme.colors.primary};
  }

  & > span {
    display: flex;
    align-items: center;
    transform: rotate(90deg);
    font-size: 13px;

    & > svg {
      position: relative;
      top: 0px;
      margin-right: 8px;
    }
  }

  @media (min-width: ${floatBreakpoint}px) {
    display: none;
  }
`;

const Scrollable = styled.div`
  max-height: 100%;
  overflow-y: auto;

  ::-webkit-scrollbar {
    width: 5px;
  }

  ::-webkit-scrollbar-track {
    background: ${(props) => props.theme.colors.grey5};

    border-radius: 10px;
  }

  ::-webkit-scrollbar-thumb {
    background: ${(props) => props.theme.colors.grey2};
    border-radius: 10px;
  }
`;

type FilterSidebarProps = {
  children: React.ReactNode;
};

export const FilterSidebar = ({ children }: FilterSidebarProps) => {
  const [open, setOpen] = useState(window.innerWidth > floatBreakpoint);

  const handlers = useSwipeable({
    onSwipedLeft: () => setOpen(false),
  });

  const props = useSpring({
    transform: `translate3d(${open ? 0 : 100}%, 0px, 0px)`,
  });

  useEffect(() => {
    const handler = debounce(() => {
      setOpen(window.innerWidth > 768);
    }, 250);

    window.addEventListener("resize", handler);

    return () => {
      window.removeEventListener("resize", handler);
    };
  }, []);

  return (
    <Container width={260} style={props} {...handlers}>
      <OpenButton type="button" onClick={() => setOpen((old) => !old)}>
        <span>
          {open ? <FaChevronUp /> : <FaChevronDown />}
          Filters
        </span>
      </OpenButton>
      <Scrollable>{children}</Scrollable>
    </Container>
  );
};
