import styled from "styled-components";
import { space, SpaceProps } from "styled-system";

type HelpBlockProps = SpaceProps & {
  color: string;
};

const HelpBlock = styled.p<HelpBlockProps>`
  color: ${(props) => props.theme.colors[props.color || "body"]};
  font-size: 12px;
  ${space};
`;

export default HelpBlock;
