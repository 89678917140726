import React from "react";
import { FaMinus, FaPlus } from "react-icons/fa";
import styled from "styled-components";
import Button from "./Button";

export type QuantityInputProps = {
  value: number;
  onChange: (value: number) => void;
  maxValue?: number;
};

const Container = styled.div`
  background-color: #eee;
  display: flex;
  align-items: center;
  height: 34px;

  & > span {
    margin: 0 6px;
    font-weight: 600;
    font-size: 14px;
    display: inline-block;
    min-width: 40px;
    text-align: center;
    font-family: ${(props) => props.theme.fonts.mono};
  }
`;

const QtyButton = styled(Button)`
  width: 32px;
  padding: 0;
  min-width: 0;
  height: 100%;

  & svg {
    margin: 0;
  }

  &:first-child {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  &:last-child {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
`;

export const QuantityInput = ({
  value,
  onChange,
  maxValue,
}: QuantityInputProps) => {
  const display = maxValue == null ? value.toString() : `${value}/${maxValue}`;

  return (
    <Container>
      <QtyButton onClick={() => onChange(value - 1)} disabled={value <= 1}>
        <FaMinus />
      </QtyButton>
      <span>{display}</span>
      <QtyButton
        onClick={() => onChange(value + 1)}
        disabled={maxValue != null && value >= maxValue}
      >
        <FaPlus />
      </QtyButton>
    </Container>
  );
};
