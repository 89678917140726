import { EnrollmentChallengeOptionDto } from "@oaktyres/model";
import styled from "styled-components";
import { Box, Flex, ManufacturerLabel, Text } from "@oaktyres/ui";
import { useManufacturers } from "@oaktyres/queries";
import React from "react";
import { ManufacturerLabelProps } from "@oaktyres/ui/dist/components/ManufacturerLabel";

const Item = styled.button<{ active: boolean }>`
  border: 1px solid
    ${(props) =>
      props.active ? props.theme.colors.primary : props.theme.colors.grey3};
  border-radius: 9px;
  cursor: pointer;
  background: white;
  display: flex;
  align-items: center;
  padding: 6px 12px;
  text-align: left;

  @media (min-width: ${(props) => props.theme.breakpoints[0]}) {
    padding: 12px 18px;
  }
`;

const Pip = styled.div<{ active: boolean }>`
  border-radius: 50%;
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid
    ${(props) =>
      props.active ? props.theme.colors.primary : props.theme.colors.grey3};

  &:after {
    width: 16px;
    height: 16px;
    display: block;
    content: "";
    border-radius: 50%;
    background-color: ${(props) =>
      props.active ? props.theme.colors.primary : "white"};
  }
`;

export type ChallengeOptionSelectProps = {
  options: EnrollmentChallengeOptionDto[];
  selected: number | null;
  onChange: (index: number) => void;
};

export const ChallengeOptionSelect = ({
  options,
  selected,
  onChange,
}: ChallengeOptionSelectProps) => {
  const manus = useManufacturers();

  const getLabelProps = (code: string): ManufacturerLabelProps => {
    const m = manus.data?.find((x) => x.crossReference === code);

    return {
      name: m?.name ?? null,
      code: code,
      icon: m?.icon ?? null,
      staticRoot: process.env.REACT_APP_STATIC_ROOT ?? "",
    };
  };

  return (
    <Flex flexDirection={"column"} style={{ gap: 6 }} width="100%">
      {options.map((x, i) => (
        <Item active={i === selected} onClick={() => onChange(i)}>
          <Pip active={i === selected} />
          <Flex ml={3} flexDirection="column" flex={1}>
            <Text fontWeight={600} mb="3px" fontSize={[1, 2]}>
              {x.qty} X {x.patternName}
            </Text>
            <Flex alignItems="center">
              <ManufacturerLabel {...getLabelProps(x.manufacturer)} />
              <Text
                color="grey2"
                fontWeight={600}
                fontSize="14px"
                ml={3}
                display={["none", "inline"]}
              >
                {x.stockCode}
              </Text>
            </Flex>
          </Flex>
          <Text fontWeight={600} fontSize={[2, 3]}>
            {(x.price * x.qty).toLocaleString("en-GB", {
              style: "currency",
              currency: "gbp",
            })}
          </Text>
        </Item>
      ))}
    </Flex>
  );
};
