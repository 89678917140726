import {
  PriceDto,
  ProductDto,
  TyreAvailability,
  TyreSearchPayload,
} from "@oaktyres/model";
import {
  BasketItem,
  IStockItem,
  productToStockItem,
  tyreToStockItem,
} from "@oaktyres/queries";
import axios from "axios";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { useRef } from "react";
import { useMemo } from "react";
import { useQuery } from "react-query";
import { useScopedAccount } from "../components/ScopedAccountProvider";

type BasketStorageItem = {
  code: string;
  qty: number;
  supply: "delivery" | string;
};

export type BasketStorage = {
  items: BasketStorageItem[];
};

const wallFlags: (keyof TyreSearchPayload)[] = [
  "bsw",
  "owl",
  "rwl",
  "wsw",
  "rbl",
  "ww",
];

export const formatFullFitment = (item: TyreSearchPayload): string =>
  [
    `${item.sizePrefix || ""}${item.section}/${item.profile}`,
    `${item.construction || ""}${item.rim}${item.commercialSuffix || ""}`,
    `${item.load}${item.speed}`,
    ...item.oes.map((x) => x.mark),
    item.runFlat ? item.runFlatTech || "RUN FLAT" : null,
    item.noiseCancellingTech,
    item.selfSealingTech,
    ...wallFlags.map((x) => (item[x] ? x.toUpperCase() : null)),
  ]
    .filter(Boolean)
    .join(" ");

export type UseBasketReturnType = {
  items: BasketItem[];
  accountCode: string | null;
  clearBasket: () => void;
  addToBasket: (
    code: string,
    qty: number,
    supply: "delivery" | string,
  ) => boolean;
  updateItem: (code: string, qty: number) => void;
  refreshBasket: () => void;
};

const BasketContext = React.createContext<UseBasketReturnType>(null!);

const fromStorage = () => {
  const s: BasketStorage = JSON.parse(
    window.localStorage.getItem("portal-basket") ?? "null",
  ) ?? { items: [] };

  return s.items;
};

type DataType = ProductDto | TyreSearchPayload;

const isProduct = (x: DataType): x is ProductDto => "attributes" in x;

const getStock = (
  codes: string[],
  accountCode: string,
): Promise<IStockItem[]> => {
  return Promise.all(
    codes.map((x) =>
      axios
        .get<DataType>(`/api/v2/stock/${x}?accountCode=${accountCode}`)
        .then(({ data }) => data)
        .then((p) =>
          isProduct(p) ? productToStockItem(p, x) : tyreToStockItem(p),
        ),
    ),
  );
};

export function BasketProvider({ children }: { children: React.ReactNode }) {
  const [accountCode] = useScopedAccount();
  const [items, setItems] = useState<BasketStorageItem[]>(fromStorage());
  const lastDataRef = useRef<BasketItem[]>([]);
  const stockData = useQuery(
    ["stock", items.map((x) => x.code), accountCode],
    () =>
      getStock(
        items.map((x) => x.code),
        accountCode,
      ),
    {
      retryOnMount: true,
      refetchOnWindowFocus: true,
      enabled: accountCode !== "",
    },
  );

  const basketItems = useMemo(() => {
    const data = items.map<BasketItem>((x) => {
      const last = lastDataRef.current.find((o) => o.code === x.code);

      if (stockData.isLoading) {
        return (
          last ?? {
            ...x,
            status: "loading",
          }
        );
      }

      if (!stockData.isSuccess) {
        return {
          ...x,
          status: "error",
        };
      }

      const item = stockData.data.find((t) => x.code === t.stockCode);

      if (item == null) {
        return {
          ...x,
          status: "error",
        };
      }

      return {
        ...x,
        status: "loaded",
        data: item,
      };
    });
    lastDataRef.current = data;
    return data;
  }, [stockData, items]);

  useEffect(() => {
    const s: BasketStorage = {
      items: items.map((x) => ({
        code: x.code,
        qty: x.qty,
        supply: x.supply,
      })),
    };

    window.localStorage.setItem("portal-basket", JSON.stringify(s));
  }, [items]);

  const clearBasket = useCallback(() => {
    setItems([]);
  }, []);

  const addToBasket = useCallback(
    (code: string, qty: number, supply: string) => {
      if (items.some((x) => x.supply !== supply)) {
        window.alert(
          "Your basket contains items with a different delivery/collection method. Mixed baskets are not currently supported",
        );
        return false;
      }
      setItems((old) => {
        if (old.some((x) => x.code === code)) {
          return old
            .map((x) =>
              x.code === code
                ? {
                    ...x,
                    qty: x.qty + qty,
                  }
                : x,
            )
            .filter((x) => x.qty > 0);
        } else if (qty > 0) {
          return [
            ...old,
            {
              code: code,
              qty: qty,
              supply: supply,
              status: "added",
            },
          ];
        } else {
          return old;
        }
      });
      return true;
    },
    [accountCode, items],
  );

  const updateItem = useCallback((code: string, qty: number) => {
    setItems((old) =>
      old
        .map((x) => ({
          ...x,
          qty: x.code === code ? qty : x.qty,
        }))
        .filter((x) => x.qty > 0),
    );
  }, []);

  const refreshBasket = useCallback(() => {
    stockData.refetch();
  }, [stockData.refetch]);

  const res = {
    items: basketItems,
    clearBasket,
    addToBasket,
    accountCode,
    updateItem,
    refreshBasket,
  };

  return (
    <BasketContext.Provider value={res}>{children}</BasketContext.Provider>
  );
}

export const useScopedBasket = () => useContext(BasketContext);
