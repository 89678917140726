import { Box, Panel, Text, Flex } from "@oaktyres/ui";
import React from "react";
import { FaBuilding, FaLock, FaTruck } from "react-icons/fa";
import { Redirect, Route, Switch } from "react-router";
import { NavLink } from "react-router-dom";
import styled from "styled-components";
import { useScopedAccount } from "../../components/ScopedAccountProvider";
import { AccountDeliveryPane } from "./AccountDeliveryPane";
import { AccountDetailsPane } from "./AccountDetailsPane";
import { AccountSecurityPane } from "./AccountSecurityPane";

const MenuLink = styled(NavLink)`
  border: 1px solid transparent;
  color: ${(props) => props.theme.colors.grey1};
  border-radius: 3px;
  margin-bottom: 6px;
  padding: 3px 6px;
  display: flex;
  align-items: center;
  transition: all 0.3s ease;

  & > svg {
    font-size: 0.8em;
    margin-right: 6px;
    fill: ${(props) => props.theme.colors.grey1};
  }

  &:last-child {
    margin-bottom: 0;
  }

  &:hover {
    text-decoration: none;
    border: 1px solid ${(props) => props.theme.colors.grey3};
    color: ${(props) => props.theme.colors.body};
  }

  &.active {
    color: ${(props) => props.theme.colors.primary};
    svg {
      fill: ${(props) => props.theme.colors.primary};
    }
  }
`;

export const ManageAccount = () => {
  const [scopedAccount] = useScopedAccount();

  return (
    <Box width="100%" maxWidth={1600}>
      <Panel p={3} mb={3}>
        <Switch>
          <Route path="/account/details">
            <Text fontWeight={600} fontSize={4}>
              {scopedAccount} / Account Details
            </Text>
          </Route>
          <Route path="/account/delivery">
            <Text fontWeight={600} fontSize={4}>
              {scopedAccount} / Delivery &amp; Collection
            </Text>
          </Route>
          <Route path="/account/security">
            <Text fontWeight={600} fontSize={4}>
              {scopedAccount} / Security
            </Text>
          </Route>
        </Switch>
      </Panel>
      <Flex flexDirection={["column", "column", "row"]} width="100%">
        <Flex flexDirection={"column"} width={["100%", "100%", "300px"]} mr={3}>
          <Panel p={3} mb={3} order={[1, 1, 0]}>
            <MenuLink to="/account/details">
              <FaBuilding style={{ width: "20px" }} />
              Account Details
            </MenuLink>
            <MenuLink to="/account/delivery">
              <FaTruck style={{ width: "20px" }} />
              Delivery &amp; Collection
            </MenuLink>
            <MenuLink to="/account/security">
              <FaLock style={{ width: "20px" }} />
              Security
            </MenuLink>
          </Panel>
        </Flex>
        <Flex flex="1">
          <Switch>
            <Route path="/account/details" component={AccountDetailsPane} />
            <Route path="/account/delivery" component={AccountDeliveryPane} />
            <Route path="/account/security" component={AccountSecurityPane} />
            <Redirect to="/account/details" />
          </Switch>
        </Flex>
      </Flex>
    </Box>
  );
};
