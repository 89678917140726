import { uniq } from "lodash";
import React, { useRef, useState } from "react";
import styled from "styled-components";
import { space, SpaceProps } from "styled-system";
import { useOnClickOutside } from "../utils/useOnClickOutside";
import { lighten } from "polished";
import CheckBox from "./Checkbox";
import { Flex } from "./Flex";
import Panel from "./Panel";
import Text from "./Text";

export type MultiSelectProps = {
  options: { value: string; label: string }[];
  value: string[];
  onChange: (value: string[]) => void;
  defaultLabel?: string;
} & SpaceProps;

const Container = styled.div<SpaceProps>`
  position: relative;
  width: 100%;
  ${space};
`;

const SelectButton = styled.button`
  display: flex;
  justify-content: space-between;
  width: 100%;
  border: 1px solid ${(props) => props.theme.colors.grey3};
  border-radius: 3px;
  cursor: pointer;
  line-height: 1;
  background-color: #fff;
  background-image: linear-gradient(to top, #f9f9f9, #fff 33%);
  align-items: center;
  padding: 8px 12px;
  padding-right: 6px;

  &:after {
    content: "";
    justify-self: end;
    width: 0.7em;
    height: 0.4em;
    margin-top: 0.1em;
    pointer-events: none;
    margin-right: 0.2em;
    background-color: ${(props) => props.theme.colors.primary};
    clip-path: polygon(100% 0%, 0 0%, 50% 100%);
  }
`;

const OptionsPanel = styled(Panel)`
  position: absolute;
  z-index: 1000;
  left: 0;
  top: calc(100% + 6px);
  width: 100%;
  max-height: 250px;
  overflow-y: auto;
`;

const Item = styled(Flex)`
  cursor: pointer;
  &:hover {
    background-color: ${(props) => lighten(0.4, props.theme.colors.primary)};
  }
`;

export function MultiSelect({
  options,
  value = [],
  onChange,
  defaultLabel = "<None>",
  ...rest
}: MultiSelectProps) {
  const containerRef = useRef<HTMLDivElement>(null!);
  const [open, setOpen] = useState<boolean>(false);

  useOnClickOutside(containerRef, () => {
    setOpen(false);
  });

  const toggle = () => setOpen((v) => !v);

  const label = value.length === 0 ? defaultLabel : `${value.length} selected`;

  const setItem = (itemValue: string) => (val: boolean) => {
    if (!open) return;
    if (val) {
      onChange(uniq([...value, itemValue]));
    } else {
      onChange(value.filter((x) => x !== itemValue));
    }
  };

  const toggleItem = (itemValue: string) => () =>
    setItem(itemValue)(!value.includes(itemValue));

  return (
    <Container ref={containerRef} {...rest}>
      <SelectButton type="button" onClick={toggle}>
        {label}
      </SelectButton>
      {open && (
        <OptionsPanel color="none" boxShadow={2} p={1} border={1}>
          {options.map((x) => (
            <Item
              alignItems="center"
              p={1}
              onClick={toggleItem(x.value)}
              key={x.value}
            >
              <CheckBox mb={0} mr={2} value={value.includes(x.value)} />
              <Text fontWeight={500}>{x.label}</Text>
            </Item>
          ))}
        </OptionsPanel>
      )}
    </Container>
  );
}
