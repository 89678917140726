import React from "react";
import {
  createContext,
  useContext,
  useState,
  useEffect,
  useCallback,
} from "react";
import axios from "axios";
import type { AuthRequest, AuthResponse } from "@oaktyres/model";

export type LoginPayload = {
  accountCodeOrEmail: string;
  password: string;
};

export type UserContextType = {
  user: AuthResponse | null;
  login: (loginPayload: AuthRequest) => Promise<boolean>;
  logoutToAccount: (code: string) => Promise<void>;
  logout: () => Promise<void>;
  resetPassword: (code: string, password: string) => Promise<boolean>;
};

export const CookieUserContext = createContext<UserContextType>(null!);

export const CookieUserProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [loading, setLoading] = useState<boolean>(true);
  const [user, setUser] = useState<AuthResponse | null>(null);

  useEffect(() => {
    axios
      .get("/api/v2/auth/me")
      .then(({ data }) => {
        setUser(data);
      })
      .catch(() => {
        setUser(null);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    const i = axios.interceptors.response.use(
      function (response) {
        return response;
      },
      function (error) {
        if (error.response?.status === 401) {
          setUser(null);
        }
        return Promise.reject(error);
      }
    );

    return () => {
      axios.interceptors.response.eject(i);
    };
  }, []);

  const login = useCallback(async (login: AuthRequest): Promise<boolean> => {
    try {
      const { data } = await axios.post("/api/v2/auth/token", login);
      setUser(data);
      return true;
    } catch (e) {
      setUser(null);
      return false;
    }
  }, []);

  const logout = useCallback(async (): Promise<void> => {
    await axios.post("/api/v2/auth/logout");
    setUser(null);
  }, []);

  const logoutToAccount = useCallback(async (accountCode: string) => {
    const { data } = await axios.post("/api/v2/auth/account-token", {
      accountCode,
    });
    setUser(data);
  }, []);

  const resetPassword = useCallback(
    async (resetCode: string, newPassword: string): Promise<boolean> => {
      try {
        const { data } = await axios.post("/api/v2/auth/reset-password", {
          resetCode,
          newPassword,
        });
        setUser(data);
        return true;
      } catch {
        return false;
      }
    },
    []
  );

  const value = {
    user,
    login,
    logout,
    logoutToAccount,
    resetPassword,
  };

  return (
    <CookieUserContext.Provider value={value}>
      {loading ? null : children}
    </CookieUserContext.Provider>
  );
};

export const useAuth = () => useContext(CookieUserContext);
