import React from "react";
import styled, { keyframes, css } from "styled-components";

const skChase = keyframes`
  100% { transform: rotate(360deg); } 
`;

const skChaseDot = keyframes`
  80%, 100% { transform: rotate(360deg); } 
`;

const skChaseDotBefore = keyframes`
  50% {
    transform: scale(0.4); 
  } 100%, 0% {
    transform: scale(1.0); 
  } 
`;

type WrapperProps = {
  overlay?: boolean;
};

type ContainerProps = {
  small?: boolean;
  tiny?: boolean;
};

const Wrapper = styled.div<WrapperProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;

  ${(props) =>
    props.overlay &&
    css`
      position: absolute;
      background: rgba(255, 255, 255, 0.5);
      z-index: 10000;
      top: 0;
      left: 0;
    `}
`;

const Container = styled.div<ContainerProps>`
  width: ${(props) => (props.tiny ? 20 : props.small ? 40 : 80)}px;
  height: ${(props) => (props.tiny ? 20 : props.small ? 40 : 80)}px;
  margin: ${(props) => (props.tiny ? 10 : props.small ? 20 : 40)}px auto;
  position: relative;
  animation: ${skChase} 2.5s infinite linear both;
`;

const Dot = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  animation: ${skChaseDot} 2s infinite ease-in-out both;

  &:before {
    content: "";
    display: block;
    width: 25%;
    height: 25%;
    background-color: ${(props) => props.theme.colors.primary};
    border-radius: 100%;
    animation: ${skChaseDotBefore} 2s infinite ease-in-out both;
  }
  &:nth-child(1) {
    animation-delay: -1.1s;
  }
  &:nth-child(2) {
    animation-delay: -1s;
  }
  &:nth-child(3) {
    animation-delay: -0.9s;
  }
  &:nth-child(4) {
    animation-delay: -0.8s;
  }
  &:nth-child(5) {
    animation-delay: -0.7s;
  }
  &:nth-child(6) {
    animation-delay: -0.6s;
  }
  &:nth-child(1):before {
    animation-delay: -1.1s;
  }
  &:nth-child(2):before {
    animation-delay: -1s;
  }
  &:nth-child(3):before {
    animation-delay: -0.9s;
  }
  &:nth-child(4):before {
    animation-delay: -0.8s;
  }
  &:nth-child(5):before {
    animation-delay: -0.7s;
  }
  &:nth-child(6):before {
    animation-delay: -0.6s;
  }
`;

type LoaderProps = ContainerProps & WrapperProps;

const Loader = ({ overlay, small, tiny }: LoaderProps) => (
  <Wrapper overlay={overlay}>
    <Container small={small} tiny={tiny}>
      <Dot />
      <Dot />
      <Dot />
      <Dot />
      <Dot />
      <Dot />
    </Container>
  </Wrapper>
);

export default Loader;
