export enum PersonTitle {
  Unknown = 0,
  Mr = 1,
  Mrs = 2,
  Miss = 3,
}

export interface DbCallReportAttendee {
  CallReportAttendeeID?: number;
  Title: PersonTitle;
  Name: string;
  Surname: string;
  IsDepartment: boolean;
  Email: string;
  DirectPhone: string;
  MobileNumber: string;
  FaxNumber: string;
  JobTitle: string;
  IsBusinessOwner: boolean;
  MakesPurchasingDecisions: boolean;
  AccountID: number;
  AccountApplicationID: number;
  DateLastModified: Date;
  LastModifiedByID: number | null;
  IsDeleted: boolean;
  IsPrimary: boolean;
}
