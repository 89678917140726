export enum ExclusionType {
  Section,
  Profile,
  Rim,
  Speed,
  Load,
  Brand,
  Season,
}

export type ExclusionPayload = {
  type: ExclusionType;
  key: string;
  excludeFromSearch: boolean;
  allowInNonStock: boolean;
};

export type ExclusionDto = ExclusionPayload;
