import React, { useRef, useState } from "react";
import styled from "styled-components";
import { FaQuestionCircle } from "react-icons/fa";
import Panel from "./Panel";
import usePortal from "react-useportal";
import { space, SpaceProps } from "styled-system";
import Text from "./Text";
import { Flex } from "./Flex";
import { useSpring, animated } from "react-spring";

const Container = styled.div<SpaceProps>`
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  top: -2px;
  ${space};
`;

const PopOver = styled(animated(Panel))`
  position: fixed;
  max-width: 200px;
  z-index: 3000;
`;

type HelpPopoverProps = SpaceProps &
  React.HTMLAttributes<HTMLDivElement> & {
    content: string;
  };

const HelpPopover = ({ content, ...rest }: HelpPopoverProps) => {
  const { openPortal, closePortal, isOpen, Portal } = usePortal();
  const [pos, setPos] = useState({ top: 0, left: 0 });

  const iconRef = useRef<HTMLDivElement>();

  const doPosition = () => {
    const node = iconRef.current;
    if (node != null) {
      const rect = node.getBoundingClientRect();
      const desiredLeft = rect.left + 32;
      const maxLeft = window.innerWidth - 232;
      // Shift down if its pushed over
      setPos({
        top: rect.top + (desiredLeft >= maxLeft ? 16 : 0),
        left: Math.min(maxLeft, desiredLeft),
      });
    }
  };

  const handleHover = (e: any) => {
    doPosition();
    openPortal(e);
  };

  const props = useSpring({
    opacity: isOpen ? 1 : 0,
    transform: isOpen ? "translate3d(0,0,0)" : "translate3d(0, 20px, 0)",
  });

  return (
    <Container {...rest} ref={iconRef as any}>
      <FaQuestionCircle onMouseEnter={handleHover} onMouseLeave={closePortal} />
      {isOpen && (
        <Portal>
          <PopOver
            p={2}
            style={{
              ...props,
              ...pos,
            }}
          >
            <Flex alignItems="center" mb="3px">
              <FaQuestionCircle />
              <Text ml={1} fontWeight={600}>
                What's this?
              </Text>
            </Flex>
            <Text fontSize={1}>{content}</Text>
          </PopOver>
        </Portal>
      )}
    </Container>
  );
};

export default HelpPopover;
