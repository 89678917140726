import { TyreSearchPayload } from "@oaktyres/model";

const wallFlags: (keyof TyreSearchPayload)[] = [
  "bsw",
  "owl",
  "rwl",
  "wsw",
  "rbl",
  "ww",
];

export const formatFullFitment = (item: TyreSearchPayload): string =>
  [
    `${item.sizePrefix || ""}${item.section}/${item.profile}`,
    `${item.construction || ""}${item.rim}${item.commercialSuffix || ""}`,
    `${item.load}${item.speed}`,
    ...item.oes.map((x) => x.mark),
    item.runFlat ? item.runFlatTech || "RUN FLAT" : null,
    item.noiseCancellingTech,
    item.selfSealingTech,
    ...wallFlags.map((x) => (item[x] ? x.toUpperCase() : null)),
  ]
    .filter(Boolean)
    .join(" ");
