import circlesSrc from "../img/circles.svg";
import circleSrc from "../img/circle.svg";
import styled from "styled-components";

export const BlueBackground = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 18px;
  background-image: url("${circlesSrc}"), url("${circleSrc}"),
    linear-gradient(115deg, #00183c, #003c7b);
  background-repeat: no-repeat, repeat, no-repeat;
  background-size:
    auto 1000px,
    24px 24px,
    100% 100%;
  background-position:
    -200px -200px,
    0 0,
    0 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;
